import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OnboardingAdminDocument } from 'src/app/onboarding-new/models/document.model';
import {
  EncryptedDataModel,
  EncryptionService,
} from 'src/app/shared/services/encryption.service';
import { environment } from 'src/environments/environment';
import { OnboardingAdminData } from './models/onboarding-admin-data.model';
import { OnboardingFieldId } from './models/onboarding-field-id.enum';
import { OnboardingUserItem } from './models/onboarding-user-item.model';
import { OnboardingUserSearch } from './models/onboarding-user-search.model';
import { Page } from './models/page.model';
import { UserNote } from './models/user-note.model';
import { RiskReports } from 'src/app/shared/models/risk-reports.model';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  baseUrl = `${environment.BACKEND_URL}/admin-onboarding/users`;

  constructor(
    private http: HttpClient,
    private encryptionService: EncryptionService
  ) {}

  getOngoingRegistrations(
    data: OnboardingUserSearch
  ): Observable<Page<OnboardingUserItem>> {
    const url = this.baseUrl;
    const params = this.setHttpParams(data);
    return this.http.get<Page<OnboardingUserItem>>(url, { params });
  }

  getOngoingRegistrationsCsv(data: OnboardingUserSearch): Observable<string> {
    const url = `${this.baseUrl}.csv`;
    const params = this.setHttpParams(data);
    return this.http.get(url, { params, responseType: 'text' });
  }

  setHttpParams(data: any) {
    let params = new HttpParams();
    if (data.page) {
      params = params.set('page', data.page);
    }
    if (data.limit) {
      params = params.set('limit', data.limit);
    }
    if (data.orderField) {
      params = params.set('orderField', data.orderField);
    }
    if (data.orderDescending) {
      params = params.set('orderDescending', data.orderDescending);
    }
    if (data.searchText) {
      params = params.set('searchText', data.searchText);
    }
    if (data.dateFrom) {
      params = params.set('dateFrom', data.dateFrom);
    }
    if (data.dateTo) {
      params = params.set('dateTo', data.dateTo);
    }
    if (data.userType) {
      params = params.set('userType', data.userType);
    }
    if (data.withNotes) {
      params = params.set('withNotes', data.withNotes);
    }
    if (data.selectedUserState) {
      params = params.set('selectedUserState', data.selectedUserState);
    }
    if (data.overdue) {
      params = params.set('overdue', data.overdue);
    }
    return params;
  }

  async getUserData(userId: number): Promise<Observable<OnboardingAdminData>> {
    const url = `${this.baseUrl}/userId`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(userId);
    return this.http.post<OnboardingAdminData>(url, cipherModel);
  }

  async changeCddSuggestionStatus(userId: number) {
    const url = `${this.baseUrl}/userId/kyc-cdd-suggestion`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(userId);
    return this.http.put<void>(url, cipherModel);
  }

  async changeCddKeyPersonSuggestionStatus(userId: number) {
    const url = `${this.baseUrl}/userId/kyc-cdd-key-person-suggestion`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(userId);
    return this.http.put<void>(url, cipherModel);
  }

  async changeCddBusinessSuggestionStatus(userId: number) {
    const url = `${this.baseUrl}/userId/kyc-cdd-business-suggestion`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(userId);
    return this.http.put<void>(url, cipherModel);
  }

  async changeEddSuggestionStatus(userId: number) {
    const url = `${this.baseUrl}/userId/kyc-edd-suggestion`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(userId);
    return this.http.put<void>(url, cipherModel);
  }

  async changeAdjustmentSuggestionStatus(userId: number) {
    const url = `${this.baseUrl}/userId/kyc-adjustment-suggestion`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(userId);
    return this.http.put<void>(url, cipherModel);
  }

  async markUserAsDisplayed(userId: number): Promise<Observable<void>> {
    const url = `${this.baseUrl}/userId/was-displayed`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(userId);
    return this.http.put<void>(url, cipherModel);
  }

  changeDocumentStatus(
    userId: number,
    documentId: number,
    accepted: boolean
  ): Observable<OnboardingAdminDocument> {
    const url = `${this.baseUrl}/${userId}/documents/${documentId}/accepted`;
    return this.http.put<OnboardingAdminDocument>(url, { accepted });
  }

  changeDirectorDocumentStatus(
    userId: number,
    companyDirectorId: number,
    documentId: number,
    accepted: boolean
  ): Observable<OnboardingAdminDocument> {
    const url = `${this.baseUrl}/${userId}/company-directors/${companyDirectorId}/documents/${documentId}/accepted`;
    return this.http.put<OnboardingAdminDocument>(url, { accepted });
  }

  addDocumentComment(
    userId: number,
    documentId: number,
    content: string
  ): Observable<OnboardingAdminDocument> {
    const url = `${this.baseUrl}/${userId}/documents/${documentId}/comment`;
    return this.http.put<OnboardingAdminDocument>(url, { content });
  }

  addDirectorDocumentComment(
    userId: number,
    documentId: number,
    companyDirectorId: number,
    content: string
  ): Observable<OnboardingAdminDocument> {
    const url = `${this.baseUrl}/${userId}/company-directors/${companyDirectorId}/documents/${documentId}/comment`;
    return this.http.put<OnboardingAdminDocument>(url, { content });
  }

  addOrModifyFormCommment(
    userId: number,
    fieldId: OnboardingFieldId,
    content: string,
    id?: number,
    closeLinkId?: number
  ): Observable<void> {
    const url = `${this.baseUrl}/${userId}/fields/${fieldId}/comment`;
    return this.http.put<void>(url, { content, id, closeLinkId });
  }

  removeFormComment(
    userId: number,
    fieldId: OnboardingFieldId,
    id?: number,
    closeLinkId?: number
  ): Observable<void> {
    const url = `${this.baseUrl}/${userId}/fields/${fieldId}/comment`;
    return this.http.post<void>(url, { content: '', id, closeLinkId });
  }

  resendKeyPersonVerificationUrl(
    userId: number,
    directorId?: number,
    directorNewEmail?: string
  ): Observable<void> {
    const url = `${this.baseUrl}/${userId}/resend-key-person-verification-url`;
    return this.http.post<void>(url, { directorId, directorNewEmail });
  }

  acceptIndeterminates(userId: number): Observable<void> {
    const url = `${this.baseUrl}/${userId}/kyc-accept-unrejected-documents`;
    return this.http.post<void>(url, null);
  }

  verifyUser(userId: number): Observable<{ returnedToEdd: boolean }> {
    const url = `${this.baseUrl}/${userId}/kyc-accept-applicant`;
    return this.http.post<{ returnedToEdd: boolean }>(url, null);
  }

  approveApplicantAtDecisionPoint(userId: number): Observable<void> {
    const url = `${this.baseUrl}/${userId}/kyc-decision-point-approve`;
    return this.http.post<void>(url, null);
  }

  retriggerStakeholderScoring(userId: number): Observable<void> {
    const url = `${this.baseUrl}/${userId}/retrigger-stakeholder-scoring`;
    return this.http.post<void>(url, null);
  }

  rejectApplicant(userId: number, reason: number): Observable<void> {
    const url = `${this.baseUrl}/${userId}/kyc-reject-applicant`;
    return this.http.post<void>(url, { rejectionReason: reason });
  }

  returnApplication(userId: number): Observable<void> {
    const url = `${this.baseUrl}/${userId}/kyc-return-application`;
    return this.http.post<void>(url, null);
  }

  addRiskScoreProposal(
    userId: number,
    isInOnboarding: boolean
  ): Observable<RiskReports> {
    const url = `${this.baseUrl}/${userId}/kyc-add-risk-score-proposal`;
    return this.http.post<RiskReports>(url, { isInOnboarding: isInOnboarding });
  }

  saveRiskScoreProposal(
    userId: number,
    body: RiskReports
  ): Observable<RiskReports> {
    const url = `${this.baseUrl}/${userId}/kyc-save-risk-score-proposal`;
    return this.http.post<RiskReports>(url, body);
  }

  removeProposal(userId: number): Observable<RiskReports> {
    const url = `${this.baseUrl}/${userId}/kyc-remove-risk-score-proposal`;
    return this.http.post<RiskReports>(url, null);
  }

  acceptProposal(userId: number): Observable<RiskReports> {
    const url = `${this.baseUrl}/${userId}/kyc-accept-risk-score-proposal`;
    return this.http.post<RiskReports>(url, null);
  }

  rejectProposal(userId: number): Observable<RiskReports> {
    const url = `${this.baseUrl}/${userId}/kyc-reject-risk-score-proposal`;
    return this.http.post<RiskReports>(url, null);
  }

  getNotes(userId: number): Observable<UserNote[]> {
    const url = `${this.baseUrl}/${userId}/notes`;
    return this.http.get<UserNote[]>(url);
  }

  saveNote(userId: number, content: string): Observable<UserNote> {
    const url = `${this.baseUrl}/${userId}/notes`;
    return this.http.post<UserNote>(url, { content });
  }
}
