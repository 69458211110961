import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'amount',
})
export class AmountPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(
    value: number | string | undefined | null,
    digitsInfo: string = '1.2-2'
  ): any {
    if (value == null) return null;
    return this.decimalPipe.transform(value, digitsInfo, 'en-US');
  }
}
