<div fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div>
  <div fxLayout="column">
    <h1>AML Hits</h1>
  </div>
  <div class="spinner-container" *ngIf="isLoadingAmlHits">
    <app-big-loader></app-big-loader>
  </div>
  <mat-dialog-content>
    <mat-table [dataSource]="dataSourceHits" *ngIf="dataSourceHits">
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef><b>Id</b></mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.id }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef><b>Full Name</b></mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.name }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="entityType">
        <mat-header-cell *matHeaderCellDef><b>Entity Type</b></mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.entityType }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="score">
        <mat-header-cell *matHeaderCellDef><b>Score</b></mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.score }}/2</mat-cell>
      </ng-container>

      <ng-container matColumnDef="matchTypes">
        <mat-header-cell *matHeaderCellDef><b>Relevance</b></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div *ngFor="let entry of row.matchTypes">
            {{ entry | replaceUnderscore | titlecase }}
            {{ getRelevanceDescription(entry) }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="types">
        <mat-header-cell *matHeaderCellDef><b>Matched</b></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div *ngFor="let entry of row.types">
            {{ entry | titlecase }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="details">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button class="aml" mat-button (click)="getAmlHit(row.id)">
            View details
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let col; columns: displayedColumns; let entry"
      ></mat-row>
    </mat-table>
  </mat-dialog-content>
</div>
