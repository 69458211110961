<ng-container *ngIf="isReviewed">
  <div
    fxLayout="row"
    fxLayoutGap="20px grid"
    class="submitted"
    [class.comment]="field?.comment"
  >
    <div fxFlex="50" class="name">{{ label }}</div>
    <div fxFlex="50" class="value">{{ field?.commentedValue || value }}</div>
  </div>
  <div *ngIf="field?.comment" class="comment">
    Comment: {{ field?.comment }}
  </div>
</ng-container>

<ng-container *ngIf="!isReviewed || (isReviewed && field)">
  <ng-content></ng-content>
</ng-container>
