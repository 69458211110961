<div class="buttons-container" fxLayoutAlign="start center" fxLayoutGap="20px">
  <!-- <button
    mat-flat-button
    color="accent"
    class="g-small"
    data-testid="ongoing-registration-tab"
    routerLink="ongoing-registration"
    routerLinkActive="active"
  >
    Ongoing registration
  </button> -->
  <button
    mat-flat-button
    color="accent"
    class="g-small"
    data-testid="ongoing-registration-tab"
    routerLink="ongoing-registration-new"
    routerLinkActive="active"
  >
    Ongoing registration
  </button>
  <button
    mat-flat-button
    color="accent"
    class="g-small"
    data-testid="registered-users-tab"
    routerLink="registered-users"
    routerLinkActive="active"
  >
    Registered users
  </button>
</div>
<router-outlet></router-outlet>
