<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <h1>Something went wrong</h1>
  <ng-container [ngSwitch]="!!data.message">
    <div *ngSwitchCase="true">{{ data.message }}</div>
    <div *ngSwitchCase="false">
      Apologies, there was a problem with your request.<br /><br />
      Try to reload the page. If the problem persists, please
      <a href="mailto:support@ecredo.com">Report the problem</a>.
    </div>
  </ng-container>

  <div [ngSwitch]="!!data.message" class="buttons" fxLayoutAlign="end center">
    <button
      *ngSwitchCase="true"
      mat-flat-button
      mat-dialog-close
      color="accent"
    >
      Close
    </button>
    <button
      *ngSwitchCase="false"
      mat-flat-button
      color="accent"
      (click)="reloadPage()"
    >
      Reload the page
    </button>
  </div>
</mat-dialog-content>
