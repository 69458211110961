import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { TransferService } from '../../transfer.service';
import { FavouriteTransfer } from '../models/favourite-transfer.model';
import { TransferType } from '../enum/transfer-type.enum';
import { extractTermsFromFavourite } from 'src/app/shared/helpers/various-helpers.helper';

@Component({
  selector: 'app-select-favourite-overlay',
  templateUrl: './select-favourite-overlay.component.html',
  styleUrls: ['./select-favourite-overlay.component.scss'],
})
export class SelectFavouriteOverlayComponent implements OnInit {
  @Input() isE2e!: boolean;
  @Input() accountOwnerGuid!: string;
  @Output() selectFav = new EventEmitter<FavouriteTransfer>();

  TransferType = TransferType;
  isLoading: boolean = false;
  favourites: FavouriteTransfer[] = [];

  searchControl: FormControl = this.fb.control(null);
  searchSub?: Subscription;
  searchTerm: string = '';

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private transferService: TransferService
  ) {}

  ngOnInit(): void {
    this.searchSub = this.searchControl.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        startWith(this.searchTerm)
      )
      .subscribe(async (searchTerm) => {
        this.isLoading = true;
        this.searchTerm = searchTerm;
        (
          await this.transferService.getFavouriteTransfers(
            this.accountOwnerGuid
          )
        ).subscribe(
          (favourites: FavouriteTransfer[]) => {
            this.favourites = favourites.filter(
              (favouriteTransfer: FavouriteTransfer) =>
                (this.isE2e
                  ? favouriteTransfer.transferType === TransferType.E2E
                  : favouriteTransfer.transferType === TransferType.SEPA ||
                    favouriteTransfer.transferType === TransferType.SWIFT) &&
                extractTermsFromFavourite(favouriteTransfer)
                  .toLowerCase()
                  .includes(this.searchTerm.toLowerCase())
            );
            this.isLoading = false;
          },
          () => {
            this.isLoading = false;
            this.errorService.showErrorDialog();
          }
        );
      });
  }

  selectFavourite(favourite: FavouriteTransfer) {
    this.selectFav.emit(favourite);
  }
}
