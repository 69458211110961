<div class="main-container">
  <div
    fxLayout
    fxLayoutGap="30px"
    fxLayoutAlign="start center"
    class="filters-container"
    [formGroup]="filtersGroup"
  >
    <div fxLayout="column">
      <label for="search">Search</label>
      <mat-form-field appearance="outline">
        <input matInput formControlName="search" name="search" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div fxLayout="column">
      <label>Date range</label>
      <mat-form-field appearance="outline">
        <mat-date-range-input [rangePicker]="picker">
          <input
            matStartDate
            placeholder="Start date"
            formControlName="startDate"
          />
          <input matEndDate placeholder="End date" formControlName="endDate" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div class="username">Username: {{ email }}</div>
  </div>
  <div class="table-container">
    <div class="spinner-container" *ngIf="dataSource.isLoadingObs | async">
      <app-big-loader></app-big-loader>
    </div>

    <table
      mat-table
      [dataSource]="dataSource"
      [fixedLayout]="true"
      matSort
      matSortActive="dateCreated"
      matSortDirection="desc"
      matSortDisableClear
    >
      <!-- Created Updated Column -->
      <ng-container matColumnDef="dateCreated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
        <td mat-cell *matCellDef="let row">
          {{ row.dateCreated | date: 'short' }}
        </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
        <td mat-cell *matCellDef="let row">{{ row.action }}</td>
      </ng-container>

      <!-- Message Column -->
      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Message</th>
        <td mat-cell *matCellDef="let row" (click)="showData(row, false)">
          {{ row.message }}
        </td>
      </ng-container>

      <!-- Response Column-->
      <ng-container matColumnDef="response">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Response</th>
        <td mat-cell *matCellDef="let row" (click)="showData(row, true)">
          {{ row.response }}
        </td>
      </ng-container>

      <!-- UserName IP address Column -->
      <ng-container matColumnDef="ipAddress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>IP address</th>
        <td mat-cell *matCellDef="let row">{{ row.ipAddress }}</td>
      </ng-container>

      <!-- Message UUID Transaction ID Column -->
      <ng-container matColumnDef="uuidId">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header="messageUUID">Message UUID</div>
          <div mat-sort-header="transactionId">Transaction ID</div>
        </th>
        <td mat-cell *matCellDef="let row">
          <div>{{ row.messageUUID }}</div>
          <div>{{ row.transactionId }}</div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [length]="dataSource.getLength() | async"
      [pageSize]="20"
      [pageSizeOptions]="[20, 50, 100]"
    ></mat-paginator>
  </div>
</div>
