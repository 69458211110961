import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first, switchMap, tap } from 'rxjs/operators';
import { Program } from 'src/app/login/models/program.enum';
import { UserType } from 'src/app/login/models/user-type.enum';
import { convertUserState } from 'src/app/shared/helpers/various-helpers.helper';
import { OnboardingUserItem } from '../../models/onboarding-user-item.model';
import { UsersService } from '../../users.service';

@Component({
  selector: 'app-ongoing-registration-pdf-new',
  templateUrl: './ongoing-registration-pdf.component.html',
  styleUrls: ['./ongoing-registration-pdf.component.scss'],
})
export class OngoingRegistrationPdfNewComponent implements OnInit {
  data: OnboardingUserItem[] = [];
  displayedColumns: string[] = [
    'isPendingForLong',
    'names',
    'program',
    'state',
    'dateCreated',
    'contact',
    'companyName',
    'countryName',
  ];
  dateFrom?: string;
  dateTo?: string;
  searchText?: string;
  userType?: string;
  orderField: string = '';
  sortDirection: 'asc' | 'desc' = 'asc';
  now: Date = new Date();
  userStateName?: string;

  programs = Program;
  convertUserState = convertUserState;

  constructor(
    private route: ActivatedRoute,
    private usersService: UsersService
  ) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        first(),
        tap((params) => {
          this.dateFrom = params.dateFrom;
          this.dateTo = params.dateTo;
          this.searchText = params.searchText;
          this.orderField = params.orderField || '';
          
          // set user state in pdf
          this.userStateName  = params.userStateName;

          // sets sortDirection
          if (params.orderDescending) {
            this.sortDirection =
              params.orderDescending === 'true' ? 'desc' : 'asc';
          }

          // sets userType string
          switch (params.userType ? parseInt(params.userType) : null) {
            case UserType.PERSONAL:
              this.userType = 'individual';
              break;
            case UserType.CORPORATE:
              this.userType = 'business';
              break;
            default:
              break;
          }
        }),
        switchMap((params) =>
          this.usersService.getOngoingRegistrations({
            ...params,
            withNotes: true,
          })
        )
      )
      .subscribe((res) => (this.data = res.items));
  }
}
