import { Component, Inject, OnInit } from '@angular/core';
import { Card } from '../../../dashboard/models/card.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-deactivated-cards-dialog',
  templateUrl: './deactivated-cards-dialog.component.html',
  styleUrls: ['./deactivated-cards-dialog.component.scss'],
})
export class DeactivatedCardsDialogComponent implements OnInit {
  cards?: Card[];

  $breakpointObserver?: Observable<boolean>;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<DeactivatedCardsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: Card[]
  ) {
    this.cards = data;
  }

  ngOnInit() {
    this.$breakpointObserver = this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .pipe(
        map(
          (state) =>
            state.breakpoints[Breakpoints.XSmall] ||
            state.breakpoints[Breakpoints.Small]
        )
      );
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
