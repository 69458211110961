export enum RiskReportState {
  ONBOARDING_ORIGINAL = 1,
  ONBOARDING_PROPOSED = 2,
  ONBOARDING_APPROVED = 3,
  APPLICATION_VERIFIED_ORIGINAL = 4,
  APPLICATION_VERIFIED_PROPOSED = 5,
  APPLICATION_VERIFIED_APPROVED = 6,
  ONBOARDING_REJECTED = 7,
  APPLICATION_VERIFIED_REJECTED = 8,
}
