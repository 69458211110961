import { Component, Input } from '@angular/core';

export interface Step {
  name: string;
  completed: boolean;
  substeps: string[];
}

@Component({
  selector: 'app-stepper-new',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperNewComponent {
  @Input() steps: Step[] = [];
}
