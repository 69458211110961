<div
  fxLayout="column"
  class="main"
  ngClass.gt-sm="medium"
  ngClass.gt-md="large"
>
  <app-header></app-header>
  <div class="content" cdk-scrollable>
    <router-outlet></router-outlet>
  </div>
</div>
