<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div fxLayout="row" *ngIf="userType === userTypes.PERSONAL">
    <div>
      <app-account-header [program]="programs.JADE"></app-account-header>
      <div>
        <div fxLayout="column" fxLayoutAlign="start center">
          <div class="price">Free</div>
          <div class="user-account">Your current Account</div>
        </div>
        <ul>
          <li>
            Loading on card:<br />
            <strong>3,000&euro;/month, 36,000&euro;/year</strong>
          </li>
          <li>
            Loading on IBAN:<br />
            <strong>6,000&euro;/month, 72,000&euro;/year</strong>
          </li>
          <li><strong>Free EU IBAN</strong> (EUR)</li>
          <li>
            <strong>eCREDO MasterCard</strong><br />
            10€ fee including delivery within EU
          </li>
          <li class="up-to">
            <strong>SEPA Payments</strong><br />
            Transfers in EUR within SEPA region
          </li>
          <li>
            <strong>International Payments (SWIFT)</strong><br />
            Transfers in major currencies worldwide
          </li>
          <li>
            <strong>e2e Now</strong><br />
            Instant transfer of funds between eCREDO users
          </li>
          <li>
            <strong>Currency Conversion Exchange</strong><br />
            Convert between 10 major currencies
          </li>
          <li>
            <strong>Low fees ATM Cash Withdrawals</strong><br />
            Up to 900&euro;/month
          </li>
          <li>
            <strong>In-store and online Payments</strong><br />
            Including MasterCard 3D secure
          </li>
        </ul>
      </div>
    </div>

    <div class="vertical-line"></div>

    <div>
      <app-account-header [program]="programs.EMERALD"></app-account-header>
      <div>
        <div fxLayout="column" fxLayoutAlign="start center">
          <div class="price">9.95&euro;/month</div>
          <button
            mat-stroked-button
            color="accent"
            class="g-small"
            (click)="upgradeAccount()"
          >
            Upgrade to Emerald
          </button>
        </div>
        <ul>
          <li>
            Loading on card:<br />
            <strong>6,000&euro;/month, 72,000&euro;/year</strong>
          </li>
          <li>
            Loading on IBAN:<br />
            <strong>50,000&euro;/month, 200,000&euro;/year</strong>
          </li>
          <li><strong>Free EU IBAN</strong> (EUR)</li>
          <li>
            <strong>eCREDO MasterCard</strong><br />
            5€ fee including delivery within EU
          </li>
          <li>Up to <strong>3 additional cards</strong></li>
          <li class="up-to">
            <strong>SEPA Payments</strong><br />
            Transfers in EUR within SEPA region
          </li>
          <li>
            <strong>International Payments (SWIFT)</strong><br />
            Transfers in major currencies worldwide
          </li>
          <li>
            <strong>e2e Now</strong><br />
            Instant transfer of funds between eCREDO users
          </li>
          <li>
            <strong>Currency Conversion Exchange</strong><br />
            Convert between 10 major currencies
          </li>
          <li>
            <strong>Low fees ATM Cash Withdrawals</strong><br />
            Up to 900&euro;/month
          </li>
          <li>
            <strong>In-store and online Payments</strong><br />
            Including MasterCard 3D secure
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div fxLayout="row" *ngIf="userType === userTypes.CORPORATE">
    <div>
      <app-account-header [program]="programs.ONYX"></app-account-header>
      <div>
        <div fxLayout="column" fxLayoutAlign="start center">
          <div class="price">Free</div>
          <div class="user-account">Your current Account</div>
        </div>

        <ul>
          <li>
            Loading on card:<br />
            <strong>55,000&euro;/month, 660,000&euro;/year</strong>
          </li>
          <li>
            Loading on IBAN:<br />
            <strong>110,000&euro;/month, 1,320,000&euro;/year</strong>
          </li>
          <li class="up-to">Up to <strong>9 Corporate cards</strong></li>
          <li><strong>Free EU IBAN</strong> (EUR)</li>
          <li>
            <strong>Free eCREDO MasterCard</strong><br />
            Including delivery within EU
          </li>
          <li>
            <strong>Free SEPA Payments</strong><br />
            Transfers in EUR within SEPA region
          </li>
          <li>
            <strong>International Payments (SWIFT)</strong><br />
            Transfers in major currencies worldwide
          </li>
          <li>
            <strong>e2e Now</strong><br />
            Instant transfer of funds between eCREDO users
          </li>
          <li>
            <strong>Currency Conversion Exchange</strong><br />
            Convert between 10 major currencies
          </li>
          <li>
            <strong>No fee ATM Cash Withdrawals</strong><br />
            Up to 900&euro;/month
          </li>
          <li>
            <strong>In-store and online Payments</strong><br />
            Including MasterCard 3D secure
          </li>
        </ul>
      </div>
    </div>

    <div class="vertical-line"></div>

    <div>
      <app-account-header [program]="programs.RUBY"></app-account-header>
      <div>
        <div fxLayout="column" fxLayoutAlign="start center">
          <div class="price">9.95&euro;/month</div>
          <button
            mat-stroked-button
            color="accent"
            class="g-small"
            (click)="upgradeAccount()"
          >
            Upgrade to Ruby Account
          </button>
        </div>
        <ul>
          <li>
            Loading on card:<br />
            <strong>110,000&euro;/month, 1,320,000&euro;/year</strong>
          </li>
          <li>
            Loading on IBAN:<br />
            <strong>1,000,000&euro;/month, 3,000,000&euro;/year</strong>
          </li>
          <li class="up-to">Up to <strong>9 Corporate cards</strong></li>
          <li><strong>Free EU IBAN</strong> (EUR)</li>
          <li>
            <strong>Free eCREDO MasterCard</strong><br />
            Including delivery within EU
          </li>
          <li>
            <strong>Free SEPA Payments</strong><br />
            Transfers in EUR within SEPA region
          </li>
          <li>
            <strong>International Payments (SWIFT)</strong><br />
            Transfers in major currencies worldwide
          </li>
          <li>
            <strong>e2e Now</strong><br />
            Instant transfer of funds between eCREDO users
          </li>
          <li>
            <strong>Currency Conversion Exchange</strong><br />
            Convert between 10 major currencies
          </li>
          <li>
            <strong>No fee ATM Cash Withdrawals</strong><br />
            Up to 900&euro;/month
          </li>
          <li>
            <strong>In-store and online Payments</strong><br />
            Including MasterCard 3D secure
          </li>
        </ul>
      </div>
    </div>
  </div>
</mat-dialog-content>
