<div fxLayoutAlign="end">
  <button mat-icon-button [mat-dialog-close]="shouldRefresh">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="dialog-container">
  <div fxLayout="column">
    <h1 class="dialog-title">Report lost/stolen card</h1>

    <div class="dialog-description" fxLayout="column">
      <span>
        You are about to close the <strong>{{ this.card?.name }}</strong></span
      >
      <span>The card will be deactivated permanently.</span>
      <span>Do you want to continue?</span>
    </div>
  </div>
  <div class="buttons">
    <button
      mat-flat-button
      color="red"
      class="success-button red-button"
      [appButtonLoader]="isLoading"
      [disabled]="isLoading"
      (click)="reportCard()"
    >
      Yes, deactivate the card
    </button>
    <button
      mat-stroked-button
      color="accent"
      (click)="closeDialog()"
      [disabled]="isLoading"
    >
      No, keep the card
    </button>
  </div>
</div>
