import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DateTime } from 'luxon';
import { merge, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { UserLogSearch } from './models/user-log-search.model';
import {
  LogDialogComponent,
  LogDialogData,
} from './log-dialog/log-dialog.component';
import { RegisteredUserLogsDataSource } from './registered-user-logs.datasource';
import { DbLogsService } from './db-logs.service';
import { UserLogItem } from './models/user-log-item.model';

@Component({
  selector: 'app-registered-user-logs',
  templateUrl: './registered-user-logs.component.html',
  styleUrls: ['./registered-user-logs.component.scss'],
})
export class RegisteredUserLogsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @Input() email!: string;
  @Input() userId!: number;

  dataSource!: RegisteredUserLogsDataSource;
  displayedColumns: string[] = [
    'dateCreated',
    'action',
    'message',
    'response',
    'ipAddress',
    'uuidId',
  ];

  filtersGroup: FormGroup = this.fb.group({
    search: null,
    startDate: null,
    endDate: null,
  });

  private formSub?: Subscription;
  private sortSub?: Subscription;
  private mergeSub?: Subscription;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private dbLogsService: DbLogsService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.dataSource = new RegisteredUserLogsDataSource(
      this.dbLogsService,
      this.errorService
    );
    const params: UserLogSearch = {
      userId: this.userId,
      limit: 20,
      orderField: 'dateCreated',
      orderDescending: true,
    };
    this.dataSource.loadLogs(params);
  }

  ngAfterViewInit(): void {
    // listens for filters values
    this.formSub = merge(
      this.searchControl.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged()
      ),
      this.startDateControl.valueChanges.pipe(distinctUntilChanged()),
      this.endDateControl.valueChanges.pipe(distinctUntilChanged())
    )
      .pipe(
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadLogsPage();
        })
      )
      .subscribe();

    // reset the paginator after sorting
    this.sortSub = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );

    this.mergeSub = merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadLogsPage()))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.formSub?.unsubscribe();
    this.sortSub?.unsubscribe();
    this.mergeSub?.unsubscribe();
  }

  loadLogsPage(): void {
    const params: UserLogSearch = {
      userId: this.userId,
      page: this.paginator.pageIndex + 1, // backend counts pages from 1 and mat-paginator from 0
      limit: this.paginator.pageSize,
      searchText: this.searchControl.value,
      dateFrom: this.startDateControl.value
        ? DateTime.fromISO(this.startDateControl.value).toFormat('yyyy-MM-dd')
        : '',
      dateTo: this.endDateControl.value
        ? DateTime.fromISO(this.endDateControl.value).toFormat('yyyy-MM-dd')
        : '',
      orderField: this.sort.active,
      orderDescending: this.sort.direction === 'desc',
    };
    this.dataSource.loadLogs(params);
  }

  showData(log: UserLogItem, isResponse: boolean): void {
    this.dialog.open<LogDialogComponent, LogDialogData>(LogDialogComponent, {
      panelClass: 'dialog-with-close-button',
      width: '720px',
      data: {
        isResponse,
        text: isResponse ? log.response : log.message,
      },
    });
  }

  get searchControl(): FormControl {
    return this.filtersGroup.get('search') as FormControl;
  }
  get startDateControl(): FormControl {
    return this.filtersGroup.get('startDate') as FormControl;
  }
  get endDateControl(): FormControl {
    return this.filtersGroup.get('endDate') as FormControl;
  }
}
