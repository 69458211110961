import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CompanyDirectorWithDocuments,
  SaveCompanyDirector,
} from './models/company-director.model';
import { CorporateForm } from './models/corporate-form.model';
import { AppDocument } from './models/document.model';
import { OnboardingData } from './models/onboarding-data.model';
import { PersonalForm } from './models/personal-form.model';
import {
  SaveCompanyCounterparty,
  CompanyCounterparty,
} from './models/company-counterparty.model';
import { CorporateDeclarationForm } from './models/corporate-declaration-form.model';
import {
  SaveCompanyAccount,
  CompanyAccount,
} from './models/company-account.model';
import {
  SaveCompanyCloseLink,
  CompanyCloseLink,
} from './models/company-close-link.model';
import { CompanyDirectorCloseLink } from './models/company-director-close-link.model';
import {
  CompanyGroupMember,
  SaveCompanyGroupMember,
} from './models/company-group-member.model';
import { PersonalDeclarationForm } from './models/personal-declaration-form.model';
import {
  EncryptedDataModel,
  EncryptionService,
} from '../shared/services/encryption.service';
import { KeyPersonVerificationData } from '../admin/users/ongoing-registration-new/ongoing-registration-user/risk-assessment/verification/models/key-person-verification-data.model';
import { KeyPersonsVerificationForm } from '../admin/users/ongoing-registration-new/ongoing-registration-user/risk-assessment/verification/models/key-person-verification-form.model';
import { VerificationResponse } from '../admin/users/ongoing-registration-new/ongoing-registration-user/risk-assessment/verification/models/verification-response.model';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  constructor(
    private http: HttpClient,
    private encryptionService: EncryptionService
  ) {}

  getOnboardingData(): Observable<OnboardingData> {
    const url = `${environment.BACKEND_URL}/onboarding`;
    return this.http.get<OnboardingData>(url);
  }

  savePersonalForm(personalForm: PersonalForm): Observable<void> {
    const url = `${environment.BACKEND_URL}/onboarding/personal-form`;
    return this.http.put<void>(url, personalForm);
  }

  getVerificationUrl(
    faceAndId: boolean,
    address: boolean,
    companyDirectorId?: number
  ): Observable<{
    verification_url: string;
    reference_id: string;
  }> {
    const url = `${environment.BACKEND_URL}/onboarding/verification-url`;
    return this.http.post<{ verification_url: string; reference_id: string }>(
      url,
      { faceAndId, address, companyDirectorId }
    );
  }

  async getVerificationResponse(
    body: VerificationResponse
  ): Promise<
    Observable<{ documents: AppDocument[]; companyDirectorId?: number }>
  > {
    const url = `${environment.BACKEND_URL}/onboarding/verification-response`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(body);
    return this.http.post<{
      documents: AppDocument[];
      companyDirectorId?: number;
    }>(url, cipherModel);
  }

  getKeyPersonVerificationForm(
    verificationToken: string
  ): Observable<KeyPersonsVerificationForm> {
    const url = `${environment.BACKEND_URL}/onboarding/key-person-verification-form`;
    return this.http.post<KeyPersonsVerificationForm>(url, {
      verificationToken,
    });
  }

  updateKeyPersonVerificationForm(
    body: KeyPersonVerificationData
  ): Observable<void> {
    const url = `${environment.BACKEND_URL}/onboarding/update-key-person-verification-form`;
    return this.http.post<void>(url, body);
  }

  getKeyPersonVerificationUrl(verificationToken: string): Observable<{
    verification_url: string;
    reference_id: string;
  }> {
    const url = `${environment.BACKEND_URL}/onboarding/key-person-verification-url`;
    return this.http.post<{ verification_url: string; reference_id: string }>(
      url,
      { verificationToken }
    );
  }

  async getKeyPersonVerificationResponse(
    body: VerificationResponse
  ): Promise<Observable<{ documents: AppDocument[] }>> {
    const url = `${environment.BACKEND_URL}/onboarding/key-person-verification-response`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(body);
    return this.http.post<{ documents: AppDocument[] }>(url, cipherModel);
  }

  savePersonalDeclarationForm(
    personalForm: PersonalDeclarationForm
  ): Observable<void> {
    const url = `${environment.BACKEND_URL}/onboarding/personal-declaration-form`;
    return this.http.put<void>(url, personalForm);
  }

  saveCorporateForm(corporateForm: CorporateForm): Observable<void> {
    const url = `${environment.BACKEND_URL}/onboarding/corporate-form`;
    return this.http.put<void>(url, corporateForm);
  }

  saveCorporateDeclarationForm(
    corporateForm: CorporateDeclarationForm
  ): Observable<void> {
    const url = `${environment.BACKEND_URL}/onboarding/corporate-declaration-form`;
    return this.http.put<void>(url, corporateForm);
  }

  uploadDocument(formData: FormData): Observable<AppDocument> {
    const url = `${environment.BACKEND_URL}/onboarding/documents`;
    return this.http.post<AppDocument>(url, formData);
  }

  updateDocument(
    documentId: number,
    anotherFileName: string,
    reasonWhyNotProvided: string,
    clientId?: number
  ): Observable<AppDocument> {
    const url = `${environment.BACKEND_URL}/onboarding/documents/${documentId}`;
    return this.http.patch<AppDocument>(url, {
      anotherFileName,
      reasonWhyNotProvided,
      clientId,
    });
  }

  clearDocument(
    documentId: number,
    clientId?: number
  ): Observable<AppDocument | null> {
    const url = `${environment.BACKEND_URL}/onboarding/documents/${documentId}/clear-requests`;
    return this.http.post<AppDocument | null>(url, {
      clientId,
    });
  }

  uploadDirectorDocument(
    directorId: number,
    formData: FormData
  ): Observable<AppDocument> {
    const url = `${environment.BACKEND_URL}/onboarding/company-directors/${directorId}/documents`;
    return this.http.post<AppDocument>(url, formData);
  }

  updateDirectorDocument(
    directorId: number,
    documentId: number,
    anotherFileName: string,
    reasonWhyNotProvided: string
  ): Observable<AppDocument> {
    const url = `${environment.BACKEND_URL}/onboarding/company-directors/${directorId}/documents/${documentId}`;
    return this.http.patch<AppDocument>(url, {
      anotherFileName,
      reasonWhyNotProvided,
    });
  }

  clearDirectorDocument(
    directorId: number,
    documentId: number
  ): Observable<AppDocument | null> {
    const url = `${environment.BACKEND_URL}/onboarding/company-directors/${directorId}/documents/${documentId}/clear-requests`;
    return this.http.post<AppDocument | null>(url, null);
  }

  createCompanyDirectorOrShareholder(
    body: SaveCompanyDirector
  ): Observable<CompanyDirectorWithDocuments> {
    const url = `${environment.BACKEND_URL}/onboarding/company-directors`;
    return this.http.post<CompanyDirectorWithDocuments>(url, body);
  }

  updateCompanyDirectorOrShareholder(
    id: number,
    body: SaveCompanyDirector
  ): Observable<CompanyDirectorWithDocuments> {
    const url = `${environment.BACKEND_URL}/onboarding/company-directors/${id}`;
    return this.http.patch<CompanyDirectorWithDocuments>(url, body);
  }

  createCompanyCloseLink(
    body: SaveCompanyCloseLink
  ): Observable<CompanyCloseLink> {
    const url = `${environment.BACKEND_URL}/onboarding/company-close-links`;
    return this.http.post<CompanyCloseLink>(url, body);
  }

  updateCompanyCloseLink(
    id: number,
    body: SaveCompanyCloseLink
  ): Observable<CompanyCloseLink> {
    const url = `${environment.BACKEND_URL}/onboarding/company-close-links/${id}`;
    return this.http.patch<CompanyCloseLink>(url, body);
  }

  updateCompanyDirectorCloseLink(
    id: number,
    body: CompanyDirectorCloseLink[]
  ): Observable<CompanyDirectorCloseLink[]> {
    const url = `${environment.BACKEND_URL}/onboarding/company-director-close-links/${id}`;
    return this.http.post<CompanyDirectorCloseLink[]>(url, body);
  }

  createCompanyCounterparty(
    body: SaveCompanyCounterparty
  ): Observable<CompanyCounterparty> {
    const url = `${environment.BACKEND_URL}/onboarding/company-counterparties`;
    return this.http.post<CompanyCounterparty>(url, body);
  }

  updateCompanyCounterparty(
    id: number,
    body: SaveCompanyCounterparty
  ): Observable<CompanyCounterparty> {
    const url = `${environment.BACKEND_URL}/onboarding/company-counterparties/${id}`;
    return this.http.patch<CompanyCounterparty>(url, body);
  }

  createCompanyGroupMember(
    body: SaveCompanyGroupMember
  ): Observable<CompanyGroupMember> {
    const url = `${environment.BACKEND_URL}/onboarding/company-group-members`;
    return this.http.post<CompanyGroupMember>(url, body);
  }

  updateCompanyGroupMember(
    id: number,
    body: SaveCompanyGroupMember
  ): Observable<CompanyGroupMember> {
    const url = `${environment.BACKEND_URL}/onboarding/company-group-members/${id}`;
    return this.http.patch<CompanyGroupMember>(url, body);
  }

  createCompanyAccount(body: SaveCompanyAccount): Observable<CompanyAccount> {
    const url = `${environment.BACKEND_URL}/onboarding/company-accounts`;
    return this.http.post<CompanyAccount>(url, body);
  }

  updateCompanyAccount(
    id: number,
    body: SaveCompanyAccount
  ): Observable<CompanyAccount> {
    const url = `${environment.BACKEND_URL}/onboarding/company-accounts/${id}`;
    return this.http.patch<CompanyAccount>(url, body);
  }

  submitApplication(): Observable<void> {
    const url = `${environment.BACKEND_URL}/onboarding/submit-requests`;
    return this.http.post<void>(url, null);
  }
}
