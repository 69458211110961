import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Program } from '../../../login/models/program.enum';
import { CardActivationDialogComponent } from '../card-activiation-dialog/card-activation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Card } from '../../models/card.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-section-card-header',
  templateUrl: './account-section-card-header.component.html',
  styleUrls: ['./account-section-card-header.component.scss'],
})
export class AccountSectionCardHeaderComponent implements OnInit {
  @Input() program?: Program;
  @Input() cards?: Card[];
  @Input() isLoadingCards?: boolean;
  @Input() isCardActive?: boolean;
  @Input() name?: string;
  @Input() availableBalance?: number;
  @Input() balance?: number;
  @Input() cardholder?: string;
  @Input() isLocked?: boolean;
  @Input() isIssued?: boolean;
  @Input() isChevron?: boolean = true;
  @Input() currentCard?: Card;
  @Output() readonly cardActivated = new EventEmitter<void>();
  @Output() readonly pickedCard = new EventEmitter<Card>();

  get notCurrentCards(): Card[] {
    return this.cards!.filter((card) => card !== this.currentCard);
  }

  open: boolean = false;
  isCardDropdown?: boolean;

  constructor(public dialog: MatDialog, public router: Router) {}

  ngOnInit(): void {
    this.isCardDropdown = this.program !== Program.JADE;
  }

  openCardsDropDown() {
    this.open = !this.open;
  }

  activateCard(card?: Card) {
    this.dialog.open(CardActivationDialogComponent, {
      width: '720px',
      height: '460px',
      panelClass: 'dialog-with-close-button',
      autoFocus: false,
      data: { card, onSuccess: () => this.onCardActivated() },
    });
  }

  onCardActivated(): void {
    this.cardActivated.emit();
    this.open = false;
  }

  onPickedCard(card: Card): void {
    this.pickedCard.emit(card);
    this.open = false;
  }

  showChevron(): boolean {
    if (this.cards) {
      if (this.cards.length === 0) return true;
      if (this.isCardActive) return true;
    }
    return false;
  }

  expiresSoon(): boolean {
    // show activate button on 20th of the previous month of expiration
    if (this.cards && this.cards[0]) {
      var strExpDate = this?.cards[0].expireDate || '';
      var year: number = +strExpDate.substring(0, 4);
      var month: number = +strExpDate.substring(5, 7);
      var days = +strExpDate.substring(8, 10);
      const cardExpiryDt = new Date(year, month - 1, days);
      const dtAppear = new Date(year, month - 2, 20);
      var dateNow = new Date(Date.now());

      // between 20th of previous month of expiration and end of expiration month
      if (dateNow >= dtAppear && dateNow <= cardExpiryDt) {
        return true;
      }
    }
    return false;
  }

  // Show activate button when card is expired or issued
  showActiveButton(): boolean {
    if (this.cards) {
      if (this.cards.length === 0) return false;
      if (this.isIssued || this.expiresSoon()) return true;
    }
    return false;
  }

  accentColor(): boolean {
    if (this.cards) {
      if (this.cards.length === 0) return false;
      if (this.isLocked) return true;
      if (this.isCardActive) return false;
    }
    return true;
  }
  redirectToCard() {
    this.router.navigateByUrl('sub-dashboard/cards');
  }
}
