import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';

export function transferAmountValidator(): ValidatorFn {
  return (formGroup: AbstractControl): null => {
    const otherFieldsGroup = formGroup.get('otherFields') as FormGroup;
    const amountControl = otherFieldsGroup.get('amount') as FormControl;
    const toAmountControl = otherFieldsGroup.get('toAmount') as FormControl;

    const amount = amountControl.value;
    const toAmount = toAmountControl?.value;

    // if amount is <= 0 then display error
    if (amount <= 0) {
      amountControl.setErrors({ ...amountControl?.errors, min: true });
    } else {
      delete amountControl.errors?.min;
    }
    if (toAmount <= 0) {
      toAmountControl.setErrors({ ...toAmountControl?.errors, min: true });
    } else {
      delete toAmountControl?.errors?.min;
    }

    if (!Object.keys(amountControl.errors || {}).length) {
      amountControl?.setErrors(null);
    }
    if (!Object.keys(toAmountControl?.errors || {}).length) {
      toAmountControl?.setErrors(null);
    }

    return null;
  };
}
