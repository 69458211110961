<div [formGroup]="documentsForm">
  <ng-container *ngIf="identityDocumentTypeControl.value">
    <div
      fxLayout="column"
      fxLayoutGap="25px grid"
      fxLayoutAlign="space-between"
    >
      <ng-container *ngFor="let control of documentsControls; let i = index">
        <app-file-input
          [companyDirectorId]="companyDirectorId"
          [class.hidden]="control.disabled"
          [formControl]="control"
          [disableFileRemove]="true"
          fxFlex="50"
          fxFlex.lt-sm="100"
        ></app-file-input>
      </ng-container>
    </div>
  </ng-container>
</div>
