<mat-card class="summary">
  <div fxLayoutAlign="space-between center">
    <div>
      <h2 class="header">Risk Assessment</h2>
    </div>
    <div *ngIf="!isInOnboarding">
      <button mat-icon-button (click)="reloadRiskAssessments()">
        <mat-icon color="accent">refresh</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider style="margin-bottom: 5px"></mat-divider>
  <div class="table-container">
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.id }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="dateCreated">
        <mat-header-cell *matHeaderCellDef>Date created</mat-header-cell>
        <mat-cell *matCellDef="let row">{{
          row.dateCreated | date: 'dd/MM/yyyy HH:mm'
        }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="riskReportStateId">
        <mat-header-cell *matHeaderCellDef>State </mat-header-cell>
        <mat-cell *matCellDef="let row">{{
          riskReportStateNamesMap.get(row.riskReportStateId)
        }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="foundInSanctionList">
        <mat-header-cell *matHeaderCellDef>Sanction list</mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          [class.found]="row.foundInSanctionList"
          >{{ getFoundValue(row.foundInSanctionList) }}</mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="pepScore">
        <mat-header-cell *matHeaderCellDef>PEP score</mat-header-cell>
        <mat-cell *matCellDef="let row" [class.found]="isHigh(row.pepScore)">{{
          row.pepScore
        }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="adverseMediaScore">
        <mat-header-cell *matHeaderCellDef>Adverse media score</mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          [class.found]="isHigh(row.adverseMediaScore)"
          >{{ row.adverseMediaScore }}</mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="criminalRecordScore">
        <mat-header-cell *matHeaderCellDef
          >Criminal record score</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          [class.found]="isHigh(row.criminalRecordScore)"
          >{{ row.criminalRecordScore }}</mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="dataOccupationProfessionScore">
        <mat-header-cell *matHeaderCellDef
          >Occupation status score</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          [class.found]="isHigh(row.dataOccupationProfessionScore)"
          >{{ row.dataOccupationProfessionScore }}</mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="dataOccupationScore">
        <mat-header-cell *matHeaderCellDef
          >Industry involved score</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          [class.found]="isHigh(row.dataOccupationScore)"
          >{{ row.dataOccupationScore }}</mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="dataMonthlyIncomeScore">
        <mat-header-cell *matHeaderCellDef
          >Monthly income score</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          [class.found]="isHigh(row.dataMonthlyIncomeScore)"
          >{{ row.dataMonthlyIncomeScore }}</mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="dataMonthlySpendScore">
        <mat-header-cell *matHeaderCellDef>Monthly spend score</mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          [class.found]="isHigh(row.dataMonthlySpendScore)"
          >{{ row.dataMonthlySpendScore }}</mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="accountPurposeScore">
        <mat-header-cell *matHeaderCellDef
          >Account purpose score</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          [class.found]="isHigh(row.accountPurposeScore)"
          >{{ row.accountPurposeScore }}</mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="countryScore">
        <mat-header-cell *matHeaderCellDef>Country score</mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          [class.found]="isHigh(row.countryScore)"
          >{{ row.countryScore }}</mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="canFundFromOwnAccountScore">
        <mat-header-cell *matHeaderCellDef
          >Fund from an EEA account score
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          [class.found]="isHigh(row.canFundFromOwnAccountScore)"
        >
          {{ row.canFundFromOwnAccountScore }} <br />
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="directorsPEPScore">
        <mat-header-cell *matHeaderCellDef
          >PEP stakeholders score</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          [class.found]="isHigh(row.directorsPEPScore)"
        >
          {{ row.directorsPEPScore }} <br />
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="adverseMediaCriminalRecordScore">
        <mat-header-cell *matHeaderCellDef
          >Adverse media/Criminal record score</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          [class.found]="isHigh(row.adverseMediaCriminalRecordScore)"
        >
          {{ row.adverseMediaCriminalRecordScore }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="groupMemberAmlScore">
        <mat-header-cell *matHeaderCellDef
          >Group members Sanction/Adverse media/Criminal record
          score</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          [class.found]="isHigh(row.groupMemberAmlScore)"
        >
          {{ row.groupMemberAmlScore }}<br />
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="financialStatementScore">
        <mat-header-cell *matHeaderCellDef
          >Financial statement score</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          [class.found]="isHigh(row.financialStatementScore)"
        >
          {{ row.financialStatementScore }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="companyActivityScore">
        <mat-header-cell *matHeaderCellDef>Business area score</mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          [class.found]="isHigh(row.companyActivityScore)"
        >
          {{ row.companyActivityScore }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="percentageOfCashScore">
        <mat-header-cell *matHeaderCellDef
          >Percentage of cash score</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          [class.found]="isHigh(row.percentageOfCashScore)"
        >
          {{ row.percentageOfCashScore }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="jurisdictionsScore">
        <mat-header-cell *matHeaderCellDef>Jurisdictions score</mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          [class.found]="isHigh(row.jurisdictionsScore)"
        >
          {{ row.jurisdictionsScore }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="incOpeCountryScore">
        <mat-header-cell *matHeaderCellDef
          >Operating/<br />
          Incorporation country score</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          [class.found]="isHigh(row.incOpeCountryScore)"
        >
          {{ row.incOpeCountryScore }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="directorCountryScore">
        <mat-header-cell *matHeaderCellDef
          >Residency of stakeholders score</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          [class.found]="isHigh(row.directorCountryScore)"
        >
          {{ row.directorCountryScore }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="totalRiskScore">
        <mat-header-cell *matHeaderCellDef>Total risk score</mat-header-cell>
        <mat-cell *matCellDef="let row" [class.found]="atLeastOneHigh(row)"
          >{{ row.totalRiskScore || '-' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="riskLevel">
        <mat-header-cell *matHeaderCellDef>Risk level</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.riskLevel || '-' }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="adjustedRiskLevel">
        <mat-header-cell *matHeaderCellDef>Adjusted risk level</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.adjustedRiskLevel || '-' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="dueDiligenceLevel">
        <mat-header-cell *matHeaderCellDef>Due diligence level</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.dueDiligenceLevel || '-' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="detailedReportButton">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button mat-icon-button (click)="seeDetailedReport(row.id)">
            <mat-icon class="go-icon">content_paste_go</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="riskScoreChange">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <!-- ADD EMPTY PROPOSAL -->
          <button
            *ngIf="allowAddProposal(row.id)"
            mat-icon-button
            [disabled]="isAddingRiskForm"
            [appButtonLoader]="isAddingRiskForm"
            (click)="addProposal()"
          >
            <mat-icon svgIcon="add"></mat-icon>
          </button>

          <!-- REMOVE PROPOSAL -->
          <button
            *ngIf="allowRemoveProposal(row.id)"
            mat-icon-button
            [disabled]="isRemovingRiskForm"
            [appButtonLoader]="isRemovingRiskForm"
            (click)="removeProposal()"
          >
            <mat-icon class="remove-icon">close</mat-icon>
          </button>

          <!-- ACCEPT PROPOSAL -->
          <button
            class="accept-button"
            *ngIf="allowAcceptRejectProposal(row.id)"
            mat-flat-button
            color="accent"
            [disabled]="isAcceptingRiskForm"
            [appButtonLoader]="isAcceptingRiskForm"
            (click)="acceptProposal()"
          >
            Accept
          </button>

          <!-- REJECT PROPOSAL -->
          <button
            class="reject-button"
            *ngIf="allowAcceptRejectProposal(row.id)"
            mat-flat-button
            [disabled]="isRejectingRiskForm"
            [appButtonLoader]="isRejectingRiskForm"
            (click)="rejectProposal()"
          >
            Reject
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let col; columns: displayedColumns; let entry"
        [ngClass]="{
          highlight: personalData
            ? entry.id === personalData?.currentRiskReportId
            : entry.id === corporateData?.currentRiskReportId
        }"
      ></mat-row>
    </mat-table>
  </div>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]"></mat-paginator>

  <div fxLayoutAlign="end center" class="button-container">
    <button
      *ngIf="!isInOnboarding"
      mat-flat-button
      color="accent"
      (click)="changeAdjustmentSuggestionStatus()"
      form="ngForm"
    >
      {{
        adjustmentSuggestionMade
          ? 'Return for adjustments'
          : 'Send for approval'
      }}
    </button>
  </div>
</mat-card>
