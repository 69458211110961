import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { getCountryByCode } from 'src/app/shared/helpers/various-helpers.helper';
import { Country } from 'src/app/shared/models/country.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { RecurringTransfer } from '../shared/models/recurring-transfer.model';
import { TransferService } from '../transfer.service';
import { InfoService } from '../../shared/info-dialog/info.service';
import { AccountType } from 'src/app/dashboard/shared/account-type.enum';
@Component({
  templateUrl: './recurring.component.html',
  styleUrls: ['./recurring.component.scss'],
})
export class RecurringComponent implements OnInit, OnDestroy {
  isLoadingTransfers: boolean = false;
  isLoadingCountries: boolean = false;
  isRemoving: boolean = false;

  countries: Country[] = [];
  recurringTransfers: RecurringTransfer[] = [];
  sepaTooltipText: string =
    'SEPA transfer can be made only In Euro. The beneficiary of the SEPA transfer must reside in one of the EEA country.';

  searchControl: FormControl = this.fb.control(null);
  searchSub?: Subscription;
  currentReqSub?: Subscription;

  getCountryByCode = getCountryByCode;
  AccountType = AccountType;

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private sharedService: SharedService,
    private transferService: TransferService,
    private infoService: InfoService
  ) {}

  ngOnInit(): void {
    // initial fetch of countries
    this.isLoadingCountries = true;
    this.sharedService.getAllCountries().subscribe(
      (countries) => {
        this.countries = countries;
        this.isLoadingCountries = false;
      },
      () => {
        this.errorService.showErrorDialog();
        this.isLoadingCountries = false;
      }
    );

    this.searchSub = this.searchControl.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged(), startWith(null))
      .subscribe((searchTerm) => this.fetchRecurringTransfers(searchTerm));
  }

  ngOnDestroy(): void {
    this.searchSub?.unsubscribe();
  }

  removeRecurringTransfer(rtId: number): void {
    this.isRemoving = true;
    this.transferService.stopRecurringTransfer(rtId).subscribe(
      () => {
        this.fetchRecurringTransfers(this.searchControl.value);
        this.isRemoving = false;
      },
      () => {
        this.errorService.showErrorDialog();
        this.isRemoving = false;
      }
    );
  }

  showInfo(): void {
    let title: string = 'Recurring Payments';
    let message1: string =
      'Recurring payments allow you to make regular transfers to other bank accounts and eCredo users. You can set up scheduled recurring payments by initiating a SEPA or SWIFT transfer. Your planned recurring transfers can be managed under Recurring Transfers.';
    this.infoService.showInfoDialog(title, message1, '');
  }

  private fetchRecurringTransfers(searchTerm?: string): void {
    this.currentReqSub?.unsubscribe(); // cancels previous request
    this.isLoadingTransfers = true;
    this.currentReqSub = this.transferService
      .getRecurringTransfers(searchTerm)
      .subscribe(
        (recurringTransfers) => {
          this.recurringTransfers = recurringTransfers;
          this.isLoadingTransfers = false;
        },
        () => {
          this.errorService.showErrorDialog();
          this.isLoadingTransfers = false;
        }
      );
  }
}
