import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Program } from 'src/app/login/models/program.enum';
import { UserType } from 'src/app/login/models/user-type.enum';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { RegisteredUsersService } from './registered-users.service';
import { convertUserState } from 'src/app/shared/helpers/various-helpers.helper';
import { RegisteredUserSearch } from './models/registered-user-search.model';
import { RegisteredUsersDataSource } from './registered-users.datasource';
import { AdminNoteDialogData } from '../ongoing-registration-new/admin-note-dialog/admin-note-dialog-data.model';
import { AdminNoteDialogComponent } from '../ongoing-registration-new/admin-note-dialog/admin-note-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RegisteredUserItem } from './models/registered-user-item.model';

@Component({
  templateUrl: './registered-users.component.html',
  styleUrls: ['./registered-users.component.scss'],
})
export class RegisteredUsersComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  programs = Program;
  convertUserState = convertUserState;

  dataSource!: RegisteredUsersDataSource;
  displayedColumns: string[] = [
    'id',
    'names',
    'companyName',
    'program',
    'activatedDate',
    'noteCount',
    'state',
    'contact',
    'countryName',
    'dateOfBirth',
  ];

  filtersGroup: FormGroup = this.fb.group({
    search: null,
    individuals: true,
    businesses: true,
  });

  private formSub: Subscription | undefined;

  constructor(
    private fb: FormBuilder,
    private registeredUsersService: RegisteredUsersService,
    private errorService: ErrorService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.dataSource = new RegisteredUsersDataSource(
      this.registeredUsersService,
      this.errorService
    );
    const params: RegisteredUserSearch = { limit: 20 };
    this.dataSource.loadRegisteredUsers(params);
  }

  ngAfterViewInit(): void {
    // listens for filters values
    this.formSub = merge(
      this.searchControl.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged()
      ),
      this.individualsControl.valueChanges,
      this.businessesControl.valueChanges
    )
      .pipe(
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadPage();
        })
      )
      .subscribe();

    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadPage()))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.formSub?.unsubscribe();
  }

  loadPage(): void {
    let userType;
    if (this.individualsControl.value && !this.businessesControl.value) {
      userType = UserType.PERSONAL;
    } else if (!this.individualsControl.value && this.businessesControl.value) {
      userType = UserType.CORPORATE;
    }

    const params: RegisteredUserSearch = {
      page: this.paginator.pageIndex + 1,
      limit: this.paginator.pageSize,
      searchText: this.searchControl.value,
      userType,
      orderField: this.sort.active,
      orderDescending: this.sort.direction === 'desc',
    };
    this.dataSource.loadRegisteredUsers(params);
  }

  addNote(data: RegisteredUserItem): void {
    this.dialog
      .open<AdminNoteDialogComponent, AdminNoteDialogData, number>(
        AdminNoteDialogComponent,
        {
          panelClass: 'dialog-with-close-button',
          width: '1000px',
          disableClose: true,
          data: {
            ...data,
            headerText: data.companyName
              ? data.companyName
              : `${data.firstName} ${data.lastName}`,
          },
        }
      )
      .beforeClosed()
      .subscribe((noteCount) => (data.noteCount = noteCount!));
  }

  get searchControl(): FormControl {
    return this.filtersGroup.get('search') as FormControl;
  }
  get individualsControl(): FormControl {
    return this.filtersGroup.get('individuals') as FormControl;
  }
  get businessesControl(): FormControl {
    return this.filtersGroup.get('businesses') as FormControl;
  }
}
