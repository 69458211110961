import { RiskReportState } from "src/app/onboarding-new/models/risk-report-state.enum";

export const riskReportStateNamesMap = new Map<RiskReportState, string>([
  [RiskReportState.ONBOARDING_ORIGINAL, 'Original (Onboarding)'],
  [RiskReportState.ONBOARDING_PROPOSED, 'Proposed (Onboarding)'],
  [RiskReportState.ONBOARDING_APPROVED, 'Approved (Onboarding)'],
  [RiskReportState.APPLICATION_VERIFIED_ORIGINAL, 'Original (Application Verified)'],
  [RiskReportState.APPLICATION_VERIFIED_PROPOSED, 'Proposed (Application Verified)'],
  [RiskReportState.APPLICATION_VERIFIED_APPROVED, 'Approved (Application Verified)'],
  [RiskReportState.ONBOARDING_REJECTED, 'Rejected (Onboarding)'],
  [RiskReportState.APPLICATION_VERIFIED_REJECTED, 'Rejected (Application Verified)'],
]);