import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  baseUrl = `${environment.BACKEND_URL}/files`;

  constructor(private http: HttpClient) {}

  generateUrl(location: string): Observable<{ url: string }> {
    const url = `${this.baseUrl}/download-links`;
    return this.http.post<{ url: string }>(url, { location });
  }

  downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }
}
