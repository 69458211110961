<div fxLayout="column" class="container">
  <!-- Field label -->
  <label
    *ngIf="
      value.documentType !== documentTypes.ANOTHER_FILE || viewOnly;
      else editableLabel
    "
    class="file-label"
    [class.rejected]="!viewOnly && value.doc?.isAccepted === false"
    [class.rejected-uploaded]="
      !viewOnly && value.doc?.rejectionReason && value.doc?.isAccepted === null
    "
  >
    {{
      value.customLabel ||
        value.doc?.anotherFileName ||
        (showProofOfOperatingAddress &&
        value.documentType === documentTypes.PROOF_OF_ADDRESS
          ? 'Proof of Operating Address'
          : getDocumentName(value.documentType))
    }}
  </label>

  <!-- View only version -->
  <div *ngIf="viewOnly && !value.doc?.isAccepted" class="main view-only">
    <div class="desc">
      <div>
        {{ value.doc?.reasonWhyNotUploaded ? 'Not provided' : 'Submitted' }}
      </div>
      <div *ngIf="value.doc?.reasonWhyNotUploaded">
        {{ value.doc?.reasonWhyNotUploaded }}
      </div>
    </div>
    <div *ngIf="isAdmin" fxLayout="row" fxLayoutAlign="space-between center">
      <!-- open file - admin only -->
      <div
        class="open-submitted-file"
        fxLayoutAlign="start center"
        fxLayoutGap="12px"
        (click)="openFileDialog.emit()"
      >
        <mat-icon svgIcon="file"></mat-icon>
        <div fxFlex fxLayout class="desc">
          <span class="file-base">{{ fileBase }}</span>
          {{ fileExtension }}
        </div>
      </div>
      <!-- download file - admin only -->
      <button color="accent" mat-icon-button (click)="downloadFile.emit()">
        <mat-icon class="material-icons-outlined">get_app</mat-icon>
      </button>
    </div>
  </div>

  <!-- Verified version -->
  <div *ngIf="value.doc?.isAccepted" class="main verified">Verified</div>

  <!-- Editable version -->
  <div *ngIf="!viewOnly && !value.doc?.isAccepted" class="main">
    <!-- whole conversation and admin comment -->
    <div *ngIf="value.doc?.rejectionReason">
      <button
        *ngIf="value.doc?.comments && value.doc!.comments.length > 1"
        mat-button
        class="whole-conversation"
        (click)="openWholeConversation()"
      >
        <div fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-icon class="material-icons-outlined">forum</mat-icon>
          <div>Whole conversation</div>
        </div>
      </button>
      <div
        class="rejection-reason"
        [class.rejected]="value.doc?.isAccepted === false"
        [class.rejected-uploaded]="
          value.doc?.rejectionReason && value.doc?.isAccepted === null
        "
      >
        {{ value.doc?.rejectionReason }}
      </div>
    </div>

    <!-- description/message -->
    <ng-container class="desc" [ngSwitch]="currentState">
      <div
        *ngSwitchCase="fileInputStates.isEmpty"
        data-testid="no-file-uploaded"
        class="desc"
      >
        No file (.pdf, .png or .jpg. Up to 20MB)
      </div>
      <div *ngSwitchCase="fileInputStates.isProcessing" class="desc">
        Processing...
      </div>
      <div *ngSwitchCase="fileInputStates.isErroring" class="desc error">
        {{ errorMessage }}
      </div>
      <div
        *ngSwitchCase="fileInputStates.isUploaded"
        data-testid="file-uploaded-correctly"
        class="desc"
      >
        Uploaded
      </div>
    </ng-container>
    <!-- content -->
    <!-- [class.hidden] used as a workaround for a bug https://github.com/georgipeltekov/ngx-file-drop/issues/225 -->
    <div [class.hidden]="currentState !== fileInputStates.isEmpty">
      <ngx-file-drop
        dropZoneLabel="Drop the document here or"
        accept=".jpg,.jpeg,.png,.pdf,.heic,.tif,.tiff"
        [showBrowseBtn]="true"
        [multiple]="false"
        [directory]="false"
        browseBtnLabel="browse"
        dropZoneClassName="drop-zone"
        contentClassName="content"
        browseBtnClassName="browse-button"
        (onFileDrop)="dropped($event)"
      ></ngx-file-drop>
    </div>

    <!-- Document not provided -->
    <div
      *ngIf="value.documentType !== documentTypes.ANOTHER_FILE"
      [class.hidden]="
        !(
          currentState === fileInputStates.isEmpty ||
          currentState === fileInputStates.isReason
        )
      "
      [class.checkbox-margin]="currentState === fileInputStates.isEmpty"
    >
      <!-- Optional documents only -->
      <!-- <mat-checkbox
        data-testid="cannot-provide-this-document"
        [ngModel]="currentState === fileInputStates.isReason"
        (ngModelChange)="toggleReasonCheckbox(reasonInput)"
        >I can’t provide this document</mat-checkbox
      > -->
      <div
        [class.hidden]="currentState !== fileInputStates.isReason"
        class="reason-container"
      >
        <label>Why can’t you provide this document?</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            data-testid="why-cannot-provide-document-input"
            [ngModel]="value.doc?.reasonWhyNotUploaded"
            (ngModelChange)="updateReason($event)"
            [ngModelOptions]="{ updateOn: 'blur' }"
            maxlength="500"
            required
            #reasonInput="matInput"
          />
        </mat-form-field>
      </div>
    </div>

    <!-- All other states -->
    <div
      *ngIf="
        currentState !== fileInputStates.isEmpty &&
        currentState !== fileInputStates.isReason
      "
      fxLayout
      fxLayoutAlign="start center"
      class="file-container"
    >
      <!-- left icon -->
      <ng-container [ngSwitch]="currentState">
        <mat-icon
          *ngSwitchCase="fileInputStates.isProcessing"
          svgIcon="file"
          class="left-icon"
        ></mat-icon>
        <mat-icon
          *ngSwitchCase="fileInputStates.isErroring"
          svgIcon="cancel"
          class="left-icon"
        ></mat-icon>
        <mat-icon
          *ngSwitchCase="fileInputStates.isUploaded"
          svgIcon="checked"
          class="left-icon checked"
        ></mat-icon>
      </ng-container>
      <!-- file name -->
      <div fxFlex fxLayout class="file-name">
        <span class="file-base">{{ fileBase }}</span>
        {{ fileExtension }}
      </div>

      <!-- download file - admin only -->
      <button
        *ngIf="currentState === fileInputStates.isUploaded && isAdmin"
        color="accent"
        mat-icon-button
        (click)="downloadFile.emit()"
      >
        <mat-icon class="material-icons-outlined">get_app</mat-icon>
      </button>

      <!-- open file dialog - admin only -->
      <button
        *ngIf="currentState === fileInputStates.isUploaded && isAdmin"
        class="open-file"
        mat-icon-button
        (click)="openFileDialog.emit()"
      >
        <mat-icon svgIcon="file"></mat-icon>
      </button>

      <!-- remove button (don't show in onboarding cdd form)-->
      <button
        *ngIf="
          currentState !== fileInputStates.isProcessing && !disableFileRemove
        "
        mat-icon-button
        (click)="value.doc ? removeFile() : clearError()"
      >
        <mat-icon
          svgIcon="trash"
          data-testid="trash-icon-button"
          color="accent"
        ></mat-icon>
      </button>
    </div>
  </div>
</div>

<!-- TEMPLATE FOR: editable label (for additional files) -->
<ng-template #editableLabel>
  <div fxLayoutAlign="space-between center" class="another-file-name">
    <mat-form-field appearance="outline">
      <input
        matInput
        placeholder="Document name"
        data-testid="another-file-input"
        [ngModel]="anotherFileName"
        (ngModelChange)="updateFileName($event)"
        [ngModelOptions]="{ updateOn: 'blur' }"
        [disabled]="!!value.doc?.isAccepted || !!value.doc?.rejectionReason"
        maxlength="50"
        #anotherFileNameInput
      />
    </mat-form-field>
    <div
      *ngIf="!value.doc && currentState !== fileInputStates.isProcessing"
      class="cancel"
      data-testid="another-file-cancel-button"
      (click)="removeField.emit()"
    >
      Cancel
    </div>
  </div>
</ng-template>
