import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Card } from '../../../models/card.model';
import { Program } from '../../../../login/models/program.enum';
import { MatDialog } from '@angular/material/dialog';
import { OrderNewCardDialogComponent } from '../../order-new-card-dialog/order-new-card-dialog.component';
import { AccountStatus } from '../../../shared/account-status.enum';

@Component({
  selector: 'app-cards-dropdown',
  templateUrl: './cards-dropdown.component.html',
  styleUrls: ['./cards-dropdown.component.scss'],
})
export class CardsDropdownComponent {
  @Input() program?: Program;
  @Input() cards!: Card[];
  @Input() isLoadingCards?: boolean;
  @Output() readonly cardActivated = new EventEmitter<void>();
  @Output() readonly pickedCard = new EventEmitter<Card>();

  AccountStatus = AccountStatus;

  constructor(private dialog: MatDialog) {}

  isOrderNewCard(): boolean {
    const nonExpiredCards: number = this.cards?.filter(
      (card) =>
        card.status !== AccountStatus.EXPIRED &&
        card.status !== AccountStatus.CLOSED
    ).length;

    switch (this.program) {
      case Program.EMERALD:
        return nonExpiredCards < 4;
      case Program.ONYX:
      case Program.RUBY:
        return nonExpiredCards < 9;
      default:
        return false;
    }
  }

  orderNewCard() {
    this.dialog.open(OrderNewCardDialogComponent, {
      width: '405px',
      autoFocus: false,
    });
  }
}
