<app-base-login-page title="eCREDO Payments Portal" [titleCentered]="true">
  <app-login-card imgName="payment.svg">
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
      <div fxLayout="column" class="text">
        <strong>Sorry,</strong>
        <div>Your payment has been rejected.</div>
      </div>
      <button
        mat-flat-button
        color="accent"
        [appButtonLoader]="isLoading"
        [disabled]="isLoading"
        (click)="tryAgain()"
      >
        Try again
      </button>
    </div>
  </app-login-card>
</app-base-login-page>
