<div fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <form
    [formGroup]="closeLinkForm"
    class="main"
    (ngSubmit)="save(closeLinkForm)"
  >
    <h2>Legal Entity</h2>

    <!-- NAME -->
    <app-onboarding-form-item-new
      label="Company name"
      [isReviewed]="isReviewed && !isFirstTimeEDD"
      [value]="companyCloseLink?.name"
      [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_CLOSE_LINK_NAME)"
    >
      <div fxLayout="column" class="field">
        <label for="name">Company name</label>
        <mat-form-field appearance="outline">
          <input matInput name="name" formControlName="name" />
          <mat-error
            data-testid="validation-message-box"
            *ngIf="nameControl?.hasError('required')"
            >Field is required</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="nameControl?.hasError('pattern')"
            >Forbidden character used</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="nameControl?.hasError('maxlength')"
            >Company name can’t be longer than 100 characters</mat-error
          >
        </mat-form-field>
      </div>
    </app-onboarding-form-item-new>

    <!-- REGISTRATION NUMBER -->
    <app-onboarding-form-item-new
      label="Registration number"
      [isReviewed]="isReviewed && !isFirstTimeEDD"
      [value]="companyCloseLink?.registrationNumber"
      [field]="
        fieldsMap.get(OnboardingFieldId.BUSINESS_CLOSE_LINK_REGISTRATION_NUMBER)
      "
    >
      <div fxLayout="column" class="field">
        <label for="registrationNumber">Registration number</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            name="registrationNumber"
            formControlName="registrationNumber"
          />
          <mat-error
            data-testid="validation-message-box"
            *ngIf="registrationNumberControl?.hasError('required')"
            >Field is required</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="registrationNumberControl?.hasError('pattern')"
            >Forbidden character used</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="registrationNumberControl?.hasError('maxlength')"
            >Registration number can’t be longer than 50 characters</mat-error
          >
        </mat-form-field>
      </div>
    </app-onboarding-form-item-new>

    <!-- DATE OF INCORPORATION -->
    <app-onboarding-form-item-new
      label="Date of Incorporation"
      [isReviewed]="isReviewed && !isFirstTimeEDD"
      [value]="companyCloseLink?.incorporationDate"
      [field]="
        fieldsMap.get(
          OnboardingFieldId.BUSINESS_CLOSE_LINK_DATE_OF_INCORPORATION
        )
      "
    >
      <div fxLayout="column" class="field">
        <label for="incorporationDate">Date of Incorporation</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            [matDatepicker]="picker"
            name="incorporationDate"
            formControlName="incorporationDate"
            readonly
            [max]="today"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error
            data-testid="validation-message-box"
            *ngIf="incorporationDateControl?.hasError('required')"
            >Field is required</mat-error
          >
        </mat-form-field>
      </div>
    </app-onboarding-form-item-new>

    <!-- ADDRESS -->
    <ng-container formGroupName="address">
      <h2 class="dark">Registered Address</h2>

      <app-onboarding-form-item-new
        label="Address (Street, building number, place)"
        [isReviewed]="isReviewed && !isFirstTimeEDD"
        [value]="companyCloseLink?.address?.streetAddress"
        [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_CLOSE_LINK_ADDRESS)"
      >
        <div fxLayout="column" class="field">
          <label for="streetAddress"
            >Address (Street, building number, place)</label
          >
          <mat-form-field appearance="outline">
            <input
              matInput
              name="streetAddress"
              formControlName="streetAddress"
            />
            <mat-error *ngIf="streetAddressControl?.hasError('required')"
              >Field is required</mat-error
            >
            <mat-error *ngIf="streetAddressControl?.hasError('pattern')"
              >Forbidden character used</mat-error
            >
            <mat-error *ngIf="streetAddressControl?.hasError('maxlength')"
              >Address can’t be longer than 100 characters</mat-error
            >
          </mat-form-field>
        </div>
      </app-onboarding-form-item-new>

      <app-onboarding-form-item-new
        label="Address 2nd line (optional)"
        [isReviewed]="isReviewed && !isFirstTimeEDD"
        [value]="companyCloseLink?.address?.additionalStreetAddress"
        [field]="
          fieldsMap.get(OnboardingFieldId.BUSINESS_CLOSE_LINK_ADDRESS_2ND_LINE)
        "
      >
        <div fxLayout="column" class="field">
          <label for="additionalStreetAddress"
            >Address 2nd line (optional)</label
          >
          <mat-form-field appearance="outline">
            <input
              matInput
              name="additionalStreetAddress"
              formControlName="additionalStreetAddress"
            />
            <mat-error
              *ngIf="additionalStreetAddressControl?.hasError('pattern')"
              >Forbidden character used</mat-error
            >
            <mat-error
              *ngIf="additionalStreetAddressControl?.hasError('maxlength')"
              >Address can’t be longer than 100 characters</mat-error
            >
          </mat-form-field>
        </div>
      </app-onboarding-form-item-new>

      <div
        [fxLayout]="isReviewed && !isFirstTimeEDD ? 'column' : 'row'"
        [fxLayoutGap]="isReviewed && !isFirstTimeEDD ? '0px' : '35px'"
      >
        <app-onboarding-form-item-new
          label="Zip/Postal code"
          [isReviewed]="isReviewed && !isFirstTimeEDD"
          [value]="companyCloseLink?.address?.postCode"
          [field]="
            fieldsMap.get(OnboardingFieldId.BUSINESS_CLOSE_LINK_ZIP_POSTAL_CODE)
          "
        >
          <div
            fxLayout="column"
            [ngClass]="isReviewed && !isFirstTimeEDD ? 'field' : 'zip'"
          >
            <label for="postCode">Zip/Postal code</label>
            <mat-form-field appearance="outline">
              <input matInput name="postCode" formControlName="postCode" />
              <mat-error *ngIf="postCodeControl?.hasError('required')"
                >Field is required</mat-error
              >
              <mat-error *ngIf="postCodeControl?.hasError('pattern')"
                >Forbidden char.</mat-error
              >
              <mat-error *ngIf="postCodeControl?.hasError('maxlength')"
                >Too long</mat-error
              >
            </mat-form-field>
          </div>
        </app-onboarding-form-item-new>

        <app-onboarding-form-item-new
          label="City"
          [isReviewed]="isReviewed && !isFirstTimeEDD"
          [value]="companyCloseLink?.address?.city"
          [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_CLOSE_LINK_CITY)"
        >
          <div
            fxLayout="column"
            [ngClass]="isReviewed && !isFirstTimeEDD ? 'field' : 'city'"
          >
            <label for="city">City</label>
            <mat-form-field appearance="outline">
              <input matInput name="city" formControlName="city" />
              <mat-error *ngIf="cityControl?.hasError('required')"
                >Field is required</mat-error
              >
              <mat-error *ngIf="cityControl?.hasError('pattern')"
                >Forbidden character used</mat-error
              >
              <mat-error *ngIf="cityControl?.hasError('maxlength')"
                >City can’t be longer than 50 characters</mat-error
              >
            </mat-form-field>
          </div>
        </app-onboarding-form-item-new>
      </div>

      <app-onboarding-form-item-new
        label="Country"
        [isReviewed]="isReviewed && !isFirstTimeEDD"
        [value]="
          getCountryById(countries, companyCloseLink?.address?.countryId)
        "
        [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_CLOSE_LINK_COUNTRY)"
      >
        <div fxLayout="column" class="field">
          <label for="countryId">Country</label>
          <mat-form-field appearance="outline">
            <mat-select
              placeholder="Please select"
              name="countryId"
              formControlName="countryId"
            >
              <mat-option
                *ngFor="let country of countries"
                [value]="country.id"
                >{{ country.name | titlecase }}</mat-option
              >
            </mat-select>
            <mat-error *ngIf="countryIdControl?.hasError('required')"
              >Field is required</mat-error
            >
          </mat-form-field>
        </div>
      </app-onboarding-form-item-new>
    </ng-container>

    <div fxLayoutAlign="end center" class="button-container">
      <button
        mat-flat-button
        [disabled]="isAdding || closeLinkForm.invalid || closeLinkForm.pristine"
        [appButtonLoader]="isAdding"
        color="accent"
        type="submit"
      >
        {{ isNotOnboarding ? 'Confirm' : 'Save' }}
      </button>
    </div>
  </form>
</mat-dialog-content>
