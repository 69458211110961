import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { UserLogItem } from '../../users/registered-users/registered-user/registered-user-logs/models/user-log-item.model';
import { UserLogSearch } from '../../users/registered-users/registered-user/registered-user-logs/models/user-log-search.model';
import { ReportsService } from '../reports.service';

export class UserIpLoginDataSource implements DataSource<UserLogItem> {
  private userIpLoginSubject = new BehaviorSubject<UserLogItem[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private lengthSubject = new BehaviorSubject<number>(0);
  private currentRequestSub?: Subscription;
  public isLoadingObs = this.loadingSubject.asObservable();
  displayedDocumentOptions: string[] = [];

  constructor(
    private reportsService: ReportsService,
    private errorService: ErrorService
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<UserLogItem[]> {
    return this.userIpLoginSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.userIpLoginSubject.complete();
    this.loadingSubject.complete();
    this.lengthSubject.complete();
  }

  // count of all items in the source
  getLength(): Observable<number> {
    return this.lengthSubject.asObservable();
  }

  getCount(): number {
    return this.lengthSubject.value;
  }

  loadUserIpLogin(data: UserLogSearch): void {
    this.currentRequestSub?.unsubscribe(); // cancels previous request
    this.loadingSubject.next(true);

    this.currentRequestSub = this.reportsService
      .getUserIpLogin(data)
      .pipe(
        map((res) => {
          this.lengthSubject.next(res.totalCount);
          return res.items;
        }),
        catchError((err) => {
          this.errorService.showErrorDialog();
          return of([]);
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((userIpLogin) => this.userIpLoginSubject.next(userIpLogin));
  }
}
