export enum CardPurpose {
  'Investment Purposes' = 'Investment Purposes',
  'Receive my social welfare payments' = 'Receive my social welfare payments',
  'Receive my salary' = 'Receive my salary',
  'Pay my bills' = 'Pay my bills',
  'Personal/Household Expenses' = 'Personal/Household Expenses',
  'Receive income/dividends' = 'Receive income/dividends',
  'Send or receive money transfers' = 'Send or receive money transfers',
  'For use abroad/travel' = 'For use abroad/travel',
  'Gaming or Betting on-line' = 'Gaming or Betting on-line',
  'Point of Sale purchases' = 'Point of Sale purchases',
}
