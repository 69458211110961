import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { RegistrationService } from 'src/app/login/services/registration.service';
import { PasswordComponent } from 'src/app/login/shared/password/password.component';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';

@Component({
  selector: 'app-provide-new-password',
  templateUrl: './provide-new-password.component.html',
  styleUrls: ['./provide-new-password.component.scss'],
})
export class ProvideNewPasswordComponent implements OnInit {
  @ViewChild(PasswordComponent, { static: true })
  passwordComp!: PasswordComponent;

  @Input() token: string = '';

  isLoading = false;
  passwordForm: FormGroup = new FormGroup({});

  constructor(
    private errorService: ErrorService,
    private registrationService: RegistrationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.passwordForm = this.passwordComp.getGroup();
  }

  async saveNewPassword(): Promise<void> {
    this.isLoading = true;
    const password = this.passwordForm.get('password')?.value;
    (
      await this.registrationService.resetPassword(this.token, password)
    ).subscribe(
      () => {
        this.isLoading = false;
        this.router.navigate(['login'], { state: { isPasswordReset: true } });
      },
      (err) => {
        this.isLoading = false;
        if (err.status === 422) {
          this.passwordForm
            ?.get('password')
            ?.setErrors({ notUniquePassword: true });
        } else {
          this.errorService.showErrorDialog();
        }
      }
    );
  }
}
