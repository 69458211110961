import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { UsersService } from '../../../users.service';
import {
  FormGroup,
  Validators,
  AbstractControl,
  FormBuilder,
} from '@angular/forms';
import { closeReasonNamesMap } from '../../../registered-users/registered-user/shared/closeReasonNamesMap';
import { CloseReason } from 'src/app/login/models/close-reason.enum';

export interface ClientDecisionDialogData {
  userId: number;
  userName: string;
  approve: boolean;
  isPersonal: boolean;
  isKeyPersonsDecisionPoint: boolean;
}

@Component({
  templateUrl: './client-decision-dialog.component.html',
  styleUrls: ['./client-decision-dialog.component.scss'],
})
export class ClientDecisionDialogComponent implements OnInit {
  isProceeding: boolean = false;
  userId!: number;
  userName!: string;
  approve!: boolean;
  isPersonal!: boolean;
  isKeyPersonsDecisionPoint!: boolean;
  closeReasonNamesMap = closeReasonNamesMap;

  constructor(
    private errorService: ErrorService,
    private usersService: UsersService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: ClientDecisionDialogData,
    public dialogRef: MatDialogRef<ClientDecisionDialogComponent, boolean> // returns true if success
  ) {
    this.userId = data.userId;
    this.userName = data.userName;
    this.approve = data.approve;
    this.isPersonal = data.isPersonal;
    this.isKeyPersonsDecisionPoint = data.isKeyPersonsDecisionPoint;
  }

  reasonForm: FormGroup = this.fb.group({
    closeReason: ['', Validators.required],
  });

  ngOnInit(): void {
    closeReasonNamesMap.delete(CloseReason.CLIENT_DECISION);
  }

  proceedDecision(): void {
    this.isProceeding = true;

    let service;
    let reason = this.closeReasonControl?.value;
    if (this.approve) {
      service = this.usersService.approveApplicantAtDecisionPoint(this.userId);
    } else {
      service = this.usersService.rejectApplicant(this.userId, reason);
    }
    service.subscribe(
      () => {
        this.isProceeding = false;
        this.dialogRef.close(true);
      },
      (error) => {
        this.isProceeding = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  get closeReasonControl(): AbstractControl | null {
    return this.reasonForm.get('closeReason');
  }
}
