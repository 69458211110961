import { Component, OnInit } from '@angular/core';
import { Sections } from '../../dashboard/shared/sections.enum';
import { Router } from '@angular/router';
import { Transaction } from '../../dashboard/models/transaction.model';
import { Card } from '../../dashboard/models/card.model';
import { DashboardService } from '../../dashboard/dashboard.service';
import { ErrorService } from '../../shared/error-dialog/error.service';
import { Observable } from 'rxjs';
import { Program } from '../../login/models/program.enum';
import { OrderNewCardDialogComponent } from '../../dashboard/account-section/order-new-card-dialog/order-new-card-dialog.component';
import { filter, first, map } from 'rxjs/operators';
import { AuthService } from '../../login/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { LockUnlockCardDialogComponent } from './lock-unlock-card-dialog/lock-unlock-card-dialog.component';
import { ShowPinDialogComponent } from './show-pin-dialog/show-pin-dialog.component';
import { ReportCardComponent } from './report-card/report-card.component';
import { AllAccounts } from 'src/app/dashboard/models/all-accounts.model';
import { DeactivatedCardsDialogComponent } from './deactivated-cards-dialog/deactivated-cards-dialog.component';
import { AccountStatus } from '../../dashboard/shared/account-status.enum';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
})
export class CardsComponent implements OnInit {
  accountTransactions: Transaction[] = [];
  cards: Card[] = [];
  deactivatedCards: Card[] = [];

  program?: Program;
  currentCard?: Card;

  isLoadingCards: boolean = false;
  isLoadingTransactions: boolean = false;
  isLoadingHeader: boolean = false;

  $breakpointObserver?: Observable<boolean>;

  Program = Program;
  Sections = Sections;
  AccountStatus = AccountStatus;

  constructor(
    private dashboardService: DashboardService,
    private authService: AuthService,
    private errorService: ErrorService,
    private router: Router,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.refresh();

    this.authService
      .getAuthenticatedUserObs()
      .pipe(first())
      .subscribe(
        (user) => {
          this.program = user?.program;
        },
        (error) => {
          this.errorService.showErrorDialog(error.error.message);
        }
      );

    this.$breakpointObserver = this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .pipe(
        map(
          (state) =>
            state.breakpoints[Breakpoints.XSmall] ||
            state.breakpoints[Breakpoints.Small]
        )
      );
  }

  loadCards(accounts: AllAccounts, pickedCardId?: number): void {
    // this.isLoadingCards = true;
    // this.isLoadingHeader = true;
    // this.cards = [];
    // this.deactivatedCards = [];
    // const allCards = accounts.cards.map((card) =>
    //   this.dashboardService.getAccountInfo(card)
    // );
    // if (allCards.length > 0) {
    //   forkJoin(allCards).subscribe(
    //     (cardsInfo: AccountInfo[]) => {
    //       cardsInfo.map((details, index) => {
    //         const number = details.cardHolder.cardNumber.slice(-4);
    //         const card: Card = {
    //           cardholder: `${details.cardHolder.firstName} ${details.cardHolder.lastName}`,
    //           balance: +details.cardInfo.availBal,
    //           status: details.cardInfo.cardStatus,
    //           name: `Card - ${number}`,
    //           expireDate: details.cardInfo.expirationDate,
    //           numAccountID: accounts.cards[index].numId,
    //         };
    //         if (!this.isDeactivated(card.status)) {
    //           this.cards.push(card);
    //         } else {
    //           this.deactivatedCards.push(card);
    //         }
    //       });
    //       if (pickedCardId) {
    //         this.currentCard = this.cards.find(
    //           (card) => card.numAccountID === pickedCardId
    //         );
    //       } else {
    //         this.currentCard = this.cards.find(
    //           (card) => !this.isDeactivated(card.status)
    //         );
    //       }
    //       this.isLoadingCards = false;
    //       this.isLoadingHeader = false;
    //     },
    //     (error) => {
    //       this.isLoadingCards = false;
    //       this.isLoadingHeader = false;
    //       this.errorService.showErrorDialog();
    //     }
    //   );
    // } else {
    //   this.isLoadingCards = false;
    //   this.isLoadingHeader = false;
    // }
  }

  loadTransactions(accounts: AllAccounts, cardId: number) {
    //   this.isLoadingTransactions = true;
    //   this.accountTransactions = [];
    //   const index = accounts.cards.findIndex((card) => card.numId === cardId);
    //   const dateRange = calculateDateRange();
    //   this.dashboardService
    //     .getAccountStatements(accounts.cards[index].numId, {
    //       limit: 8,
    //       dtStartDate: dateRange.past,
    //       dtEndDate: dateRange.present,
    //     })
    //     .subscribe(
    //       (statement) => {
    //         this.accountTransactions = statement.items;
    //         this.isLoadingTransactions = false;
    //       },
    //       (error) => {
    //         this.isLoadingTransactions = false;
    //         if (error.status === 400) return;
    //         this.errorService.showErrorDialog();
    //       }
    //     );
  }

  refresh(): void {
    this.dashboardService.getAccounts().subscribe(
      (accounts) => {
        // const notDeactivatedCards: AccountDetails[] = accounts.cards.filter(
        // (card) => !this.isDeactivated(card.numPFSAccountStatusId)
        // );
        // this.loadCards(accounts, notDeactivatedCards[0]?.numId);
        // if (notDeactivatedCards[0])
        // this.loadTransactions(accounts, notDeactivatedCards[0].numId);
      },
      () => {
        this.errorService.showErrorDialog();
      }
    );
  }

  showPin(): void {
    this.dialog.open(ShowPinDialogComponent, {
      width: '405px',
      height: '265px',
      panelClass: 'dialog-with-close-button',
      data: this.currentCard,
    });
  }

  lockUnlockCard(): void {
    this.dialog
      .open(LockUnlockCardDialogComponent, {
        width: '405px',
        height: '336px',
        panelClass: 'dialog-with-close-button',
        data: this.currentCard,
      })
      .beforeClosed()
      .pipe(filter((shouldRefresh) => !!shouldRefresh))
      .subscribe(() => {
        this.dashboardService.getAccounts().subscribe(
          (accounts) => {
            this.loadCards(accounts, this.currentCard?.numAccountID);
          },
          () => this.errorService.showErrorDialog()
        );
      });
  }

  reportCard(): void {
    this.dialog
      .open(ReportCardComponent, {
        width: '405px',
        height: '358px',
        panelClass: 'dialog-with-close-button',
        data: this.currentCard,
      })
      .beforeClosed()
      .pipe(filter((shouldRefresh) => !!shouldRefresh))
      .subscribe(() => {
        this.dashboardService.getAccounts().subscribe(
          (accounts) => {
            this.loadCards(accounts);
          },
          () => this.errorService.showErrorDialog()
        );
      });
  }

  displayDeactivatedCards() {
    this.dialog.open(DeactivatedCardsDialogComponent, {
      width: '720px',
      height: '521px',
      panelClass: 'dialog-with-close-button',
      data: this.deactivatedCards,
    });
  }

  navigateToStatements(): void {
    this.router.navigateByUrl('/statement');
  }

  changeCurrentCard(card: Card): void {
    this.currentCard = card;
    this.dashboardService.getAccounts().subscribe((accounts) => {
      this.loadTransactions(accounts, card.numAccountID);
    });
  }

  isOrderNewCard(): boolean {
    const nonExpiredCards: number = this.cards?.filter(
      (card) => !this.isDeactivated(card.status)
    ).length;

    switch (this.program) {
      case Program.EMERALD:
        return nonExpiredCards < 4;
      case Program.ONYX:
      case Program.RUBY:
        return nonExpiredCards < 9;
      default:
        return false;
    }
  }

  orderNewCard(): void {
    this.dialog
      .open(OrderNewCardDialogComponent, {
        width: '405px',
        autoFocus: false,
      })
      .beforeClosed()
      .pipe(filter((shouldRefresh) => !!shouldRefresh))
      .subscribe(() => {
        this.dashboardService.getAccounts().subscribe(
          (accounts) => {
            this.loadCards(accounts);
          },
          () => this.errorService.showErrorDialog()
        );
      });
  }

  private isDeactivated(cardStatus: AccountStatus): boolean {
    return (
      cardStatus === AccountStatus.EXPIRED ||
      cardStatus === AccountStatus.CLOSED
    );
  }
}
