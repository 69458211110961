<div [class.container-width]="section !== sections.WALLET" class="container">
  <div fxLayoutAlign="space-between start">
    <div fxLayout *ngIf="!isLoadingHeader">
      <div fxLayoutAlign="start start">
        <mat-icon
          [svgIcon]="svgIconName"
          class="icon"
          *ngIf="!isLocked"
        ></mat-icon>
        <img
          src="/assets/img/lock-clock-white.svg"
          alt="locked"
          *ngIf="isLocked"
          class="locked-icon"
        />
      </div>

      <div fxLayout="column">
        <div fxLayoutAlign="start start" class="header">
          <strong>{{ headerName }}</strong>
        </div>
        <div
          *ngIf="section === sections.IBAN && accountNumber && !isLoadingHeader"
          class="iban-number"
        >
          <img
            src="assets/img/copy-white.svg"
            alt="copy"
            width="14"
            height="14"
            class="cursor copy-icon"
            [cdkCopyToClipboard]="accountNumber"
          />
          <span class="account-number">{{ convertIban(accountNumber) }}</span>
        </div>
      </div>

      <div class="header locked-span">
        <span *ngIf="isLocked">locked</span>
      </div>
    </div>
    <div *ngIf="section !== sections.WALLET">
      <div class="header" *ngIf="!isLoadingHeader">
        <div
          fxLayout="column"
          fxLayoutAlign="end end"
          fxLayoutGap="15px"
          style="font-size: 20px"
        >
          <span>Available balance: {{ availableBalance | amount }} EUR </span>
          <span>Book balance: {{ balance | amount }} EUR</span>
        </div>
      </div>
      <app-small-loader *ngIf="isLoadingHeader"></app-small-loader>
    </div>
  </div>

  <div
    *ngIf="
      section === sections.CARD && expiryDate && cardHolder && !isLoadingHeader
    "
    fxLayout
  >
    <div><img src="assets/img/master-card.svg" alt="master-card" /></div>
    <div fxLayout="column" class="card-details">
      <span>Expires end of {{ expiryDate | date: 'M/yyyy' }}</span>
      <span>{{ cardHolder | titlecase }}</span>
    </div>
  </div>
  <app-small-loader
    *ngIf="isLoadingHeader"
    class="small-loader"
    fxLayoutAlign="center center"
  ></app-small-loader>
</div>
