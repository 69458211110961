import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export interface CardPaymentDialogData {
  src: string;
}

@Component({
  templateUrl: './card-payment-dialog.component.html',
  styleUrls: ['./card-payment-dialog.component.scss'],
})
export class CardPaymentDialogComponent {
  src?: SafeResourceUrl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CardPaymentDialogData,
    private sanitizer: DomSanitizer
  ) {
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(data.src);
  }
}
