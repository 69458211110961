import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { combineLatest, Subscription } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { Country } from '../shared/models/country.model';
import { UserState } from '../login/models/user-state.enum';
import { UserType } from '../login/models/user-type.enum';
import { AuthService } from '../login/services/auth.service';
import { ErrorService } from '../shared/error-dialog/error.service';
import { OnboardingData } from './models/onboarding-data.model';
import { OnboardingService } from './onboarding.service';
import { Step } from './stepper/stepper.component';
import { SharedService } from '../shared/services/shared.service';
import { Program } from '../login/models/program.enum';

@Component({
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingNewComponent implements OnInit, OnDestroy {
  userStates = UserState;
  userTypes = UserType;

  isLoading = false;
  userState: UserState | undefined = UserState.VERIFIED_MOBILE_PHONE_NUMBER;
  userType: UserType | undefined;
  userProgram: Program | undefined;
  onboardingData!: OnboardingData;
  countries: Country[] = [];
  acceptedCountries: Country[] = [];
  steps: Step[] = [];
  private dataSub: Subscription | undefined;
  Program = Program;

  constructor(
    private onboardingService: OnboardingService,
    private authService: AuthService,
    private errorService: ErrorService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    const authenticatedUserObs = this.authService.getAuthenticatedUserObs();
    const onboardingDataObs = this.onboardingService.getOnboardingData();
    const allCountriesObs = this.sharedService.getAllCountries();

    this.dataSub = combineLatest([authenticatedUserObs, allCountriesObs])
      .pipe(
        tap(([authenticatedUser, countries]) => {
          // authenticatedUser
          this.userState = authenticatedUser?.state;
          this.userType = authenticatedUser?.type;
          this.userProgram = authenticatedUser?.program;
          // countries
          this.countries = countries;
          this.acceptedCountries = countries;
        }),
        // it gets new onboarding data when authenticated user was changed
        mergeMap(() => {
          this.isLoading = true;
          return onboardingDataObs;
        })
      )
      .subscribe(
        (onboardingData) => {
          this.onboardingData = onboardingData;
          this.setSteps(
            onboardingData.cellPhoneNumberVerifiedDate,
            onboardingData.documentsSubmittedDate,
            onboardingData.reviewStartedDate
          );
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
          this.errorService.showErrorDialog();
        }
      );
  }

  ngOnDestroy(): void {
    this.dataSub?.unsubscribe();
  }

  private setSteps(
    cellPhoneNumberVerifiedDate?: string,
    documentsSubmittedDate?: string,
    reviewStartedDate?: string
  ): void {
    this.steps = [
      {
        name: 'Initial registration',
        completed: true,
        substeps: [
          'Email verified',
          'Mobile number verified',
          cellPhoneNumberVerifiedDate
            ? DateTime.fromISO(cellPhoneNumberVerifiedDate).toLocaleString(
                DateTime.DATE_SHORT
              )
            : '',
        ],
      },
      {
        name: 'Application submitted',
        completed: [
          UserState.ADDITIONAL_INFORMATION_REQUESTED,
          UserState.DECISION_POINT,
          UserState.EDD,
          UserState.EDD_SUBMITTED,
          UserState.KEY_PERSONS_VERIFICATION,
          UserState.KEY_PERSONS_DECISION_POINT,
          UserState.BUSINESS_CDD_REVIEW,
        ].includes(this.userState as UserState),
        substeps: [
          documentsSubmittedDate
            ? DateTime.fromISO(documentsSubmittedDate).toLocaleString(
                DateTime.DATE_SHORT
              )
            : '',
        ],
      },
      {
        name: 'Application under review',
        completed: [
          UserState.ADDITIONAL_INFORMATION_REQUESTED,
          UserState.DECISION_POINT,
          UserState.EDD,
          UserState.EDD_SUBMITTED,
          UserState.KEY_PERSONS_VERIFICATION,
          UserState.KEY_PERSONS_DECISION_POINT,
          UserState.BUSINESS_CDD_REVIEW,
        ].includes(this.userState as UserState),
        substeps: [
          reviewStartedDate
            ? DateTime.fromISO(reviewStartedDate).toLocaleString(
                DateTime.DATE_SHORT
              )
            : '',
        ],
      },
      { name: 'Application verified', completed: false, substeps: [] },
    ];
  }
}
