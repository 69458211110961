<div
  *ngIf="toUserGroup"
  fxLayout="column"
  [formGroup]="toUserGroup"
  class="accounts-container"
>
  <div fxHide.lt-sm fxLayout="row" fxLayoutAlign="space-between center">
    <h1>From</h1>
    <button mat-icon-button class="info-button" (click)="showInfo()">
      <mat-icon class="info-icon">info_outline</mat-icon>
    </button>
    <div></div>
    <h1>To</h1>
  </div>
  <div
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutAlign="space-between center"
  >
    <!-- FROM -->
    <div fxLayoutAlign.lt-sm="start" fxLayout.lt-sm="column" class="from-to">
      <div fxLayout="row">
        <h1 class="small-screen-h1" fxHide.gt-xs>From</h1>
        <button
          mat-icon-button
          fxHide.gt-xs
          class="small-info-button"
          (click)="showInfo()"
        >
          <mat-icon
            class="small-info-icon small-screen-h1 material-icons-outlined"
            >info_outline</mat-icon
          >
        </button>
      </div>

      <app-account-list-item [account]="fromAccount"></app-account-list-item>
    </div>

    <!-- ARROW -->
    <button mat-icon-button color="accent" class="arrow">
      <mat-icon
        svgIcon="arrow"
        [class.switch-accounts-button]="$breakpointObserver | async"
      ></mat-icon>
    </button>

    <!-- TO -->
    <div fxLayoutAlign.lt-sm="start" fxLayout.lt-sm="column" class="from-to">
      <h1 fxHide.gt-xs class="negative-margin small-screen-h1">To</h1>
      <div
        fxLayout="column"
        class="to-selector"
        [class.is-open]="isOpen"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
      >
        <label>eCREDO registered phone number</label>
        <div class="phone-description">
          Enter a plus sign (+) followed by the country code and phone number,
          e.g. +357xxxxxxxx
        </div>
        <mat-form-field>
          <input matInput formControlName="phoneNumber" />
          <button
            mat-icon-button
            matSuffix
            class="favourite-button"
            (click)="isOpen = !isOpen; $event.stopPropagation()"
          >
            <mat-icon class="material-icons-outlined">book</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </div>

  <!-- FAVOURITES MENU -->
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayWidth]="trigger.elementRef.nativeElement.offsetWidth"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
  >
    <app-select-favourite-overlay
      [isE2e]="true"
      [accountOwnerGuid]="accountOwnerGuid"
      (selectFav)="onSelectFav($event)"
    ></app-select-favourite-overlay>
  </ng-template>
</div>
