import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-billing-section',
  templateUrl: './billing-section.component.html',
  styleUrls: ['./billing-section.component.scss'],
})
export class BillingSectionComponent {
  @Input() date!: string;
  @Input() name!: string;
  @Input() transaction!: number;
  @Input() balance!: number;
  @Input() description!: string;
  @Input() fee?: number;
}
