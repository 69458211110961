<mat-card>
  <div class="main-container">
    <div fxLayout="column" class="header-container">
      <div fxLayout fxLayoutAlign="space-between center">
        <img src="assets/img/logo-dark.svg" alt="eCredo logo" />
        <h1>{{ transactionDetails?.processorType }} Transfer details</h1>
      </div>
      <div fxFlexAlign="end" class="date">
        <span>{{
          transactionDetails?.dateTimeEntered | date: 'shortDate'
        }}</span>
      </div>
      <div class="info">
        <span class="bold">Customer copy</span><br />
        <span>International Payments</span><br />
        <span>
          {{ transactionDetails?.processorType }}
          <ng-container [ngSwitch]="transactionDetails?.inwardOutward">
            <ng-container *ngSwitchCase="inwardOutwardEnum.INWARD"
              >incoming</ng-container
            >
            <ng-container *ngSwitchCase="inwardOutwardEnum.OUTWARD"
              >outgoing</ng-container
            >
          </ng-container>
          credit transfer
        </span>
      </div>
      <div>
        <span>{{ transactionDetails?.debtorFullName }}</span
        ><br />
        <span
          >Please note that we have executed the following payment according to
          your instructions</span
        >
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between none">
      <div fxFlex="50" fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="0 0 168px">Reference number</div>
          <div fxFlex="1 1 100">
            {{ pfsTransactionId }}
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="0 0 168px">Sender bank</div>
          <div fxFlex="1 1 100">
            {{ transactionDetails?.debtorBankIdentifier }}
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="0 0 168px">Ordering customer</div>
          <div fxFlex="1 1 100">
            {{ transactionDetails?.debtorAccountIdentifier }} <br />
            <br />
            {{ transactionDetails?.debtorFullName }} <br />
            {{ transactionDetails?.debtorFullAddress }}
          </div>
        </div>
      </div>

      <div class="vertical-line"></div>

      <div
        fxFlex="50"
        fxLayout="column"
        fxLayoutGap="10px"
        class="submitted-list"
      >
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="0 0 168px">Receiving bank</div>
          <div fxFlex="1 1 100">
            {{ transactionDetails?.creditorBankIdentifier }}
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="0 0 168px">Beneficiary</div>
          <div fxFlex="1 1 100">
            <span>{{ transactionDetails?.creditorAccountIdentifier }}</span
            ><br />
            <span>{{ transactionDetails?.creditorFullName }}</span>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="0 0 168px">Details of payment</div>
          <div fxFlex="1 1 100">
            {{ transactionDetails?.description }}
          </div>
        </div>
      </div>
    </div>

    <div class="line"></div>

    <div fxLayout="row" fxLayoutAlign="space-between none">
      <div fxFlex="50" fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="0 0 168px">Transaction date</div>
          <div fxFlex="100">
            {{ transactionDetails?.dateTimeEntered | date: 'shortDate' }}
          </div>
        </div>
        <!-- <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="0 0 168px">Fees</div>
          <div fxFlex="100">
            {{ transactionDetails?.fees | amount }}
          </div>
        </div> -->
      </div>
      <div class="vertical-line"></div>
      <div fxFlex="50" fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div>Remitted amount</div>
          <div>{{ transactionDetails?.amount | amount }} {{ currency }}</div>
        </div>
        <!-- TODO uncomment and integrate when backend is ready -->
        <!-- <div class="grey-line"></div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div>Amount debited</div>
          <div></div>
        </div>
        <div class="grey-line"></div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div>Charges</div>
          <div></div>
        </div>
        <div class="charges" fxLayout="column" fxLayoutGap="10px">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>Creation commission</div>
            <div></div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>Same day commission</div>
            <div></div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>Handling commission</div>
            <div></div>
          </div>
        </div>
        <div class="grey-line"></div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div>Total charges</div>
          <div></div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="bold">
          <div>Total amount debited</div>
          <div></div>
        </div> -->
      </div>
    </div>

    <div class="grey-line separated"></div>
    <div fxLayout="row" fxLayoutAlign="space-between none">
      <div>Email: support@ecredo.com</div>
      <div>THIS ADVICE REQUIRES NO SIGNATURE</div>
    </div>

    <div class="line"></div>
    <span
      >Payment Instructions are revocable by request provided the beneficiary
      has not already been advised or credited.</span
    >
  </div>
</mat-card>
