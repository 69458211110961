import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-form-review-new',
  templateUrl: './admin-form-review.component.html',
  styleUrls: ['./admin-form-review.component.scss'],
})
export class AdminFormReviewNewComponent {
  @Input() personalGroup?: FormGroup;
  @Input() corporateGroup?: FormGroup;
  @Input() accountGroup!: FormGroup;
  @Input() counterpartyGroup!: FormGroup;
  @Input() closeLinkGroup!: FormGroup;
  @Input() groupMemberGroup!: FormGroup;
  @Input() userId!: number;
  @Input() isSameAddress?: boolean;
  @Input() isEDD?: boolean;
  @Input() showExtraQuestions!: boolean;

  // corporate
  get incorporationAddressGroup(): FormGroup {
    return this.corporateGroup?.get('incorporationAddress') as FormGroup;
  }
  get operatingAddressGroup(): FormGroup {
    return this.corporateGroup?.get('operatingAddress') as FormGroup;
  }
  get accountControl(): FormArray {
    return this.accountGroup.get('accountForm') as FormArray;
  }
  get counterpartyControl(): FormArray {
    return this.counterpartyGroup.get('counterpartyForm') as FormArray;
  }
  get closeLinkControl(): FormArray {
    return this.closeLinkGroup.get('closeLinkForm') as FormArray;
  }
  get groupMemberControl(): FormArray {
    return this.groupMemberGroup.get('groupMemberForm') as FormArray;
  }

  // personal
  get addressGroup(): FormGroup {
    return this.personalGroup?.get('address') as FormGroup;
  }
}
