<div class="main-container">
  <div fxLayout="row" fxLayoutAlign="start center">
    <h1>Recurring Transfers</h1>
    <mat-icon class="material-icons-outlined info" (click)="showInfo()"
      >info</mat-icon
    >
  </div>
  <br />
  <div fxLayout="column">
    <label for="search">Search</label>
    <mat-form-field appearance="outline">
      <input
        matInput
        name="search"
        [formControl]="searchControl"
        placeholder="Search transfer"
      />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <div class="results-container">
    <app-big-loader
      *ngIf="isLoadingTransfers || isLoadingCountries"
    ></app-big-loader>
    <ng-container *ngIf="!isLoadingTransfers && !isLoadingCountries">
      <mat-card *ngFor="let transfer of recurringTransfers">
        <div
          fxLayout
          fxLayout.lt-sm="column"
          class="data-container"
          fxLayoutGap="24px"
        >
          <!-- LEFT COLUMN -->
          <div fxFlex="45%" fxFlex.lt-md="100%">
            <!-- TRANSFER TYPE -->
            <div
              fxLayout
              fxLayoutAlign="start center"
              fxLayoutGap="4px"
              class="section big-text"
            >
              <span>{{ transfer.transferType }}</span>
              <mat-icon
                *ngIf="transfer.transferType === 'SEPA Transfer'"
                class="material-icons-outlined"
                color="accent"
                [matTooltip]="sepaTooltipText"
                >info</mat-icon
              >
            </div>

            <div class="section">
              <div>Recurrence</div>
              <div class="medium-text">
                {{ transfer.recurringCycleId }}, starting
                {{ transfer.valueDate | date: 'dd/MM/yyyy' }}
              </div>
              <div class="medium-text normal-weight">
                Next transfer
                {{ transfer.dateNextTransfer | date: 'dd/MM/yyyy' }}
              </div>
            </div>
            <div fxLayout>
              <div fxFlex="50%" fxFlex.lt-md="100%" class="section">
                <div>Amount</div>
                <div class="big-text">
                  <!-- *show currency of selected account because can only send out funds with the same currency as the originating account -->
                  {{ transfer.amount | amount }}
                  {{ transfer.accountSelectedCurrency }}
                </div>
              </div>
            </div>
            <div
              class="section"
              *ngIf="
                transfer.benRateCurrency !== transfer.accountSelectedCurrency
              "
            >
              <div>Currency</div>
              <div class="medium-text">
                {{ transfer.benRateCurrency }}
              </div>
            </div>

            <div class="section">
              <div>Charges option</div>
              <div class="medium-text">
                {{ transfer.charges.toUpperCase() }}
              </div>
            </div>
            <div class="section">
              <div>Description for Payer</div>
              <div class="medium-text">
                {{ transfer.payerDescription }}
              </div>
              <div>Description for Beneficiary</div>
              <div class="medium-text">
                {{ transfer.beneficiaryDescription }}
              </div>
            </div>
          </div>

          <!-- RIGHT COLUMN -->
          <div fxFlex="55%">
            <h1>From</h1>
            <div class="section">
              <div class="medium-text">
                {{
                  transfer.accountSelectedType === AccountType.IBAN
                    ? 'IBAN account'
                    : transfer.accountSelectedCurrency + ' Wallet'
                }}
              </div>
            </div>

            <h1>To</h1>
            <div class="section medium-text">
              <div>{{ transfer.beneficiaryName }}</div>
              <div>
                {{ transfer.beneficiaryAddress1 }}
                <span *ngIf="transfer.beneficiaryAddress2">
                  {{ transfer.beneficiaryAddress2 }}
                </span>
              </div>
              <div>
                {{ transfer.beneficiaryZip }} {{ transfer.beneficiaryCity }}
              </div>
              <div>
                {{
                  getCountryByCode(countries, transfer.beneficiaryCountryCode)
                }}
              </div>
            </div>
            <div class="section">
              <div>IBAN or Account Number</div>
              <div class="medium-text">{{ transfer.accountNumber }}</div>
            </div>
            <div class="section">
              <div>Bank SWIFT / BIC</div>
              <div class="medium-text">{{ transfer.bic }}</div>
            </div>
            <div class="section" *ngIf="transfer.bankName">
              <div>Bank Name</div>
              <div class="medium-text">{{ transfer.bankName }}</div>
            </div>
            <div class="section" *ngIf="transfer.bankAddress">
              <div>Bank Address</div>
              <div class="medium-text">{{ transfer.bankAddress }}</div>
            </div>
            <div class="section" *ngIf="transfer.intermediaryBankBic">
              <div>Intermediary Bank SWIFT / BIC</div>
              <div class="medium-text">{{ transfer.intermediaryBankBic }}</div>
            </div>
            <div class="section" *ngIf="transfer.intermediaryBankName">
              <div>Intermediary Bank Name</div>
              <div class="medium-text">{{ transfer.intermediaryBankName }}</div>
            </div>
            <div class="section" *ngIf="transfer.intermediaryBankAddress">
              <div>Intermediary Bank Address</div>
              <div class="medium-text">
                {{ transfer.intermediaryBankAddress }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="buttons-container"
          fxLayout
          fxLayoutAlign="start center"
          fxLayoutGap="30px"
        >
          <!-- REMOVE -->
          <app-small-loader *ngIf="isRemoving"></app-small-loader>
          <div
            *ngIf="!isRemoving"
            fxLayoutAlign="start center"
            fxLayoutGap="8px"
            class="fake-button"
            (click)="removeRecurringTransfer(transfer.id)"
          >
            <mat-icon svgIcon="cancel"></mat-icon>
            <div>Cancel transfer</div>
          </div>
        </div>
      </mat-card>
    </ng-container>
  </div>
</div>
