import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RegistrationService } from 'src/app/login/services/registration.service';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';

@Component({
  selector: 'app-provide-email',
  templateUrl: './provide-email.component.html',
  styleUrls: ['./provide-email.component.scss'],
})
export class ProvideEmailComponent {
  isLoading = false;
  mailSent = false;

  resetForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private registrationService: RegistrationService
  ) {}

  async reset(): Promise<void> {
    this.isLoading = true;
    const email = this.resetForm.get('email')?.value;
    (await this.registrationService.sendResetPasswordEmail(email)).subscribe(
      () => {
        this.isLoading = false;
        this.mailSent = true;
      },
      (err) => {
        this.isLoading = false;
        if (err.status === 400) {
          this.resetForm.get('email')?.setErrors({ email: true });
        } else {
          this.errorService.showErrorDialog();
        }
      }
    );
  }
}
