import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { OnboardingFieldId } from 'src/app/admin/users/models/onboarding-field-id.enum';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { getStringValue } from 'src/app/shared/helpers/various-helpers.helper';
import { OnboardingData } from '../../models/onboarding-data.model';
import { OnboardingService } from '../../onboarding.service';
import { CorporateDeclarationForm } from '../../models/corporate-declaration-form.model';

@Component({
  selector: 'app-onboarding-business-declaration-form',
  templateUrl: './onboarding-business-declaration-form.component.html',
  styleUrls: ['./onboarding-business-declaration-form.component.scss'],
})
export class OnboardingBusinessDeclarationFormComponent
  implements OnInit, OnDestroy
{
  @Input() set onboardingData(val: OnboardingData | undefined) {
    // fieldsMap
    this.fieldsMap.clear();
    val?.fields
      .filter((field) => !!field.comment)
      .forEach((field) => {
        let commentedValue = field.commentedValue;
        this.fieldsMap.set(field.id, {
          comment: field.comment,
          commentedValue,
        });
      });

    const formValue = {
      ...val?.corporateForm,
    };
    this.declarationForm.patchValue(formValue);

    if (this.declarationForm.valid) {
      setTimeout(() => this.isComplete.emit(true));
    }
    this._onboardingData = val;
  }
  get onboardingData(): OnboardingData | undefined {
    return this._onboardingData;
  }

  @Input() isReviewed: boolean = false;
  @Output() isComplete = new EventEmitter<boolean>();

  OnboardingFieldId = OnboardingFieldId;

  declarationForm: FormGroup = this.fb.group({
    applyOnBehalfOfCompany: ['', Validators.requiredTrue],
    ableDiscloseInformation: ['', Validators.requiredTrue],
    activitiesNotInclude: ['', Validators.requiredTrue],
    isCorrectInformation: ['', Validators.requiredTrue],
  });

  isSaving: boolean = false;
  fieldsMap: Map<
    string,
    { comment: string | null; commentedValue: string | null } | null
  > = new Map<
    string,
    { comment: string | null; commentedValue: string | null } | null
  >();
  private _onboardingData: OnboardingData | undefined = undefined;
  private declarationFormSub: Subscription | undefined = undefined;
  getStringValue = getStringValue;

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private onboardingService: OnboardingService
  ) {}

  ngOnInit(): void {
    this.declarationFormSub = this.declarationForm.valueChanges.subscribe(() =>
      this.isComplete.emit(false)
    );
  }

  ngOnDestroy(): void {
    this.declarationFormSub?.unsubscribe();
  }

  saveDesclarationForm(): void {
    this.isSaving = true;
    this.isComplete.emit(false); // prevents submitting application while saving
    const body: CorporateDeclarationForm = {
      ...this.declarationForm.value,
    };
    this.onboardingService.saveCorporateDeclarationForm(body).subscribe(
      () => {
        this.isSaving = false;
        this.isComplete.emit(true);
      },
      () => {
        this.isSaving = false;
        this.errorService.showErrorDialog();
      }
    );
  }
}
