import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { OnboardingService } from '../../onboarding.service';
import { Country } from 'src/app/shared/models/country.model';
import { OnboardingField } from '../../models/onboarding-field.model';
import {
  CompanyGroupMember,
  SaveCompanyGroupMember,
} from '../../models/company-group-member.model';
import { Address } from 'src/app/shared/models/address.model';

@Component({
  selector: 'app-company-group-members-form',
  templateUrl: './company-group-members-form.component.html',
  styleUrls: ['./company-group-members-form.component.scss'],
})
export class CompanyGroupMembersFormComponent implements OnInit, OnDestroy {
  @Input() countries: Country[] = [];
  @Input() fields: OnboardingField[] = [];
  @Input() set companyGroupMembers(val: CompanyGroupMember[]) {
    const groupMembersIds = new Set();
    this.groupMembers = [];

    val.forEach((groupMember) => {
      groupMembersIds.add(groupMember.id.toString());
      this.groupMembers.push(groupMember);

      // adds controls to groupMembersGroup
      if (!this.groupMembersGroup.get(groupMember.id.toString())) {
        this.groupMembersGroup.addControl(
          groupMember.id.toString(),
          this.fb.control(null, Validators.requiredTrue)
        );
      }
    });

    // removes controls from groupMembersGroup that are not needed anymore
    Object.keys(this.groupMembersGroup.controls).forEach((key) => {
      if (!groupMembersIds.has(key)) {
        this.groupMembersGroup.removeControl(key);
      }
    });

    this._companyGroupMembers = val;
  }
  get companyGroupMembers(): CompanyGroupMember[] {
    return this._companyGroupMembers;
  }
  @Input() isReviewed: boolean = false;
  // It is emitted when group member is modified by user (so that it can be show proprerly in other section)
  @Output() companyGroupMembersChange = new EventEmitter<
    CompanyGroupMember[]
  >();
  @Output() isComplete = new EventEmitter<boolean>();

  groupMembers: CompanyGroupMember[] = [];
  isAdding: boolean = false;
  private _companyGroupMembers: CompanyGroupMember[] = [];
  private groupMembersFormSub: Subscription | undefined = undefined;

  groupMembersForm: FormGroup = this.fb.group(
    {
      groupMembers: this.fb.group(
        {},
        {
          validators: [
            // minControlsNumberValidator(1),
          ],
        } // it has to be at least one group member
      ), // this group is used only for validation purposes (contains values 'isValid', and validation 'requiredTrue')
    },
    { updateOn: 'blur' }
  );

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private onboardingService: OnboardingService
  ) {}

  ngOnInit(): void {
    // Emits status of the form when it changes
    this.groupMembersFormSub = this.groupMembersForm.statusChanges
      .pipe(startWith(this.groupMembersForm.status))
      .subscribe((status) => {
        setTimeout(() => this.isComplete.emit(status === 'VALID'));
      });
  }

  ngOnDestroy(): void {
    this.groupMembersFormSub?.unsubscribe();
  }

  addGroupMember(): void {
    this.isComplete.emit(false); // prevents saving application while adding group member
    this.isAdding = true;
    const address: Address = {
      streetAddress: '',
      additionalStreetAddress: '',
      postCode: '',
      city: '',
      countryId: 60,
    };
    const body: SaveCompanyGroupMember = {
      name: '',
      registrationNumber: '',
      incorporationDate: '',
      address: address,
      isActive: true,
    };
    this.onboardingService.createCompanyGroupMember(body).subscribe(
      (newGroupMember) => {
        this.companyGroupMembersChange.emit([
          ...this.companyGroupMembers,
          newGroupMember,
        ]);
        this.isAdding = false;
      },
      () => {
        this.errorService.showErrorDialog();
        this.isAdding = false;
      }
    );
  }

  // used for emitting company group members when one was changed
  onGroupMemberChange(newGroupMember: CompanyGroupMember): void {
    const index = this.companyGroupMembers.findIndex(
      (companyGroupMember) => newGroupMember.id === companyGroupMember.id
    );

    const newCompanyGroupMembers = [...this.companyGroupMembers];
    newCompanyGroupMembers[index] = {
      ...this.companyGroupMembers[index],
      ...newGroupMember,
    };

    this.companyGroupMembersChange.emit(newCompanyGroupMembers);
  }

  // used for emitting company group members when one was removed
  onGroupMemberRemove(id: number): void {
    const newCompanyGroupMembers = this.companyGroupMembers.filter(
      (companyGroupMember) => companyGroupMember.id !== id
    );
    this.companyGroupMembersChange.emit(newCompanyGroupMembers);
  }

  // used for setting group member as complete (in groupMembersGroup)
  setComplete(groupMemberId: number, isComplete: boolean): void {
    this.groupMembersGroup.get(groupMemberId.toString())?.setValue(isComplete);
  }

  trackByFn(index: number, item: CompanyGroupMember): number {
    return item.id;
  }

  get groupMembersGroup(): FormGroup {
    return this.groupMembersForm.get('groupMembers') as FormGroup;
  }
}
