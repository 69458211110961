import { Component, OnInit } from '@angular/core';
import { WalletInstance } from '../../dashboard/models/walletInstance';
import { Sections } from '../../dashboard/shared/sections.enum';
import { currencies } from '../../dashboard/shared/currencies';
import { DashboardService } from '../../dashboard/dashboard.service';
import { ErrorService } from '../../shared/error-dialog/error.service';
import { AllAccounts } from 'src/app/dashboard/models/all-accounts.model';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
})
export class WalletComponent implements OnInit {
  isLoadingBalances: boolean = false;
  accounts?: AllAccounts;
  wallet: WalletInstance = {};
  sections = Sections;
  isLoadingWallet: boolean = false;

  constructor(
    private dashboardService: DashboardService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.refresh();
  }

  refresh(): void {
    this.isLoadingWallet = true;
    this.isLoadingBalances = true;

    currencies.forEach((currency) => {
      this.wallet[currency.short] = {
        availableBalance: null,
        balance: null,
        isLoading: true,
      };
    });

    this.dashboardService.getAccounts().subscribe(
      (accounts) => {
        if (accounts.wallets) {
          currencies.map((currency) => {
            const acc = accounts.wallets.find((w) => w.ccy === currency.short);
            if (acc !== undefined) {
              this.wallet[currency.short].availableBalance =
                acc.availableBalance;
              this.wallet[currency.short].balance = acc.balance;
            }
            this.wallet[currency.short].isLoading = false;
          });
        }

        this.isLoadingWallet = false;
        this.isLoadingBalances = false;
      },
      () => {
        this.isLoadingWallet = false;
        this.isLoadingBalances = false;
        currencies.map((currency) => {
          this.wallet[currency.short].isLoading = false;
        });
        this.errorService.showErrorDialog();
      }
    );
  }
}
