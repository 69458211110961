<div fxLayout="column">
  <div fxFlexAlign="center" class="text">
    Type the new password and confirm it
  </div>
  <app-password [extendPasswordRepeat]="true"></app-password>
  <button
    mat-flat-button
    fxFlexAlign="center"
    color="accent"
    data-testid="reset-password-button"
    [disabled]="passwordForm.invalid || isLoading"
    [appButtonLoader]="isLoading"
    (click)="saveNewPassword()"
  >
    Reset password
  </button>
</div>
