import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Country } from 'src/app/shared/models/country.model';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { TopUp } from 'src/app/transfer/shared/models/top-up.model';
import { TransferService } from 'src/app/transfer/transfer.service';
import {
  CardPaymentDialogComponent,
  CardPaymentDialogData,
} from 'src/app/shared/card-payment-dialog/card-payment-dialog.component';

@Component({
  templateUrl: './top-up-dialog.component.html',
  styleUrls: ['./top-up-dialog.component.scss'],
})
export class TopUpDialogComponent implements OnInit {
  isLoading: boolean = false;
  isContinuing: boolean = false;
  countries: Country[] = [];

  topUpForm: FormGroup = this.fb.group({
    amount: [
      null,
      [Validators.required, Validators.pattern('^[0-9]+[.]?[0-9]{0,2}$')],
    ],
    firstName: [
      null,
      [Validators.required, Validators.pattern('^[-_0-9A-Za-z ]{0,50}$')],
    ],
    lastName: [
      null,
      [Validators.required, Validators.pattern('^[-_0-9A-Za-z ]{0,50}$')],
    ],
    billAddress1: [
      null,
      [Validators.required, Validators.pattern('^[-_0-9A-Za-z ]{0,100}$')],
    ],
    billAddress2: [null, [Validators.pattern('^[-_0-9A-Za-z ]{0,100}$')]],
    billZipCode: [
      null,
      [Validators.required, Validators.pattern("^[a-zA-Z0-9 '.,-]{1,50}$")],
    ],
    billCity: [
      null,
      [Validators.required, Validators.pattern('^[-_0-9A-Za-z ]{0,50}$')],
    ],
    billCountryCode: [null, [Validators.required]],
  });

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private errorService: ErrorService,
    private sharedService: SharedService,
    private transferService: TransferService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.sharedService.getAllCountries().subscribe(
      (countries) => {
        this.countries = countries;
        this.isLoading = false;
      },
      () => {
        this.errorService.showErrorDialog();
        this.isLoading = false;
      }
    );
  }

  continueToPayment(): void {
    this.isContinuing = true;
    const body: TopUp = {
      ...this.topUpForm.value,
      amount: this.amountControl.value,
      successfulURL: `${window.location.origin}/sub-dashboard/iban-account?topUpSuccess=true`,
      failedURL: `${window.location.origin}/sub-dashboard/iban-account?topUpSuccess=false`,
    };

    this.transferService.topUp(body).subscribe(
      ({ url }) => {
        this.isContinuing = false;
        this.dialog.open<CardPaymentDialogComponent, CardPaymentDialogData>(
          CardPaymentDialogComponent,
          {
            width: '500px',
            height: '800px',
            disableClose: true,
            data: {
              src: url,
            },
          }
        );
      },
      () => {
        this.isContinuing = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  get amountControl(): FormControl {
    return this.topUpForm.get('amount') as FormControl;
  }
  get firstNameControl(): FormControl {
    return this.topUpForm.get('firstName') as FormControl;
  }
  get lastNameControl(): FormControl {
    return this.topUpForm.get('lastName') as FormControl;
  }
  get billAddress1Control(): FormControl {
    return this.topUpForm.get('billAddress1') as FormControl;
  }
  get billAddress2Control(): FormControl {
    return this.topUpForm.get('billAddress2') as FormControl;
  }
  get billZipCodeControl(): FormControl {
    return this.topUpForm.get('billZipCode') as FormControl;
  }
  get billCityControl(): FormControl {
    return this.topUpForm.get('billCity') as FormControl;
  }
  get billCountryCodeControl(): FormControl {
    return this.topUpForm.get('billCountryCode') as FormControl;
  }
}
