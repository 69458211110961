import {
  ChangeDetectorRef,
  Component,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export interface VerificationDialogData {
  src: string;
}

@Component({
  templateUrl: './verification-dialog.component.html',
  styleUrls: ['./verification-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VerificationDialogComponent {
  src?: SafeResourceUrl;
  showCloseButton: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: VerificationDialogData,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef
  ) {
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(data.src);
  }

  updateCloseButtonVisibility(showCloseButton: boolean) {
    this.showCloseButton = showCloseButton;
    this.cdRef.detectChanges();
  }
}
