import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { documentValidator } from 'src/app/shared/document.validator';
import {
  acceptedDocumentTypes,
  getIdentityDocumentsTypesSet,
} from 'src/app/shared/helpers/various-helpers.helper';
import { AppDocumentType } from '../../models/document-type.enum';
import { AppDocument } from '../../models/document.model';

@Component({
  selector: 'app-director-document-form',
  templateUrl: './director-document-form.component.html',
  styleUrls: ['./director-document-form.component.scss'],
})
export class DirectorDocumentFormComponent implements OnInit, OnDestroy {
  @Input() isReviewed: boolean = false;
  @Input() isFirstTimeEDD: boolean = false;
  @Input() show: boolean = true;
  // KYB: form for id/address docs and additional documents for key persons
  @Input() set documents(val: AppDocument[]) {
    // reset form from previous values
    this.documentsForm = this.fb.group({
      documents: this.fb.array([]),
    });

    // if id or passport documents exist then add their document types
    const identityDocumentsTypesSet = getIdentityDocumentsTypesSet();
    const idDocumentType = val.find((doc) =>
      identityDocumentsTypesSet.has(doc.type)
    )?.type;
    if (
      idDocumentType === AppDocumentType.IDENTITY_CARD ||
      idDocumentType === AppDocumentType.IDENTITY_CARD_SECOND_PAGE
    ) {
      this.documentsArray.push(
        this.fb.control(
          {
            doc: val.find((doc) => doc.type === AppDocumentType.IDENTITY_CARD),
            documentType: AppDocumentType.IDENTITY_CARD,
          },
          documentValidator()
        )
      );
      this.documentsArray.push(
        this.fb.control(
          {
            doc: val.find(
              (doc) => doc.type === AppDocumentType.IDENTITY_CARD_SECOND_PAGE
            ),
            documentType: AppDocumentType.IDENTITY_CARD_SECOND_PAGE,
          },
          documentValidator()
        )
      );
    } else if (idDocumentType === AppDocumentType.PASSPORT) {
      this.documentsArray.push(
        this.fb.control(
          {
            doc: val.find((doc) => doc.type === AppDocumentType.PASSPORT),
            documentType: AppDocumentType.PASSPORT,
          },
          documentValidator()
        )
      );
    }
    // always add proof of address
    this.documentsArray.push(
      this.fb.control(
        {
          doc: val.find((doc) => doc.type === AppDocumentType.PROOF_OF_ADDRESS),
          documentType: AppDocumentType.PROOF_OF_ADDRESS,
          customLabel: this.proofOfAddressLabel,
        },
        documentValidator()
      )
    );

    // display additional documents of key person if application was returned to applicant and not first time in EDD
    if (this.isReviewed && !this.isFirstTimeEDD) {
      this.addAdditionalDocuments(val);
    }
    this._documents = val;
  }
  get documents(): AppDocument[] {
    return this._documents;
  }
  @Input() companyDirectorId: number | undefined = undefined;
  @Input() set proofOfAddressLabel(val: string) {
    this._proofOfAddressLabel = val;
  }
  get proofOfAddressLabel(): string {
    return this._proofOfAddressLabel;
  }
  @Output() isComplete = new EventEmitter<boolean>();

  private _documents: AppDocument[] = [];
  private _proofOfAddressLabel: string = 'Proof of Address';
  private documentsFormSub: Subscription | null = null;

  acceptedDocumentTypes = acceptedDocumentTypes;

  documentsForm = this.fb.group({
    documents: this.fb.array([]),
  });

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.documentsFormSub = this.documentsForm.statusChanges
      .pipe(startWith(this.documentsForm.status))
      .subscribe((status) =>
        setTimeout(() => {
          this.isComplete.emit(status === 'VALID');
        })
      );
  }

  ngOnDestroy(): void {
    this.documentsFormSub?.unsubscribe();
  }

  addAnotherFile(): void {
    this.documentsArray.push(
      this.fb.control(
        { documentType: AppDocumentType.ANOTHER_FILE },
        documentValidator()
      )
    );
  }

  removeField(index: number): void {
    this.documentsArray.removeAt(index);
  }

  // Adds controls for documents with ANOTHER_FILE type
  private addAdditionalDocuments(documents: AppDocument[]): void {
    documents
      .filter((doc) => doc.type === AppDocumentType.ANOTHER_FILE)
      .forEach((doc) =>
        this.documentsArray.push(
          this.fb.control(
            {
              doc,
              documentType: doc.type,
            },
            documentValidator()
          )
        )
      );
  }

  get documentsArray(): FormArray {
    return this.documentsForm.get('documents') as FormArray;
  }
  get documentsControls(): FormControl[] {
    return this.documentsArray.controls as FormControl[];
  }
}
