import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { OnboardingService } from '../../onboarding.service';
import {
  CompanyCloseLink,
  SaveCompanyCloseLink,
} from '../../models/company-close-link.model';
import {
  getCountryById,
  getStringValue,
  percentageValidators,
} from 'src/app/shared/helpers/various-helpers.helper';
import { Country } from 'src/app/shared/models/country.model';
import { CompanyDirectorCloseLink } from '../../models/company-director-close-link.model';
import { CompanyCloseLinkDialogComponent } from '../company-close-link-dialog/company-close-link-dialog.component';
import { Address } from 'src/app/shared/models/address.model';
import { DateTime } from 'luxon';
import { OnboardingField } from '../../models/onboarding-field.model';
import { OnboardingFieldId } from 'src/app/admin/users/models/onboarding-field-id.enum';

export const checkboxAndPercentagesValidator = () => {
  return (group: FormGroup) => {
    // mark form invalid if ubo/shareholder is selected but percentage is not filled
    if (
      (group.value?.isUBO && !group.value?.percentageOwnedByUBO) ||
      (group.value?.isShareholder && !group.value?.percentageOwnedByShareholder)
    ) {
      return { percentageError: true };
    } else return null;
  };
};
export interface CompanyCloseLinkSelectDialogData {
  countries: Country[];
  closeLinks: CompanyCloseLink[];
  directorCloseLinks: CompanyDirectorCloseLink[];
  directorId: number;
  isLegalPerson: boolean;
  isReviewed: boolean;
  isFirstTimeEDD: boolean;
  fields: OnboardingField[];
  hasCloselinks: boolean;
  isNotOnboarding: boolean;
}

@Component({
  selector: 'app-company-close-link-select-dialog',
  templateUrl: './company-close-link-select-dialog.component.html',
  styleUrls: ['./company-close-link-select-dialog.component.scss'],
})
export class CompanyCloseLinkSelectDialogComponent
  implements OnInit, OnDestroy
{
  isAdding: boolean = false;
  isRemoving: boolean = false;

  closeLinks!: CompanyCloseLink[];
  directorCloseLinks!: CompanyDirectorCloseLink[];
  directorId!: number;
  isLegalPerson!: boolean;
  isReviewed!: boolean;
  isFirstTimeEDD!: boolean;
  fields: OnboardingField[] = [];
  hasCloselinks!: boolean;
  isNotOnboarding!: boolean;
  fieldsMap: Map<
    { directorId: number | null; closeLinkId: number | null },
    {
      comment: string | null;
      commentedValue: string | null;
    } | null
  > = new Map<
    { directorId: number | null; closeLinkId: number | null },
    {
      comment: string | null;
      commentedValue: string | null;
      directorId: number | null;
      closeLinkId: number | null;
    } | null
  >();

  countries!: Country[];
  percentageValidators = percentageValidators;
  companyCloseLinksChange = new EventEmitter<CompanyCloseLink[]>();
  companyDirectorCloseLinksChange = new EventEmitter<
    CompanyDirectorCloseLink[]
  >();
  dialogRefAddCompanyCloseLink!: MatDialogRef<CompanyCloseLinkDialogComponent>;
  dialogRefUpdateCompanyCloseLink!: MatDialogRef<CompanyCloseLinkDialogComponent>;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: CompanyCloseLinkSelectDialogData,
    private errorService: ErrorService,
    private onboardingService: OnboardingService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CompanyCloseLinkSelectDialogComponent>
  ) {}

  getCountryById = getCountryById;
  getStringValue = getStringValue;
  OnboardingFieldId = OnboardingFieldId;

  form: FormGroup = this.fb.group({
    directorCloseLinksForm: this.fb.array([]),
  });

  closeLinksFilledInEDD() {
    if (this.directorCloseLinks && this.isFirstTimeEDD && this.hasCloselinks) {
      var dcl = this.directorCloseLinks.find(
        (directorCloseLink) => directorCloseLink.directorId === this.directorId
      );
      // if said that has close links but director-close links don't exist, show warning
      if (!dcl) {
        return false;
      }
    }
    return true;
  }

  ngOnInit(): void {
    this.closeLinks = this.data.closeLinks;
    this.countries = this.data.countries;
    this.directorCloseLinks = this.data.directorCloseLinks;
    this.directorId = this.data.directorId;
    this.isLegalPerson = this.data.isLegalPerson;
    this.isReviewed = this.data.isReviewed;
    this.isFirstTimeEDD = this.data.isFirstTimeEDD;
    this.fields = this.data.fields;
    this.hasCloselinks = this.data.hasCloselinks;
    this.isNotOnboarding = this.data.isNotOnboarding;
    this.fieldsMap.clear();

    this.closeLinks.forEach((closeLink) => {
      // get director-close link row - each combination is unique
      var directorCloseLink = this.directorCloseLinks.filter(
        (directorCloseLink) =>
          directorCloseLink.closeLinkId === closeLink.id &&
          directorCloseLink.directorId === this.directorId
      )[0];
      if (directorCloseLink) {
        this.directorCloseLinksControl.push(
          this.fb.group(
            {
              name: closeLink.name,
              registrationNumber: closeLink.registrationNumber,
              incorporationDate: closeLink.incorporationDate,
              address: closeLink.address,
              directorId: directorCloseLink.directorId,
              closeLinkId: directorCloseLink.closeLinkId,
              isDirector: directorCloseLink.isDirector,
              isShareholder: directorCloseLink.isShareholder,
              isUBO: directorCloseLink.isUBO,
              percentageOwnedByShareholder: [
                directorCloseLink.percentageOwnedByShareholder,
                this.percentageValidators,
              ],
              percentageOwnedByUBO: [
                directorCloseLink.percentageOwnedByUBO,
                this.percentageValidators,
              ],
            },
            { validators: checkboxAndPercentagesValidator() }
          )
        );
        // fieldsMap
        this.fields
          ?.filter(
            (field) =>
              !!field.comment &&
              field.id === OnboardingFieldId.BUSINESS_CLOSE_LINK_DIRECTOR &&
              field.closeLinkId === directorCloseLink.closeLinkId &&
              field.directorId === directorCloseLink.directorId
          )
          .forEach((field) => {
            let commentedValue = field.commentedValue;
            this.fieldsMap.set(
              { directorId: field.directorId, closeLinkId: field.closeLinkId },
              {
                comment: field.comment,
                commentedValue,
              }
            );
          });
      } else {
        this.directorCloseLinksControl.push(
          this.fb.group(
            {
              name: closeLink.name,
              registrationNumber: closeLink.registrationNumber,
              incorporationDate: closeLink.incorporationDate,
              address: closeLink.address,
              directorId: this.directorId,
              closeLinkId: closeLink.id,
              isDirector: false,
              isShareholder: false,
              isUBO: false,
              percentageOwnedByShareholder: [null, this.percentageValidators],
              percentageOwnedByUBO: [null, this.percentageValidators],
            },
            { validators: checkboxAndPercentagesValidator() }
          )
        );
      }
    });
  }

  ngOnDestroy() {
    if (this.dialogRefAddCompanyCloseLink) {
      this.dialogRefAddCompanyCloseLink.close();
    }
    if (this.dialogRefUpdateCompanyCloseLink) {
      this.dialogRefUpdateCompanyCloseLink.close();
    }
  }

  // show the warning only if the close link is connected to the key person
  isInvalidCloseLink(cl: any) {
    return (
      cl?.directorId === this.directorId &&
      (!cl.name ||
        !cl.incorporationDate ||
        !cl.registrationNumber ||
        !cl.address?.streetAddress ||
        !cl.address?.city ||
        !cl.address?.postCode ||
        !cl.address?.countryId)
    );
  }

  getField(closeLinkId: number) {
    for (let [key, value] of this.fieldsMap) {
      if (
        key.directorId === this.directorId &&
        key.closeLinkId === closeLinkId
      ) {
        return value;
      }
    }
    return null;
  }

  addCompanyCloseLink(): void {
    this.dialogRefAddCompanyCloseLink =
      this.dialog.open<CompanyCloseLinkDialogComponent>(
        CompanyCloseLinkDialogComponent,
        {
          panelClass: 'dialog-with-close-button',
          width: '570px',
          disableClose: true,
          data: {
            countries: this.countries,
            isNotOnboarding: this.isNotOnboarding,
          },
        }
      );
    this.dialogRefAddCompanyCloseLink
      .afterClosed()
      .subscribe((newCloseLink) => {
        if (newCloseLink) {
          // update close links
          this.closeLinks = [...this.closeLinks, newCloseLink];
          // update director close link form
          this.directorCloseLinksControl.push(
            this.fb.group(
              {
                name: newCloseLink.name,
                registrationNumber: newCloseLink.registrationNumber,
                incorporationDate: newCloseLink.incorporationDate,
                address: newCloseLink.address,
                directorId: this.directorId,
                closeLinkId: newCloseLink.id,
                isDirector: false,
                isShareholder: false,
                isUBO: false,
                percentageOwnedByShareholder: [null, this.percentageValidators],
                percentageOwnedByUBO: [null, this.percentageValidators],
              },
              { validators: checkboxAndPercentagesValidator() }
            )
          );
          this.companyCloseLinksChange.emit(this.closeLinks); // emit to update company close links of other key persons
        }
      });
  }

  updateCompanyCloseLink(index: number) {
    this.dialogRefUpdateCompanyCloseLink =
      this.dialog.open<CompanyCloseLinkDialogComponent>(
        CompanyCloseLinkDialogComponent,
        {
          panelClass: 'dialog-with-close-button',
          width: '570px',
          disableClose: true,
          data: {
            countries: this.countries,
            companyCloseLink: this.closeLinks[index],
            isReviewed: this.isReviewed,
            isFirstTimeEDD: this.isFirstTimeEDD,
            fields: this.fields,
            isNotOnboarding: this.isNotOnboarding,
          },
        }
      );
    this.dialogRefUpdateCompanyCloseLink
      .afterClosed()
      .subscribe((updatedCloseLink: CompanyCloseLink) => {
        if (updatedCloseLink) {
          // update close links
          this.closeLinks[index] = updatedCloseLink;
          // update control close links
          this.getNameControl(index)?.patchValue(updatedCloseLink.name);
          this.getRegistrationNumberControl(index)?.patchValue(
            updatedCloseLink.registrationNumber
          );
          this.getIncorporationDateControl(index)?.patchValue(
            updatedCloseLink.incorporationDate
          );
          this.getAddressControl(index)?.patchValue(updatedCloseLink.address);
          this.companyCloseLinksChange.emit(this.closeLinks);
        }
      });
  }

  saveDirectorCloseLinks() {
    this.isAdding = true;
    var companyDirectorCloseLinks: CompanyDirectorCloseLink[] = [];
    for (let directorCloseLink of this.directorCloseLinksControl?.value) {
      if (
        !(
          !directorCloseLink.isDirector &&
          !directorCloseLink.isShareholder &&
          !directorCloseLink.isUBO
        )
      ) {
        // only send the ones that don't have empty status
        const item: CompanyDirectorCloseLink = {
          directorId: directorCloseLink.directorId,
          closeLinkId: directorCloseLink.closeLinkId,
          isDirector: directorCloseLink.isDirector,
          isShareholder: directorCloseLink.isShareholder,
          isUBO: directorCloseLink.isUBO,
          percentageOwnedByShareholder:
            directorCloseLink.percentageOwnedByShareholder,
          percentageOwnedByUBO: directorCloseLink.percentageOwnedByUBO,
        };
        companyDirectorCloseLinks.push(item);
      }
    }
    if (this.isNotOnboarding) {
      // emit only the director's close link combinations
      this.isAdding = false;
      this.companyDirectorCloseLinksChange.emit(companyDirectorCloseLinks);
      this.form.markAsPristine(); // disable save button after change is saved
      this.dialogRef.close();
    } else {
      this.onboardingService
        .updateCompanyDirectorCloseLink(
          this.directorId,
          companyDirectorCloseLinks
        )
        .subscribe(
          (updatedDirectorCloseLinks) => {
            this.isAdding = false;
            // emit close link combinations of all directors
            this.directorCloseLinks = updatedDirectorCloseLinks;
            this.companyDirectorCloseLinksChange.emit(
              updatedDirectorCloseLinks
            );
            this.form.markAsPristine(); // disable save button after change is saved
            this.dialogRef.close();
          },
          () => {
            this.errorService.showErrorDialog();
            this.isAdding = false;
          }
        );
    }
  }

  // cannot remove close links after kyc verified
  removeCompanyCloseLink(index: number) {
    this.isRemoving = true;
    const currentCompanyCloseLink =
      this.getDirectorCloseLinkControl(index).value;
    const address: Address = {
      streetAddress: '',
      additionalStreetAddress: '',
      postCode: '',
      city: '',
      countryId: 60,
    };
    const companyCloseLink: SaveCompanyCloseLink = {
      name: '',
      registrationNumber: '',
      incorporationDate: '',
      address: address,
    };
    this.onboardingService
      .updateCompanyCloseLink(
        currentCompanyCloseLink.closeLinkId,
        companyCloseLink
      )
      .subscribe(
        (newCloseLink) => {
          this.isRemoving = false;
          if (!newCloseLink) {
            this.closeLinks.splice(index, 1);
            this.directorCloseLinksControl?.removeAt(index);
            this.companyCloseLinksChange.emit(this.closeLinks);
          }
        },
        (error) => {
          if (error.status === 409) {
            this.errorService.showErrorDialog(error.error.message);
          } else {
            this.errorService.showErrorDialog();
          }
          this.isRemoving = false;
        }
      );
  }

  convertDate(val: string) {
    return val ? DateTime.fromISO(val).toLocaleString(DateTime.DATE_MED) : null;
  }

  // director close links
  get directorCloseLinksControl(): FormArray {
    return this.form.get('directorCloseLinksForm') as FormArray;
  }
  getDirectorCloseLinkControl(index: number): FormGroup {
    return this.directorCloseLinksControl.at(index) as FormGroup;
  }
  // key person position
  getIsShareholderControl(index: number): AbstractControl | null {
    return this.getDirectorCloseLinkControl(index).get('isShareholder');
  }
  getIsUBOControl(index: number): AbstractControl | null {
    return this.getDirectorCloseLinkControl(index).get('isUBO');
  }
  // percentages of shares
  getPercentageOwnedByShareholderControl(
    index: number
  ): AbstractControl | null {
    return this.getDirectorCloseLinkControl(index).get(
      'percentageOwnedByShareholder'
    );
  }
  getPercentageOwnedByUBOControl(index: number): AbstractControl | null {
    return this.getDirectorCloseLinkControl(index).get('percentageOwnedByUBO');
  }
  // close link info
  getNameControl(index: number): AbstractControl | null {
    return this.getDirectorCloseLinkControl(index).get('name');
  }
  getRegistrationNumberControl(index: number): AbstractControl | null {
    return this.getDirectorCloseLinkControl(index).get('registrationNumber');
  }
  getIncorporationDateControl(index: number): AbstractControl | null {
    return this.getDirectorCloseLinkControl(index).get('incorporationDate');
  }
  getAddressControl(index: number): AbstractControl | null {
    return this.getDirectorCloseLinkControl(index).get('address');
  }
}
