import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NgControl,
  Validators,
} from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { OnboardingAdminField } from '../../../models/onboarding-admin-field.model';
import { OnboardingFieldId } from '../../../models/onboarding-field-id.enum';
import { UsersService } from '../../../users.service';

interface AdminField {
  id?: number; // id of account/counterparty/key person/close link/group member
  closeLinkId?: number; // id of close link when referring to key person-close link combination
  value: string;
  fieldId: OnboardingFieldId;
  onboardingField: OnboardingAdminField;
}

@Component({
  selector: 'app-admin-field-new',
  templateUrl: './admin-field.component.html',
  styleUrls: ['./admin-field.component.scss'],
})
export class AdminFieldNewComponent implements ControlValueAccessor, OnInit {
  @Input() label!: string;
  @Input() userId!: number;
  @Input() isEDD?: boolean;
  @Input() disableComment?: boolean = false;
  @Input() allowResendIdVerificationUrl: boolean = false;
  generateCorrectDataTestId(name: string, suffix: string): string {
    const nameWithDashes =
      name
        .replace(/\(.*\)/, '')
        .toLowerCase()
        .split(' ')
        .join('-') + suffix;
    return nameWithDashes.split('/').join('-').replace(/\-\-/, '-');
  }

  OnboardingFieldId = OnboardingFieldId;
  value!: AdminField;
  onChange = (val: any) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  isLoading: boolean = false;
  isSending: boolean = false;
  showComment: boolean = false;

  constructor(
    private ngControl: NgControl,
    private errorService: ErrorService,
    private usersService: UsersService,
    private fb: FormBuilder
  ) {
    this.ngControl.valueAccessor = this;
  }

  directorForm!: FormGroup;

  ngOnInit(): void {
    if (this.allowResendIdVerificationUrl) {
      this.directorForm = this.fb.group({
        id: this.value.id,
        email: [
          this.value.value,
          [
            Validators.required,
            Validators.email,
            Validators.pattern('^[a-zA-Z0-9@\\.\\-_]{1,255}$'),
          ],
        ],
      });
    }
  }
  get emailControl(): AbstractControl | null {
    return this.directorForm.get('email');
  }

  addOrEditComment(val: string): void {
    this.isLoading = true;
    this.ngControl.control?.markAsPending();
    // send id of bank/pi account/counterparty/director/close link/group member
    this.usersService
      .addOrModifyFormCommment(
        this.userId,
        this.value.fieldId,
        val,
        this.value.id,
        this.value.closeLinkId
      )
      .subscribe(
        () => {
          this.value = {
            ...this.value,
            onboardingField: {
              ...this.value.onboardingField,
              comment: val,
            },
          };
          this.onChange(this.value);
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.errorService.showErrorDialog(error.error.message);
          this.value = {
            ...this.value,
            onboardingField: {
              ...this.value.onboardingField,
              comment: undefined,
            },
          };
          this.onChange(this.value);
          this.showComment = false; // don't show comment if error exists
        }
      );
  }

  removeComment(commentInput: MatInput): void {
    if (this.value.onboardingField.comment) {
      this.isLoading = true;
      this.ngControl.control?.markAsPending();
      this.usersService
        .removeFormComment(
          this.userId,
          this.value.fieldId,
          this.value.id,
          this.value.closeLinkId
        )
        .subscribe(
          () => {
            this.value = {
              ...this.value,
              onboardingField: {
                ...this.value.onboardingField,
                comment: undefined,
              },
            };
            this.onChange(this.value);
            this.isLoading = false;
            this.showComment = false;
          },
          () => {
            this.isLoading = false;
            this.errorService.showErrorDialog();
          }
        );
    } else {
      this.value.onboardingField.comment = undefined; // needed for validation purposes
      this.onChange(this.value);
      this.showComment = false;
      commentInput.ngControl.control?.markAsUntouched(); // needed for clearing validation
    }
  }

  startCommenting(commentInput: MatInput): void {
    this.showComment = true;
    this.value.onboardingField.comment = ''; // needed for validation purposes
    this.onChange(this.value);
    setTimeout(() => commentInput.focus());
  }

  // Methods below are needed for ControlValueAccessor
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
  writeValue(val: any) {
    this.value = val;
  }

  resendKeyPersonVerificationUrl(): void {
    this.isSending = true;
    this.usersService
      .resendKeyPersonVerificationUrl(
        this.userId,
        this.value.id,
        this.emailControl?.value
      )
      .subscribe(
        () => {
          this.isSending = false;
        },
        (error) => {
          this.isSending = false;
          this.errorService.showErrorDialog(error.error.message);
        }
      );
  }
}
