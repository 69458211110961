import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { AccountInfo } from 'src/app/dashboard/models/accountInfo.model';
import { currencies } from 'src/app/dashboard/shared/currencies';
import { InwardOutward } from '../models/inward-outward.enum';
import { TransactionInfoResponse } from '../models/transaction-info-response.model';
import { StatementService } from '../statement.service';

@Component({
  selector: 'app-statement-details-pdf',
  templateUrl: './statement-details-pdf.component.html',
  styleUrls: ['./statement-details-pdf.component.scss'],
})
export class StatementDetailsPdfComponent implements OnInit {
  pfsTransactionId?: string;
  transactionDetails?: TransactionInfoResponse;
  accountInfo?: AccountInfo;
  currency?: string;
  inwardOutwardEnum = InwardOutward;

  constructor(
    private route: ActivatedRoute,
    private statementService: StatementService
  ) {}

  ngOnInit(): void {
    // TODO
    // this.route.queryParams
    //   .pipe(
    //     first(),
    //     switchMap(({ numAccountId, pfsTransactionID }) => {
    //       this.pfsTransactionId = pfsTransactionID;
    //       return forkJoin([
    //         this.statementService.getPaymentDetails({
    //           numAccountId,
    //           pfsTransactionID,
    //         }),
    //         this.statementService.getAccountInfo(numAccountId),
    //       ]);
    //     })
    //   )
    //   .subscribe(([t, a]) => {
    //     this.transactionDetails = t;
    //     this.accountInfo = a;
    //     this.currency = currencies.find(
    //       (c) => c.isoNumber === a?.cardInfo.accountBaseCurrency
    //     )?.short;
    //   });
  }
}
