<app-base-login-page title="Identity Verification">
  <app-big-loader *ngIf="isLoading"></app-big-loader>
  <app-login-card *ngIf="!isLoading && form" imgName="person.svg">
    <h2 *ngIf="verificationAccepted">
      Verification successful!<br />
      You can now close the page.
    </h2>

    <ng-container *ngIf="!verificationAccepted">
      <h2>Verify your identity</h2>
      <div>
        Please make sure that the documents you upload satisfy the following
        requirements:
        <li>Documents uploaded must be in a readable format.</li>
        <li>All documents should be in English or Greek.</li>
        <li>
          Ensure that your data are exactly as shown on your identity document
        </li>
      </div>
      <br />
    </ng-container>

    <ng-container [formGroup]="form">
      <div fxLayout="column" class="field">
        <label for="firstName">First name</label>
        <mat-form-field appearance="outline" class="g-disabled-grey">
          <input matInput name="firstName" formControlName="firstName" />
          <mat-error
            data-testid="validation-message-box"
            *ngIf="firstNameControl?.hasError('required')"
            >Field is required</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="firstNameControl?.hasError('pattern')"
            >Forbidden character used</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="firstNameControl?.hasError('maxlength')"
            >First name can’t be longer than 50 characters</mat-error
          >
        </mat-form-field>
      </div>

      <div fxLayout="column" class="field">
        <label for="lastName">Last name</label>
        <mat-form-field appearance="outline" class="g-disabled-grey">
          <input matInput name="lastName" formControlName="lastName" />
          <mat-error
            data-testid="validation-message-box"
            *ngIf="lastNameControl?.hasError('required')"
            >Field is required</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="lastNameControl?.hasError('pattern')"
            >Forbidden character used</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="lastNameControl?.hasError('maxlength')"
            >Last name can’t be longer than 50 characters</mat-error
          >
        </mat-form-field>
      </div>

      <div fxLayout="column" class="field">
        <label for="dateOfBirth">Date of Birth</label>
        <mat-form-field appearance="outline" class="g-disabled-grey">
          <input
            matInput
            [matDatepicker]="picker"
            name="dateOfBirth"
            formControlName="dateOfBirth"
            [max]="minDob"
            readonly
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="dateOfBirthControl?.hasError('required')"
            >Field is required</mat-error
          >
        </mat-form-field>
      </div>

      <div fxLayoutAlign="center" *ngIf="!verificationAccepted">
        <button
          mat-flat-button
          color="accent"
          class="save"
          [disabled]="
            form.pristine || form.invalid || isSaving || verificationAccepted
          "
          [appButtonLoader]="isSaving"
          (click)="updateKeyPersonVerificationForm()"
        >
          Save changes
        </button>
      </div>
      <mat-divider></mat-divider>

      <div fxLayout="column" *ngIf="!verificationAccepted">
        <div class="text">
          Please ensure that you have your identity document ready, and that
          your camera is enabled.
        </div>
        <button
          mat-flat-button
          color="accent"
          [disabled]="isGettingVerificationUrl"
          [appButtonLoader]="isGettingVerificationUrl"
          (click)="getVerificationUrl()"
        >
          Verify your identity
        </button>
      </div>

      <app-key-person-verified-document-form
        *ngIf="verificationAccepted"
        [documents]="documents"
      ></app-key-person-verified-document-form>
    </ng-container>
  </app-login-card>
</app-base-login-page>
