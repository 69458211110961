import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Country } from 'src/app/shared/models/country.model';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { phoneValidator } from 'src/app/shared/phone.validator';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeMap,
} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ChangeEmailDialogNewComponent } from '../change-email-dialog/change-email-dialog.component';
import { ChangePhoneNumberDialogNewComponent } from '../change-phone-number-dialog/change-phone-number-dialog.component';
import { ChangePasswordDialogNewComponent } from '../change-password-dialog/change-password-dialog.component';
import { UserProfileService } from '../../user-profile.service';
import { Subscription } from 'rxjs';
import {
  atLeastOneCheckboxIsChecked,
  atLeastOneCheckboxIsCheckedCounterparty,
  getCountryById,
  percentageValidators,
} from 'src/app/shared/helpers/various-helpers.helper';
import { AuthService } from 'src/app/login/services/auth.service';
import { Program } from 'src/app/login/models/program.enum';
import { BusinessUserProfileData } from '../../models/business-user-profile-data.model';
import { AccountInformationDialogNewComponent } from '../account-information-dialog/account-information-dialog.component';
import { UserEventsService } from '../../../shared/services/user-events.service';
import { ServerSentEventType } from '../../../shared/models/server-sent-event-type.enum';
import { CompanyAccount } from 'src/app/onboarding-new/models/company-account.model';
import { CompanyCloseLink } from 'src/app/onboarding-new/models/company-close-link.model';
import { CompanyCounterparty } from 'src/app/onboarding-new/models/company-counterparty.model';
import { CompanyDirectorCloseLink } from 'src/app/onboarding-new/models/company-director-close-link.model';
import { CompanyGroupMember } from 'src/app/onboarding-new/models/company-group-member.model';
import { Occupation } from 'src/app/onboarding-new/models/occupation.enum';
import { CashPercentage } from 'src/app/onboarding-new/models/cash-percentage.enum';
import { LegalStatus } from 'src/app/onboarding-new/models/legal-status.enum';
import { AccountPurposeBusiness } from 'src/app/onboarding-new/models/account-purpose-business.enum';
import { DateTime } from 'luxon';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { v4 as uuidv4 } from 'uuid';
import { CompanyDirectorWithDocuments } from 'src/app/onboarding-new/models/company-director.model';
import { CompanyCloseLinkSelectDialogComponent } from 'src/app/onboarding-new/shared/company-close-link-select-dialog/company-close-link-select-dialog.component';
import { CorporateForm } from 'src/app/onboarding-new/models/corporate-form.model';
import { SaveRegisteredUser } from 'src/app/admin/users/registered-users/models/save-registered-user.model';
import { RegistrationService } from 'src/app/login/services/registration.service';

@Component({
  selector: 'app-user-profile-business-new',
  templateUrl: './user-profile-business.component.html',
  styleUrls: ['./user-profile-business.component.scss'],
})
export class UserProfileBusinessNewComponent implements OnInit, OnDestroy {
  @Input() requestMoreData!: boolean;
  @Input() businessUserProfileData!: BusinessUserProfileData;
  @Input() countries: Country[] = [];
  @Input() companyDirectors!: CompanyDirectorWithDocuments[];
  @Input() companyAccounts?: CompanyAccount[];
  @Input() companyCounterparties?: CompanyCounterparty[];
  @Input() companyGroupMembers?: CompanyGroupMember[];
  @Input() companyCloseLinks?: CompanyCloseLink[];
  @Input() companyDirectorCloseLinks?: CompanyDirectorCloseLink[];
  @Output() readonly dataChanged = new EventEmitter<void>();

  form!: FormGroup;
  isSaving: boolean = false;
  statusCheckboxes = [
    'isDirector',
    'isShareholder',
    'isAuthorizedPerson',
    'isUBO',
    'percentageOwnedByShareholder',
    'percentageOwnedByUBO',
  ];

  // TODO: Decide what to do with documents and which fields to not change later (see registered-user-profile-corporate-new)

  registeredAddressTooltip: string =
    "The registered address is the official address of the company, registered with the Company's house.";
  operatingAddressTooltip: string =
    'The operating address is where the company has existence of a place of business or activity (registered branch).';
  closeLinksTooltip: string =
    'A situation in which two or more natural or legal persons are linked by: (a) participation in the form of ownership, direct or by way of control, of 20 % or more of the voting rights or capital of an undertaking; (b) ‘control’ which means the relationship between a parent undertaking and a subsidiary, in all the cases referred to in Article 22(1) and (2) of Directive 2013/34/EU, or a similar relationship between any natural or legal person and an undertaking, any subsidiary undertaking of a subsidiary undertaking also being considered to be a subsidiary of the parent undertaking which is at the head of those undertakings;  (c) a permanent link of both or all of them to the same person by a control relationship.';

  getCountryById = getCountryById;
  occupations = Object.entries(Occupation);
  cashPercentages = Object.entries(CashPercentage);
  legalStatuses = Object.entries(LegalStatus);
  accountPurposes = Object.entries(AccountPurposeBusiness);
  LegalStatus = LegalStatus;
  today: Date = new Date();
  highRiskCountries: string[] = [];

  readonly minAge = 18;
  minDob: Date = new Date(
    this.today.getFullYear() - this.minAge,
    this.today.getMonth(),
    this.today.getDate()
  );
  percentageValidators = percentageValidators;

  program?: Program;
  programs = Program;
  marketingConsentForm!: FormGroup;

  avatarUrl?: string | null = null;
  avatarErrorMessage = '';
  isAvatarUploading = false;
  isLoading?: boolean = false;
  userId!: number;

  private eventSubscription?: Subscription;
  private isMarketingConsentSubscription?: Subscription;
  private authenticatedUserSubscription?: Subscription;

  constructor(
    private fb: FormBuilder,
    private userProfileService: UserProfileService,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private authService: AuthService,
    private userEventsService: UserEventsService,
    private ngZone: NgZone,
    private registrationService: RegistrationService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      entityForm: this.fb.group({
        businessName: [
          this.businessUserProfileData?.businessName,
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .`'\\/()@_+#=&-]*$"),
            Validators.maxLength(100),
          ],
        ],
        businessTradingName: [
          this.businessUserProfileData?.businessTradingName,
          [
            Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
            Validators.maxLength(100),
          ],
        ],
        taxNumber: [
          this.businessUserProfileData?.taxNumber,
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
            Validators.maxLength(50),
          ],
        ],
        vatNumber: [
          this.businessUserProfileData?.vatNumber,
          [
            Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
            Validators.maxLength(50),
          ],
        ],
        companyUrls: [
          this.businessUserProfileData?.companyUrls,
          [
            Validators.pattern(
              "^[a-zA-Z0-9 ~!@#$%&*\\(\\)\\-\\+=[\\]\\/:;',.?_]*$"
            ),
            Validators.maxLength(200),
          ],
        ],
        companyActivity: [
          this.businessUserProfileData?.companyActivity,
          Validators.required,
        ],
        companyActivityDescr: [
          this.businessUserProfileData?.companyActivityDescr,
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
            Validators.maxLength(1000),
          ],
        ],
        isRegulated: [
          this.businessUserProfileData?.isRegulated,
          Validators.required,
        ],
        regulatoryAuthority: [
          this.businessUserProfileData?.regulatoryAuthority,
          [
            Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
            Validators.maxLength(200),
          ],
        ],
        canFundFromOwnAccount: [
          this.businessUserProfileData?.canFundFromOwnAccount,
          Validators.required,
        ],
        annualIncome: [
          this.businessUserProfileData?.annualIncome
            ? parseInt(
                this.businessUserProfileData?.annualIncome
              ).toLocaleString('en-US')
            : null,
          [
            Validators.required,
            Validators.maxLength(19),
            Validators.pattern('^(?!.*,,)[0-9]+(?:,[0-9]+)*$'), // prohibits comma at the start/end and consecutive commas
          ],
        ],
        accountPurpose: [
          this.businessUserProfileData?.accountPurpose
            ? this.businessUserProfileData?.accountPurpose.split(', ')
            : '',
          [Validators.required],
        ],
        monthlyLoading: [
          this.businessUserProfileData?.monthlyLoading
            ? parseInt(
                this.businessUserProfileData?.monthlyLoading
              ).toLocaleString('en-US')
            : null,
          [
            Validators.required,
            Validators.maxLength(19),
            Validators.pattern('^(?!.*,,)[0-9]+(?:,[0-9]+)*$'), // prohibits comma at the start/end and consecutive commas
          ],
        ],
        percentageOfCash: [
          this.businessUserProfileData?.percentageOfCash,
          Validators.required,
        ],
        doubleAnnualIncome: [
          this.businessUserProfileData?.doubleAnnualIncome,
          Validators.required,
        ],
        zeroBalanceStatement: [
          this.businessUserProfileData?.zeroBalanceStatement,
          Validators.required,
        ],
        legalStatus: [
          this.isOtherLegalStatus()
            ? LegalStatus['Other type of Company']
            : this?.businessUserProfileData?.legalStatus,
          Validators.required,
        ],
        otherLegalStatus: [
          this.isOtherLegalStatus()
            ? this?.businessUserProfileData?.legalStatus
            : null,
          [
            Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
            Validators.maxLength(100),
          ],
        ],
        operateInHighRiskCountries: [
          this.businessUserProfileData?.operateInHighRiskCountries,
          Validators.required,
        ],
        isFinancialStatementAudited: [
          this.businessUserProfileData?.isFinancialStatementAudited,
          Validators.required,
        ],
        expectedNumOfPhysicalCards: [
          this.businessUserProfileData?.expectedNumOfPhysicalCards,
          Validators.required,
        ],
        incorporationDate: [
          this.businessUserProfileData?.incorporationDate
            ? DateTime.fromISO(this.businessUserProfileData?.incorporationDate)
            : null,
          Validators.required,
        ],
        registrationNumber: [
          this.businessUserProfileData?.registrationNumber,
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
            Validators.maxLength(50),
          ],
        ],
        additionalPhoneNumber: [
          this.businessUserProfileData?.additionalPhoneNumber,
          phoneValidator(),
        ],
        incorporationAddress: this.fb.group({
          streetAddress: [
            this.businessUserProfileData?.incorporationAddress?.streetAddress,
            [
              Validators.required,
              Validators.pattern("^[a-zA-Z0-9 .,&`'\\/()-]*$"),
              Validators.maxLength(100),
            ],
          ],
          additionalStreetAddress: [
            this.businessUserProfileData?.incorporationAddress
              ?.additionalStreetAddress,
            [
              Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
              Validators.maxLength(100),
            ],
          ],
          postCode: [
            this.businessUserProfileData?.incorporationAddress?.postCode,
            [
              Validators.required,
              Validators.pattern('^[a-zA-Z0-9 ()\\/-]*$'),
              Validators.maxLength(50),
            ],
          ],
          city: [
            this.businessUserProfileData?.incorporationAddress?.city,
            [
              Validators.required,
              Validators.pattern("^[a-zA-Z0-9 `'\\/()-]*$"),
              Validators.maxLength(50),
            ],
          ],
          countryId: [
            this.businessUserProfileData?.incorporationAddress?.countryId,
            Validators.required,
          ],
        }),
        operatingAddress: this.fb.group({
          streetAddress: [
            this.businessUserProfileData?.operatingAddress?.streetAddress,
            [
              Validators.required,
              Validators.pattern("^[a-zA-Z0-9 .,&`'\\/()-]*$"),
              Validators.maxLength(100),
            ],
          ],
          additionalStreetAddress: [
            this.businessUserProfileData?.operatingAddress
              ?.additionalStreetAddress,
            [
              Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
              Validators.maxLength(100),
            ],
          ],
          postCode: [
            this.businessUserProfileData?.operatingAddress?.postCode,
            [
              Validators.required,
              Validators.pattern('^[a-zA-Z0-9 ()\\/-]*$'),
              Validators.maxLength(50),
            ],
          ],
          city: [
            this.businessUserProfileData?.operatingAddress?.city,
            [
              Validators.required,
              Validators.pattern("^[a-zA-Z0-9 `'\\/()-]*$"),
              Validators.maxLength(50),
            ],
          ],
          countryId: [
            this.businessUserProfileData?.operatingAddress?.countryId,
            Validators.required,
          ],
        }),
        isSameAddress: [
          JSON.stringify(this.businessUserProfileData?.incorporationAddress) ===
            JSON.stringify(this.businessUserProfileData?.operatingAddress),
          Validators.required,
        ],
      }),
      directorForm: this.fb.array([], {
        validators: [
          this.sharesValidator.bind(this), // shares don't exceed 100%
          this.positionValidator.bind(this), // only one authorized person and at least one director/shareholder/UBO
        ],
      }),
      groupMemberForm: this.fb.array([]),
      closeLinkForm: this.fb.array([]),
      accountForm: this.fb.array([]),
      counterpartyForm: this.fb.array([], {
        validators: [
          this.atLeastOneIncomingOutgoingTransaction.bind(this), // at least one incoming and one outgoing transaction
        ],
      }),
    });

    this.companyDirectors.forEach((director) => {
      this.addDirector(director);
    });

    if (this.companyAccounts) {
      this.companyAccounts.forEach((account) => {
        this.addAccount(account);
      });
    }

    if (this.companyCounterparties) {
      this.companyCounterparties.forEach((account) => {
        this.addCounterparty(account);
      });
    }

    if (this.companyGroupMembers) {
      this.companyGroupMembers.forEach((groupMember) => {
        this.addGroupMember(groupMember);
      });
    }

    this.incorporationDateControl?.disable();

    this.additionalPhoneNumberControl?.valueChanges
      .pipe(first())
      .subscribe((val) => {
        // removes redundant prefix from phone number when set from backend
        this.additionalPhoneNumberControl?.setValue(val, {
          emitEvent: false,
        });
        setTimeout(() => this.additionalPhoneNumberControl?.markAsPristine());
      });

    this.registrationService.getHighRiskCountries().subscribe((countries) => {
      this.highRiskCountries = countries.map((c) =>
        getCountryById(countries, c.id)
      );
    });

    // conditional validators
    this.isRegulatedControl?.valueChanges.subscribe((val) => {
      if (val) {
        this.entityFormControl
          .get('regulatoryAuthority')
          ?.setValidators([
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
            Validators.maxLength(200),
          ]);
      } else {
        this.entityFormControl.get('regulatoryAuthority')?.clearValidators();
        this.entityFormControl.get('regulatoryAuthority')?.setValue(null);
      }
      this.entityFormControl
        .get('regulatoryAuthority')
        ?.updateValueAndValidity();
    });

    this.legalStatusControl?.valueChanges.subscribe((val) => {
      if (val === LegalStatus['Other type of Company']) {
        this.entityFormControl
          .get('otherLegalStatus')
          ?.setValidators([
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
            Validators.maxLength(100),
          ]);
      } else {
        this.entityFormControl.get('otherLegalStatus')?.clearValidators();
      }
      this.entityFormControl.get('otherLegalStatus')?.updateValueAndValidity();
    });

    this.marketingConsentForm = this.fb.group({
      isMarketingConsent: this.businessUserProfileData.isMarketingConsent,
    });

    this.isMarketingConsentSubscription =
      this.isMarketingConsentControl.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((val) =>
          this.userProfileService
            .updateMarketingConsent({
              isMarketingConsent: val,
            })
            .subscribe(
              () => {},
              () => this.errorService.showErrorDialog()
            )
        );

    this.authenticatedUserSubscription = this.authService
      .getAuthenticatedUserObs()
      .subscribe((authenticatedUser) => {
        this.program = authenticatedUser?.program;
        this.avatarUrl = authenticatedUser?.avatarUrl;
        this.userId = authenticatedUser!.id;
      });

    if (!this.form.valid) {
      this.form.markAllAsTouched();
    }
  }

  isOtherLegalStatus() {
    return (
      this?.businessUserProfileData?.legalStatus &&
      this?.businessUserProfileData?.legalStatus !==
        LegalStatus['Private Limited Company'] &&
      this?.businessUserProfileData?.legalStatus !==
        LegalStatus['Public Limited Company']
    );
  }

  addDirector(director: CompanyDirectorWithDocuments) {
    if (director.isLegalPerson) {
      this.directorFormControl.push(
        this.fb.group(
          {
            id: [director.id],
            firstName: null,
            lastName: null,
            isDirector: [director.isDirector, { updateOn: 'change' }],
            isShareholder: [director.isShareholder, { updateOn: 'change' }],
            isUBO: [director.isUBO, { updateOn: 'change' }],
            isAuthorizedPerson: [
              director.isAuthorizedPerson,
              { updateOn: 'change' },
            ],
            email: [
              director.email,
              [
                Validators.required,
                Validators.email,
                Validators.pattern('^[a-zA-Z0-9@\\.\\-_]{1,255}$'),
              ],
            ],
            dateOfBirth: null,
            nationality: null,
            address: this.fb.group({
              streetAddress: [
                director.address?.streetAddress,
                [
                  Validators.required,
                  Validators.pattern("^[a-zA-Z0-9 .,&`'\\/()-]*$"),
                  Validators.maxLength(100),
                ],
              ],
              additionalStreetAddress: [
                director.address?.additionalStreetAddress,
                [
                  Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
                  Validators.maxLength(100),
                ],
              ],
              postCode: [
                director.address?.postCode,
                [
                  Validators.required,
                  Validators.pattern('^[a-zA-Z0-9 ()\\/-]*$'),
                  Validators.maxLength(50),
                ],
              ],
              city: [
                director.address?.city,
                [
                  Validators.required,
                  Validators.pattern("^[a-zA-Z0-9 `'\\/()-]*$"),
                  Validators.maxLength(50),
                ],
              ],
              countryId: [director.address?.countryId, Validators.required],
            }),
            isPEP: null,
            phoneNumber: null,
            taxNumber: [director?.taxNumber],
            taxResidencyCountry: director?.taxResidencyCountry,
            percentageOwnedByShareholder: [
              director?.percentageOwnedByShareholder,
              this.percentageValidators,
            ],
            percentageOwnedByUBO: [
              director?.percentageOwnedByUBO,
              this.percentageValidators,
            ],
            isLegalPerson: [true, Validators.requiredTrue],
            legalPersonName: [
              director?.legalPersonName,
              [
                Validators.required,
                Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
                Validators.maxLength(100),
              ],
            ],
            legalPersonRegistrationNumber: [
              director?.legalPersonRegistrationNumber,
              [
                Validators.required,
                Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
                Validators.maxLength(50),
              ],
            ],
            legalPersonIncorporationDate: [
              director?.legalPersonIncorporationDate
                ? DateTime.fromISO(director?.legalPersonIncorporationDate)
                : null,
              Validators.required,
            ],
            isActive: [director?.isActive, Validators.required],
            hasCloseLinks: [director?.hasCloseLinks, { updateOn: 'change' }],
            // documents: [director?.documents],
          },
          {
            updateOn: 'blur',
            validators: [
              atLeastOneCheckboxIsChecked(),
              this.closeLinksFilled(director.id),
            ],
          }
        )
      );
    } else {
      this.directorFormControl.push(
        this.fb.group(
          {
            id: [director.id],
            firstName: [
              director.firstName,
              [
                Validators.required,
                Validators.pattern("^[a-zA-Z `'.-]*$"),
                Validators.maxLength(50),
              ],
            ],
            lastName: [
              director.lastName,
              [
                Validators.required,
                Validators.pattern("^[a-zA-Z `'.-]*$"),
                Validators.maxLength(50),
              ],
            ],
            isDirector: [director.isDirector, { updateOn: 'change' }],
            isShareholder: [director.isShareholder, { updateOn: 'change' }],
            isUBO: [director.isUBO, { updateOn: 'change' }],
            isAuthorizedPerson: [
              director.isAuthorizedPerson,
              { updateOn: 'change' },
            ],
            email: [
              director.email,
              [
                Validators.required,
                Validators.email,
                Validators.pattern('^[a-zA-Z0-9@\\.\\-_]{1,255}$'),
              ],
            ],
            dateOfBirth: [
              director.dateOfBirth
                ? DateTime.fromISO(director.dateOfBirth)
                : null,
              Validators.required,
            ],
            nationality: [director.nationality, Validators.required],
            address: this.fb.group({
              streetAddress: [
                director.address?.streetAddress,
                [
                  Validators.required,
                  Validators.pattern("^[a-zA-Z0-9 .,&`'\\/()-]*$"),
                  Validators.maxLength(100),
                ],
              ],
              additionalStreetAddress: [
                director.address?.additionalStreetAddress,
                [
                  Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
                  Validators.maxLength(100),
                ],
              ],
              postCode: [
                director.address?.postCode,
                [
                  Validators.required,
                  Validators.pattern('^[a-zA-Z0-9 ()\\/-]*$'),
                  Validators.maxLength(50),
                ],
              ],
              city: [
                director.address?.city,
                [
                  Validators.required,
                  Validators.pattern("^[a-zA-Z0-9 `'\\/()-]*$"),
                  Validators.maxLength(50),
                ],
              ],
              countryId: [director.address?.countryId, Validators.required],
            }),
            isPEP: [director.isPEP, Validators.required],
            phoneNumber: [
              director.phoneNumber,
              { updateOn: 'change' }, // because enabling a disabled director the phoneNumber became invalid
              [Validators.required, phoneValidator()],
            ],
            taxNumber: [
              director.taxNumber,
              [
                Validators.required,
                Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
                Validators.maxLength(50),
              ],
            ],
            taxResidencyCountry: director.taxResidencyCountry, // optional
            percentageOwnedByShareholder: [
              director.percentageOwnedByShareholder,
              this.percentageValidators,
            ],
            percentageOwnedByUBO: [
              director.percentageOwnedByUBO,
              this.percentageValidators,
            ],
            isLegalPerson: false,
            legalPersonName: null,
            legalPersonRegistrationNumber: null,
            legalPersonIncorporationDate: null,
            isActive: [director?.isActive, Validators.required],
            hasCloseLinks: [director?.hasCloseLinks, { updateOn: 'change' }],
            // documents: [director?.documents],
          },
          {
            updateOn: 'blur',
            validators: [
              atLeastOneCheckboxIsChecked(),
              this.closeLinksFilled(director.id),
            ],
          }
        )
      );
    }

    // conditional validators
    this.subscribeDirectorToValueChanges(director.id);
  }

  addNewDirector(isNaturalPerson: boolean): void {
    const id = uuidv4();
    if (isNaturalPerson) {
      this.directorFormControl.push(
        this.fb.group(
          {
            id: [id],
            firstName: [
              null,
              [
                Validators.required,
                Validators.pattern("^[a-zA-Z `'.-]*$"),
                Validators.maxLength(50),
              ],
            ],
            lastName: [
              null,
              [
                Validators.required,
                Validators.pattern("^[a-zA-Z `'.-]*$"),
                Validators.maxLength(50),
              ],
            ],
            isDirector: [false, { updateOn: 'change' }],
            isShareholder: [false, { updateOn: 'change' }],
            isUBO: [false, { updateOn: 'change' }],
            isAuthorizedPerson: [false, { updateOn: 'change' }],
            email: [
              null,
              [
                Validators.required,
                Validators.email,
                Validators.pattern('^[a-zA-Z0-9@\\.\\-_]{1,255}$'),
              ],
            ],
            dateOfBirth: [null, Validators.required],
            nationality: [null, Validators.required],
            address: this.fb.group({
              streetAddress: [
                null,
                [
                  Validators.required,
                  Validators.pattern("^[a-zA-Z0-9 .,&`'\\/()-]*$"),
                  Validators.maxLength(100),
                ],
              ],
              additionalStreetAddress: [
                null,
                [
                  Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
                  Validators.maxLength(100),
                ],
              ],
              postCode: [
                null,
                [
                  Validators.required,
                  Validators.pattern('^[a-zA-Z0-9 ()\\/-]*$'),
                  Validators.maxLength(50),
                ],
              ],
              city: [
                null,
                [
                  Validators.required,
                  Validators.pattern("^[a-zA-Z0-9 `'\\/()-]*$"),
                  Validators.maxLength(50),
                ],
              ],
              countryId: [null, Validators.required],
            }),
            isPEP: [null, Validators.required],
            phoneNumber: [null, [Validators.required, phoneValidator()]],
            taxNumber: [
              null,
              [
                Validators.required,
                Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
                Validators.maxLength(50),
              ],
            ],
            taxResidencyCountry: null, // optional
            percentageOwnedByShareholder: [null, this.percentageValidators],
            percentageOwnedByUBO: [null, this.percentageValidators],
            isLegalPerson: false,
            legalPersonName: null,
            legalPersonRegistrationNumber: null,
            legalPersonIncorporationDate: null,
            isActive: [true, Validators.required],
            hasCloseLinks: [null, { updateOn: 'change' }],
            // documents: [],
          },
          {
            updateOn: 'blur',
            validators: [
              atLeastOneCheckboxIsChecked(),
              this.closeLinksFilled(id),
            ],
          }
        )
      );
    } else {
      this.directorFormControl.push(
        this.fb.group(
          {
            id: [id],
            firstName: null,
            lastName: null,
            isDirector: [false, { updateOn: 'change' }],
            isShareholder: [false, { updateOn: 'change' }],
            isUBO: [false, { updateOn: 'change' }],
            isAuthorizedPerson: [false, { updateOn: 'change' }],
            email: [
              null,
              [
                Validators.required,
                Validators.email,
                Validators.pattern('^[a-zA-Z0-9@\\.\\-_]{1,255}$'),
              ],
            ],
            dateOfBirth: null,
            nationality: null,
            address: this.fb.group({
              streetAddress: [
                null,
                [
                  Validators.required,
                  Validators.pattern("^[a-zA-Z0-9 .,&`'\\/()-]*$"),
                  Validators.maxLength(100),
                ],
              ],
              additionalStreetAddress: [
                null,
                [
                  Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
                  Validators.maxLength(100),
                ],
              ],
              postCode: [
                null,
                [
                  Validators.required,
                  Validators.pattern('^[a-zA-Z0-9 ()\\/-]*$'),
                  Validators.maxLength(50),
                ],
              ],
              city: [
                null,
                [
                  Validators.required,
                  Validators.pattern("^[a-zA-Z0-9 `'\\/()-]*$"),
                  Validators.maxLength(50),
                ],
              ],
              countryId: [null, Validators.required],
            }),
            isPEP: null,
            phoneNumber: null,
            taxNumber: [null],
            taxResidencyCountry: null,
            percentageOwnedByShareholder: [null, this.percentageValidators],
            percentageOwnedByUBO: [null, this.percentageValidators],
            isLegalPerson: [true, Validators.requiredTrue],
            legalPersonName: [
              null,
              [
                Validators.required,
                Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
                Validators.maxLength(100),
              ],
            ],
            legalPersonRegistrationNumber: [
              null,
              [
                Validators.required,
                Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
                Validators.maxLength(50),
              ],
            ],
            legalPersonIncorporationDate: [null, Validators.required],
            isActive: [true, Validators.required],
            hasCloseLinks: [null, { updateOn: 'change' }],
            // documents: [],
          },
          {
            updateOn: 'blur',
            validators: [
              atLeastOneCheckboxIsChecked(),
              this.closeLinksFilled(id),
            ],
          }
        )
      );
    }

    // conditional validators
    this.subscribeDirectorToValueChanges(id);
    // this.areFilesUploaded = false;
  }

  subscribeDirectorToValueChanges(id: number | string) {
    // when searching for index get all directors in the form regardless of the disabled status
    const allDirectors = this.directorFormControl?.getRawValue();
    const directorIndex = allDirectors.findIndex(
      (d: CompanyDirectorWithDocuments) => d.id === id
    );
    if (directorIndex !== -1) {
      const directorControl = this.getDirectorControl(directorIndex);
      // disable non-active directors
      if (directorControl.get('isActive')?.value === false) {
        directorControl.disable();
      } else {
        // set if close links are required or not
        this.setValidatorsForCloseLinks(directorControl, directorIndex);

        // if status/position of key person changes, set if close links are required or not
        this.statusCheckboxes.forEach((checkbox) => {
          directorControl.get(checkbox)?.valueChanges.subscribe(() => {
            this.setValidatorsForCloseLinks(directorControl, directorIndex);
          });
        });

        this.isShareholderControl(directorIndex)?.valueChanges.subscribe(
          (val) => {
            if (val) {
              directorControl
                .get('percentageOwnedByShareholder')
                ?.setValidators(
                  this.percentageValidators.concat(Validators.required)
                );
            } else {
              directorControl
                .get('percentageOwnedByShareholder')
                ?.clearValidators();
            }
            directorControl
              .get('percentageOwnedByShareholder')
              ?.updateValueAndValidity();
          }
        );

        this.isUBOControl(directorIndex)?.valueChanges.subscribe((val) => {
          if (val) {
            directorControl
              .get('percentageOwnedByUBO')
              ?.setValidators(
                this.percentageValidators.concat(Validators.required)
              );
          } else {
            directorControl.get('percentageOwnedByUBO')?.clearValidators();
          }
          directorControl.get('percentageOwnedByUBO')?.updateValueAndValidity();
        });

        // if director says that has no close links - delete its close link connections
        this.hasCloseLinksControl(directorIndex)?.valueChanges.subscribe(
          (val) => {
            if (!val) {
              this.companyDirectorCloseLinks =
                this.companyDirectorCloseLinks?.filter(
                  (dcl) =>
                    !(
                      dcl.directorId ===
                      this.getDirectorControl(directorIndex).value.id
                    )
                );
            }
          }
        );
      }
    }
  }

  setValidatorsForCloseLinks(
    directorControl: FormGroup,
    directorIndex: number
  ) {
    /** set close links to required if:
     * 1. key-person has already completed his close links (hasCloseLinks) regardless of client risk and key-person position, even if he's authorized person
     * OR
     * 2. key-person hasn't completed his close links (hasCloseLinks) and is not the only authorized contact person and client is high risk
     */
    if (
      this.hasCloseLinksControl(directorIndex)?.value !== null ||
      (this.hasCloseLinksControl(directorIndex)?.value === null &&
        !this.isOnlyAuthorizedPerson(directorIndex) &&
        this.requestMoreData)
    ) {
      directorControl
        .get('hasCloseLinks')
        ?.setValidators([Validators.required]);
    } else {
      directorControl.get('hasCloseLinks')?.clearValidators();
    }
    directorControl.get('hasCloseLinks')?.updateValueAndValidity();
  }

  // returns if key person is only authorized contact person
  isOnlyAuthorizedPerson(index: number) {
    return !(
      this.isLegalPersonControl(index)?.value ||
      (!this.isLegalPersonControl(index)?.value &&
        (!this.isAuthorizedPersonControl(index)?.value ||
          this.isDirectorControl(index)?.value ||
          this.isShareholderControl(index)?.value ||
          this.isUBOControl(index)?.value))
    );
  }

  addAccount(account: CompanyAccount) {
    this.accountFormControl.push(
      this.fb.group({
        id: [account.id],
        name: [
          account.name,
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .,`'&\\/()-]*$"),
            Validators.maxLength(100),
          ],
        ],
        country: [account.country, Validators.required],
        isActive: [account.isActive, Validators.required],
      })
    );

    // when searching for index get all accounts in the form regardless of the disabled status
    const allAccounts = this.accountFormControl?.getRawValue();
    const accountIndex = allAccounts.findIndex(
      (d: CompanyAccount) => d.id === account.id
    );
    if (accountIndex !== -1) {
      const accountControl = this.getAccountControl(accountIndex);
      // disable non-active accounts
      if (accountControl.get('isActive')?.value === false) {
        accountControl.disable();
      }
    }
  }

  addNewAccount() {
    this.accountFormControl.push(
      this.fb.group({
        id: [uuidv4()],
        name: [
          null,
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .,`'&\\/()-]*$"),
            Validators.maxLength(100),
          ],
        ],
        country: [null, Validators.required],
        isActive: [true, Validators.required],
      })
    );
  }

  addCounterparty(counterparty: CompanyCounterparty) {
    this.counterpartyFormControl.push(
      this.fb.group(
        {
          id: [counterparty.id],
          name: [
            counterparty.name,
            [
              Validators.required,
              Validators.pattern("^[a-zA-Z0-9 .,`'&\\/()-]*$"),
              Validators.maxLength(100),
            ],
          ],
          country: [counterparty.country, Validators.required],
          businessActivity: [
            counterparty.businessActivity,
            Validators.required,
          ],
          isIncomingTransaction: [counterparty.isIncomingTransaction],
          isOutgoingTransaction: [counterparty.isOutgoingTransaction],
          isActive: [counterparty.isActive, Validators.required],
        },
        { validators: atLeastOneCheckboxIsCheckedCounterparty() }
      )
    );

    // when searching for index get all counterparties in the form regardless of the disabled status
    const allCounterparties = this.counterpartyFormControl?.getRawValue();
    const counterpartyIndex = allCounterparties.findIndex(
      (d: CompanyAccount) => d.id === counterparty.id
    );
    if (counterpartyIndex !== -1) {
      const counterpartyControl =
        this.getCounterpartyControl(counterpartyIndex);
      // disable non-active counterparties
      if (counterpartyControl.get('isActive')?.value === false) {
        counterpartyControl.disable();
      }
    }
  }

  addNewCounterparty() {
    this.counterpartyFormControl.push(
      this.fb.group(
        {
          id: [uuidv4()],
          name: [
            null,
            [
              Validators.required,
              Validators.pattern("^[a-zA-Z0-9 .,`'&\\/()-]*$"),
              Validators.maxLength(100),
            ],
          ],
          country: [null, Validators.required],
          businessActivity: [null, Validators.required],
          isIncomingTransaction: [null],
          isOutgoingTransaction: [null],
          isActive: [true, Validators.required],
        },
        { validators: atLeastOneCheckboxIsCheckedCounterparty() }
      )
    );
  }

  addGroupMember(groupMember: CompanyGroupMember) {
    this.groupMemberFormControl.push(
      this.fb.group({
        id: [groupMember.id],
        name: [
          groupMember.name,
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
            Validators.maxLength(100),
          ],
        ],
        registrationNumber: [
          groupMember.registrationNumber,
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
            Validators.maxLength(50),
          ],
        ],
        incorporationDate: [
          groupMember.incorporationDate
            ? DateTime.fromISO(groupMember.incorporationDate)
            : null,
          [Validators.required],
        ],
        address: this.fb.group({
          streetAddress: [
            groupMember.address?.streetAddress,
            [
              Validators.required,
              Validators.pattern("^[a-zA-Z0-9 .,&`'\\/()-]*$"),
              Validators.maxLength(100),
            ],
          ],
          additionalStreetAddress: [
            groupMember.address?.additionalStreetAddress,
            [
              Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
              Validators.maxLength(100),
            ],
          ],
          postCode: [
            groupMember.address?.postCode,
            [
              Validators.required,
              Validators.pattern('^[a-zA-Z0-9 ()\\/-]*$'),
              Validators.maxLength(50),
            ],
          ],
          city: [
            groupMember.address?.city,
            [
              Validators.required,
              Validators.pattern("^[a-zA-Z0-9 `'\\/()-]*$"),
              Validators.maxLength(50),
            ],
          ],
          countryId: [groupMember.address?.countryId, Validators.required],
        }),
        isActive: [groupMember.isActive, Validators.required],
      })
    );

    // when searching for index get all group members in the form regardless of the disabled status
    const allGroupMembers = this.groupMemberFormControl?.getRawValue();
    const groupMemberIndex = allGroupMembers.findIndex(
      (d: CompanyGroupMember) => d.id === groupMember.id
    );
    if (groupMemberIndex !== -1) {
      const groupMemberControl = this.getGroupMemberControl(groupMemberIndex);
      // disable non-active group members
      if (groupMemberControl.get('isActive')?.value === false) {
        groupMemberControl.disable();
      }
    }
  }

  addNewGroupMember() {
    this.groupMemberFormControl.push(
      this.fb.group({
        id: [uuidv4()],
        name: [
          '',
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
            Validators.maxLength(100),
          ],
        ],
        registrationNumber: [
          '',
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
            Validators.maxLength(50),
          ],
        ],
        incorporationDate: ['', [Validators.required]],
        address: this.fb.group({
          streetAddress: [
            '',
            [
              Validators.required,
              Validators.pattern("^[a-zA-Z0-9 .,&`'\\/()-]*$"),
              Validators.maxLength(100),
            ],
          ],
          additionalStreetAddress: [
            '',
            [
              Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
              Validators.maxLength(100),
            ],
          ],
          postCode: [
            '',
            [
              Validators.required,
              Validators.pattern('^[a-zA-Z0-9 ()\\/-]*$'),
              Validators.maxLength(50),
            ],
          ],
          city: [
            '',
            [
              Validators.required,
              Validators.pattern("^[a-zA-Z0-9 `'\\/()-]*$"),
              Validators.maxLength(50),
            ],
          ],
          countryId: ['', Validators.required],
        }),
        isActive: [true, Validators.required],
      })
    );
  }

  // check if user says that they have close links and close links were entered
  closeLinksFilled(id: number | string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (this.companyDirectorCloseLinks && value.hasCloseLinks === true) {
        var dcl = this.companyDirectorCloseLinks.find(
          (directorCloseLink) => directorCloseLink.directorId === id
        );
        // if said that has close links but director-close links don't exist, form is Incomplete
        if (!dcl) {
          return { missingCloseLinks: true };
        }
      }
      return null;
    };
  }

  selectCompanyCloseLink(directorId: number, index: number) {
    let dialogRef = this.dialog.open<CompanyCloseLinkSelectDialogComponent>(
      CompanyCloseLinkSelectDialogComponent,
      {
        panelClass: 'dialog-with-close-button',
        width: '770px',
        disableClose: true,
        data: {
          countries: this.countries,
          closeLinks: this.companyCloseLinks,
          directorCloseLinks: this.companyDirectorCloseLinks,
          directorId: directorId,
          isLegalPerson: this.isLegalPersonControl(index)?.value,
          isReviewed: false,
          isFirstTimeEDD: true,
          hasCloselinks: this.hasCloseLinksControl(index)?.value,
          isNotOnboarding: true,
        },
      }
    );
    const closeLinkChangeSub =
      dialogRef.componentInstance.companyCloseLinksChange.subscribe(
        (updatedCloseLinks) => {
          // receives all close links (new and updated ones with uuidv4 id)
          this.companyCloseLinks = updatedCloseLinks;
        }
      );
    const directorCloseLinkChangeSub =
      dialogRef.componentInstance.companyDirectorCloseLinksChange.subscribe(
        (updatedDirectorCloseLinks) => {
          // receives only the close links of the given director (new and updated ones with close link uuidv4 id)

          // make a copy of the original array of close links combinations
          const directorCloseLinksCopy = this.companyDirectorCloseLinks
            ? [...this.companyDirectorCloseLinks]
            : [];

          updatedDirectorCloseLinks.forEach((newDcl) => {
            const index = directorCloseLinksCopy.findIndex(
              (dcl) =>
                dcl.closeLinkId === newDcl.closeLinkId &&
                dcl.directorId === directorId
            );

            if (index !== -1) {
              // if the combination with the same closeLinkId and directorId exists, update its properties
              directorCloseLinksCopy[index] = {
                ...directorCloseLinksCopy[index],
                ...newDcl,
              };
            } else {
              // if the combination doesn't exist, add it to the array
              directorCloseLinksCopy.push(newDcl);
            }
          });

          // find close link combinations that don't exist in the array for the given director
          const directorCloseLinksToRemove = directorCloseLinksCopy.filter(
            (dcl) =>
              dcl.directorId === directorId &&
              !updatedDirectorCloseLinks.some(
                (newDcl) =>
                  dcl.closeLinkId === newDcl.closeLinkId &&
                  dcl.directorId === directorId
              )
          );

          // remove close link combinations that don't exist in the array for the given director
          const newDirectorCloseLinks = directorCloseLinksCopy.filter((dcl) => {
            return !directorCloseLinksToRemove.some(
              (closeLinkToRemove) =>
                dcl.closeLinkId === closeLinkToRemove.closeLinkId &&
                dcl.directorId === closeLinkToRemove.directorId
            );
          });

          this.companyDirectorCloseLinks = newDirectorCloseLinks;
        }
      );
    dialogRef.afterClosed().subscribe(() => {
      closeLinkChangeSub.unsubscribe();
      directorCloseLinkChangeSub.unsubscribe();
      this.getDirectorControl(index).updateValueAndValidity(); // rerun validators to check closeLinksFilled
    });
  }

  async saveRegisteredUserForm(): Promise<void> {
    this.isSaving = true;
    const incorporationAddress = {
      countryId: this.incorporationCountryIdControl?.value,
      streetAddress: this.incorporationStreetAddressControl?.value,
      additionalStreetAddress:
        this.incorporationAdditionalStreetAddressControl?.value || '',
      postCode: this.incorporationPostCodeControl?.value,
      city: this.incorporationCityControl?.value,
    };
    const operatingAddress = this.isSameAddressControl?.value
      ? incorporationAddress
      : {
          countryId: this.operatingCountryIdControl?.value,
          streetAddress: this.operatingStreetAddressControl?.value,
          additionalStreetAddress:
            this.operatingAdditionalStreetAddressControl?.value || '',
          postCode: this.operatingPostCodeControl?.value,
          city: this.operatingCityControl?.value,
        };
    const corporateForm: CorporateForm = {
      businessName: this.businessNameControl?.value,
      businessTradingName: this.businessTradingNameControl?.value,
      taxNumber: this.entityTaxNumberControl?.value,
      vatNumber: this.vatNumberControl?.value,
      companyUrls: this.companyUrlsControl?.value,
      companyActivity: this.companyActivityControl?.value,
      companyActivityDescr: this.companyActivityDescrControl?.value,
      isRegulated: this.isRegulatedControl?.value,
      regulatoryAuthority: this.regulatoryAuthorityControl?.value,
      canFundFromOwnAccount: this.canFundFromOwnAccountControl?.value,
      annualIncome: this.annualIncomeControl?.value
        ? this.annualIncomeControl?.value.replace(/\D/g, '')
        : '',
      accountPurpose: this.accountPurposeControl?.value
        ? this.accountPurposeControl?.value.join(', ')
        : '',
      monthlyLoading: this.monthlyLoadingControl?.value
        ? this.monthlyLoadingControl?.value.replace(/\D/g, '')
        : '',
      percentageOfCash: this.percentageOfCashControl?.value,
      doubleAnnualIncome: this.doubleAnnualIncomeControl?.value,
      zeroBalanceStatement: this.zeroBalanceStatementControl?.value,
      legalStatus:
        this.legalStatusControl?.value === LegalStatus['Other type of Company']
          ? this.otherLegalStatusControl?.value
          : this.legalStatusControl?.value,
      operateInHighRiskCountries: this.operateInHighRiskCountriesControl?.value,
      isFinancialStatementAudited:
        this.isFinancialStatementAuditedControl?.value,
      expectedNumOfPhysicalCards: this.expectedNumOfPhysicalCardsControl?.value,
      incorporationDate: (
        this.incorporationDateControl?.value as DateTime
      ).toISODate(),
      registrationNumber: this.registrationNumberControl?.value,
      additionalPhoneNumber:
        this.additionalPhoneNumberControl?.value?.e164Number || null,
      incorporationAddress,
      operatingAddress,
    };

    const directorRawValues = this.directorFormControl?.getRawValue(); // get all control values from the form, regardless of the disabled status
    const companyDirectors = directorRawValues.map((d) => {
      const body = {
        ...d,
        dateOfBirth: d.dateOfBirth
          ? (d.dateOfBirth as DateTime).toISODate()
          : null,
        phoneNumber: d.phoneNumber
          ? d.phoneNumber.hasOwnProperty('e164Number')
            ? d.phoneNumber.e164Number
            : d.phoneNumber
          : null,
        legalPersonIncorporationDate: d.legalPersonIncorporationDate
          ? (d.legalPersonIncorporationDate as DateTime).toISODate()
          : null,
      };
      return body;
    });

    const groupMemberRawValues = this.groupMemberFormControl?.getRawValue(); // get all control values from the form, regardless of the disabled status
    const companyGroupMembers = groupMemberRawValues.map((gm) => {
      const body = {
        ...gm,
        incorporationDate: gm.incorporationDate
          ? (gm.incorporationDate as DateTime).toISODate()
          : null,
      };
      return body;
    });

    const body: SaveRegisteredUser = {
      userId: this.userId,
      corporateData: corporateForm,
      companyDirectors: companyDirectors,
      companyCloseLinks: this.companyCloseLinks,
      companyDirectorCloseLinks: this.companyDirectorCloseLinks,
      companyCounterparties: this.counterpartyFormControl?.getRawValue(),
      companyAccounts: this.accountFormControl?.getRawValue(),
      companyGroupMembers: companyGroupMembers,
    };

    // dont save ids of directors/close links/accounts/... in backend because they are uuidv4 strings
    (await this.userProfileService.updateCorporateForm(body)).subscribe(
      () => {
        this.form.markAsPristine();
        this.isSaving = false;
        this.dataChanged.emit();
      },
      (error) => {
        this.isSaving = false;
        error.status === 403
          ? this.errorService.showErrorDialog(error.error.message)
          : this.errorService.showErrorDialog();
      }
    );
  }

  get isMarketingConsentControl(): AbstractControl {
    return this.marketingConsentForm.get('isMarketingConsent')!;
  }

  onDirectorToggleChange(
    event: MatSlideToggleChange,
    index: number,
    id: number
  ): void {
    const isChecked = event.checked;

    if (isChecked) {
      this.getDirectorControl(index).get('isActive')?.setValue(true);
      const control = this.directorFormControl.at(index) as AbstractControl;
      control.enable();
      this.subscribeDirectorToValueChanges(control.value.id);
    } else {
      // if id is string (uuidv4) then delete it, otherwise disable it
      if (typeof id === 'string') {
        this.directorFormControl.removeAt(index);
      } else {
        this.getDirectorControl(index).get('isActive')?.setValue(false);
        this.getDirectorControl(index).disable();
      }
    }
    this.form.markAsDirty();
  }

  onAccountToggleChange(
    event: MatSlideToggleChange,
    index: number,
    id: number
  ): void {
    const isChecked = event.checked;

    if (isChecked) {
      this.getAccountControl(index).get('isActive')?.setValue(true);
      const control = this.accountFormControl.at(index) as AbstractControl;
      control.enable();
    } else {
      // if id is string (uuidv4) then delete it, otherwise disable it
      if (typeof id === 'string') {
        this.accountFormControl.removeAt(index);
      } else {
        this.getAccountControl(index).get('isActive')?.setValue(false);
        this.getAccountControl(index).disable();
      }
    }
    this.form.markAsDirty();
  }

  onCounterpartyToggleChange(
    event: MatSlideToggleChange,
    index: number,
    id: number
  ): void {
    const isChecked = event.checked;

    if (isChecked) {
      this.getCounterpartyControl(index).get('isActive')?.setValue(true);
      const control = this.counterpartyFormControl.at(index) as AbstractControl;
      control.enable();
    } else {
      // if id is string (uuidv4) then delete it, otherwise disable it
      if (typeof id === 'string') {
        this.counterpartyFormControl.removeAt(index);
      } else {
        this.getCounterpartyControl(index).get('isActive')?.setValue(false);
        this.getCounterpartyControl(index).disable();
      }
    }
    this.form.markAsDirty();
  }

  onGroupMemberToggleChange(
    event: MatSlideToggleChange,
    index: number,
    id: number
  ): void {
    const isChecked = event.checked;

    if (isChecked) {
      this.getGroupMemberControl(index).get('isActive')?.setValue(true);
      const control = this.groupMemberFormControl.at(index) as AbstractControl;
      control.enable();
    } else {
      // if id is string (uuidv4) then delete it, otherwise disable it
      if (typeof id === 'string') {
        this.groupMemberFormControl.removeAt(index);
      } else {
        this.getGroupMemberControl(index).get('isActive')?.setValue(false);
        this.getGroupMemberControl(index).disable();
      }
    }
    this.form.markAsDirty();
  }

  // Stakeholders
  get directorFormControl(): FormArray {
    return this.form.get('directorForm') as FormArray;
  }
  getDirectorControl(index: number): FormGroup {
    return this.directorFormControl.at(index) as FormGroup;
  }
  firstNameControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('firstName');
  }
  lastNameControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('lastName');
  }
  isShareholderControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('isShareholder');
  }
  isUBOControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('isUBO');
  }
  isAuthorizedPersonControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('isAuthorizedPerson');
  }
  isDirectorControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('isDirector');
  }
  public emailControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('email');
  }
  dateOfBirthControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('dateOfBirth');
  }
  nationalityControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('nationality');
  }
  // address
  addressGroup(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('address');
  }
  streetAddressControl(index: number): AbstractControl | null | undefined {
    return this.addressGroup(index)?.get('streetAddress');
  }
  additionalStreetAddressControl(
    index: number
  ): AbstractControl | null | undefined {
    return this.addressGroup(index)?.get('additionalStreetAddress');
  }
  postCodeControl(index: number): AbstractControl | null | undefined {
    return this.addressGroup(index)?.get('postCode');
  }
  cityControl(index: number): AbstractControl | null | undefined {
    return this.addressGroup(index)?.get('city');
  }
  countryIdControl(index: number): AbstractControl | null | undefined {
    return this.addressGroup(index)?.get('countryId');
  }
  // natural person
  isPEPControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('isPEP');
  }
  phoneNumberControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('phoneNumber');
  }
  taxNumberControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('taxNumber');
  }
  percentageOwnedByShareholderControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('percentageOwnedByShareholder');
  }
  percentageOwnedByUBOControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('percentageOwnedByUBO');
  }
  // legal person
  isLegalPersonControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('isLegalPerson');
  }
  legalPersonNameControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('legalPersonName');
  }
  legalPersonRegistrationNumberControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('legalPersonRegistrationNumber');
  }
  legalPersonIncorporationDateControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('legalPersonIncorporationDate');
  }
  // close links
  hasCloseLinksControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('hasCloseLinks');
  }

  // Bank/PI accounts
  get accountFormControl(): FormArray {
    return this.form.get('accountForm') as FormArray;
  }
  getAccountControl(index: number): FormGroup {
    return this.accountFormControl.at(index) as FormGroup;
  }
  accountNameControl(index: number): AbstractControl | null {
    return this.getAccountControl(index).get('name');
  }
  accountCountryControl(index: number): AbstractControl | null {
    return this.getAccountControl(index).get('country');
  }

  // Counterparties
  get counterpartyFormControl(): FormArray {
    return this.form.get('counterpartyForm') as FormArray;
  }
  getCounterpartyControl(index: number): FormGroup {
    return this.counterpartyFormControl.at(index) as FormGroup;
  }
  counterpartyNameControl(index: number): AbstractControl | null {
    return this.getCounterpartyControl(index).get('name');
  }
  counterpartyCountryControl(index: number): AbstractControl | null {
    return this.getCounterpartyControl(index).get('country');
  }
  counterpartyBusinessActivityControl(index: number): AbstractControl | null {
    return this.getCounterpartyControl(index).get('businessActivity');
  }

  // Group members
  get groupMemberFormControl(): FormArray {
    return this.form.get('groupMemberForm') as FormArray;
  }
  getGroupMemberControl(index: number): FormGroup {
    return this.groupMemberFormControl.at(index) as FormGroup;
  }
  groupMemberNameControl(index: number): AbstractControl | null {
    return this.getGroupMemberControl(index).get('name');
  }
  groupMemberRegistrationNumberControl(index: number): AbstractControl | null {
    return this.getGroupMemberControl(index).get('registrationNumber');
  }
  groupMemberIncorporationDateControl(index: number): AbstractControl | null {
    return this.getGroupMemberControl(index).get('incorporationDate');
  }
  // address
  groupMemberAddressGroup(index: number): AbstractControl | null {
    return this.getGroupMemberControl(index).get('address');
  }
  groupMemberStreetAddressControl(
    index: number
  ): AbstractControl | null | undefined {
    return this.groupMemberAddressGroup(index)?.get('streetAddress');
  }
  groupMemberAdditionalStreetAddressControl(
    index: number
  ): AbstractControl | null | undefined {
    return this.groupMemberAddressGroup(index)?.get('additionalStreetAddress');
  }
  groupMemberPostCodeControl(
    index: number
  ): AbstractControl | null | undefined {
    return this.groupMemberAddressGroup(index)?.get('postCode');
  }
  groupMemberCityControl(index: number): AbstractControl | null | undefined {
    return this.groupMemberAddressGroup(index)?.get('city');
  }
  groupMemberCountryIdControl(
    index: number
  ): AbstractControl | null | undefined {
    return this.groupMemberAddressGroup(index)?.get('countryId');
  }

  get closeLinkFormControl(): FormArray {
    return this.form.get('closeLinkForm') as FormArray;
  }
  get entityFormControl(): FormGroup {
    return this.form.get('entityForm') as FormGroup;
  }
  get businessNameControl(): AbstractControl | null {
    return this.entityFormControl.get('businessName');
  }
  get businessTradingNameControl(): AbstractControl | null {
    return this.entityFormControl.get('businessTradingName');
  }
  get entityTaxNumberControl(): AbstractControl | null {
    return this.entityFormControl.get('taxNumber');
  }
  get vatNumberControl(): AbstractControl | null {
    return this.entityFormControl.get('vatNumber');
  }
  get companyUrlsControl(): AbstractControl | null {
    return this.entityFormControl.get('companyUrls');
  }
  get companyActivityControl(): AbstractControl | null {
    return this.entityFormControl.get('companyActivity');
  }
  get companyActivityDescrControl(): AbstractControl | null {
    return this.entityFormControl.get('companyActivityDescr');
  }
  get isRegulatedControl(): AbstractControl | null {
    return this.entityFormControl.get('isRegulated');
  }
  get regulatoryAuthorityControl(): AbstractControl | null {
    return this.entityFormControl.get('regulatoryAuthority');
  }
  get canFundFromOwnAccountControl(): AbstractControl | null {
    return this.entityFormControl.get('canFundFromOwnAccount');
  }
  get annualIncomeControl(): AbstractControl | null {
    return this.entityFormControl.get('annualIncome');
  }
  get accountPurposeControl(): AbstractControl | null {
    return this.entityFormControl.get('accountPurpose');
  }
  get monthlyLoadingControl(): AbstractControl | null {
    return this.entityFormControl.get('monthlyLoading');
  }
  get percentageOfCashControl(): AbstractControl | null {
    return this.entityFormControl.get('percentageOfCash');
  }
  get doubleAnnualIncomeControl(): AbstractControl | null {
    return this.entityFormControl.get('doubleAnnualIncome');
  }
  get zeroBalanceStatementControl(): AbstractControl | null {
    return this.entityFormControl.get('zeroBalanceStatement');
  }
  get legalStatusControl(): AbstractControl | null {
    return this.entityFormControl.get('legalStatus');
  }
  get otherLegalStatusControl(): AbstractControl | null {
    return this.entityFormControl.get('otherLegalStatus');
  }
  get operateInHighRiskCountriesControl(): AbstractControl | null {
    return this.entityFormControl.get('operateInHighRiskCountries');
  }
  get isFinancialStatementAuditedControl(): AbstractControl | null {
    return this.entityFormControl.get('isFinancialStatementAudited');
  }
  get expectedNumOfPhysicalCardsControl(): AbstractControl | null {
    return this.entityFormControl.get('expectedNumOfPhysicalCards');
  }
  get incorporationDateControl(): AbstractControl | null {
    return this.entityFormControl.get('incorporationDate');
  }
  get registrationNumberControl(): AbstractControl | null {
    return this.entityFormControl.get('registrationNumber');
  }
  get additionalPhoneNumberControl(): AbstractControl | null {
    return this.entityFormControl.get('additionalPhoneNumber');
  }
  get isSameAddressControl(): AbstractControl | null {
    return this.entityFormControl.get('isSameAddress');
  }

  // incorporation address
  get incorporationAddressGroup(): AbstractControl | null {
    return this.entityFormControl.get('incorporationAddress');
  }
  get incorporationStreetAddressControl(): AbstractControl | null | undefined {
    return this.incorporationAddressGroup?.get('streetAddress');
  }
  get incorporationAdditionalStreetAddressControl():
    | AbstractControl
    | null
    | undefined {
    return this.incorporationAddressGroup?.get('additionalStreetAddress');
  }
  get incorporationPostCodeControl(): AbstractControl | null | undefined {
    return this.incorporationAddressGroup?.get('postCode');
  }
  get incorporationCityControl(): AbstractControl | null | undefined {
    return this.incorporationAddressGroup?.get('city');
  }
  get incorporationCountryIdControl(): AbstractControl | null | undefined {
    return this.incorporationAddressGroup?.get('countryId');
  }

  // operating address
  get operatingAddressGroup(): AbstractControl | null {
    return this.entityFormControl.get('operatingAddress');
  }
  get operatingStreetAddressControl(): AbstractControl | null | undefined {
    return this.operatingAddressGroup?.get('streetAddress');
  }
  get operatingAdditionalStreetAddressControl():
    | AbstractControl
    | null
    | undefined {
    return this.operatingAddressGroup?.get('additionalStreetAddress');
  }
  get operatingPostCodeControl(): AbstractControl | null | undefined {
    return this.operatingAddressGroup?.get('postCode');
  }
  get operatingCityControl(): AbstractControl | null | undefined {
    return this.operatingAddressGroup?.get('city');
  }
  get operatingCountryIdControl(): AbstractControl | null | undefined {
    return this.operatingAddressGroup?.get('countryId');
  }

  changeAvatar(file: File): void {
    this.isAvatarUploading = true;
    this.userProfileService.uploadAvatar(file).subscribe(
      (avatarUrl) => {
        this.avatarUrl = avatarUrl;
        this.authService.getAuthenticatedUser().subscribe();
        this.isAvatarUploading = false;
      },
      (err) => {
        const errorMessage =
          err.status === 400 ? 'Wrong file format' : 'Upload failed';
        this.avatarErrorMessage = errorMessage;
        this.isAvatarUploading = false;
      }
    );
  }

  removeAvatar() {
    this.userProfileService
      .deleteAvatar()
      .pipe(mergeMap(() => this.authService.getAuthenticatedUser()))
      .subscribe(
        () => {},
        () => this.errorService.showErrorDialog()
      );
  }

  changeEmail(): void {
    this.dialog.open<ChangeEmailDialogNewComponent>(
      ChangeEmailDialogNewComponent,
      {
        panelClass: 'dialog-with-close-button',
        width: '670px',
        disableClose: true,
      }
    );
  }

  changePhoneNumber(): void {
    let dialogRef = this.dialog.open<ChangePhoneNumberDialogNewComponent>(
      ChangePhoneNumberDialogNewComponent,
      {
        panelClass: 'dialog-with-close-button',
        width: '670px',
        disableClose: true,
      }
    );
    const phoneNumberChangeSub =
      dialogRef.componentInstance.phoneNumberChange.subscribe(
        (updatedPhoneNumber) => {
          this.businessUserProfileData.cellPhoneNumber = updatedPhoneNumber;
        }
      );
    dialogRef.afterClosed().subscribe(() => {
      phoneNumberChangeSub.unsubscribe();
    });
  }

  changePassword(): void {
    this.dialog.open<ChangePasswordDialogNewComponent>(
      ChangePasswordDialogNewComponent,
      {
        panelClass: 'dialog-with-close-button',
        width: '670px',
        disableClose: true,
      }
    );
  }

  showAccountsInformation(): void {
    this.dialog
      .open<AccountInformationDialogNewComponent>(
        AccountInformationDialogNewComponent,
        {
          panelClass: 'dialog-with-close-button',
          width: '670px',
          disableClose: true,
        }
      )
      .beforeClosed()
      .pipe(filter((shouldUpgrade) => !!shouldUpgrade))
      .subscribe(
        () => {
          this.upgradeToRuby();
        },
        () => {
          this.errorService.showErrorDialog();
        }
      );
  }

  upgradeToRuby(): void {
    this.eventSubscription = this.userEventsService.userEventsObservable
      .pipe(
        map((event) => JSON.parse(event)),
        filter(
          (event) => event.type === ServerSentEventType.PFS_ACCOUNT_CREATED // &&
          // event.subType === AccountsCreationEvents.PACKAGE_CREATED
        )
      )
      .subscribe(
        () => {
          this.ngZone.run(() => {
            this.isLoading = false;
            window.location.reload();
          });
        },
        (error) => {
          this.ngZone.run(() => {
            this.isLoading = false;
            this.errorService.showErrorDialog(error.error.message);
          });
        }
      );
    this.isLoading = true;
    this.userProfileService.upgradeAccount().subscribe(
      () => {},
      (error) => {
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  ngOnDestroy() {
    this.isMarketingConsentSubscription?.unsubscribe();
    this.authenticatedUserSubscription?.unsubscribe();
    this.eventSubscription?.unsubscribe();
  }

  private sharesValidator() {
    if (this.form && this.directorFormControl.value.length > 0) {
      const shares = this.directorFormControl?.value
        .filter((d: { isShareholder: any }) => d.isShareholder)
        .map(
          (s: { percentageOwnedByShareholder: any }) =>
            s.percentageOwnedByShareholder
        );
      if (shares.length > 0) {
        const sum = shares.reduce((sum: any, p: any) => sum + p, 0);
        if (sum > 100) {
          return { sharesMoreThan100: true };
        }
      }
    }
    return null;
  }

  private positionValidator() {
    if (this.form && this.directorFormControl.value.length > 0) {
      if (
        this.directorFormControl?.value.filter(
          (d: { isAuthorizedPerson: any }) => d.isAuthorizedPerson
        ).length === 1 &&
        this.directorFormControl?.value.filter(
          (d: { isDirector: any }) => d.isDirector
        ).length > 0 &&
        this.directorFormControl?.value.filter(
          (d: { isShareholder: any }) => d.isShareholder
        ).length > 0 &&
        this.directorFormControl?.value.filter((d: { isUBO: any }) => d.isUBO)
          .length > 0
      ) {
        return null;
      } else {
        return { atLeastOnePosition: true };
      }
    }
    return null;
  }

  private atLeastOneIncomingOutgoingTransaction() {
    if (this.form && this.counterpartyFormControl.length > 0) {
      if (
        this.counterpartyFormControl?.value.filter(
          (c: { isIncomingTransaction: boolean; isActive: boolean }) =>
            c.isIncomingTransaction && c.isActive
        ).length > 0 &&
        this.counterpartyFormControl?.value.filter(
          (c: { isOutgoingTransaction: boolean; isActive: boolean }) =>
            c.isOutgoingTransaction && c.isActive
        ).length > 0
      ) {
        this.counterpartyFormControl.setErrors(null);
        return null;
      } else {
        if (
          this.counterpartyFormControl?.value.filter(
            (c: { isIncomingTransaction: boolean; isActive: boolean }) =>
              c.isIncomingTransaction && c.isActive
          ).length === 0 &&
          this.counterpartyFormControl?.value.filter(
            (c: { isOutgoingTransaction: boolean; isActive: boolean }) =>
              c.isOutgoingTransaction && c.isActive
          ).length > 0
        ) {
          return { incomingTransactionRequired: true };
        }
        if (
          this.counterpartyFormControl?.value.filter(
            (c: { isOutgoingTransaction: boolean; isActive: boolean }) =>
              c.isOutgoingTransaction && c.isActive
          ).length === 0 &&
          this.counterpartyFormControl?.value.filter(
            (c: { isIncomingTransaction: boolean; isActive: boolean }) =>
              c.isIncomingTransaction && c.isActive
          ).length > 0
        ) {
          return { outgoingTransactionRequired: true };
        }
        if (
          this.counterpartyFormControl?.value.filter(
            (c: { isOutgoingTransaction: boolean; isActive: boolean }) =>
              c.isOutgoingTransaction && c.isActive
          ).length === 0 &&
          this.counterpartyFormControl?.value.filter(
            (c: { isIncomingTransaction: boolean; isActive: boolean }) =>
              c.isIncomingTransaction && c.isActive
          ).length === 0
        ) {
          return { atLeastOneCounterpartyIsRequired: true };
        }
      }
    }
    return null;
  }
}
