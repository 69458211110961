import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Program } from '../../../../models/program.enum';

@Component({
  templateUrl: './account-details-dialog.component.html',
  styleUrls: ['./account-details-dialog.component.scss'],
})
export class AccountDetailsDialogComponent {
  programs = Program;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Program,
    public dialogRef: MatDialogRef<AccountDetailsDialogComponent, Program>
  ) {}

  chooseProgram(selectedProgram: Program): void {
    this.dialogRef.close(selectedProgram);
  }

  switchToEmerald(): void {
    this.data = Program.EMERALD;
  }

  switchToRuby(): void {
    this.data = Program.RUBY;
  }
}
