<div *ngIf="section === Sections.WALLET" class="wallet-section">
  <table class="wallets">
    <thead>
      <tr>
        <th></th>
        <th></th>
        <th class="align-right">Available</th>
        <th class="align-right">Book</th>
        <th class="align-right"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let currency of currencies">
        <td>
          <img [src]="imagePath(currency.short)" [alt]="currency.short" />
        </td>
        <td>{{ currency.name }}</td>
        <td class="align-right">
          <app-small-loader
            *ngIf="wallet[currency.short]!.isLoading"
          ></app-small-loader>
          <div *ngIf="!wallet[currency.short]!.isLoading">
            {{ wallet[currency.short].availableBalance | amount }}
          </div>
        </td>
        <td class="align-right">
          <app-small-loader
            *ngIf="wallet[currency.short]!.isLoading"
          ></app-small-loader>
          <div *ngIf="!wallet[currency.short]!.isLoading">
            {{ wallet[currency.short].balance | amount }}
          </div>
        </td>
        <td class="align-right">{{ currency.short }}</td>
      </tr>
    </tbody>
  </table>
</div>

<app-card-section-empty-state
  *ngIf="
    this.cardSectionEmptyState && !isLoadingCards && !isLoadingCardTransactions
  "
></app-card-section-empty-state>

<app-big-loader-not-centered
  *ngIf="
    ((isLoadingCardTransactions || isLoadingCards) &&
      section === Sections.CARD) ||
    (isLoadingIBANTransactions && section === Sections.IBAN)
  "
  fxLayout
  fxLayoutAlign="center center"
  class="big-loader-container"
></app-big-loader-not-centered>

<div
  *ngIf="
    accountTransactions?.length !== 0 &&
    ((section === Sections.CARD && !isLoadingCardTransactions) ||
      (section === Sections.IBAN && !isLoadingIBANTransactions))
  "
>
  <div *ngFor="let transaction of accountTransactions">
    <app-billing-section
      [date]="transaction.date"
      [fee]="transaction.fee"
      [name]="transaction.termOwner"
      [transaction]="transaction.amount"
      [balance]="transaction.availableBalance"
      [description]="transaction.description"
    ></app-billing-section>
  </div>
</div>

<div
  *ngIf="
    (accountTransactions?.length === 0 || accountTransactions === undefined) &&
    ((section === Sections.CARD &&
      isCardActive &&
      !isLoadingCardTransactions) ||
      (!isLoadingIBANTransactions && section === Sections.IBAN))
  "
  fxLayout="column"
  fxLayoutAlign="center center"
  class="empty-transactions-container"
>
  <span class="header">No transactions yet.</span>
  <span class="sub-header">Your transactions will appear here.</span>
</div>

<div
  *ngIf="
    cards?.length === 0 &&
    !isLoadingCards &&
    !isLoadingCardTransactions &&
    section === Sections.CARD
  "
  class="empty-transactions-container additional-space"
  fxLayout="column"
  fxLayoutAlign="center center"
>
  <span class="header">No cards yet.</span>
  <span class="sub-header">Your card transactions will appear here.</span>
  <button
    mat-stroked-button
    color="accent"
    class="g-small button"
    (click)="orderNewCard()"
  >
    Order new card
  </button>
</div>
