import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommentType } from 'src/app/onboarding-new/models/comment-type.enum';
import { AppComment } from '../../models/comment.model';

export interface AdminConversationDialog {
  isAdmin: boolean;
  comments?: AppComment[];
  headerText: string;
}

@Component({
  templateUrl: './admin-conversation-dialog.component.html',
  styleUrls: ['./admin-conversation-dialog.component.scss'],
})
export class AdminConversationDialogNewComponent {
  isAdmin: boolean = false;
  comments: AppComment[] = [];
  headerText: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: AdminConversationDialog) {
    this.isAdmin = data.isAdmin;
    this.comments = data.comments || [];
    this.headerText = data.headerText;
  }

  commentTypes = CommentType;

  // checks if comments is a reply
  isReply(index: number): boolean {
    return !!this.comments[index - 1];
  }

  // checks if admin added comment after file upload
  isFileRejection(index: number): boolean {
    return this.comments[index - 1]?.commentType === CommentType.FILE_UPLOADED;
  }
}
