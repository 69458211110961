import { Component, EventEmitter, Output } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { TransferService } from 'src/app/transfer/transfer.service';
import { ActivationFileModel } from '../models/activation-file.model';

export enum FileUploadState {
  isEmpty,
  isProcessing,
  isErroring,
  isUploaded,
}

@Component({
  selector: 'app-activation-file-upload',
  templateUrl: './activation-file-upload.component.html',
  styleUrls: ['./activation-file-upload.component.scss'],
})
export class ActivationFileUploadComponent {
  @Output() upload = new EventEmitter<Observable<ActivationFileModel>>();
  @Output() clearFile = new EventEmitter<void>();

  file?: File;
  state: FileUploadState = FileUploadState.isEmpty;
  FileUploadState = FileUploadState;

  constructor(private transferService: TransferService) {}

  clear(): void {
    this.state = FileUploadState.isEmpty;
    this.clearFile.emit();
  }

  dropped(fileDrop: NgxFileDropEntry): void {
    if (
      !(this.state === FileUploadState.isProcessing) &&
      fileDrop &&
      fileDrop.fileEntry.isFile
    ) {
      (fileDrop.fileEntry as FileSystemFileEntry).file((file: File) => {
        this.file = file;
        this.state = FileUploadState.isProcessing;
        const uploadObs = this.transferService
          .activationFileUpload(file)
          .pipe(shareReplay());

        uploadObs.subscribe(
          () => (this.state = FileUploadState.isUploaded),
          () => (this.state = FileUploadState.isErroring)
        );
        this.upload.emit(uploadObs);
      });
    }
  }
}
