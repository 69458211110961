import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  CardPaymentDialogComponent,
  CardPaymentDialogData,
} from 'src/app/shared/card-payment-dialog/card-payment-dialog.component';
import {
  EncryptedDataModel,
  EncryptionService,
} from 'src/app/shared/services/encryption.service';
import { environment } from 'src/environments/environment';
import { Country } from '../../shared/models/country.model';
import { Registration } from '../models/registration.model';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private encryptionService: EncryptionService
  ) {}

  getEEACountries(): Observable<Country[]> {
    const url = `${environment.BACKEND_URL}/eea-countries`;
    return this.http.get<Country[]>(url);
  }

  getHighRiskCountries(): Observable<Country[]> {
    const url = `${environment.BACKEND_URL}/high-risk-countries`;
    return this.http.get<Country[]>(url);
  }

  async register(body: Registration): Promise<Observable<void>> {
    const url = `${environment.BACKEND_URL}/registration/sign-up-requests`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(body);
    return this.http.post<void>(url, cipherModel);
  }

  resendConfirmationEmail(): Observable<void> {
    const url = `${environment.BACKEND_URL}/registration/resend-confirmation-link-requests`;
    return this.http.post<void>(url, null);
  }

  confirmEmail(token: string): Observable<{ refresh: string; token: string }> {
    const url = `${environment.BACKEND_URL}/registration/email-confirmation-requests`;
    return this.http.post<{ refresh: string; token: string }>(url, { token });
  }

  sendSms(phoneNumber: string): Observable<void> {
    const url = `${environment.BACKEND_URL}/registration/mobile-phone-change-requests`;
    return this.http.post<void>(url, { phoneNumber });
  }

  verifyPhoneNumber(otp: string): Observable<void> {
    const url = `${environment.BACKEND_URL}/registration/mobile-phone-confirmation-requests`;
    return this.http.post<void>(url, { otp });
  }

  async sendResetPasswordEmail(email: string): Promise<Observable<void>> {
    const url = `${environment.BACKEND_URL}/forgot-password/forgot-password-emails`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt({ email });
    return this.http.post<void>(url, cipherModel);
  }

  async resetPassword(
    token: string,
    password: string
  ): Promise<Observable<void>> {
    const url = `${environment.BACKEND_URL}/forgot-password/reset-password-requests`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt({ token, password });
    return this.http.post<void>(url, cipherModel);
  }

  initialCardPayment(): Observable<{ url: string }> {
    const requestUrl = `${environment.BACKEND_URL}/financial/cardIssuingPayment`;
    const successfulURL = `${window.location.origin}/initial-payment-success`;
    const failedURL = `${window.location.origin}/initial-payment-fail`;

    return this.http
      .post<{ url: string }>(requestUrl, {
        successfulURL,
        failedURL,
      })
      .pipe(
        tap(({ url }) => {
          this.dialog.open<CardPaymentDialogComponent, CardPaymentDialogData>(
            CardPaymentDialogComponent,
            {
              width: '500px',
              height: '500px',
              disableClose: true,
              data: {
                src: url,
              },
            }
          );
        })
      );
  }

  createAccountsPackage(): Observable<void> {
    const url = `${environment.BACKEND_URL}/financial/createPackage`;
    return this.http.post<void>(url, null);
  }
}
