export enum Occupation {
  'Public servant / Police / Military' = 'Public servant / Police / Military',
  'Agriculture / Farming / Foresting' = 'Agriculture / Farming / Foresting',
  'Craftwork / Trade' = 'Craftwork / Trade',
  'Culture / Sport' = 'Culture / Sport',
  'Banking / Insurance / Finance / Auditing' = 'Banking / Insurance / Finance / Auditing',
  'Construction / Publicworks' = 'Construction / Publicworks',
  'Education' = 'Education',
  'Manufacturing / Maintenance' = 'Manufacturing / Maintenance',
  'Medical / Paramedical / Pharmaceuticals' = 'Medical / Paramedical / Pharmaceuticals',
  'Food industry / Hospitality / Tourism' = 'Food industry / Hospitality / Tourism',
  'Services / IT' = 'Services / IT',
  'Social Security' = 'Social Security',
  'Advertising' = 'Advertising',
  'Consumer goods' = 'Consumer goods',
  'Energy & Utilities' = 'Energy & Utilities',
  'Fishing' = 'Fishing',
  'Healthcare' = 'Healthcare',
  'Investment companies' = 'Investment companies',
  'Industry / handicraft' = 'Industry / handicraft',
  'Media / Entertainment' = 'Media / Entertainment',
  'Mining & Quarrying' = 'Mining & Quarrying',
  'Printing & Publishing' = 'Printing & Publishing',
  'Real estate' = 'Real estate',
  'Shipping' = 'Shipping',
  'Telecommunications' = 'Telecommunications',
  'Trading' = 'Trading',
  'Transport' = 'Transport',
  'Collection of donations from charities or other non-profit organizations' = 'Collection of donations from charities or other non-profit organizations',
  'Agencies Recruiting Foreign workers' = 'Agencies Recruiting Foreign workers',
  'Art & Antiques' = 'Art & Antiques',
  'Diamond and Precious metals Merchants' = 'Diamond and Precious metals Merchants',
  'Political Parties' = 'Political Parties',
  'Private Security Firms' = 'Private Security Firms',
  'Sale of alcohol, tobacco products, cigarettes, e-cigarettes, pharmaceutical products, prescription drugs, personal medical devices, stored value cards and vouchers' = 'Sale of alcohol, tobacco products, cigarettes, e-cigarettes, pharmaceutical products, prescription drugs, personal medical devices, stored value cards and vouchers',
  'Licensed or supervised gambling and betting companies' = 'Licensed or supervised gambling and betting companies',
  'Provision of escrow services' = 'Provision of escrow services',
  'Selling of transferable securities (including forex contracts) and other investment type instruments including interests in private entities or properties' = 'Selling of transferable securities (including forex contracts) and other investment type instruments including interests in private entities or properties',
  'Crowd funding investments' = 'Crowd funding investments',
  'Airline and chartered flight / corporate jet (scheduled or unscheduled) or any other type of non-cargo air flight activities' = 'Airline and chartered flight / corporate jet (scheduled or unscheduled) or any other type of non-cargo air flight activities',
  'Adult Industry' = 'Adult Industry',
  'Diamond Mining' = 'Diamond Mining',
  'Escort and companion services' = 'Escort and companion services',
  'File Sharing' = 'File Sharing',
  'Remittance Services' = 'Remittance Services',
}
