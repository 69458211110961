import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserType } from '../login/models/user-type.enum';
import { ErrorService } from '../shared/error-dialog/error.service';
import { UserProfileData } from './models/user-profile-data.model';
import { ChangeEmailSuccessDialogNewComponent } from './components/change-email-success-dialog/change-email-success-dialog.component';
import { UserProfileService } from './user-profile.service';
import { Country } from '../shared/models/country.model';
import { combineLatest } from 'rxjs';
import { SharedService } from '../shared/services/shared.service';

@Component({
  selector: 'app-user-profile-new',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileNewComponent implements OnInit {
  userTypes = UserType;
  userType: UserType | undefined;
  userProfileData!: UserProfileData;
  isLoading = false;
  isEmailChangeSuccess = false;
  countries: Country[] = [];

  constructor(
    private sharedService: SharedService,
    private userProfileService: UserProfileService,
    private errorService: ErrorService,
    private router: Router,
    private dialog: MatDialog
  ) {
    const state = this.router.getCurrentNavigation()?.extras.state;
    this.isEmailChangeSuccess = state?.isEmailChangeSuccess;
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh(): void {
    this.isLoading = true;
    combineLatest([
      this.userProfileService.getUserProfile(),
      this.sharedService.getAllCountries(),
    ]).subscribe(
      ([userProfileData, countries]) => {
        this.userProfileData = userProfileData;
        this.countries = countries;
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
        this.errorService.showErrorDialog();
      }
    );
    if (this.isEmailChangeSuccess) {
      this.dialog.open<ChangeEmailSuccessDialogNewComponent>(
        ChangeEmailSuccessDialogNewComponent,
        {
          panelClass: 'dialog-with-close-button',
          width: '670px',
          disableClose: true,
        }
      );
    }
  }
}
