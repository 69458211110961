import { Component } from '@angular/core';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { RegistrationService } from '../../services/registration.service';

@Component({
  templateUrl: './initial-payment-fail.component.html',
  styleUrls: ['./initial-payment-fail.component.scss'],
})
export class InitialPaymentFailComponent {
  isLoading: boolean = false;

  constructor(
    private errorService: ErrorService,
    private registrationService: RegistrationService
  ) {}

  tryAgain(): void {
    this.isLoading = true;

    // TODO: add card fee payment and then the account creation
    this.registrationService.initialCardPayment().subscribe(
      () => (this.isLoading = false),
      () => {
        this.errorService.showErrorDialog();
        this.isLoading = false;
      }
    );
  }
}
