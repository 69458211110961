import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from './login/services/auth.service';
import { ErrorService } from './shared/error-dialog/error.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon(
      'add',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/add.svg')
    );
    iconRegistry.addSvgIcon(
      'arrow',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/arrow.svg')
    );
    iconRegistry.addSvgIcon(
      'both-directions',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/both-directions.svg')
    );
    iconRegistry.addSvgIcon(
      'cancel',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/cancel.svg')
    );
    iconRegistry.addSvgIcon(
      'card',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/card.svg')
    );
    iconRegistry.addSvgIcon(
      'checked',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/checked.svg')
    );
    iconRegistry.addSvgIcon(
      'chevron-expanded',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/chevron-expanded.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'chevron-contracted',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/chevron-contracted.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'dot',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/dot.svg')
    );
    iconRegistry.addSvgIcon(
      'file',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/file.svg')
    );
    iconRegistry.addSvgIcon(
      'hourglass',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/hourglass.svg')
    );
    iconRegistry.addSvgIcon(
      'iban',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/iban.svg')
    );
    iconRegistry.addSvgIcon(
      'random-check',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/random-check.svg')
    );
    iconRegistry.addSvgIcon(
      'trash',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/trash.svg')
    );
    iconRegistry.addSvgIcon(
      'user',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/user.svg')
    );
    iconRegistry.addSvgIcon(
      'wallet',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/wallet.svg')
    );
  }

  ngOnInit(): void {
    // initial getting of authenticated user
    if (this.authService.isLoggedIn()) {
      this.authService.getAuthenticatedUser().subscribe(
        () => {},
        () => {
          this.errorService.showErrorDialog();
        }
      );
    }
  }
}
