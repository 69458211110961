import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/login/services/auth.service';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { UserProfileService } from '../../user-profile.service';

@Component({
  selector: 'app-change-email-dialog-new',
  templateUrl: './change-email-dialog.component.html',
  styleUrls: ['./change-email-dialog.component.scss'],
})
export class ChangeEmailDialogNewComponent {
  hidePassword = true;
  isSuccess = false;
  isSending = false;

  form = this.fb.group({
    email: [
      '',
      [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-zA-Z0-9@._-]{1,255}$'),
      ],
    ],
    password: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private userProfileService: UserProfileService,
    private errorService: ErrorService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<ChangeEmailDialogNewComponent>
  ) {}

  async submit() {
    this.isSending = true;
    (
      await this.userProfileService.requestEmailChange(
        this.emailControl.value,
        this.passwordControl.value
      )
    ).subscribe(
      () => {
        this.isSuccess = true;
        this.isSending = false;
      },
      (err) => {
        this.isSending = false;
        if (err.status === 400) {
          this.passwordControl.setErrors({ wrongPassword: true });
        } else if (err.status === 409) {
          this.emailControl.setErrors({ emailInUse: true });
        } else if (err.status === 403) {
          this.authService.logOut(false, true);
          this.dialogRef.close();
        } else if (err.status === 406) {
          this.emailControl.setErrors({ disposableEmail: true });
        } else {
          this.errorService.showErrorDialog();
        }
      }
    );
  }

  get emailControl(): AbstractControl {
    return this.form.get('email')!;
  }

  get passwordControl(): AbstractControl {
    return this.form.get('password')!;
  }
}
