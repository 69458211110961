<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content fxLayout="column">
  <h1>{{ data.isResponse ? 'Response' : 'Message' }}</h1>
  <pre class="response">{{ formattedText }}</pre>
  <button mat-flat-button mat-dialog-close color="accent" fxFlexAlign="end">
    Close
  </button>
</mat-dialog-content>
