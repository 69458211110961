import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Page } from '../../../models/page.model';
import { UserLogItem } from '../../../registered-users/registered-user/registered-user-logs/models/user-log-item.model';

@Component({
  selector: 'app-action-logs',
  templateUrl: './action-logs.component.html',
  styleUrls: ['./action-logs.component.scss'],
})
export class ActionLogsComponent implements OnInit {
  @Input() userId!: number;
  @Input() actionLogs!: Page<UserLogItem> | undefined;
  @Output() reloadActionLogs = new EventEmitter<void>();

  dataSource!: MatTableDataSource<UserLogItem>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  public displayedColumns: string[] = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.actionLogs.currentValue) {
      this.setDataSource();
    }
  }

  ngOnInit(): void {
    this.displayedColumns = ['dateCreated', 'action', 'message'];
    this.setDataSource();
  }

  setDataSource() {
    this.dataSource = new MatTableDataSource(this.actionLogs?.items);
    setTimeout(() => (this.dataSource.paginator = this.paginator));
  }

  reloadLogs() {
    this.reloadActionLogs.emit();
  }
}
