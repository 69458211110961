<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button [mat-dialog-close]="notes.length">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content fxLayout="column" fxLayoutGap="20px">
  <app-big-loader *ngIf="isLoading"></app-big-loader>
  <ng-container *ngIf="!isLoading">
    <h1>{{ data.headerText }}</h1>
    <div class="details-container">
      <div>{{ programs[data.program] | titlecase }} account</div>
      <div *ngIf="data.dateCreated">
        Registration started {{ data.dateCreated | date: 'short' }}
      </div>
      <div>Email: {{ data.email }}</div>
      <div>Phone: {{ data.cellPhoneNumber }}</div>
    </div>
    <div class="state">{{ convertUserState(data.state) | titlecase }}</div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <label>Note (not visible for customer)</label>
      <mat-form-field>
        <textarea
          matInput
          cdkTextareaAutosize
          [cdkAutosizeMinRows]="3"
          [(ngModel)]="currentNote"
        ></textarea>
      </mat-form-field>
      <button
        mat-flat-button
        color="accent"
        class="g-medium"
        [disabled]="!currentNote || isSaving"
        [appButtonLoader]="isSaving"
        (click)="saveNote()"
      >
        Save note
      </button>
    </div>
    <div fxLayout="column" fxLayoutGap="24px">
      <div *ngFor="let note of notes" fxLayout="column" fxLayoutGap="8px">
        <div class="note-header">
          <span>{{ note.authorName }}</span>
          <span *ngIf="note.authorId === this.authenticatedUser?.id">
            (You)</span
          >,
          {{ note.dateCreated | date: 'short' }}
        </div>
        <div class="note-content">{{ note.content }}</div>
        <!-- only author of last note can make changes in the uploaded documents -->
        <app-note-document-form
          [clientId]="data.id"
          [userNoteId]="note.id"
          [isLastNoteAuthor]="
            note.id === notes[notes.length - 1].id &&
            note.authorId === authenticatedUser?.id
          "
          [documents]="note.documents"
        ></app-note-document-form>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end">
      <button mat-flat-button [mat-dialog-close]="notes.length" color="accent">
        Close
      </button>
    </div>
  </ng-container>
</mat-dialog-content>
