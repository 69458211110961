import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { RiskAssessmentService } from '../risk-assessment.service';
import { AmlHitTitle } from '../models/aml-hit-title.model';
import { MatTableDataSource } from '@angular/material/table';
import { ShowAmlHitDialogComponent } from '../show-aml-hit-dialog/show-aml-hit-dialog.component';
import { getRelevanceDescription } from 'src/app/shared/helpers/various-helpers.helper';

export interface ShowAmlHitsDialogData {
  reportId: number;
  userId: number;
  riskReportType: number;
}

@Component({
  selector: 'app-show-aml-hits-dialog',
  templateUrl: './show-aml-hits-dialog.component.html',
  styleUrls: ['./show-aml-hits-dialog.component.scss'],
})
export class ShowAmlHitsDialogComponent implements OnInit {
  isLoadingAmlHits: boolean = false;

  userId!: number;
  reportId!: number;
  riskReportType!: number;

  dataSourceHits!: MatTableDataSource<AmlHitTitle>;
  public displayedColumns: string[] = [
    'id',
    'name',
    'entityType',
    'score',
    'matchTypes',
    'types',
    'details',
  ];

  getRelevanceDescription = getRelevanceDescription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShowAmlHitsDialogData,
    private riskAssessmentService: RiskAssessmentService,
    private errorService: ErrorService,
    private dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.userId = this.data.userId;
    this.reportId = this.data.reportId;
    this.riskReportType = this.data.riskReportType;
    await this.getAmlHits();
  }

  async getAmlHits() {
    this.isLoadingAmlHits = true;
    (
      await this.riskAssessmentService.getAmlHits(
        this.userId,
        this.reportId,
        this.riskReportType
      )
    ).subscribe(
      (amlHits) => {
        this.isLoadingAmlHits = false;
        this.dataSourceHits = new MatTableDataSource(amlHits);
      },
      (error) => {
        this.isLoadingAmlHits = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  async getAmlHit(amlHitId: number) {
    this.dialog.open<ShowAmlHitDialogComponent>(ShowAmlHitDialogComponent, {
      panelClass: 'dialog-with-close-button',
      width: '1800px',
      disableClose: true,
      data: {
        amlHitId,
        userId: this.userId,
      },
    });
  }
}
