<ng-container [formGroup]="passwordForm">
  <div fxLayout="column">
    <label for="password">
      <ng-container *ngIf="!newPassword">Password</ng-container>
      <ng-container *ngIf="newPassword">New password</ng-container>
    </label>
    <mat-form-field appearance="outline">
      <input
        matInput
        name="password"
        [type]="hidePassword ? 'password' : 'text'"
        formControlName="password"
        (focus)="extendAll = true"
        data-testid="password-input"
      />
      <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword">
        <mat-icon
          data-testid="show-hide-password-button"
          class="material-icons-outlined"
          >{{ hidePassword ? 'visibility' : 'visibility_off' }}</mat-icon
        >
      </button>
      <mat-error
        data-testid="validation-message-box"
        *ngIf="passwordForm.get('password')?.hasError('required')"
        >Field is required</mat-error
      >
      <mat-error
        data-testid="validation-message-box"
        *ngIf="passwordForm.get('password')?.hasError('pattern')"
        >Password is invalid</mat-error
      >
      <mat-error
        data-testid="validation-message-box"
        *ngIf="passwordForm.get('password')?.hasError('notUniquePassword')"
      >You can't set up the same password as previous ones</mat-error
    >
    </mat-form-field>
  </div>
  <ng-container *ngIf="extendAll">
    <div class="password-validation">
      <div
        *ngFor="let condition of passwordConditions"
        fxLayoutAlign="start center"
      >
        <div fxFlex="30px" fxLayoutAlign="center center">
          <img
            [src]="
              password.match(condition.code)
                ? 'assets/img/checked.svg'
                : 'assets/img/checked-not.svg'
            "
          />
        </div>
        <span [class.success]="password.match(condition.code)">{{
          condition.description
        }}</span>
      </div>
    </div>
  </ng-container>
  <div fxLayout="column" *ngIf="extendAll || extendPasswordRepeat">
    <label for="passwordRepeat">Password once again</label>
    <mat-form-field appearance="outline">
      <input
        matInput
        name="passwordRepeat"
        [type]="hidePasswordRepeat ? 'password' : 'text'"
        formControlName="passwordRepeat"
        data-testid="password-once-again-input"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="hidePasswordRepeat = !hidePasswordRepeat"
      >
        <mat-icon
          data-testid="show-hide-once-again-password-button"
          class="material-icons-outlined"
          >{{ hidePasswordRepeat ? 'visibility' : 'visibility_off' }}</mat-icon
        >
      </button>
      <mat-error
        data-testid="validation-message-box"
        *ngIf="passwordForm.get('passwordRepeat')?.hasError('required')"
        >Field is required</mat-error
      >
      <mat-error
        data-testid="validation-message-box"
        *ngIf="passwordForm.get('passwordRepeat')?.hasError('match')"
        >Password doesn’t match</mat-error
      >
    </mat-form-field>
  </div>
</ng-container>
