<app-base-login-page title="eCREDO Accounts">
  <app-login-card imgName="payment.svg">
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
      <div fxLayout="column" fxLayoutAlign="start center" class="section">
        <strong>Success!</strong>
        <div>Your payment has been accepted.</div>
      </div>
      <div fxLayout="column" fxLayoutAlign="start center" class="section">
        <strong>Congratulations!</strong>
        <div>Your accounts have been created.<br /></div>
      </div>
      <button mat-flat-button color="accent" routerLink="/dashboard">
        Go to dashboard
      </button>
    </div>
  </app-login-card>
</app-base-login-page>
