<app-sub-dashboard-header
  *ngIf="cards.length > 0"
  svgIconName="card"
  [headerName]="currentCard?.name"
  [availableBalance]="currentCard?.balance"
  [section]="Sections.CARD"
  [cardHolder]="currentCard?.cardholder"
  [expiryDate]="currentCard?.expireDate"
  [isLoadingHeader]="isLoadingHeader"
  [isLocked]="currentCard?.status === AccountStatus.DEPOSIT_ONLY"
></app-sub-dashboard-header>

<div class="wrapper" fxLayout.lt-md="column" fxLayout>
  <div fxLayout="column" fxFlexOrder.lt-md="2" fxFlexOrder="1">
    <div class="container">
      <!--CARD BUTTONS-->
      <app-account-section-card-header
        *ngIf="$breakpointObserver | async"
        [program]="Program.JADE"
        [cards]="cards"
        [isLoadingCards]="isLoadingCards"
        [isCardActive]="currentCard?.status === AccountStatus.OPEN"
        [name]="currentCard?.name"
        [availableBalance]="currentCard?.balance"
        [cardholder]="currentCard?.cardholder"
        [isLocked]="currentCard?.status === AccountStatus.DEPOSIT_ONLY"
        [isIssued]="currentCard?.status === AccountStatus.ISSUED"
        [isChevron]="false"
      ></app-account-section-card-header>
      <div class="cards-buttons-container" *ngIf="!isLoadingCards">
        <!--Setting to JADE so it won't display dropdown-->

        <div *ngIf="cards.length > 0">
          <app-cards-tab-button
            title="Pin Reveal"
            description="WARNING: Instant charge of 1 EUR once clicked"
            imgName="pin.svg"
            alt="Pin Reveal"
            (click)="showPin()"
          ></app-cards-tab-button>
          <mat-divider></mat-divider>
          <app-cards-tab-button
            [title]="
              currentCard?.status === AccountStatus.DEPOSIT_ONLY
                ? 'Unlock the card'
                : 'Lock the card'
            "
            [description]="
              currentCard?.status === AccountStatus.DEPOSIT_ONLY
                ? 'The card will be immediately activated'
                : 'The card will be temporarily inactive'
            "
            imgName="lock-clock-blue.svg"
            alt="Lock clock"
            (click)="lockUnlockCard()"
          ></app-cards-tab-button>
          <mat-divider></mat-divider>
          <app-cards-tab-button
            title="Report lost or stolen card"
            description="The card will be deactivated"
            imgName="lock-or-stolen-card.svg"
            alt="Lock or stolen card"
            (click)="reportCard()"
          ></app-cards-tab-button>
        </div>
        <div
          *ngIf="cards.length === 0 && !isLoadingCards"
          fxLayout="column"
          fxLayoutAlign="center center"
          class="empty-state-container"
        >
          <div class="empty-state-title">No cards to display</div>
          <div class="empty-state-description">
            Order new card or check out
            <strong>Obsolete cards</strong> section
          </div>
        </div>
      </div>
      <div
        fxLayoutAlign="center center"
        *ngIf="isLoadingCards"
        class="cards-buttons-loader"
      >
        <app-big-loader-not-centered></app-big-loader-not-centered>
      </div>
    </div>

    <!--TRANSACTIONS-->
    <div *ngIf="cards.length > 0">
      <div class="h2-container">
        <h2>Latest transactions</h2>
      </div>
      <div class="container">
        <div class="transaction-container">
          <!-- This are cards transaction but setup is the same like for IBAN   >-->
          <app-account-sub-section
            [section]="Sections.IBAN"
            [accountTransactions]="accountTransactions"
            [isLoadingIBANTransactions]="isLoadingTransactions"
          ></app-account-sub-section>
          <div
            fxLayoutAlign="end center"
            class="statements-redirection"
            *ngIf="!isLoadingTransactions && accountTransactions?.length !== 0"
          >
            <span
              >More transactions? Go to
              <strong
                class="colored-text cursor"
                (click)="navigateToStatements()"
                >Statement</strong
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--CARDS-->
  <div class="cards-container" fxFlexOrder.lt-md="1" fxFlexOrder="2">
    <div *ngIf="!isLoadingCards">
      <div *ngFor="let card of cards">
        <app-card-item
          *ngIf="
            card.status !== AccountStatus.EXPIRED &&
            card.status !== AccountStatus.CLOSED
          "
          class="cursor"
          [card]="card"
          [isChevron]="false"
          [isPicked]="card.numAccountID === currentCard?.numAccountID"
          (cardActivated)="refresh()"
          (pickedCard)="changeCurrentCard($event)"
        ></app-card-item>
        <mat-divider
          *ngIf="
            card.status !== AccountStatus.EXPIRED &&
            card.status !== AccountStatus.CLOSED
          "
        ></mat-divider>
      </div>
    </div>
    <div
      fxLayoutAlign="start center"
      class="new-card cursor"
      *ngIf="isOrderNewCard() && !isLoadingCards"
      (click)="orderNewCard()"
    >
      <mat-icon svgIcon="add"></mat-icon>
      <span>Order new card</span>
    </div>
    <mat-divider *ngIf="isOrderNewCard() && !isLoadingCards"></mat-divider>
    <div
      fxLayoutAlign="start center"
      class="new-card cursor"
      *ngIf="!isLoadingCards"
      (click)="displayDeactivatedCards()"
    >
      <mat-icon svgIcon="hourglass"></mat-icon>
      <span>Obsolete cards</span>
    </div>
    <div fxLayoutAlign="center center" *ngIf="isLoadingCards">
      <app-big-loader-not-centered></app-big-loader-not-centered>
    </div>
  </div>
</div>
