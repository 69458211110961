import { Component, Input } from '@angular/core';

import { Step } from '../../stepper/stepper.component';

@Component({
  selector: 'app-onboarding-layout-new',
  templateUrl: './onboarding-layout.component.html',
  styleUrls: ['./onboarding-layout.component.scss'],
})
export class OnboardingLayoutNewComponent {
  @Input()
  steps: Step[] = [];
}
