import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-base-login-page',
  templateUrl: './base-login-page.component.html',
  styleUrls: ['./base-login-page.component.scss'],
})
export class BaseLoginPageComponent {
  @Input() title: string = '';
  @Input() buttonText: string = '';
  @Input() buttonRouterLink: string = '';
  @Input() titleCentered: boolean = false;
}
