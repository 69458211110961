<div class="section-container">
  <div fxLayoutAlign="space-between">
    <span>{{ date.substring(0, 10) }}</span>
    <span>Fee: {{ fee ? fee : 'No fee' }}</span>
  </div>
  <div fxLayoutAlign="space-between" class="bolded">
    <span>{{ name }}</span>
    <div [class.deposit-transaction]="transaction > 0">
      <span>
        <ng-container *ngIf="transaction > 0">+</ng-container
        >{{ transaction | amount }}
      </span>
    </div>
  </div>
  <div fxLayoutAlign="space-between">
    <div class="text-overflow">
      <span *ngIf="description">{{ description }}</span>
    </div>
    <span class="no-wrap">Balance: {{ balance | amount }}</span>
  </div>
</div>
<mat-divider></mat-divider>
