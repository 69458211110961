export enum AccountPurposeBusiness {
  'Commercial Payments/Receivables' = 'Commercial Payments/Receivables',
  'Payroll payments' = 'Payroll payments',
  'Cross-border transfers' = 'Cross-border transfers',
  'Deposits/Savings' = 'Deposits/Savings',
  'Insurance contributions' = 'Insurance contributions',
  'Income from Investments' = 'Income from Investments',
  'Receiving Dividends' = 'Receiving Dividends',
  'Investment Purposes' = 'Investment Purposes',
  'Crypto operations' = 'Crypto operations',
  'Intra Group Financing' = 'Intra Group Financing',
  'Trade Finance' = 'Trade Finance',
  'Credit Facilities' = 'Credit Facilities',
  'Granting Loans' = 'Granting Loans',
  'Receiving non-commercial contributions from members' = 'Receiving non-commercial contributions from members',
  'Granting non-commercial contributions to members' = 'Granting non-commercial contributions to members',
  'Subscriptions/Redemptions' = 'Subscriptions/Redemptions',
}
