import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { documentValidator } from 'src/app/shared/document.validator';
import { AppDocumentType } from '../../models/document-type.enum';
import { AppDocument } from '../../models/document.model';

@Component({
  selector: 'app-business-documents-form-new',
  templateUrl: './business-documents-form.component.html',
  styleUrls: ['./business-documents-form.component.scss'],
})
export class BusinessDocumentsFormNewComponent implements OnInit, OnDestroy {
  @Input() verificationAccepted: boolean = false;
  @Input() set documents(val: AppDocument[]) {
    // reset form from previous values
    this.documentsForm = this.fb.group({
      documents: this.fb.array([]),
    });
    if (this.verificationAccepted) {
      this.documentsArray.push(
        this.fb.control(
          {
            documentType: AppDocumentType.PROOF_OF_ADDRESS,
          },
          documentValidator()
        )
      );
    }

    this.documentTypes.forEach((type) => {
      this.documentsArray.push(
        this.fb.control(
          {
            documentType: type,
          },
          type !== AppDocumentType.LICENSE ? documentValidator() : null
        )
      );
    });

    this.documentsControls.forEach((control) =>
      control.setValue({
        ...control.value,
        doc: val.find((doc) => doc.type === control.value.documentType),
      })
    );
    // creates controls with data for documents with type ANOTHER_FILE
    this.addAdditionalDocuments(val);
    this._documents = val;
  }
  get documents(): AppDocument[] {
    return this._documents;
  }
  @Input() isReviewed: boolean = false;
  @Input() isFirstTimeEDD: boolean = false;
  @Output() isComplete = new EventEmitter<boolean>();
  @Output() progressLabel = new EventEmitter<string>();

  documentsForm = this.fb.group({
    documents: this.fb.array([]),
  });

  documentTypes = [
    AppDocumentType.CERTIFICATE_OF_INCORPORATION,
    AppDocumentType.MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION,
    AppDocumentType.LATEST_FINANCIAL_STATEMENTS,
    AppDocumentType.CERTIFICATE_OF_SHAREHOLDERS,
    AppDocumentType.CERTIFICATE_OF_DIRECTORS,
    AppDocumentType.CERTIFICATE_OF_REGISTERED_OFFICE,
    AppDocumentType.BOARD_RESOLUTION,
    AppDocumentType.LICENSE,
  ];

  private _documents: AppDocument[] = [];
  private documentsFormSub: Subscription | null = null;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.documentsFormSub = this.documentsForm.statusChanges
      .pipe(startWith(this.documentsForm.status))
      .subscribe((status) => {
        const validFieldsNumber = this.documentsControls
          .map((control) => (control.status === 'VALID' ? 1 : 0) as number)
          .reduce((prev, curr) => prev + curr);

        setTimeout(() => {
          this.isComplete.emit(this.verificationAccepted && status === 'VALID');
          this.progressLabel.emit(
            `${validFieldsNumber}/${this.documentsControls.length}`
          );
        });
      });
  }

  ngOnDestroy(): void {
    this.documentsFormSub?.unsubscribe();
  }

  addAnotherFile(): void {
    this.documentsArray.push(
      this.fb.control(
        { documentType: AppDocumentType.ANOTHER_FILE },
        documentValidator()
      )
    );
  }

  removeField(index: number): void {
    this.documentsArray.removeAt(index);
  }

  // Adds controls for documents with ANOTHER_FILE type
  private addAdditionalDocuments(documents: AppDocument[]): void {
    documents
      .filter((doc) => doc.type === AppDocumentType.ANOTHER_FILE)
      .forEach((doc) =>
        this.documentsArray.push(
          this.fb.control(
            {
              doc,
              documentType: doc.type,
            },
            documentValidator()
          )
        )
      );
  }

  get documentsArray(): FormArray {
    return this.documentsForm.get('documents') as FormArray;
  }
  get documentsControls(): FormControl[] {
    return this.documentsArray.controls as FormControl[];
  }
}
