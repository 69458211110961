import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { UsersService } from '../../../users.service';

export interface VerifyClientDialogData {
  userId: number;
  userName: string;
}

@Component({
  templateUrl: './verify-client-dialog.component.html',
  styleUrls: ['./verify-client-dialog.component.scss'],
})
export class VerifyClientDialogComponent {
  isVerifying: boolean = false;
  userId!: number;
  userName!: string;
  showReturnedToEddText: boolean = false;

  constructor(
    private errorService: ErrorService,
    private usersService: UsersService,
    @Inject(MAT_DIALOG_DATA) public data: VerifyClientDialogData,
    public dialogRef: MatDialogRef<VerifyClientDialogComponent, boolean> // returns true if success
  ) {
    this.userId = data.userId;
    this.userName = data.userName;
  }

  verifyClient(): void {
    this.isVerifying = true;
    this.usersService.verifyUser(this.userId).subscribe(
      (res) => {
        this.showReturnedToEddText = res.returnedToEdd;
        this.isVerifying = false;
        if (!this.showReturnedToEddText) {
          this.closeDialog();
        }
      },
      (error) => {
        this.isVerifying = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  closeDialog() {
    this.dialogRef.close(true);
  }
}
