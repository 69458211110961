<div class="container">
  <div fxLayout="column">
    <app-sub-dashboard-header
      *ngIf="($breakpointObserver | async) === false"
      [availableBalance]="availableBalance"
      [balance]="balance"
      [accountNumber]="accountNumber"
      [section]="Sections.IBAN"
      [isLoadingHeader]="isLoadingHeader"
      headerName="IBAN Account"
      svgIconName="iban"
      class="sub-header"
    >
    </app-sub-dashboard-header>
    <div fxLayout fxLayout.sm="column" fxLayout.xs="column">
      <div fxFlexOrder.lt-md="2" fxFlexOrder="1">
        <h2 class="h2-container">Latest transactions</h2>
        <div
          class="transaction-container"
          fxLayout="column"
          fxFlexOrder="1"
          fxFlexOrder.lt-md="2"
        >
          <app-account-section-header
            *ngIf="$breakpointObserver | async"
            svgIconName="iban"
            name="IBAN Account"
            [availableBalance]="availableBalance"
            [balance]="balance"
            [accountNumber]="accountNumber"
            [section]="Sections.IBAN"
            [isLoadingIBAN]="isLoadingHeader"
            [isChevron]="false"
          ></app-account-section-header>
          <div class="loader-container">
            <app-account-sub-section
              [accountTransactions]="accountTransactions"
              [isLoadingIBANTransactions]="isLoadingTransactions"
              [section]="Sections.IBAN"
            ></app-account-sub-section>
          </div>

          <div
            fxLayoutAlign="end center"
            class="statements-redirection"
            *ngIf="!isLoadingTransactions && accountTransactions?.length !== 0"
          >
            <span
              >More transactions? Go to
              <strong
                class="colored-text cursor"
                (click)="navigateToStatements()"
                >Statement</strong
              ></span
            >
          </div>
        </div>
      </div>
      <div
        [ngClass]="
          isCorporateUser ? 'buttons-container-corporate' : 'buttons-container'
        "
        fxLayout="column"
        fxFlexOrder.lt-md="1"
        fxFlexOrder="2"
      >
        <div
          fxLayout
          fxLayoutAlign="start center"
          class="button-padding cursor"
          (click)="generateCertificate()"
        >
          <mat-icon
            svgIcon="file"
            color="accent"
            class="icon-margin"
          ></mat-icon>
          <span>IBAN Certificate</span>
          <app-small-loader *ngIf="isGeneratingCertificate"></app-small-loader>
        </div>

        <mat-divider *ngIf="!isCorporateUser"></mat-divider>
        <div fxLayout="row">
          <div
            *ngIf="!isCorporateUser"
            class="button-padding cursor"
            fxLayout
            fxLayoutAlign="start center"
            (click)="topUpWithCard()"
          >
            <img
              src="assets/img/credit-card.svg"
              class="icon-margin"
              width="24"
              height="24"
              alt="credit-card"
            />
            <span>Top up with a debit card</span>
          </div>
          <div
            *ngIf="!isCorporateUser"
            fxLayout
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            class="header-container"
          >
            <mat-icon class="material-icons-outlined info" (click)="showInfo()"
              >info</mat-icon
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
