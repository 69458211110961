<div mat-dialog-title fxLayoutAlign="end">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <ng-container [ngSwitch]="!!data.message1">
        <div *ngSwitchCase="true"><h1>{{ data.title1 }}</h1></div>
        <div *ngSwitchCase="true">{{ data.message1 }}</div> <br/>
        <div *ngSwitchCase="true"><h1>{{ data.title2 }}</h1></div>
        <div *ngSwitchCase="true">{{ data.message2 }}</div>
        <div *ngSwitchCase="true">{{ data.message3 }}</div>

        <div *ngSwitchCase="false"><h1>Something went wrong</h1></div>
        <div *ngSwitchCase="false">
        Apologies, there was a problem with your request.<br /><br />
        Try to reload the page. If the problem persists, please
        <a href="mailto:support@ecredo.com">Report the problem</a>.
        </div>
    </ng-container>
  
    <div [ngSwitch]="!!data.message1" class="buttons" fxLayoutAlign="end center">
      <button
        *ngSwitchCase="false"
        mat-flat-button
        color="accent"
        (click)="reloadPage()"
      >
        Reload the page
      </button>
    </div>
  </mat-dialog-content>
  