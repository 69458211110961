<mat-card>
  <div fxLayoutAlign="space-between center">
    <div>
      <span class="header">Audit Trail</span>
    </div>
    <div>
      <button mat-icon-button (click)="reloadLogs()">
        <mat-icon color="accent">refresh</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider style="margin-bottom: 5px"></mat-divider>

  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="dateCreated">
      <mat-header-cell *matHeaderCellDef>Date Created</mat-header-cell>
      <mat-cell *matCellDef="let row">{{
        row.dateCreated | date: 'dd/MM/yyyy HH:mm'
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef>Action type</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.action }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="message">
      <mat-header-cell *matHeaderCellDef>Message</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.message }}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let col; columns: displayedColumns; let entry"
    ></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]"></mat-paginator>
</mat-card>
