import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Sections } from '../../shared/sections.enum';
import { currencies } from '../../shared/currencies';
import { WalletInstance } from '../../models/walletInstance';
import { Card } from '../../models/card.model';
import { Transaction } from '../../models/transaction.model';
import { OrderNewCardDialogComponent } from '../order-new-card-dialog/order-new-card-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-account-sub-section',
  templateUrl: './account-sub-section.component.html',
  styleUrls: ['./account-sub-section.component.scss'],
})
export class AccountSubSectionComponent implements OnChanges {
  @Input() isCardActive?: boolean;
  @Input() section!: Sections;
  @Input() accountTransactions?: Transaction[];
  @Input() cards?: Card[];
  @Input() isLoadingCards!: boolean;
  @Input() wallet!: WalletInstance;
  @Input() isLoadingIBANTransactions!: boolean;
  @Input() isLoadingCardTransactions!: boolean;

  Sections = Sections;
  cardSectionEmptyState?: boolean;
  currencies = currencies;

  constructor(private dialog: MatDialog) {}

  imagePath(name: string): string {
    return `assets/img/country-flags/${name}.svg`;
  }

  orderNewCard() {
    this.dialog.open(OrderNewCardDialogComponent, {
      width: '405px',
      autoFocus: false,
      panelClass: 'dialog-with-close-button',
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.isCardActive ||
      changes.accountTransactions ||
      changes.isLoadingCardTransactions
    ) {
      this.cardSectionEmptyState =
        !this.isCardActive &&
        !!(this.cards || []).length &&
        this.section === Sections.CARD &&
        !(this.accountTransactions || []).length;
    }
  }
}
