<div fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="dialog-container">
  <div fxLayout="column">
    <h1 class="dialog-title">Pin Reveal</h1>
    <span class="dialog-description">
      Your PIN will be sent via SMS message to your contact phone</span
    >
  </div>
</div>
