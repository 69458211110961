import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Sections } from '../../shared/sections.enum';
import { Router } from '@angular/router';
import { convertIban } from '../../../shared/helpers/various-helpers.helper';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-account-section-header',
  templateUrl: './account-section-header.component.html',
  styleUrls: ['./account-section-header.component.scss'],
})
export class AccountSectionHeaderComponent {
  @Input() svgIconName!: string;
  @Input() isLoadingIBAN!: boolean;
  @Input() accountNumber?: string;
  @Input() name?: string;
  @Input() availableBalance?: number;
  @Input() balance?: number;
  @Input() section!: Sections;
  @Input() isChevron: boolean = true;
  @Input() isLoadingWallet?: boolean;
  @Output() readonly cardActivated = new EventEmitter<void>();

  Sections = Sections;

  constructor(public router: Router, private dialog: MatDialog) {}

  //Inserts space every 4 characters
  convertIban = convertIban;

  redirectTo() {
    this.section === Sections.IBAN
      ? this.router.navigateByUrl('sub-dashboard/iban-account')
      : this.router.navigateByUrl('sub-dashboard/wallet');
  }
}
