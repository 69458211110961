<div
  fxLayout
  fxLayoutAlign="space-between center"
  (click)="changeCurrentCard()"
  [class.picked]="isPicked"
>
  <div
    class="container"
    fxLayout="column"
    [class.small-screen-container]="isSmallScreen"
  >
    <div fxLayoutAlign="space-between center">
      <div class="name-container" fxLayoutAlign="center center">
        <mat-icon
          svgIcon="card"
          [class.inactive-card]="card.status !== AccountStatus.OPEN"
          *ngIf="card.status !== AccountStatus.DEPOSIT_ONLY"
        ></mat-icon>
        <img
          src="assets/img/lock-clock-grey.svg"
          alt="locked"
          *ngIf="card.status === AccountStatus.DEPOSIT_ONLY"
          class="locked-icon"
        />
        <div>
          <span [class.inactive-card]="card.status !== AccountStatus.OPEN">{{
            card.name
          }}</span>
          <span
            *ngIf="card.status === AccountStatus.DEPOSIT_ONLY"
            class="locked-span inactive-card"
            >locked</span
          >
        </div>
      </div>
      <div
        class="value"
        *ngIf="
          card.balance !== undefined &&
          (card.status === AccountStatus.OPEN ||
            card.status === AccountStatus.DEPOSIT_ONLY) &&
          !showActiveButton()
        "
        [class.no-chevron-balance]="!isChevron"
      >
        {{ card.balance | amount }} EUR
      </div>
    </div>
    <div fxLayoutAlign="left-end center">
      <img src="assets/img/master-card.svg" alt="master-card" />
      <span class="card-holder">{{ card.cardholder | titlecase }}</span>
    </div>
  </div>
  <div
    class="chevron-container cursor"
    fxLayoutAlign="center center"
    *ngIf="
      card.status === AccountStatus.OPEN &&
      isChevron &&
      !deactivatedCards &&
      !showActiveButton()
    "
  >
    <mat-icon svgIcon="chevron-contracted"></mat-icon>
  </div>
  <div
    *ngIf="deactivatedCards"
    class="expired-date"
    [class.expired-date-small-screen]="isSmallScreen"
    fxLayout
  >
    <span>{{
      card.status === AccountStatus.EXPIRED ? 'Expired' : 'Closed'
    }}</span>
    <span class="date-margin"> {{ card.expireDate | date: 'M/yyyy' }} </span>
  </div>
  <div
    fxLayoutAlign="center center"
    *ngIf="
      (showActiveButton() ||
        (card.status !== AccountStatus.OPEN &&
          card.status !== AccountStatus.DEPOSIT_ONLY)) &&
      !deactivatedCards
    "
  >
    <button
      mat-stroked-button
      color="accent"
      class="g-small activation-button"
      (click)="activateCard(card, $event)"
    >
      Activate
    </button>
  </div>
</div>
