<div class="buttons-container" fxLayoutAlign="start center" fxLayoutGap="20px">
  <button
    mat-flat-button
    color="accent"
    class="g-small"
    routerLink="activation"
    routerLinkActive="active"
  >SMS Activation Codes
  </button>
  <button
    mat-flat-button
    color="accent"
    class="g-small"
    routerLink="update-chids"
    routerLinkActive="active"
  >Update CHIDS
</button>
</div>
<router-outlet></router-outlet>