import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { OnboardingUserItem } from '../models/onboarding-user-item.model';
import { OnboardingUserSearch } from '../models/onboarding-user-search.model';
import { UsersService } from '../users.service';

export class OngoingRegistrationDataSource
  implements DataSource<OnboardingUserItem>
{
  private ongoingRegistrationsSubject = new BehaviorSubject<
    OnboardingUserItem[]
  >([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private lengthSubject = new BehaviorSubject<number>(0);
  private currentRequestSub: Subscription | undefined;
  public isLoadingObs = this.loadingSubject.asObservable();

  constructor(
    private usersService: UsersService,
    private errorService: ErrorService
  ) {}

  connect(
    collectionViewer: CollectionViewer
  ): Observable<OnboardingUserItem[]> {
    return this.ongoingRegistrationsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.ongoingRegistrationsSubject.complete();
    this.loadingSubject.complete();
    this.lengthSubject.complete();
  }

  // count of all items in the source
  getLength(): Observable<number> {
    return this.lengthSubject.asObservable();
  }

  getCount(): number {
    return this.lengthSubject.value;
  }

  loadOngoingRegistrations(data: OnboardingUserSearch): void {
    this.currentRequestSub?.unsubscribe(); // cancels previous request
    this.loadingSubject.next(true);

    this.currentRequestSub = this.usersService
      .getOngoingRegistrations(data)
      .pipe(
        map((res) => {
          this.lengthSubject.next(res.totalCount);
          return res.items;
        }),
        catchError(() => {
          this.errorService.showErrorDialog();
          return of([]);
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((ongoingRegistrations) =>
        this.ongoingRegistrationsSubject.next(ongoingRegistrations)
      );
  }
}
