<div [formGroup]="filtersGroup">
  <div
    fxLayout
    fxLayoutAlign="space-between center"
    [ngClass]="
      accountTable.accountName.includes('Wallet')
        ? 'header-container-wallet'
        : 'header-container'
    "
  >
    <div fxLayout fxLayoutAlign="start center">
      <button
        mat-stroked-button
        color="accent"
        (click)="goBack()"
        class="go-back-button"
        fxLayoutAlign="center center"
      >
        <mat-icon svgIcon="arrow" class="arrow-icon"></mat-icon> Back
      </button>
      <img
        *ngIf="accountTable.accountIconName === 'master-card'"
        src="assets/img/master-card.svg"
        alt="master-card"
      />
      <div class="account-name">
        {{ accountTable.accountName }}
      </div>
    </div>
    <div fxLayout fxLayoutAlign="end center">
      <div class="code">
        {{ accountTable.accountDetails.code }}
        <br />
        {{ accountTable.accountDetails.iban }}
      </div>
      <div
        class="account-status"
        [ngClass]="
          isAccountOpen(accountTable.accountDetails.status) ? 'open' : 'other'
        "
      >
        {{ getAccountStatusName(accountTable.accountDetails.status) }}
      </div>
    </div>
  </div>

  <div
    fxLayout
    fxLayoutGap="30px"
    fxLayoutAlign="start center"
    class="input-container"
  >
    <div fxLayout="column" fxFlex="228px">
      <label>Search</label>
      <mat-form-field appearance="outline">
        <input matInput name="search" formControlName="search" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  </div>

  <table
    mat-table
    [dataSource]="dataSource"
    [fixedLayout]="true"
    matSort
    matSortDisableClear
    multiTemplateDataRows
  >
    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>
        <div mat-sort-header="date">Transaction Date</div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div>{{ row.transactionDate | date: 'shortDate' }}</div>
      </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>
        <div mat-sort-header="description">Description</div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div>{{ row.description }}</div>
      </td>
    </ng-container>

    <!-- Debit Column -->
    <ng-container matColumnDef="debit">
      <th mat-header-cell *matHeaderCellDef class="right">
        <div mat-sort-header="debit">Debit</div>
      </th>
      <td mat-cell *matCellDef="let row" class="right">
        <div>
          {{ row.debit | amount }}
        </div>
      </td>
    </ng-container>

    <!-- Credit Column -->
    <ng-container matColumnDef="credit">
      <th mat-header-cell *matHeaderCellDef class="right">
        <div mat-sort-header="credit">Credit</div>
      </th>
      <td mat-cell *matCellDef="let row" class="right">
        <div>
          {{ row.credit | amount }}
        </div>
      </td>
    </ng-container>

    <!-- Balance Column -->
    <ng-container matColumnDef="balance">
      <th mat-header-cell *matHeaderCellDef class="right">
        <div mat-sort-header="balance">Balance</div>
      </th>
      <td mat-cell *matCellDef="let row" class="right">
        <div>{{ row.balance | amount }}</div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr *matNoDataRow>
      <td [attr.colspan]="5" *ngIf="dataSource.isLoadingObs | async">
        <div class="no-data-row" fxLayoutAlign="center center">
          <app-big-loader-not-centered></app-big-loader-not-centered>
        </div>
      </td>
      <td
        [attr.colspan]="5"
        *ngIf="
          (dataSource.isLoadingObs | async) !== true &&
          (dataSource.lengthSubjectObs | async) === 0
        "
      >
        <div class="no-data-row" fxLayoutAlign="center center">
          No results
          <span *ngIf="searchControl.value">
            for <em> {{ searchControl.value }}</em></span
          >
        </div>
      </td>
    </tr>
  </table>
  <mat-paginator
    class="mat-paginator-container"
    [length]="dataSource.getLength() | async"
    [pageSize]="20"
    [pageSizeOptions]="[20, 50, 100]"
  ></mat-paginator>
</div>
