import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Page } from '../admin/users/models/page.model';
import { AccountInfo } from '../dashboard/models/accountInfo.model';
import { StatementItem } from './models/statement-item.model';
import { StatementSearch } from './models/statement-search.model';
import { PaymentSearch } from './models/payment-search.model';
import { PaymentItem } from './models/payment-item.model';

@Injectable({
  providedIn: 'root',
})
export class StatementService {
  financialUrl = `${environment.BACKEND_URL}/financial`;

  constructor(private http: HttpClient) {}

  getStatement(data: StatementSearch): Observable<Page<StatementItem>> {
    const url = `${this.financialUrl}/account-statement`;
    return this.http.post<Page<StatementItem>>(url, data);
  }

  getStatementsCsv(data: StatementSearch): Observable<string> {
    const url = `${this.financialUrl}/account-statement.csv`;
    return this.http.post(url, data, { responseType: 'text' });
  }

  getPaymentDetails(body: PaymentSearch): Observable<PaymentItem> {
    const url = `${this.financialUrl}/payment-details`;
    return this.http.post<PaymentItem>(url, body);
  }

  getAccountInfo(numAccountId: number): Observable<AccountInfo> {
    const url = `${this.financialUrl}/accountInfo`;
    return this.http.post<AccountInfo>(url, { numAccountId });
  }
}
