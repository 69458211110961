import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { AccountDetails } from 'src/app/dashboard/models/account-details.model';
import { FavouriteTransfer } from '../../shared/models/favourite-transfer.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { InfoService } from '../../../shared/info-dialog/info.service';

@Component({
  selector: 'app-e2e-accounts-selector',
  templateUrl: './e2e-accounts-selector.component.html',
  styleUrls: ['./e2e-accounts-selector.component.scss'],
})
export class E2eAccountsSelectorComponent implements OnInit {
  @Input() fromAccount?: AccountDetails;
  @Input() accountOwnerGuid!: string;
  @Output() selectFav = new EventEmitter<FavouriteTransfer>();

  toUserGroup?: FormGroup;
  isOpen: boolean = false;
  $breakpointObserver?: Observable<boolean>;

  constructor(
    private controlContainer: ControlContainer,
    private breakpointObserver: BreakpointObserver,
    private infoService: InfoService
  ) {}

  ngOnInit(): void {
    this.toUserGroup = this.controlContainer.control as FormGroup;
    this.$breakpointObserver = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(map((state) => state.breakpoints[Breakpoints.XSmall]));
  }

  onSelectFav(favourite: FavouriteTransfer): void {
    this.isOpen = false;
    this.selectFav.emit(favourite);
  }

  showInfo(): void {
    let title: string = 'e2e Now';
    let message: string =
      "Transfer funds instantly between eCREDO users providing only the recipient's email address. Fee applies for e2e Now transfers.";
    this.infoService.showInfoDialog(title, message);
  }
}
