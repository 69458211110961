import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/login/services/auth.service';
import { RegistrationService } from 'src/app/login/services/registration.service';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';

@Component({
  selector: 'app-email-sent',
  templateUrl: './email-sent.component.html',
  styleUrls: ['./email-sent.component.scss'],
})
export class EmailSentComponent implements OnInit {
  isLoggedIn: boolean = false;
  isSending: boolean = false;
  sentAgain: boolean = false;

  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private registrationService: RegistrationService
  ) {}

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isLoggedIn();
  }

  sendEmail(): void {
    this.sentAgain = false;
    this.isSending = true;
    this.registrationService.resendConfirmationEmail().subscribe(
      () => {
        this.sentAgain = true;
        this.isSending = false;
      },
      () => {
        this.sentAgain = false;
        this.isSending = false;
        this.errorService.showErrorDialog();
      }
    );
  }
}
