<!-- <div style="color: #ffff; margin-top: 100px; margin-left: 50px; margin-right: 50px; font-size: large; text-align: center; line-height: normal;">We are temporarily postponing the onboarding of new clients. Please email our support team at support@ecredo.com with your request, and we shall contact you shortly. <br> We apologise for the inconvenience.</div> -->
<app-base-login-page
  [title]="
    currentStep === registrationSteps.SELECT_PROGRAM
      ? 'New Account'
      : 'Register'
  "
  buttonText="Log in"
  buttonRouterLink="/login"
>
  <ng-container [ngSwitch]="currentStep">
    <app-choose-account
      *ngSwitchCase="registrationSteps.SELECT_PROGRAM"
      (selectProgram)="chooseProgram($event)"
    ></app-choose-account>
    <app-provide-data
      *ngSwitchCase="registrationSteps.PROVIDE_DATA"
      [selectedProgram]="selectedProgram"
      (changeProgram)="changeProgram()"
      (registered)="onRegistered()"
    ></app-provide-data>
  </ng-container>
</app-base-login-page>
