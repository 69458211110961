<mat-card>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div
      fxLayout
      fxLayoutGap="30px"
      fxLayoutAlign="start center"
      class="filters-container"
      [formGroup]="filtersGroup"
    >
      <div fxLayout="column">
        <label for="search">Search</label>
        <mat-form-field appearance="outline">
          <input
            data-testid="admin-search-input"
            matInput
            formControlName="search"
            name="search"
          />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <label>Date range</label>
        <mat-form-field appearance="outline">
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              data-testid="start-date-input"
              placeholder="Start date"
              formControlName="startDate"
            />
            <input
              matEndDate
              data-testid="end-date-input"
              placeholder="End date"
              formControlName="endDate"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <mat-checkbox
        data-testid="individuals-checkbox-text"
        color="accent"
        formControlName="individuals"
        >Individuals</mat-checkbox
      >
      <mat-checkbox
        data-testid="businesses-checkbox-text"
        color="accent"
        formControlName="businesses"
        >Businesses</mat-checkbox
      >
      <mat-checkbox color="accent" formControlName="overdue"
        >Overdue</mat-checkbox
      >
      <mat-radio-group
        fxLayout="row wrap"
        fxLayoutGap="19px"
        name="selectedUserState"
        formControlName="selectedUserState"
      >
        <mat-radio-button [value]="UserState.INITIATED_REGISTRATION">
          <div>Ongoing</div>
        </mat-radio-button>
        <mat-radio-button
          *ngIf="businessesControl.value"
          [value]="UserState.KEY_PERSONS_VERIFICATION"
          ><div>Key Persons Verification</div>
        </mat-radio-button>
        <mat-radio-button [value]="UserState.DECISION_POINT">
          <div>Decision Point (before scoring)</div>
        </mat-radio-button>
        <mat-radio-button [value]="UserState.EDD">
          <div>Decision Point (after scoring)</div>
        </mat-radio-button>
        <mat-radio-button [value]="UserState.WITHDRAWN">
          <div>Withdrawn</div>
        </mat-radio-button>
        <mat-radio-button [value]="UserState.REJECTED">
          <div>Rejected</div>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div>
      <button
        mat-stroked-button
        data-testid="download-pdf-button"
        color="accent"
        class="g-small"
        (click)="onGenerateCsv()"
        [appButtonLoader]="isLoadingCsv"
      >
        Download CSV
      </button>
    </div>
  </div>
  <div class="table-container">
    <div class="spinner-container" *ngIf="dataSource.isLoadingObs | async">
      <app-big-loader></app-big-loader>
    </div>

    <table
      mat-table
      data-testid="admin-table"
      [dataSource]="dataSource"
      [fixedLayout]="true"
      matSort
      matSortStart="desc"
      matSortDisableClear
    >
      <!-- wasDisplayed Column -->
      <ng-container matColumnDef="dot">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let row">
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon
              data-testid="dot-icon"
              *ngIf="!row.wasDisplayed"
              svgIcon="dot"
            ></mat-icon>
          </div>
        </td>
      </ng-container>

      <!-- businessDaysOverdue Column -->
      <ng-container matColumnDef="businessDaysOverdue">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Days<br />
          overdue
        </th>
        <td mat-cell *matCellDef="let row">
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon
              data-testid="hourglass-icon"
              *ngIf="row.isPendingForLong"
              svgIcon="hourglass"
            ></mat-icon>
            <div class="overdue-days" *ngIf="row.businessDaysOverdue !== 0">
              ({{ row.businessDaysOverdue }})
            </div>
            <div
              class="zero-overdue-days"
              *ngIf="row.businessDaysOverdue === 0"
            >
              -
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User Id</th>
        <td mat-cell *matCellDef="let row">
          {{ row.id }}
        </td>
      </ng-container>

      <!-- Names Column -->
      <ng-container matColumnDef="names">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header="firstName">First name</div>
          <div mat-sort-header="lastName">Last name</div>
        </th>
        <td
          data-testid="name-cell"
          mat-cell
          *matCellDef="let row"
          [routerLink]="[row.id]"
        >
          <div>{{ row.firstName }}</div>
          <div>{{ row.lastName }}</div>
        </td>
      </ng-container>

      <!-- Program Column -->
      <ng-container matColumnDef="program">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Account</th>
        <td data-testid="program-cell" mat-cell *matCellDef="let row">
          {{ programs[row.program] | titlecase }}
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td data-testid="status-cell" mat-cell *matCellDef="let row">
          {{ convertUserState(row.state) | titlecase }}
        </td>
      </ng-container>

      <!-- Started Column -->
      <ng-container matColumnDef="dateCreated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Started</th>
        <td data-testid="started-date-cell" mat-cell *matCellDef="let row">
          {{ row.dateCreated | date: 'short' }}
        </td>
      </ng-container>

      <!-- NoteCount Column -->
      <ng-container matColumnDef="noteCount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Notes</th>
        <td mat-cell *matCellDef="let row">
          <ng-container [ngSwitch]="row.noteCount">
            <button
              data-testid="add-note-button"
              *ngSwitchCase="0"
              mat-icon-button
              (click)="addNote(row)"
            >
              <mat-icon svgIcon="add"></mat-icon>
            </button>
            <button
              mat-stroked-button
              *ngSwitchDefault
              class="note-button"
              color="accent"
              (click)="addNote(row)"
            >
              {{ row.noteCount }}
            </button>
          </ng-container>
        </td>
      </ng-container>

      <!-- Contact Column -->
      <ng-container matColumnDef="contact">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header="email">Email</div>
          <div mat-sort-header="cellPhoneNumber">Phone number</div>
        </th>
        <td data-testid="email-phone-cell" mat-cell *matCellDef="let row">
          <div>{{ row.email }}</div>
          <div>{{ row.cellPhoneNumber }}</div>
        </td>
      </ng-container>

      <!-- CompanyName Column -->
      <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Company name</th>
        <td data-testid="company-name-cell" mat-cell *matCellDef="let row">
          {{ row.companyName || '-' }}
        </td>
      </ng-container>

      <!-- CountryName Column -->
      <ng-container matColumnDef="countryName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
        <td data-testid="country-cell" mat-cell *matCellDef="let row">
          {{ row.countryName | titlecase }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [class.pending-for-long]="row.isPendingForLong"
      ></tr>
    </table>

    <mat-paginator
      [length]="dataSource.getLength() | async"
      [pageSize]="20"
      [pageSizeOptions]="[20, 50, 100]"
    ></mat-paginator>
  </div>
</mat-card>
