import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { AccountDetails } from 'src/app/dashboard/models/account-details.model';
import { FavouriteTransfer } from '../../shared/models/favourite-transfer.model';
import { InfoService } from '../../../shared/info-dialog/info.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Beneficiary } from '../../shared/models/beneficiary.model';

@Component({
  selector: 'app-ext-accounts-selector',
  templateUrl: './ext-accounts-selector.component.html',
  styleUrls: ['./ext-accounts-selector.component.scss'],
})
export class ExtAccountsSelectorComponent implements OnInit {
  @Input() accounts: AccountDetails[] = [];
  @Input() accountOwnerGuid!: string;
  @Output() selectFav = new EventEmitter<FavouriteTransfer>();
  @Output() selectBen = new EventEmitter<Beneficiary>();

  accountsGroup?: FormGroup;
  isOpenFav: boolean = false;
  isOpenBen: boolean = false;
  $breakpointObserver?: Observable<boolean>;

  constructor(
    private controlContainer: ControlContainer,
    private infoService: InfoService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.accountsGroup = this.controlContainer.control as FormGroup;
    this.$breakpointObserver = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(map((state) => state.breakpoints[Breakpoints.XSmall]));
  }

  onSelectFav(favourite: FavouriteTransfer): void {
    this.isOpenFav = false;
    this.selectFav.emit(favourite);
  }

  onSelectBen(beneficiary: Beneficiary): void {
    this.isOpenBen = false;
    this.selectBen.emit(beneficiary);
  }

  selectFromAccount(account: AccountDetails, currency: string = 'EUR'): void {
    this.fromAccountControl.setValue(account);
    this.fromCurrencyControl.setValue(currency);
  }

  showInfo(): void {
    let title1: string = 'SEPA';
    let title2: string = 'SWIFT';
    let message1: string =
      'SEPA (Single Euro Payments Area) is a way of transferring money in EUR currency only and within the European Union or in the Member States of the European Economic Area. You can execute SEPA transfers from your IBAN. The SEPA zone includes the EU member states, plus other European countries such as Iceland, Monaco, Switzerland, Liechtenstein, Norway and San Marino. Fee applies for SEPA transfers.';
    let message2: string =
      'SWIFT (Society for Worldwide Interbank Financial Telecommunications) is a type of money transfer worldwide. You can execute an outgoing SWIFT payment transfer from your IBAN and any of your currency wallets. Fee applies for SWIFT transfers.';

    this.infoService.showInfoDialog(title1, message1, title2, message2);
  }

  get fromAccountsList(): AccountDetails[] {
    const selectedAcc = this.fromAccountControl.value;
    return this.accounts.filter((acc) => acc !== selectedAcc);
  }

  get fromAccountControl(): FormControl {
    return this.accountsGroup?.get('fromAccount') as FormControl;
  }
  get fromCurrencyControl(): FormControl {
    return this.accountsGroup?.get('fromCurrency') as FormControl;
  }
  get ibanOrAccountNumberControl(): FormControl {
    return this.accountsGroup?.get('ibanOrAccountNumber') as FormControl;
  }
}
