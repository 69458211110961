<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon data-testid="close-modal-button">close</mat-icon>
  </button>
</div>

<mat-dialog-content
  data-testid="ready-to-submit-modal"
  fxLayout="column"
  fxLayoutGap="20px"
>
  <h1>Ready to submit?</h1>
  <div>
    Great! It looks like your application is complete. You can submit it for the
    review!
  </div>
  <h2>Summary of your application</h2>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-icon svgIcon="checked" class="checked"></mat-icon>
    <div data-testid="form-filled-text">Declaration form completed</div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-icon svgIcon="checked" class="checked"></mat-icon>
    <div data-testid="form-filled-text">Form filled</div>
  </div>
  <div
    *ngIf="accountsNumber"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
  >
    <mat-icon svgIcon="checked" class="checked"></mat-icon>
    <div data-testid="companys-accounts-text">
      Company’s Accounts ({{ accountsNumber }})
    </div>
  </div>
  <div
    *ngIf="counterpartiesNumber"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
  >
    <mat-icon svgIcon="checked" class="checked"></mat-icon>
    <div data-testid="companys-counterparties-text">
      Company’s Counterparties ({{ counterpartiesNumber }})
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-icon svgIcon="checked" class="checked"></mat-icon>
    <div data-testid="document-uploaded-text">Documents uploaded</div>
  </div>
  <div
    *ngIf="directorsNumber"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
  >
    <mat-icon svgIcon="checked" class="checked"></mat-icon>
    <div data-testid="companys-directors-text">
      Company’s Stakeholders and Authorized Contact Person ({{
        directorsNumber
      }})
    </div>
  </div>
  <div
    *ngIf="groupMembersNumber"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
  >
    <mat-icon svgIcon="checked" class="checked"></mat-icon>
    <div data-testid="companys-group-members-text">
      Company’s Group Members ({{ groupMembersNumber }})
    </div>
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="end start"
    fxLayoutGap="20px"
    fxLayout.lt-sm="column-reverse"
    class="buttons"
  >
    <button
      data-testid="submit-later-button"
      mat-stroked-button
      color="accent"
      mat-dialog-close
    >
      Submit later
    </button>
    <button
      mat-flat-button
      color="accent"
      data-testid="submit-your-application-modal-button"
      [disabled]="isSubmitting"
      [appButtonLoader]="isSubmitting"
      (click)="submitApplication()"
    >
      Submit your application
    </button>
  </div>
</mat-dialog-content>
