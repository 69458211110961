<div fxLayout="column">
  <ng-container
    *ngTemplateOutlet="mailSent ? emailStep2 : emailStep1"
  ></ng-container>
</div>

<ng-template #emailStep1>
  <ng-container [formGroup]="resetForm">
    <div class="text" fxFlexAlign="center">
     For security reasons, please enter the email address that you have registered in your eCREDO account, in order to receive the password reset link.
    </div>
    <div fxLayout="column">
      <label for="email">Email</label>
      <mat-form-field appearance="outline">
        <input
          matInput
          formControlName="email"
          data-testid="email-input"
          name="email"
        />
        <mat-error
          data-testid="validation-message-box"
          *ngIf="resetForm.get('email')?.hasError('required')"
          >Field is required</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="resetForm.get('email')?.hasError('email')"
          >Email is invalid</mat-error
        >
      </mat-form-field>
    </div>
    <button
      mat-flat-button
      (click)="reset()"
      fxFlexAlign="center"
      data-testid="reset-password-button"
      color="accent"
      [disabled]="resetForm.invalid || isLoading"
      [appButtonLoader]="isLoading"
    >
      Reset password
    </button>
  </ng-container>
</ng-template>

<ng-template #emailStep2>
  <p>
    We’ve just sent you an e-mail. <strong>Go to your mailbox now</strong> and
    follow the instructions.
  </p>
  <p>
    Please note that you will receive an email only if there is an account
    connected to the provided email address.
  </p>
</ng-template>
