import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function adminFormValidator(): ValidatorFn {
  return (formControl: AbstractControl): ValidationErrors | null => {
    if (formControl.value?.onboardingField?.comment === '') {
      return { missingComment: true };
    } else {
      return null;
    }
  };
}
