import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-onboarding-form-item-new',
  templateUrl: './onboarding-form-item.component.html',
  styleUrls: ['./onboarding-form-item.component.scss'],
})
export class OnboardingFormItemNewComponent {
  @Input() isReviewed: boolean = false;
  @Input() label!: string;
  @Input() value?: string | number | null | boolean;
  @Input() field?: {
    comment: string | null;
    commentedValue: string | null;
  } | null;
}
