import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { minControlsNumberValidator } from 'src/app/shared/min-controls-number.validator';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { OnboardingService } from '../../onboarding.service';
import {
  CompanyCounterparty,
  SaveCompanyCounterparty,
} from '../../models/company-counterparty.model';
import { Country } from 'src/app/shared/models/country.model';
import { OnboardingField } from '../../models/onboarding-field.model';

@Component({
  selector: 'app-company-counterparties-form',
  templateUrl: './company-counterparties-form.component.html',
  styleUrls: ['./company-counterparties-form.component.scss'],
})
export class CompanyCounterpartiesFormComponent implements OnInit, OnDestroy {
  @Input() countries: Country[] = [];
  @Input() fields: OnboardingField[] = [];
  @Input() set companyCounterparties(val: CompanyCounterparty[]) {
    const counterpartiesIds = new Set();
    this.counterparties = [];

    val.forEach((counterparty) => {
      counterpartiesIds.add(counterparty.id.toString());
      this.counterparties.push(counterparty);

      // adds controls to counterpartiesGroup
      if (!this.counterpartiesGroup.get(counterparty.id.toString())) {
        this.counterpartiesGroup.addControl(
          counterparty.id.toString(),
          this.fb.control(null, Validators.requiredTrue)
        );
      }
    });

    // removes controls from counterpartiesGroup that are not needed anymore
    Object.keys(this.counterpartiesGroup.controls).forEach((key) => {
      if (!counterpartiesIds.has(key)) {
        this.counterpartiesGroup.removeControl(key);
      }
    });

    this._companyCounterparties = val;
  }
  get companyCounterparties(): CompanyCounterparty[] {
    return this._companyCounterparties;
  }
  @Input() isReviewed: boolean = false;
  // It is emitted when counterparty is modified by user (so that it can be show proprerly in other section)
  @Output() companyCounterpartiesChange = new EventEmitter<
    CompanyCounterparty[]
  >();
  @Output() isComplete = new EventEmitter<boolean>();

  counterparties: CompanyCounterparty[] = [];
  isAdding: boolean = false;
  private _companyCounterparties: CompanyCounterparty[] = [];
  private counterpartiesFormSub: Subscription | undefined = undefined;

  counterpartiesForm: FormGroup = this.fb.group(
    {
      counterparties: this.fb.group(
        {},
        {
          validators: [
            minControlsNumberValidator(1),
            this.atLeastOneIncomingOutgoingTransaction.bind(this),
          ],
        } // it has to be at least one counterparty
      ), // this group is used only for validation purposes (contains values 'isValid', and validation 'requiredTrue')
    },
    { updateOn: 'blur' }
  );

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private onboardingService: OnboardingService
  ) {}

  ngOnInit(): void {
    // Emits status of the form when it changes
    this.counterpartiesFormSub = this.counterpartiesForm.statusChanges
      .pipe(startWith(this.counterpartiesForm.status))
      .subscribe((status) => {
        setTimeout(() => this.isComplete.emit(status === 'VALID'));
      });
  }

  ngOnDestroy(): void {
    this.counterpartiesFormSub?.unsubscribe();
  }

  addCounterparty(): void {
    this.isComplete.emit(false); // prevents saving application while adding counterparty
    this.isAdding = true;
    const body: SaveCompanyCounterparty = {
      name: '',
      country: 0,
      businessActivity: '',
      isIncomingTransaction: false,
      isOutgoingTransaction: false,
      isActive: true,
    };
    this.onboardingService.createCompanyCounterparty(body).subscribe(
      (newCounterparty) => {
        this.companyCounterpartiesChange.emit([
          ...this.companyCounterparties,
          newCounterparty,
        ]);
        this.isAdding = false;
      },
      () => {
        this.errorService.showErrorDialog();
        this.isAdding = false;
      }
    );
  }

  // used for emitting company counterparties when one was changed
  onCounterpartyChange(newCounterparty: CompanyCounterparty): void {
    const index = this.companyCounterparties.findIndex(
      (companyCounterparty) => newCounterparty.id === companyCounterparty.id
    );

    const newCompanyCounterparties = [...this.companyCounterparties];
    newCompanyCounterparties[index] = {
      ...this.companyCounterparties[index],
      ...newCounterparty,
    };

    this.companyCounterpartiesChange.emit(newCompanyCounterparties);
  }

  // used for emitting company counterparties when one was removed
  onCounterpartyRemove(id: number): void {
    const newCompanyCounterparties = this.companyCounterparties.filter(
      (companyCounterparty) => companyCounterparty.id !== id
    );
    this.companyCounterpartiesChange.emit(newCompanyCounterparties);
  }

  // used for setting counterparty as complete (in counterpartiesGroup)
  setComplete(counterpartyId: number, isComplete: boolean): void {
    this.counterpartiesGroup
      .get(counterpartyId.toString())
      ?.setValue(isComplete);
  }

  trackByFn(index: number, item: CompanyCounterparty): number {
    return item.id;
  }

  get counterpartiesGroup(): FormGroup {
    return this.counterpartiesForm.get('counterparties') as FormGroup;
  }

  private atLeastOneIncomingOutgoingTransaction() {
    if (this.counterpartiesForm && this.counterparties.length > 0) {
      if (
        this.counterparties.filter((c) => c.isIncomingTransaction).length > 0 &&
        this.counterparties.filter((c) => c.isOutgoingTransaction).length > 0
      ) {
        this.counterpartiesGroup.setErrors(null);
        return null;
      } else {
        if (
          this.counterparties.filter((c) => c.isIncomingTransaction).length ===
            0 &&
          this.counterparties.filter((c) => c.isOutgoingTransaction).length > 0
        ) {
          return { incomingTransactionRequired: true };
        }
        if (
          this.counterparties.filter((c) => c.isOutgoingTransaction).length ===
            0 &&
          this.counterparties.filter((c) => c.isIncomingTransaction).length > 0
        ) {
          return { outgoingTransactionRequired: true };
        }
      }
    }
    return null;
  }
}
