import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cards-tab-button',
  templateUrl: './cards-tab-button.component.html',
  styleUrls: ['./cards-tab-button.component.scss'],
})
export class CardsTabButtonComponent implements OnInit {
  @Input() imgName!: string;
  @Input() alt!: string;
  @Input() title!: string;
  @Input() description!: string;

  imgPath!: string;

  constructor() {}

  ngOnInit(): void {
    this.imgPath = `assets/img/${this.imgName}`;
  }
}
