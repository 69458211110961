import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Page } from '../../../models/page.model';
import { UserLogItem } from './models/user-log-item.model';
import { UserLogSearch } from './models/user-log-search.model';

@Injectable({
  providedIn: 'root',
})
export class DbLogsService {
  baseUrl = `${environment.BACKEND_URL}/db-logs`;

  constructor(private http: HttpClient) {}

  getUserLogs(data: UserLogSearch): Observable<Page<UserLogItem>> {
    const url = this.baseUrl;

    let params = new HttpParams();
    params = params.set('userId', data.userId);
    if (data.page) {
      params = params.set('page', data.page);
    }
    if (data.limit) {
      params = params.set('limit', data.limit);
    }
    if (data.orderField) {
      params = params.set('orderField', data.orderField);
    }
    if (data.orderDescending) {
      params = params.set('orderDescending', data.orderDescending);
    }
    if (data.searchText) {
      params = params.set('searchText', data.searchText);
    }
    if (data.dateFrom) {
      params = params.set('dateFrom', data.dateFrom);
    }
    if (data.dateTo) {
      params = params.set('dateTo', data.dateTo);
    }
    return this.http.get<Page<UserLogItem>>(url, { params });
  }
}
