<div fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div>
  <div fxLayout="column">
    <h1>
      KYB Hits
      <span *ngIf="kybPayload"
        >for {{ kybPayload?.kyb?.company_name }} ({{
          kybPayload?.reference
        }})</span
      >
    </h1>
  </div>
  <div class="spinner-container" *ngIf="isLoadingKybHits">
    <app-big-loader></app-big-loader>
  </div>
  <mat-dialog-content>
    <mat-table [dataSource]="dataSourceHits" *ngIf="dataSourceHits">
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef><b>Id</b></mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.id }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="companyName">
        <mat-header-cell *matHeaderCellDef><b>Company Name</b></mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.companyName }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="companyNumber">
        <mat-header-cell *matHeaderCellDef
          ><b>Company Number</b></mat-header-cell
        >
        <mat-cell *matCellDef="let row">{{ row.companyNumber }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="companyType">
        <mat-header-cell *matHeaderCellDef><b>Company Type</b></mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.companyType }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="companyJurisdictionCode">
        <mat-header-cell *matHeaderCellDef
          ><b>Company Jurisdiction Code</b></mat-header-cell
        >
        <mat-cell *matCellDef="let row">
          {{ getJurisdictionName(row.companyJurisdictionCode) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="companyIncorporationDate">
        <mat-header-cell *matHeaderCellDef
          ><b>Company Incorporation Date</b></mat-header-cell
        >
        <mat-cell *matCellDef="let row">{{
          row.companyIncorporationDate
        }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="details">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button class="kyb" mat-button (click)="getKybHit(row.id)">
            View details
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let col; columns: displayedColumns; let entry"
      ></mat-row>
    </mat-table>
  </mat-dialog-content>
</div>
