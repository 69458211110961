import { AbstractControl, ValidatorFn } from '@angular/forms';

export function matchValidator(
  controlName: string,
  matchingControlName: string
): ValidatorFn {
  return (formGroup: AbstractControl): null => {
    const control = formGroup.get(controlName);
    const matchingControl = formGroup.get(matchingControlName);
    if (control?.value !== matchingControl?.value) {
      matchingControl?.setErrors({ ...matchingControl?.errors, match: true });
    } else {
      delete matchingControl?.errors?.match;
      if (!Object.keys(matchingControl?.errors || {}).length) {
        matchingControl?.setErrors(null);
      }
    }
    return null;
  };
}
