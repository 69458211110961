import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { AdminStatementItem } from '../../shared/AdminStatementItem.model';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { ErrorService } from '../../../../../../shared/error-dialog/error.service';
import { DashboardService } from '../../../../../../dashboard/dashboard.service';
import { catchError, finalize, map } from 'rxjs/operators';
import { StatementSearch } from '../../../../../../statement/models/statement-search.model';
import { TransactionType } from '../../../../../../statement/models/transaction-type.enum';

export class GenericAccountsTableDatasource
  implements DataSource<AdminStatementItem>
{
  private statementsSubject = new BehaviorSubject<AdminStatementItem[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private lengthSubject = new BehaviorSubject<number>(0);
  public lengthSubjectObs = this.lengthSubject.asObservable();
  private currentRequestSub: Subscription | undefined;
  public isLoadingObs = this.loadingSubject.asObservable();

  constructor(
    private dashboardService: DashboardService,
    private errorService: ErrorService
  ) {}

  connect(
    collectionViewer: CollectionViewer
  ): Observable<AdminStatementItem[]> {
    return this.statementsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.statementsSubject.complete();
    this.loadingSubject.complete();
    this.lengthSubject.complete();
  }

  getLength(): Observable<number> {
    return this.lengthSubject.asObservable();
  }

  loadStatements(accountNumId: number, data: StatementSearch): void {
    this.currentRequestSub?.unsubscribe(); // cancels previous request
    this.loadingSubject.next(true);
    // TODO
    // this.currentRequestSub = this.dashboardService
    //   .getAccountStatements(accountNumId, data)
    //   .pipe(
    //     map((res) => {
    //       this.lengthSubject.next(res.totalCount);
    //       return res.items;
    //     }),
    //     catchError(() => {
    //       this.errorService.showErrorDialog();
    //       return of([]);
    //     }),
    //     finalize(() => this.loadingSubject.next(false))
    //   )
    //   .subscribe((statements) =>
    //     this.statementsSubject.next(
    //       statements.map((statement) => ({
    //         transactionDate: statement.date,
    //         balance: statement.availableBalance,
    //         credit:
    //           statement.cardType === TransactionType.CREDIT
    //             ? statement.amount
    //             : undefined,
    //         debit:
    //           statement.cardType === TransactionType.DEBIT
    //             ? statement.amount
    //             : undefined,
    //         description: statement.description,
    //       }))
    //     )
    //   );
  }
  clear(): void {
    this.currentRequestSub?.unsubscribe(); // cancels previous request
    this.lengthSubject.next(0);
    this.statementsSubject.next([]);
    this.loadingSubject.next(false);
  }
}
