<div fxLayoutAlign="end">
  <button mat-icon-button [mat-dialog-close]="shouldRefresh">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="dialog-container">
  <ng-container *ngIf="!isSuccess">
    <h1 class="dialog-title">Order a new card</h1>
    <span class="bottom-text"
      >The card will be sent to your <strong>operating address</strong>.</span
    >
    <mat-error
      class="join-length-error"
      *ngIf="
        firstNameControl?.hasError('jointLength') ||
        lastNameControl?.hasError('jointLength')
      "
      >Cardholder’s first and last name combined should contain max. 26
      characters.
    </mat-error>
    <form
      fxLayout="column"
      class="dialog-form"
      [formGroup]="orderNewCardForm"
      (ngSubmit)="submitOrderNewCard()"
    >
      <label>Cardholder’s first name</label>
      <mat-form-field appearance="outline">
        <input
          matInput
          name="firstName"
          formControlName="firstName"
          required
          #firstNameInput
        />
        <mat-hint align="end" class="input-length-hint"
          >{{ firstNameInput.value?.length || 0 }}/50</mat-hint
        >
        <mat-error *ngIf="firstNameControl?.hasError('maxlength')"
          >First name can’t be longer than 50 characters</mat-error
        >
      </mat-form-field>
      <label>Cardholder’s last name</label>
      <mat-form-field appearance="outline">
        <input
          matInput
          name="lastName"
          formControlName="lastName"
          required
          #lastNameInput
        />
        <mat-hint align="end" class="input-length-hint"
          >{{ lastNameInput.value?.length || 0 }}/50</mat-hint
        >
        <mat-error *ngIf="lastNameControl?.hasError('maxlength')"
          >Last name can’t be longer than 50 characters</mat-error
        >
      </mat-form-field>
    </form>
    <div class="button-container" fxLayoutAlign.xs="end" fxLayoutAlign.sm="end">
      <button
        mat-flat-button
        color="accent"
        (click)="submitOrderNewCard()"
        [disabled]="!orderNewCardForm.valid"
        [appButtonLoader]="isOrderLoading"
      >
        Order new card
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="isSuccess">
    <h1 class="dialog-title">Order a new card</h1>
    <img src="assets/img/checked.svg" alt="checked" height="24" width="24" />
    <br />
    <div class="success-text-container">
      <span class="bottom-text"><strong>Success!</strong></span>
      <br />
      <span class="bottom-text">Your new card has been ordered.</span>
    </div>
    <div class="button-container">
      <button mat-flat-button color="accent" [mat-dialog-close]="shouldRefresh">
        Close
      </button>
    </div>
  </ng-container>
</div>
