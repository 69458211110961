import { Component, Input } from '@angular/core';
import { MassPaymentLine } from '../models/mass-payment-line.model';

@Component({
  selector: 'app-mass-payment-table',
  templateUrl: './mass-payment-table.component.html',
  styleUrls: ['./mass-payment-table.component.scss'],
})
export class MassPaymentTableComponent {
  @Input() dataSource?: MassPaymentLine[];
  @Input() showStatus: boolean = false; // used for showing/hiding Status column
  @Input() statusMap?: Map<string, boolean>; // key: transactionId, value: transaction status

  displayedColumns: string[] = [
    'beneficiaryName',
    'ibanBic',
    'bankNameAddress',
    'addressCountry',
    'payerDescription',
    'beneficiaryDescription',
    'valueDate',
    'amount',
    'fee',
  ];
  displayedColumnsWithStatus: string[] = [
    'beneficiaryName',
    'ibanBic',
    'bankNameAddress',
    'addressCountry',
    'payerDescription',
    'beneficiaryDescription',
    'valueDate',
    'amount',
    'fee',
    'status',
  ];
}
