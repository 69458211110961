import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { RegisteredUsersService } from '../registered-users.service';
import { RegisteredUser } from '../models/registered-user.model';
import { RegisteredUserProfileNewComponent } from './registered-user-profile-new/registered-user-profile.component';
import { ComponentCanDeactivate } from 'src/app/guards/pending-changes-guard';
import { UserState } from '../../../../login/models/user-state.enum';
import { RegisteredUserDocumentsComponent } from './registered-user-documents/registered-user-documents.component';
import { Program } from 'src/app/login/models/program.enum';
import { AdminNoteDialogData } from '../../ongoing-registration-new/admin-note-dialog/admin-note-dialog-data.model';
import { AdminNoteDialogComponent } from '../../ongoing-registration-new/admin-note-dialog/admin-note-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  templateUrl: './registered-user.component.html',
  styleUrls: ['./registered-user.component.scss'],
})
export class RegisteredUserComponent implements OnInit, ComponentCanDeactivate {
  userId!: number;
  state?: UserState;
  registeredUser?: RegisteredUser;
  name?: string;
  email?: string;
  program?: Program;
  isLoading: boolean = false;

  @ViewChild(RegisteredUserProfileNewComponent)
  profileComponent?: RegisteredUserProfileNewComponent;

  @ViewChild(RegisteredUserDocumentsComponent)
  documentsComponent?: RegisteredUserDocumentsComponent;
  selectedIndex = 0;

  constructor(
    private route: ActivatedRoute,
    private errorService: ErrorService,
    private registeredUsersService: RegisteredUsersService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadUser();
  }

  loadUser() {
    this.isLoading = true;
    this.userId = Number(this.route.snapshot.paramMap.get('userId'));
    this.registeredUsersService.getRegisteredUser(this.userId).subscribe(
      (registeredUser) => {
        this.name = registeredUser.corporateData
          ? registeredUser.corporateData.businessName
          : `${registeredUser.personalData?.firstName} ${registeredUser.personalData?.lastName}`;
        this.email = registeredUser.email;
        this.registeredUser = registeredUser;
        this.isLoading = false;
        this.state = registeredUser.state;
        this.program = registeredUser.program;
      },
      () => {
        this.isLoading = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  async loadActionLogs(): Promise<void> {
    (
      await this.registeredUsersService.getRegisteredUser(this.userId)
    ).subscribe((registeredUser) => {
      if (this.registeredUser) {
        this.registeredUser.actionLogs = registeredUser?.actionLogs;
      }
    });
  }

  reloadRiskAssessmentTab() {
    this.loadUser();
    // stay in the risk assessment tab after risk scoring reload
    this.selectedIndex = 1;
  }

  reloadUser() {
    this.loadUser();
    this.selectedIndex = 0; // stay in profile
  }

  get headerText(): string {
    return this.registeredUser?.personalData
      ? `${this.registeredUser?.personalData.firstName} ${this.registeredUser?.personalData.lastName}`
      : `${this.registeredUser?.corporateData?.businessName}`;
  }

  addNote(): void {
    const data: AdminNoteDialogData = {
      id: this.userId,
      headerText: this.headerText,
      program: this.registeredUser!.program,
      email: this.registeredUser!.email,
      cellPhoneNumber: this.registeredUser!.cellPhoneNumber,
      state: this.registeredUser!.state,
    };
    this.dialog
      .open<AdminNoteDialogComponent, AdminNoteDialogData, number>(
        AdminNoteDialogComponent,
        {
          panelClass: 'dialog-with-close-button',
          width: '1000px',
          disableClose: true,
          data,
        }
      )
      .beforeClosed()
      .subscribe((noteCount) => (this.registeredUser!.noteCount = noteCount!));
  }

  canDeactivate(): boolean {
    if (this.profileComponent)
      return this.profileComponent.canDeactivate()! && true;

    if (this.documentsComponent)
      return this.documentsComponent.canDeactivate()! && true;

    return true;
  }
}
