import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { Country } from 'src/app/shared/models/country.model';
import {
  getCountryById,
  getMonthlyIncomeNew,
  getMonthlySpend,
  getNationalityById,
  getSizeOfWealth,
  getStringValue,
} from 'src/app/shared/helpers/various-helpers.helper';
import { MonthlyIncomeNew } from '../../models/monthly-income.enum';
import { OnboardingData } from '../../models/onboarding-data.model';
import { Step } from '../../stepper/stepper.component';
import { MonthlySpend } from '../../models/monthly-spend.enum';
import { AppDocumentType } from '../../models/document-type.enum';

@Component({
  selector: 'app-onboarding-individual-submitted-new',
  templateUrl: './onboarding-individual-submitted.component.html',
  styleUrls: ['./onboarding-individual-submitted.component.scss'],
})
export class OnboardingIndividualSubmittedNewComponent {
  @Input() set onboardingData(val: OnboardingData) {
    // prepares documents controls
    this.documentsArray.clear();
    val.documents.forEach((document) => {
      // don't show face in the submitted documents
      if (document.type !== AppDocumentType.FACE) {
        this.documentsArray.push(
          this.fb.control({ documentType: document.type, doc: document })
        );
      }
    });

    this._onboardingData = val;
  }
  get onboardingData(): OnboardingData {
    return this._onboardingData;
  }
  @Input() isEDDSubmitted?: boolean;
  @Input() countries: Country[] = [];
  @Input() steps: Step[] = [];
  @Input() showExtraQuestions!: boolean;

  monthlyIncomes = Object.entries(MonthlyIncomeNew);
  monthlySpends = Object.entries(MonthlySpend);
  documentsArray: FormArray = this.fb.array([]);
  private _onboardingData!: OnboardingData;

  getCountryById = getCountryById;
  getNationalityById = getNationalityById;
  getMonthlyIncome = getMonthlyIncomeNew;
  getMonthlySpend = getMonthlySpend;
  getSizeOfWealth = getSizeOfWealth;
  getStringValue = getStringValue;

  constructor(private fb: FormBuilder) {}

  get documentsControls(): FormControl[] {
    return this.documentsArray.controls as FormControl[];
  }
}
