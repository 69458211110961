import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './transactions-number.component.html',
  styleUrls: ['./transactions-number.component.scss']
})
export class TransactionsNumberComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
