import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}