  <div class="top-container">
    <div fxLayout="column" class="accounts-container">
      <div class="template-container">
        <h3>This process updates the status and expiry date (if exists) for all CHIDs in the database. <br> You will receive a notification when the process starts and another one when the process finishes. <br>
          Warning: Do not click the button again until you are notified that the process has finished.</h3>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-left: 195px;">      
        <button 
          mat-flat-button
          color="accent"
          fxFlexAlign="end"
          (click)="updateChids()"
          [disabled]="disabled"
        >
          Update All CHIDs
        </button>
      </div>
      <br/>
      <!-- <div fxLayout="row" fxLayoutGap="20px">
        <button mat-stroked-button color="accent" class="g-small"
          (click)="getAccountNumIds()">
          Get all CHIDS
        </button>
        <button mat-stroked-button color="accent" class="g-small"
          (click)="onGenerateCsv()">
          Download all iban/card statements in csv
        </button>
      </div> -->
    </div>
  </div>