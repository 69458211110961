import { Component, EventEmitter, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { phoneValidator } from 'src/app/shared/phone.validator';
import { UserProfileService } from '../../user-profile.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/login/services/auth.service';
import {
  COUNTDOWN_IN_SECONDS,
  NUMBER_OF_ALLOWED_SMS_RESENDS,
} from 'src/app/shared/helpers/various-helpers.helper';

@Component({
  selector: 'app-change-phone-number-dialog-new',
  templateUrl: './change-phone-number-dialog.component.html',
  styleUrls: ['./change-phone-number-dialog.component.scss'],
})
export class ChangePhoneNumberDialogNewComponent implements OnInit {
  hidePassword = true;
  isSent = false;
  isPending = false;
  isSuccess = false;
  smsSentAgain = false;
  isSendAgainDisabled = false;
  countdown: number = COUNTDOWN_IN_SECONDS;
  numOfResendsAllowed: number = NUMBER_OF_ALLOWED_SMS_RESENDS;
  noMoreResendsAllowed: boolean = false;

  form = this.fb.group({
    phoneNumber: ['', [Validators.required, phoneValidator()]],
    otp: ['', Validators.required],
    password: ['', Validators.required],
  });
  phoneNumberChange = new EventEmitter<string>();

  constructor(
    private fb: FormBuilder,
    private userProfileService: UserProfileService,
    private errorService: ErrorService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<ChangePhoneNumberDialogNewComponent>
  ) {}

  ngOnInit(): void {
    this.otpControl.disable();
  }

  async submit(again?: boolean) {
    if (this.numOfResendsAllowed > 0) {
      if (again) this.startCountdown();
      this.isPending = true;
      (
        await this.userProfileService.requestMobilePhoneNumberChange(
          this.phoneNumberControl.value?.e164Number,
          this.passwordControl.value
        )
      ).subscribe(
        () => {
          this.isPending = false;
          this.isSent = true;
          if (again) {
            this.smsSentAgain = true;
            this.numOfResendsAllowed -= 1;
          }
          this.otpControl.enable();
        },
        (err) => {
          this.isPending = false;
          if (err.status === 400) {
            this.passwordControl.setErrors({ wrongPassword: true });
          } else if (err.status === 403) {
            this.authService.logOut(false, true);
            this.dialogRef.close();
          } else {
            this.numOfResendsAllowed -= 1;
            this.errorService.showErrorDialog();
          }
        }
      );
    } else {
      this.noMoreResendsAllowed = true;
    }
  }

  async startCountdown() {
    this.isSendAgainDisabled = true;
    const countdownInterval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(countdownInterval);
        this.isSendAgainDisabled = false;
        this.smsSentAgain = false;
        this.countdown = COUNTDOWN_IN_SECONDS;
      }
    }, 1000);
  }

  verify(): void {
    this.isPending = true;
    this.userProfileService
      .verifyPhoneNumber(
        this.otpControl.value,
        this.phoneNumberControl.value?.e164Number
      )
      .subscribe(
        () => {
          this.isPending = false;
          this.isSuccess = true;
          this.phoneNumberChange.emit(
            this.phoneNumberControl.value?.e164Number
          );
        },
        (err) => {
          this.isPending = false;
          if (err.status === 400) {
            this.otpControl.setErrors({ wrongCode: true });
          } else {
            this.errorService.showErrorDialog();
          }
        }
      );
  }

  get otpControl(): AbstractControl {
    return this.form.get('otp')!;
  }

  get phoneNumberControl(): AbstractControl {
    return this.form.get('phoneNumber')!;
  }

  get passwordControl(): AbstractControl {
    return this.form.get('password')!;
  }
}
