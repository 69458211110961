import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { RegisteredUserItem } from './models/registered-user-item.model';
import { RegisteredUserSearch } from './models/registered-user-search.model';
import { RegisteredUsersService } from './registered-users.service';

export class RegisteredUsersDataSource
  implements DataSource<RegisteredUserItem>
{
  private registeredUsersSubject = new BehaviorSubject<
    RegisteredUserItem[]
  >([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private lengthSubject = new BehaviorSubject<number>(0);
  private currentRequestSub: Subscription | undefined;
  public isLoadingObs = this.loadingSubject.asObservable();

  constructor(
    private registeredUsersService: RegisteredUsersService,
    private errorService: ErrorService
  ) {}

  connect(
    collectionViewer: CollectionViewer
  ): Observable<RegisteredUserItem[]> {
    return this.registeredUsersSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.registeredUsersSubject.complete();
    this.loadingSubject.complete();
    this.lengthSubject.complete();
  }

  // count of all items in the source
  getLength(): Observable<number> {
    return this.lengthSubject.asObservable();
  }

  loadRegisteredUsers(data: RegisteredUserSearch): void {
    this.currentRequestSub?.unsubscribe(); // cancels previous request
    this.loadingSubject.next(true);

    this.currentRequestSub = this.registeredUsersService
      .getRegisteredUsers(data)
      .pipe(
        map((res) => {
          this.lengthSubject.next(res.totalCount);
          return res.items;
        }),
        catchError(() => {
          this.errorService.showErrorDialog();
          return of([]);
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((registeredUsers) =>
        this.registeredUsersSubject.next(registeredUsers)
      );
  }

  clear() {
    this.currentRequestSub?.unsubscribe(); // cancels previous request
    this.lengthSubject.next(0);
    this.registeredUsersSubject.next([]);
    this.loadingSubject.next(false);
  }
}
