import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DateTime } from 'luxon';
import { merge, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  tap,
} from 'rxjs/operators';
import { Program } from 'src/app/login/models/program.enum';
import { UserType } from 'src/app/login/models/user-type.enum';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { convertUserState } from 'src/app/shared/helpers/various-helpers.helper';
import { environment } from 'src/environments/environment';
import { OnboardingUserItem } from '../models/onboarding-user-item.model';
import { OnboardingUserSearch } from '../models/onboarding-user-search.model';
import { UsersService } from '../users.service';
import { AdminNoteDialogData } from './admin-note-dialog/admin-note-dialog-data.model';
import { AdminNoteDialogComponent } from './admin-note-dialog/admin-note-dialog.component';
import { OngoingRegistrationDataSource } from './ongoing-registration.datasource';
import { UserState } from 'src/app/login/models/user-state.enum';

@Component({
  templateUrl: './ongoing-registration.component.html',
  styleUrls: ['./ongoing-registration.component.scss'],
})
export class OngoingRegistrationNewComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  isLoadingCsv: boolean = false;
  programs = Program;
  convertUserState = convertUserState;
  UserState = UserState;

  dataSource!: OngoingRegistrationDataSource;
  displayedColumns: string[] = [
    'dot',
    'id',
    'businessDaysOverdue',
    'names',
    'companyName',
    'program',
    'state',
    'dateCreated',
    'noteCount',
    'contact',
    'countryName',
  ];

  filtersGroup: FormGroup = this.fb.group({
    search: null,
    startDate: null,
    endDate: null,
    individuals: true,
    businesses: true,
    overdue: false,
    selectedUserState: null,
  });

  isGeneratingPdf: boolean = false;

  private formSub: Subscription | undefined;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private usersService: UsersService,
    private errorService: ErrorService,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.dataSource = new OngoingRegistrationDataSource(
      this.usersService,
      this.errorService
    );
    const params: OnboardingUserSearch = { limit: 20 };
    this.dataSource.loadOngoingRegistrations(params);
  }

  ngAfterViewInit(): void {
    // listens for filters values
    this.formSub = merge(
      this.searchControl.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged()
      ),
      this.startDateControl.valueChanges.pipe(distinctUntilChanged()),
      this.endDateControl.valueChanges.pipe(distinctUntilChanged()),
      this.individualsControl.valueChanges,
      this.businessesControl.valueChanges,
      this.overdueControl.valueChanges,
      this.selectedUserStateControl.valueChanges //.pipe(distinctUntilChanged()),
    )
      .pipe(
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadOngoingRegistrationsPage();
        })
      )
      .subscribe();

    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadOngoingRegistrationsPage()))
      .subscribe();
  }

  onGeneratePdf() {
    // prepares frontend path with params (filters out limit/page and empty params)
    const excludedKeysSet = new Set(['limit', 'page']);
    const frontendPath = Object.entries(this.generateOnRegReqParams())
      .filter(([key, value]) => !excludedKeysSet.has(key) && !!value)
      .reduce(
        (prev, [key, value]) => `${prev}&${key}=${value}`,
        'admin/ongoing-registration-pdf-new?'
      );
    const fileName = this.getFilename();
    const url = `${environment.BACKEND_URL}/pdf?path=${encodeURIComponent(
      frontendPath
    )}&fileName=${fileName}`;

    this.isGeneratingPdf = true;
    this.httpClient
      .get(url, { observe: 'body', responseType: 'arraybuffer' })
      .pipe(finalize(() => (this.isGeneratingPdf = false)))
      .subscribe(
        (buffer) => {
          var url = window.URL.createObjectURL(new Blob([buffer]));
          var anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = fileName;
          document.body.appendChild(anchor);
          anchor.click();
          anchor.remove();
        },
        () => this.errorService.showErrorDialog()
      );
  }

  onGenerateCsv() {
    const paramsCsv = this.generateOnRegReqParams();
    paramsCsv.limit = this.dataSource.getCount();
    paramsCsv.withNotes = true;
    this.isLoadingCsv = true;
    this.usersService
      .getOngoingRegistrationsCsv(paramsCsv)
      .pipe(finalize(() => (this.isLoadingCsv = false)))
      .subscribe(
        (csv) => {
          var element = document.createElement('a');
          element.setAttribute(
            'href',
            'data:text/plain;charset=utf-8,' + encodeURIComponent(csv)
          );
          element.setAttribute('download', this.getFilename());
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        },
        () => this.errorService.showErrorDialog()
      );
  }

  getFilename() {
    const userStateName =
      Object.keys(UserState)[
        Object.values(UserState).indexOf(this.selectedUserStateControl.value)
      ];
    if (userStateName) {
      return (
        userStateName.toLocaleLowerCase().replace('_', '-') +
        '-registrations.csv'
      );
    } else {
      return 'ongoing-registrations-new.csv';
    }
  }

  ngOnDestroy(): void {
    this.formSub?.unsubscribe();
  }

  addNote(data: OnboardingUserItem): void {
    this.dialog
      .open<AdminNoteDialogComponent, AdminNoteDialogData, number>(
        AdminNoteDialogComponent,
        {
          panelClass: 'dialog-with-close-button',
          width: '1000px',
          disableClose: true,
          data: {
            ...data,
            headerText: data.companyName
              ? data.companyName
              : `${data.firstName} ${data.lastName}`,
          },
        }
      )
      .beforeClosed()
      .subscribe((noteCount) => (data.noteCount = noteCount!));
  }

  loadOngoingRegistrationsPage(): void {
    this.dataSource.loadOngoingRegistrations(this.generateOnRegReqParams());
  }

  private generateOnRegReqParams(): OnboardingUserSearch {
    // set value for userType
    let userType;
    if (this.individualsControl.value && !this.businessesControl.value) {
      userType = UserType.PERSONAL;
    } else if (!this.individualsControl.value && this.businessesControl.value) {
      userType = UserType.CORPORATE;
    }

    // set user state name in pdf
    const userStateName =
      Object.keys(UserState)[
        Object.values(UserState).indexOf(this.selectedUserStateControl.value)
      ];

    const params: OnboardingUserSearch = {
      page: this.paginator.pageIndex + 1, // backend counts pages from 1 and mat-paginator from 0
      limit: this.paginator.pageSize,
      searchText: this.searchControl.value,
      dateFrom: this.startDateControl.value
        ? DateTime.fromISO(this.startDateControl.value).toFormat('yyyy-MM-dd')
        : '',
      dateTo: this.endDateControl.value
        ? DateTime.fromISO(this.endDateControl.value).toFormat('yyyy-MM-dd')
        : '',
      userType,
      overdue: this.overdueControl?.value,
      orderField: this.sort.active,
      orderDescending: this.sort.direction === 'desc',
      selectedUserState: this.selectedUserStateControl.value,
      userStateName: userStateName ? userStateName.replace('_', ' ') : '',
    };
    return params;
  }

  get searchControl(): FormControl {
    return this.filtersGroup.get('search') as FormControl;
  }
  get startDateControl(): FormControl {
    return this.filtersGroup.get('startDate') as FormControl;
  }
  get endDateControl(): FormControl {
    return this.filtersGroup.get('endDate') as FormControl;
  }
  get individualsControl(): FormControl {
    return this.filtersGroup.get('individuals') as FormControl;
  }
  get businessesControl(): FormControl {
    return this.filtersGroup.get('businesses') as FormControl;
  }
  get overdueControl(): FormControl {
    return this.filtersGroup.get('overdue') as FormControl;
  }
  get selectedUserStateControl(): FormControl {
    return this.filtersGroup.get('selectedUserState') as FormControl;
  }
}
