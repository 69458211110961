import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CurrencyNames } from 'src/app/dashboard/models/currencyNames';
import { WalletInstance } from 'src/app/dashboard/models/walletInstance';
import { currencies } from 'src/app/dashboard/shared/currencies';
import {
  BuyCurrencyDialogComponent,
  BuyCurrencyDialogData,
} from './buy-currency-dialog/buy-currency-dialog.component';
import { forexAmountValidator } from './forex-amount.validator';
import { InfoService } from '../../../shared/info-dialog/info.service';

@Component({
  selector: 'app-currency-exchange',
  templateUrl: './currency-exchange.component.html',
  styleUrls: ['./currency-exchange.component.scss'],
})
export class CurrencyExchangeComponent implements OnInit, OnDestroy {
  @Input() isLoadingBalances?: boolean;
  @Input() wallet!: WalletInstance;
  @Output() transferSuccess: EventEmitter<void> = new EventEmitter<void>();

  currencies = currencies;

  transferForm: FormGroup = this.fb.group(
    {
      toCurr: [null, Validators.required],
      fromCurr: [null, Validators.required],
      amount: [
        null,
        [Validators.required, Validators.pattern('^[0-9]+[.]?[0-9]{0,2}$')],
      ],
    },
    {
      validators: [forexAmountValidator(this.getWallet.bind(this))],
    }
  );

  private fromCurrSub?: Subscription;
  private toCurrSub?: Subscription;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private infoService: InfoService
  ) {}

  ngOnInit(): void {
    // avoids converting to the same currency
    this.fromCurrSub = this.fromCurrControl.valueChanges.subscribe((val) => {
      if (val && val === this.toCurrControl.value) {
        this.toCurrControl.reset();
      }
    });

    this.toCurrSub = this.toCurrControl.valueChanges.subscribe((val) => {
      if (val && val === this.fromCurrControl.value) {
        this.fromCurrControl.reset();
      }
    });
  }

  ngOnDestroy(): void {
    this.fromCurrSub?.unsubscribe();
    this.toCurrSub?.unsubscribe();
  }

  showInfo(): void {
    let title: string = 'Currency Exchange';
    let message1: string =
      'In order to make currency exchange between your currency wallets, you have to initially transfer money to your EUR currency wallet from your IBAN. Then, select the currency you want to buy and the amount and currency you want to exchange it with. Before executing your conversion, you can check the foreign exchange rate provided to you through the eCREDO platform.';
    let message2: string =
      'Disclaimer: eCREDO will not be held liable, if for any reason, the currency conversion was unsuccessful at the quoted rate by the eCREDO platform. eCREDO will not guarantee the specific foreign exchange rate quoted during your previous unsuccessful attempt.';
    this.infoService.showInfoDialog(title, message1, '', message2);
  }

  getFlagPath(currency: CurrencyNames): string {
    return `assets/img/country-flags/${currency.short}.svg`;
  }

  // needed for validator
  getWallet(): WalletInstance {
    return this.wallet;
  }

  showRates(): void {
    this.dialog
      .open<BuyCurrencyDialogComponent, BuyCurrencyDialogData, boolean>(
        BuyCurrencyDialogComponent,
        {
          panelClass: 'dialog-with-close-button',
          width: '405px',
          height: '405px',
          disableClose: true,
          data: {
            fromCurr: this.fromCurrControl.value.short,
            toCurr: this.toCurrControl.value.short,
            amount: this.amountControl.value,
          },
        }
      )
      .beforeClosed()
      .pipe(filter((shouldUpdateBalances) => shouldUpdateBalances === true))
      .subscribe(() => {
        this.transferForm.reset();
        this.transferSuccess.emit();
      });
  }

  get fromCurrControl(): FormControl {
    return this.transferForm.get('fromCurr') as FormControl;
  }
  get toCurrControl(): FormControl {
    return this.transferForm.get('toCurr') as FormControl;
  }
  get amountControl(): FormControl {
    return this.transferForm.get('amount') as FormControl;
  }
}
