import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { documentValidator } from 'src/app/shared/document.validator';
import { AppDocumentType } from '../../models/document-type.enum';
import { AppDocument } from '../../models/document.model';
import { MatDialog } from '@angular/material/dialog';
import {
  AdminNoteFileDialogComponent,
  AdminNoteFileDialogData,
} from 'src/app/admin/users/ongoing-registration-new/admin-note-file-dialog/admin-note-file-dialog.component';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { FilesService } from 'src/app/shared/services/files.service';

@Component({
  selector: 'app-note-document-form',
  templateUrl: './note-document-form.component.html',
  styleUrls: ['./note-document-form.component.scss'],
})
export class NoteDocumentFormComponent implements OnInit, OnDestroy {
  @Input() clientId?: number;
  @Input() userNoteId?: number;
  @Input() isLastNoteAuthor: boolean = false;
  @Input() set documents(val: AppDocument[]) {
    // creates controls with data for documents with type ADDITIONAL_FILE
    this.addAdditionalDocuments(val);
    this._documents = val;
  }
  get documents(): AppDocument[] {
    return this._documents;
  }
  @Output() isComplete = new EventEmitter<boolean>();

  private _documents: AppDocument[] = [];
  private documentsFormSub: Subscription | null = null;

  documentsForm = this.fb.group({
    documents: this.fb.array([]),
  });

  isDownloading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private errorService: ErrorService,
    private filesService: FilesService
  ) {}

  ngOnInit(): void {
    this.documentsFormSub = this.documentsForm.statusChanges
      .pipe(startWith(this.documentsForm.status))
      .subscribe((status) =>
        setTimeout(() => {
          this.isComplete.emit(status === 'VALID');
        })
      );
  }

  ngOnDestroy(): void {
    this.documentsFormSub?.unsubscribe();
  }

  addAnotherFile(): void {
    this.documentsArray.push(
      this.fb.control(
        { documentType: AppDocumentType.ANOTHER_FILE },
        documentValidator()
      )
    );
  }

  removeField(index: number): void {
    this.documentsArray.removeAt(index);
  }

  openFileDialog(index: number): void {
    // filter document array to show only uploaded documents - filter out non-uploaded/incomplete documents
    const uploadedDocumentsArray = this.fb.array([]);
    uploadedDocumentsArray.controls = this.documentsArray.controls.filter(
      (c) => c.status === 'VALID'
    );
    this.dialog.open<AdminNoteFileDialogComponent, AdminNoteFileDialogData>(
      AdminNoteFileDialogComponent,
      {
        minWidth: '1000px',
        disableClose: true,
        panelClass: 'dialog-with-close-button',
        data: {
          control: uploadedDocumentsArray,
          documentIndex: index,
        },
      }
    );
  }

  downloadFile(index: number): void {
    this.isDownloading = true;
    const doc = this.documentsArray.at(index).value.doc;
    this.filesService
      .generateUrl(doc.location)
      .pipe(switchMap(({ url }) => this.filesService.downloadFile(url)))
      .subscribe(
        (blob) => {
          saveAs(blob, doc.name);
          this.isDownloading = false;
        },
        () => {
          this.isDownloading = false;
          this.errorService.showErrorDialog();
        }
      );
  }

  // Adds controls for documents with ANOTHER_FILE type
  private addAdditionalDocuments(documents: AppDocument[]): void {
    documents
      .filter((doc) => doc.type === AppDocumentType.ANOTHER_FILE)
      .forEach((doc) =>
        this.documentsArray.push(
          this.fb.control(
            {
              doc,
              documentType: doc.type,
            },
            documentValidator()
          )
        )
      );
  }

  get documentsArray(): FormArray {
    return this.documentsForm.get('documents') as FormArray;
  }
  get documentsControls(): FormControl[] {
    return this.documentsArray.controls as FormControl[];
  }
}
