<div fxLayout="row" fxLayoutAlign="start start" class="main-container">
  <div class="name" fxFlex="300px">{{ label }}</div>
  <div
    [attr.data-testid]="generateCorrectDataTestId(label, '-admin-review')"
    fxLayout="row wrap"
    fxFlex="100%"
  >
    <div
      class="value"
      fxFlex="300px"
      [class.showing-comment]="showComment || value.onboardingField.comment"
      [class.disabled]="disabled"
    >
      {{ value.value ? value.value : '-' }}
    </div>

    <div *ngIf="value.onboardingField.oldComment" class="old-comment">
      {{ value.onboardingField.oldComment }}
    </div>

    <div
      [fxShow]="showComment || value.onboardingField.comment"
      fxLayout="row"
      fxLayoutAlign="start center"
      class="comment-container"
    >
      <button
        mat-icon-button
        (click)="removeComment(commentInput)"
        [disabled]="isLoading || disabled"
      >
        <mat-icon svgIcon="cancel"></mat-icon>
      </button>
      <mat-form-field fxFlex="100%">
        <input
          matInput
          [ngModel]="value.onboardingField.comment"
          [ngModelOptions]="{ updateOn: 'blur' }"
          (ngModelChange)="addOrEditComment($event)"
          [disabled]="isLoading || disabled"
          required
          #commentInput="matInput"
        />
      </mat-form-field>
    </div>
    <!-- Disable the ability of admin to comment on form fields when in EDD_SUBMITTED -->
    <div
      *ngIf="
        !showComment && !value.onboardingField.comment && !disabled && !isEDD
      "
      class="comment-button"
      (click)="startCommenting(commentInput)"
    >
      Comment
    </div>
  </div>
</div>
