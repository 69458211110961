import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PersonalForm } from 'src/app/onboarding-new/models/personal-form.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  getFoundValue,
  isHigh,
} from 'src/app/shared/helpers/various-helpers.helper';
import { RiskReport } from 'src/app/shared/models/risk-report.model';
import { riskReportStateNamesMap } from '../../../registered-users/registered-user/shared/riskReportStateNamesMap';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { UsersService } from '../../../users.service';
import { Country } from 'src/app/shared/models/country.model';
import { UserState } from 'src/app/login/models/user-state.enum';
import { MatDialog } from '@angular/material/dialog';
import { CorporateForm } from 'src/app/onboarding-new/models/corporate-form.model';
import { CompanyDirector } from 'src/app/onboarding-new/models/company-director.model';
import { CompanyGroupMember } from 'src/app/onboarding-new/models/company-group-member.model';
import { DetailedReportDialogComponent } from './detailed-report-dialog/detailed-report-dialog.component';
import { RiskReports } from 'src/app/shared/models/risk-reports.model';
import { CompanyCloseLink } from 'src/app/onboarding-new/models/company-close-link.model';

@Component({
  selector: 'app-risk-assessment',
  templateUrl: './risk-assessment.component.html',
  styleUrls: ['./risk-assessment.component.scss'],
})
export class RiskAssessmentComponent implements OnInit {
  @Input() userId!: number;
  @Input() personalData!: PersonalForm | undefined;
  @Input() corporateData!: CorporateForm | undefined;
  @Input() companyDirectors?: CompanyDirector[] | undefined;
  @Input() companyGroupMembers?: CompanyGroupMember[] | undefined;
  @Input() companyCloseLinks?: CompanyCloseLink[] | undefined;
  @Input() state?: UserState;
  @Input() isInOnboarding!: boolean;
  @Input() viewOnlyState: boolean = false;
  @Input() eddSuggestionMade?: boolean = false;
  @Input() adjustmentSuggestionMade?: boolean = false;
  @Output() reloadUser = new EventEmitter<void>();

  dataSource!: MatTableDataSource<RiskReport>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  getFoundValue = getFoundValue;
  isHigh = isHigh;

  riskReportStateNamesMap = riskReportStateNamesMap;
  isAddingRiskForm: boolean = false;
  isRemovingRiskForm: boolean = false;
  isAcceptingRiskForm: boolean = false;
  isRejectingRiskForm: boolean = false;

  public displayedColumns: string[] = [];
  countries: Country[] = [];

  constructor(
    private fb: FormBuilder,
    private sharedService: SharedService,
    private errorService: ErrorService,
    private usersService: UsersService,
    private dialog: MatDialog
  ) {}

  atLeastOneHigh(r: RiskReport) {
    // red alert if total risk score is high-risk or at least one score isHigh
    if (this.personalData) {
      return (
        Number(r.totalRiskScore) >= 10 ||
        isHigh(r.pepScore) ||
        isHigh(r.adverseMediaScore) ||
        isHigh(r.criminalRecordScore) ||
        isHigh(r.dataOccupationScore) ||
        isHigh(r.dataOccupationProfessionScore) ||
        isHigh(r.dataMonthlyIncomeScore) ||
        isHigh(r.dataMonthlySpendScore) ||
        isHigh(r.accountPurposeScore) ||
        isHigh(r.countryScore)
      );
    } else {
      return (
        Number(r.totalRiskScore) >= 10 ||
        isHigh(r.canFundFromOwnAccountScore) ||
        isHigh(r.directorsPEPScore) ||
        isHigh(r.adverseMediaCriminalRecordScore) ||
        isHigh(r.groupMemberAmlScore) ||
        isHigh(r.financialStatementScore) ||
        isHigh(r.companyActivityScore) ||
        isHigh(r.percentageOfCashScore) ||
        isHigh(r.jurisdictionsScore) ||
        isHigh(r.incOpeCountryScore) ||
        isHigh(r.directorCountryScore) ||
        isHigh(r.accountPurposeScore)
      );
    }
  }

  // show icon in current report row and allow to add a proposal report in onboarding
  allowAddProposal(numId: number) {
    if (this.personalData)
      return (
        numId === this.personalData?.currentRiskReportId &&
        this.viewOnlyState === false &&
        ((this.isInOnboarding && !this.eddSuggestionMade) ||
          (!this.isInOnboarding && !this.adjustmentSuggestionMade))
      );
    else
      return (
        numId === this.corporateData?.currentRiskReportId &&
        this.viewOnlyState === false &&
        ((this.isInOnboarding && !this.eddSuggestionMade) ||
          (!this.isInOnboarding && !this.adjustmentSuggestionMade))
      );
  }

  allowRemoveProposal(numId: number) {
    if (this.personalData)
      return (
        numId === this.personalData?.proposalRiskReportId &&
        this.viewOnlyState === false &&
        ((this.isInOnboarding && !this.eddSuggestionMade) ||
          (!this.isInOnboarding && !this.adjustmentSuggestionMade))
      );
    else
      return (
        numId === this.corporateData?.proposalRiskReportId &&
        this.viewOnlyState === false &&
        ((this.isInOnboarding && !this.eddSuggestionMade) ||
          (!this.isInOnboarding && !this.adjustmentSuggestionMade))
      );
  }

  // allow to accept/reject proposal after it was send for approval
  allowAcceptRejectProposal(numId: number) {
    if (this.personalData)
      return (
        numId === this.personalData?.proposalRiskReportId &&
        this.viewOnlyState === false &&
        ((this.isInOnboarding && this.eddSuggestionMade) ||
          (!this.isInOnboarding && this.adjustmentSuggestionMade))
      );
    else
      return (
        numId === this.corporateData?.proposalRiskReportId &&
        this.viewOnlyState === false &&
        ((this.isInOnboarding && this.eddSuggestionMade) ||
          (!this.isInOnboarding && this.adjustmentSuggestionMade))
      );
  }

  ngOnInit(): void {
    if (
      this.state === UserState.DECISION_POINT ||
      this.state === UserState.KEY_PERSONS_VERIFICATION ||
      this.state === UserState.KEY_PERSONS_DECISION_POINT
    ) {
      // Don't show calculated risk score when in these states (only sanction) because total score has not been calculated yet
      this.displayedColumns = [
        'id',
        'dateCreated',
        'riskReportStateId',
        'foundInSanctionList',
        'detailedReportButton',
      ];
    } else {
      // if user is kyc verified or proceeded to EDD, show all columns
      if (this.personalData)
        this.displayedColumns = [
          'id',
          'dateCreated',
          'riskReportStateId',
          'foundInSanctionList',
          'pepScore',
          'adverseMediaScore',
          'criminalRecordScore',
          'dataOccupationProfessionScore',
          'dataOccupationScore',
          'dataMonthlyIncomeScore',
          'dataMonthlySpendScore',
          'accountPurposeScore',
          'countryScore',
          'totalRiskScore',
          'riskLevel',
          'adjustedRiskLevel',
          'dueDiligenceLevel',
          'detailedReportButton',
          'riskScoreChange',
        ];
      else {
        this.displayedColumns = [
          'id',
          'dateCreated',
          'riskReportStateId',
          'foundInSanctionList',
          'directorsPEPScore',
          'adverseMediaCriminalRecordScore',
          'groupMemberAmlScore',
          'financialStatementScore',
          'companyActivityScore',
          'percentageOfCashScore',
          'canFundFromOwnAccountScore',
          'accountPurposeScore',
          'jurisdictionsScore',
          'incOpeCountryScore',
          'directorCountryScore',
          'totalRiskScore',
          'riskLevel',
          'adjustedRiskLevel',
          'dueDiligenceLevel',
          'detailedReportButton',
          'riskScoreChange',
        ];
      }
    }

    this.sharedService.getAllCountries().subscribe(
      (countries) => {
        this.countries = countries;
      },
      (error) => {
        this.errorService.showErrorDialog(error.error.message);
      }
    );

    this.setDataSource();
  }

  setDataSource() {
    this.dataSource = this.personalData
      ? new MatTableDataSource(this.personalData?.riskReports)
      : new MatTableDataSource(this.corporateData?.riskReports);
    setTimeout(() => (this.dataSource.paginator = this.paginator));
  }

  seeDetailedReport(id: number): void {
    var personalReport, entityReport;
    const isCorporate = this.personalData ? false : true;
    if (this.personalData) {
      personalReport = this.personalData?.riskReports?.find(
        (report) => report.id === id
      );
    } else {
      entityReport = this.corporateData?.riskReports?.find(
        (report) => report.id === id
      );
    }

    // get directors report of selected risk report
    const directorReports = this.corporateData?.riskReportsDirector?.filter(
      (report) => report.companyRiskReportId === id
    );
    // get group member report of selected risk report
    const groupMemberReports =
      this.corporateData?.riskReportsGroupMember?.filter(
        (report) => report.companyRiskReportId === id
      );
    // get counterparty report of selected risk report
    const counterpartyReports =
      this.corporateData?.riskReportsCounterparty?.filter(
        (report) => report.companyRiskReportId === id
      );
    // get bank/pi account report of selected risk report
    const bankAccountReports =
      this.corporateData?.riskReportsBankAccount?.filter(
        (report) => report.companyRiskReportId === id
      );
    // get close link report of selected risk report
    const closeLinkReports = this.corporateData?.riskReportsCloseLink?.filter(
      (report) => report.companyRiskReportId === id
    );
    let dialogRef = this.dialog.open<DetailedReportDialogComponent>(
      DetailedReportDialogComponent,
      {
        panelClass: 'dialog-with-close-button',
        width: '1800px',
        disableClose: true,
        data: {
          isCorporate,
          personalReport,
          entityReport,
          directorReports,
          groupMemberReports: groupMemberReports,
          closeLinkReports: closeLinkReports,
          companyDirectors: this.companyDirectors,
          companyGroupMembers: this.companyGroupMembers,
          companyCloseLinks: this.companyCloseLinks,
          counterpartyRiskReport: counterpartyReports,
          bankAccountRiskReport: bankAccountReports,
          name: this.personalData
            ? this.personalData.firstName + ' ' + this.personalData.lastName
            : this.corporateData?.businessName,
          countries: this.countries,
          currentReportId: this.personalData
            ? this.personalData?.currentRiskReportId
            : this.corporateData?.currentRiskReportId,
          proposalReportId: this.personalData
            ? this.personalData?.proposalRiskReportId
            : this.corporateData?.proposalRiskReportId,
          isProposalReport: this.personalData
            ? this.personalData?.proposalRiskReportId === id
            : this.corporateData?.proposalRiskReportId === id,
          isInOnboarding: this.isInOnboarding,
          adjustmentSuggestionMade: this.adjustmentSuggestionMade,
          eddSuggestionMade: this.eddSuggestionMade,
          viewOnlyState: this.viewOnlyState,
        },
      }
    );
    const reportChangeSub =
      dialogRef.componentInstance.riskReportChange.subscribe(
        (updatedRiskReports) => {
          if (isCorporate) {
            // set updated risk reports (selected risk report, key persons report, group member report, close link report)
            const mainReportIndex = this.corporateData?.riskReports?.findIndex(
              (report) => report.id === id
            );
            if (
              mainReportIndex != undefined &&
              this.corporateData?.riskReports
            ) {
              this.corporateData.riskReports[mainReportIndex] =
                updatedRiskReports.riskReports[0];
            }

            // update director risk reports
            if (updatedRiskReports.riskReportsDirector) {
              for (const updatedReport of updatedRiskReports.riskReportsDirector) {
                const directorReportIndex =
                  this.corporateData?.riskReportsDirector?.findIndex(
                    (report) => report.id === updatedReport.id
                  );
                if (
                  directorReportIndex != undefined &&
                  this.corporateData?.riskReportsDirector
                ) {
                  this.corporateData.riskReportsDirector[directorReportIndex] =
                    updatedReport;
                }
              }
            }
            // update group member risk reports
            if (updatedRiskReports.riskReportsGroupMember) {
              for (const updatedReport of updatedRiskReports.riskReportsGroupMember) {
                const groupMemberReportIndex =
                  this.corporateData?.riskReportsGroupMember?.findIndex(
                    (report) => report.id === updatedReport.id
                  );
                if (
                  groupMemberReportIndex != undefined &&
                  this.corporateData?.riskReportsGroupMember
                ) {
                  this.corporateData.riskReportsGroupMember[
                    groupMemberReportIndex
                  ] = updatedReport;
                }
              }
            }
            // update close link risk reports
            if (updatedRiskReports.riskReportsCloseLink) {
              for (const updatedReport of updatedRiskReports.riskReportsCloseLink) {
                const closeLinkReportIndex =
                  this.corporateData?.riskReportsCloseLink?.findIndex(
                    (report) => report.id === updatedReport.id
                  );
                if (
                  closeLinkReportIndex != undefined &&
                  this.corporateData?.riskReportsCloseLink
                ) {
                  this.corporateData.riskReportsCloseLink[
                    closeLinkReportIndex
                  ] = updatedReport;
                }
              }
            }
          } else {
            // set updated risk reports (selected risk report)
            const mainReportIndex = this.personalData?.riskReports?.findIndex(
              (report) => report.id === id
            );
            if (
              mainReportIndex != undefined &&
              this.personalData?.riskReports
            ) {
              this.personalData.riskReports[mainReportIndex] =
                updatedRiskReports.riskReports[0];
            }
          }
          this.setDataSource();
        }
      );
    dialogRef.afterClosed().subscribe(() => {
      reportChangeSub.unsubscribe();
    });
  }

  // There should be only one proposal at a time, either in onboarding or in application verified
  addProposal() {
    this.isAddingRiskForm = true;
    this.usersService
      .addRiskScoreProposal(this.userId, this.isInOnboarding)
      .subscribe(
        (updatedRiskReports) => {
          this.isAddingRiskForm = false;
          // set updated risk reports
          this.setUpdatedRiskReports(updatedRiskReports);
        },
        (error) => {
          this.isAddingRiskForm = false;
          this.errorService.showErrorDialog(error.error.message);
        }
      );
  }

  // individuals + corporate
  removeProposal() {
    this.isRemovingRiskForm = true;
    this.usersService.removeProposal(this.userId).subscribe(
      (updatedRiskReports) => {
        this.isRemovingRiskForm = false;
        // set updated risk reports
        this.setUpdatedRiskReports(updatedRiskReports);
      },
      (error) => {
        this.isRemovingRiskForm = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  setUpdatedRiskReports(updatedRiskReports: RiskReports) {
    if (this.personalData) {
      this.personalData.riskReports = updatedRiskReports.riskReports;
      this.personalData.currentRiskReportId =
        updatedRiskReports.currentRiskReportId;
      this.personalData.proposalRiskReportId =
        updatedRiskReports.proposalRiskReportId;
    } else if (this.corporateData) {
      this.corporateData.riskReports = updatedRiskReports.riskReports;
      this.corporateData.riskReportsDirector =
        updatedRiskReports.riskReportsDirector;
      this.corporateData.riskReportsGroupMember =
        updatedRiskReports.riskReportsGroupMember;
      this.corporateData.riskReportsCounterparty =
        updatedRiskReports.riskReportsCounterparty;
      this.corporateData.riskReportsBankAccount =
        updatedRiskReports.riskReportsBankAccount;
      this.corporateData.riskReportsCloseLink =
        updatedRiskReports.riskReportsCloseLink;
      this.corporateData.currentRiskReportId =
        updatedRiskReports.currentRiskReportId;
      this.corporateData.proposalRiskReportId =
        updatedRiskReports.proposalRiskReportId;
    }
    this.setDataSource();
  }

  acceptProposal() {
    this.isAcceptingRiskForm = true;
    this.usersService.acceptProposal(this.userId).subscribe(
      (updatedRiskReports) => {
        this.isAcceptingRiskForm = false;
        // set updated risk reports
        this.setUpdatedRiskReports(updatedRiskReports);
      },
      (error) => {
        this.isAcceptingRiskForm = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  rejectProposal() {
    this.isRejectingRiskForm = true;
    this.usersService.rejectProposal(this.userId).subscribe(
      (updatedRiskReports) => {
        this.isRejectingRiskForm = false;
        // set updated risk reports
        this.setUpdatedRiskReports(updatedRiskReports);
      },
      (error) => {
        this.isRejectingRiskForm = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  async changeAdjustmentSuggestionStatus() {
    (
      await this.usersService.changeAdjustmentSuggestionStatus(this.userId)
    ).subscribe(
      () => {
        this.reloadUser.emit();
      },
      (error) => {
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  reloadRiskAssessments() {
    this.reloadUser.emit();
  }
}
