import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthenticatedUser } from 'src/app/login/models/authenticated-user.model';
import { Program } from 'src/app/login/models/program.enum';
import { UserType } from 'src/app/login/models/user-type.enum';
import { AuthService } from 'src/app/login/services/auth.service';
import { ErrorService } from '../error-dialog/error.service';
import { ServerSentEventType } from '../models/server-sent-event-type.enum';
import { UserEventsService } from '../services/user-events.service';
import { UserNotification } from './models/userNotification.model';
import { NotificationService } from './notification.service';
import { SmsService } from './sms.service';
import { NA } from '../helpers/various-helpers.helper';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  authenticatedUser: AuthenticatedUser | null = null;
  program: string = '';
  programs = Program;
  private authenticatedUserSub: Subscription | null = null;
  notifications?: UserNotification[];
  hasUnreadNotifications?: boolean;
  eventsSubscription?: Subscription;
  balance?: string;
  interval?: any;

  constructor(
    private authService: AuthService,
    private userEventsService: UserEventsService,
    private notificationService: NotificationService,
    private smsService: SmsService,
    private router: Router,
    private errorService: ErrorService
  ) {}

  isAdministrator(userType?: UserType) {
    return (
      userType === UserType.ADMINISTRATOR ||
      userType === UserType.ONBOARDING_AGENT ||
      userType === UserType.OPERATIONS_MANAGER ||
      userType === UserType.AMLCO ||
      userType === UserType.BOD
    );
  }

  getAdminType(type?: UserType) {
    switch (type) {
      case UserType.ADMINISTRATOR:
        return 'Admin';
      case UserType.ONBOARDING_AGENT:
        return 'Onboarding Officer';
      case UserType.OPERATIONS_MANAGER:
        return 'Operations Manager';
      case UserType.AMLCO:
        return 'AMLCO';
      case UserType.BOD:
        return 'BOD';
      default:
        return '';
    }
  }

  ngOnInit(): void {
    this.eventsSubscription = this.userEventsService.userEventsObservable
      .pipe(
        map((ev) => JSON.parse(ev)),
        filter((ev) => ev.type === ServerSentEventType.NEW_NOTIFICATION)
      )
      .subscribe(() => (this.hasUnreadNotifications = true));

    this.authenticatedUserSub = this.authService
      .getAuthenticatedUserObs()
      .subscribe((user) => {
        this.authenticatedUser = user;
        if (this.isAdministrator(user?.type)) {
          this.program = this.getAdminType(user?.type);

          // start only one interval
          if (!this.interval) {
            this.getSmsBalance();
            this.interval = setInterval(async () => {
              this.getSmsBalance();
            }, 1000 * 60 * 60); // get sms balance every hour
          }
        } else if (user?.program) {
          this.program = this.programs[user.program];
        } else {
          this.program = '';
        }
        this.hasUnreadNotifications = user?.hasUnreadNotifications;
      });
  }

  async getSmsBalance() {
    await this.smsService
      .getSmsBalance()
      .toPromise()
      .then((balance) => {
        this.balance = '€' + balance.smsBalance;
      })
      .catch(() => {
        this.balance = NA;
      });
  }

  onNotificationsOpened() {
    this.notificationService.getUserNotifications().subscribe(
      (n) => {
        this.notifications = n;
        this.hasUnreadNotifications = false;
      },
      () => {
        this.errorService.showErrorDialog();
      }
    );
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.authenticatedUserSub?.unsubscribe();
    this.eventsSubscription?.unsubscribe();
  }

  logOut(): void {
    if (this.interval) {
      // stop interval on logout
      clearInterval(this.interval);
    }
    this.authService.logOut();
  }

  get isActive() {
    return this.router.isActive('my-profile-new', {
      paths: 'exact',
      queryParams: 'exact',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });
  }
}
