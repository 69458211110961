import { CurrencyNames } from '../models/currencyNames';

export const currencies: CurrencyNames[] = [
  { name: 'Euro', short: 'EUR', isoNumber: '978' },
  { name: 'US dollar', short: 'USD', isoNumber: '840' },
  { name: 'UK pound', short: 'GBP', isoNumber: '826' },
  { name: 'Swiss franc', short: 'CHF', isoNumber: '756' },
  { name: 'Swedish krona', short: 'SEK', isoNumber: '752' },
  { name: 'Japanese yen', short: 'JPY', isoNumber: '392' },
  { name: 'Canadian dollar', short: 'CAD', isoNumber: '124' },
  { name: 'Israeli shekel', short: 'ILS', isoNumber: '376' },
  { name: 'Australian dollar', short: 'AUD', isoNumber: '036' },
  { name: 'Hong Kong dollar', short: 'HKD', isoNumber: '344' },
];
