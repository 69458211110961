import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Program } from '../../models/program.enum';

enum RegistrationSteps {
  SELECT_PROGRAM = 'SelectProgram',
  PROVIDE_DATA = 'ProvideData',
}

@Component({
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent {
  registrationSteps = RegistrationSteps;
  currentStep: RegistrationSteps = RegistrationSteps.SELECT_PROGRAM;
  selectedProgram: Program | null = null;

  constructor(private router: Router) {}

  changeProgram(): void {
    this.selectedProgram = null;
    this.currentStep = RegistrationSteps.SELECT_PROGRAM;
  }

  chooseProgram(selectedProgram: Program): void {
    if (selectedProgram) {
      this.selectedProgram = selectedProgram;
      this.currentStep = RegistrationSteps.PROVIDE_DATA;
    }
  }

  onRegistered(): void {
    this.router.navigate(['email-sent']);
  }
}
