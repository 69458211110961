import { Component, Input } from '@angular/core';
import { ActivationFileLine } from '../models/activation-file-line.model';

@Component({
  selector: 'app-activation-file-table',
  templateUrl: './activation-file-table.component.html',
  styleUrls: ['./activation-file-table.component.scss'],
})
export class ActivationFileTableComponent {
  @Input() dataSource?: ActivationFileLine[];
  @Input() showStatus: boolean = false; // used for showing/hiding Status column
  @Input() statusMap?: Map<string, boolean>; // key: lineUniqueId, value: transfer status

  displayedColumns: string[] = [
    'oldChid',
    'newChid',
    'lineUniqueId',
  ];
  displayedColumnsWithStatus: string[] = [
    'oldChid',
    'newChid',
    'lineUniqueId',
    'status',
  ];
}
