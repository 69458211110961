import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountDetailsDialogComponent } from './account-details-dialog/account-details-dialog.component';
import { Program } from '../../../models/program.enum';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-choose-account',
  templateUrl: './choose-account.component.html',
  styleUrls: ['./choose-account.component.scss'],
})
export class ChooseAccountComponent implements OnInit {
  @Output() selectProgram = new EventEmitter<Program>();

  $breakpointObserver?: Observable<boolean>;

  programs = Program;
  constructor(
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    this.$breakpointObserver = this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .pipe(
        map(
          (state) =>
            state.breakpoints[Breakpoints.Small] ||
            state.breakpoints[Breakpoints.XSmall]
        )
      );
  }

  chooseProgram(selectedProgram: Program): void {
    this.selectProgram.emit(selectedProgram);
  }

  showDetails(program: Program): void {
    this.dialog
      .open(AccountDetailsDialogComponent, {
        data: program,
        width: '100vw',
        maxWidth: '360px',
        panelClass: 'dialog-with-close-button',
      })
      .beforeClosed()
      .subscribe((selectedProgram) => this.chooseProgram(selectedProgram));
  }
}
