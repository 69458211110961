import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { documentValidator } from 'src/app/shared/document.validator';
import { getIdentityDocumentsTypesSet } from 'src/app/shared/helpers/various-helpers.helper';
import { AppDocumentType } from '../../models/document-type.enum';
import { AppDocument } from '../../models/document.model';
import { IdentityDocumentType } from '../../models/identity-document-type.enum';

@Component({
  selector: 'app-key-person-verified-document-form',
  templateUrl: './key-person-verified-document-form.component.html',
  styleUrls: ['./key-person-verified-document-form.component.scss'],
})
// Simple view only the documents that were verified with shufti pro
export class KeyPersonVerifiedDocumentFormComponent
  implements OnInit, OnDestroy
{
  @Input() set documents(val: AppDocument[]) {
    this.resetForm(); // reset form to remove previous values
    // adds data to predefined controls
    this.documentsControls.forEach((control) =>
      control.setValue({
        ...control.value,
        doc: val.find((doc) => doc.type === control.value.documentType),
      })
    );

    // selects identityDocumentType based on uploaded documents
    this.setIdentityDocumentType(val);
    this._documents = val;
  }
  get documents(): AppDocument[] {
    return this._documents;
  }

  @Input() companyDirectorId: number | undefined = undefined;

  private _documents: AppDocument[] = [];
  private identityDocumentTypeSub: Subscription | null = null;
  private documentsFormSub: Subscription | null = null;
  identityDocumentTypesEntries = Object.entries(IdentityDocumentType);

  // KYB: form for id docs for key persons
  documentsForm = this.fb.group({
    identityDocumentType: ['', Validators.required],
    documents: this.fb.array([
      this.fb.control(
        {
          documentType: AppDocumentType.IDENTITY_CARD,
        },
        documentValidator()
      ),
      this.fb.control(
        {
          documentType: AppDocumentType.IDENTITY_CARD_SECOND_PAGE,
        },
        documentValidator()
      ),
      this.fb.control(
        {
          documentType: AppDocumentType.PASSPORT,
        },
        documentValidator()
      ),
    ]),
  });

  resetForm() {
    this.documentsForm = this.fb.group({
      identityDocumentType: ['', Validators.required],
      documents: this.fb.array([
        this.fb.control(
          {
            documentType: AppDocumentType.IDENTITY_CARD,
          },
          documentValidator()
        ),
        this.fb.control(
          {
            documentType: AppDocumentType.IDENTITY_CARD_SECOND_PAGE,
          },
          documentValidator()
        ),
        this.fb.control(
          {
            documentType: AppDocumentType.PASSPORT,
          },
          documentValidator()
        ),
      ]),
    });
    this.ngOnInit();
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.identityDocumentTypeSub = this.identityDocumentTypeControl.valueChanges
      .pipe(startWith(this.identityDocumentTypeControl.value))
      .subscribe((val: IdentityDocumentType) => {
        // disables all identity controls
        this.idCardControl?.disable();
        this.idCardSecondPageControl?.disable();
        this.passportControl?.disable();

        // enables needed controls
        switch (val) {
          case IdentityDocumentType['ID card']: {
            this.idCardControl?.enable();
            this.idCardSecondPageControl?.enable();
            break;
          }
          case IdentityDocumentType.Passport: {
            this.passportControl?.enable();
            break;
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.identityDocumentTypeSub?.unsubscribe();
    this.documentsFormSub?.unsubscribe();
  }

  // Set identityDocumentType based on uploaded documents
  private setIdentityDocumentType(documents: AppDocument[]): void {
    const identityDocumentsTypesSet = getIdentityDocumentsTypesSet();
    const doctype = documents.find((doc) =>
      identityDocumentsTypesSet.has(doc.type)
    )?.type;
    switch (doctype) {
      case AppDocumentType.IDENTITY_CARD:
      case AppDocumentType.IDENTITY_CARD_SECOND_PAGE:
        this.identityDocumentTypeControl.setValue(
          IdentityDocumentType['ID card']
        );
        break;
      case AppDocumentType.PASSPORT:
        this.identityDocumentTypeControl.setValue(
          IdentityDocumentType.Passport
        );
        break;
      default:
        this.identityDocumentTypeControl.reset();
    }
  }

  get identityDocumentTypeControl(): FormControl {
    return this.documentsForm.get('identityDocumentType') as FormControl;
  }
  get documentsArray(): FormArray {
    return this.documentsForm.get('documents') as FormArray;
  }
  get documentsControls(): FormControl[] {
    return this.documentsArray.controls as FormControl[];
  }
  get idCardControl(): AbstractControl | null | undefined {
    return this.documentsArray.get([0]);
  }
  get idCardSecondPageControl(): AbstractControl | null | undefined {
    return this.documentsArray.get([1]);
  }
  get passportControl(): AbstractControl | null | undefined {
    return this.documentsArray.get([2]);
  }
}
