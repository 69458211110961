import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  InfoDialogComponent,
  InfoDialogData,
} from './info-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class InfoService {
  constructor(private dialog: MatDialog) {}

  showInfoDialog(title1?: string, message1?: string, title2?: string, message2?: string, message3?: string): void {
    this.dialog.open<InfoDialogComponent, InfoDialogData>(
        InfoDialogComponent,
      {
        maxWidth: '720px',
        panelClass: 'dialog-with-close-button',
        disableClose: true,
        data: {
          title1,
          message1,
          title2,
          message2,
          message3
        },
      }
    );
  }
}
