<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content [ngSwitch]="isSuccess">
  <!-- SUCCESS -->
  <div *ngSwitchCase="true" class="success-container">
    <h1>Top Up succeeded</h1>
    <div>
      Your Top-Up was successfully processed.<br /><br />
      See transaction information on the dashboard
    </div>
    <button mat-flat-button color="accent" mat-dialog-close>
      Go to Dashboard
    </button>
  </div>
  <!-- FAILURE -->
  <div *ngSwitchCase="false" class="failure-container">
    <h1>Top Up failed</h1>
    <div>
      There was an error while processing<br />
      the Top-Up.<br /><br />
      Please contact support at
      <a href="https://ecredo.com" target="_blank">www.credo.com</a>
    </div>
    <button mat-flat-button color="accent" [mat-dialog-close]="true">
      Try Again
    </button>
  </div>
</mat-dialog-content>
