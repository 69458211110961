import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';

export function recurringCycleValidator(): ValidatorFn {
  return (formGroup: AbstractControl): null => {
    const isRecurring = (formGroup.get('isRecurring') as FormControl).value;
    const recurringCycleControl = formGroup.get(
      'recurringCycle'
    ) as FormControl;

    recurringCycleControl.updateValueAndValidity({ onlySelf: true });
    if (
      isRecurring &&
      (recurringCycleControl.value === null ||
        recurringCycleControl.value === undefined)
    ) {
      recurringCycleControl.setErrors({ required: true });
    }
    return null;
  };
}
