import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { RiskAssessmentService } from '../risk-assessment.service';
import { MatTableDataSource } from '@angular/material/table';
import { ShowKybHitDialogComponent } from '../show-kyb-hit-dialog/show-kyb-hit-dialog.component';
import { KybHitTitle } from '../models/kyb-hit-title.model';
import { getJurisdictionName } from 'src/app/shared/helpers/various-helpers.helper';

export interface ShowKybHitsDialogData {
  reportId: number;
  userId: number;
  kybPayload?: any;
}

@Component({
  selector: 'app-show-kyb-hits-dialog',
  templateUrl: './show-kyb-hits-dialog.component.html',
  styleUrls: ['./show-kyb-hits-dialog.component.scss'],
})
export class ShowKybHitsDialogComponent implements OnInit {
  isLoadingKybHits: boolean = false;

  userId!: number;
  reportId!: number;
  kybPayload?: any;

  getJurisdictionName = getJurisdictionName;

  dataSourceHits!: MatTableDataSource<KybHitTitle>;
  public displayedColumns: string[] = [
    'id',
    'companyName',
    'companyNumber',
    'companyType',
    'companyJurisdictionCode',
    'companyIncorporationDate',
    'details',
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShowKybHitsDialogData,
    private riskAssessmentService: RiskAssessmentService,
    private errorService: ErrorService,
    private dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.userId = this.data.userId;
    this.reportId = this.data.reportId;
    this.kybPayload = this.data.kybPayload;
    await this.getKybHits();
  }

  async getKybHits() {
    this.isLoadingKybHits = true;
    (
      await this.riskAssessmentService.getKybHits(this.userId, this.reportId)
    ).subscribe(
      (kybHits) => {
        this.isLoadingKybHits = false;
        this.dataSourceHits = new MatTableDataSource(kybHits);
      },
      (error) => {
        this.isLoadingKybHits = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  async getKybHit(kybHitId: number) {
    this.dialog.open<ShowKybHitDialogComponent>(ShowKybHitDialogComponent, {
      panelClass: 'dialog-with-close-button',
      width: '1800px',
      disableClose: true,
      data: {
        kybHitId,
        userId: this.userId,
      },
    });
  }
}
