<div [formGroup]="fileForm" class="container">
  <div fxLayout fxLayoutAlign="space-between" fxLayoutGap="25px">
    <div fxLayout="column" class="long-field" [formGroup]="documentTypeForm">
      <label>Document type</label>
      <mat-form-field>
        <mat-select
          placeholder="Please select"
          name="documentType"
          formControlName="documentType"
          (selectionChange)="changeDocumentType($event)"
        >
          <mat-option
            *ngFor="let entry of displayedDocumentOptions"
            [value]="entry"
            >{{ entry }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <ng-container
      *ngIf="
        documentTypeControl.value &&
        (isIdentityDocument(documentTypeControl.value) ||
          isProofOfAddress(documentTypeControl.value)) &&
        isCorporateUser
      "
    >
      <div fxLayout="column" class="short-field">
        <label for="companyDirector">Director/Shareholder</label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="companyDirector">
            <mat-option
              *ngFor="let option of companyDirectors"
              [value]="option.id"
              >{{ option.firstName }} {{ option.lastName }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        documentTypeControl.value &&
        isIdentityDocument(documentTypeControl.value)
      "
    >
      <div fxLayout="column" class="short-field">
        <label for="expiryDate">Document expiry date</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            [matDatepicker]="picker"
            name="expiryDate"
            formControlName="expiryDate"
            readonly
            (click)="picker.open()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        documentTypeControl.value &&
        !isIdentityDocument(documentTypeControl.value)
      "
    >
      <div fxLayout="column" class="short-field">
        <label for="expiryDate">Document review date</label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="expiryDate">
            <mat-option [value]="ReviewCycle.HIGH_RISK"
              >{{ ReviewCycle.HIGH_RISK }} months</mat-option
            >
            <mat-option [value]="ReviewCycle.LOW_NORMAL_RISK"
              >{{ ReviewCycle.LOW_NORMAL_RISK }} months</mat-option
            >
          </mat-select>
          <mat-error *ngIf="expiryDateControl.hasError('required')"
            >Field is required</mat-error
          >
        </mat-form-field>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="documentTypeControl.value">
    <div fxLayout fxLayoutAlign="space-between" class="input-file-container">
      <app-all-file-input
        [fileLabel]="documentTypeControl.value"
        (uploadedFile)="onUploadedFile($event)"
        [clearFile]="clearFile"
      ></app-all-file-input>
      <ng-container *ngIf="isSecondPage(documentTypeControl.value)"
        ><app-all-file-input
          (uploadedFile)="onUploadedSecondFile($event)"
          [clearFile]="clearFile"
          [fileLabel]="'2nd page of ' + documentTypeControl.value"
        ></app-all-file-input
      ></ng-container>
    </div>
  </ng-container>

  <ng-container
    *ngIf="isIdentityDocument(documentTypeControl.value) && !isCorporateUser"
  >
    <div fxLayout="column" class="long-field">
      <label for="firstName">First name</label>
      <mat-form-field appearance="outline">
        <input matInput formControlName="firstName" name="firstName" />
      </mat-form-field>
    </div>
    <div fxLayout="column" class="long-field">
      <label for="lastName">Last name</label>
      <mat-form-field appearance="outline">
        <input matInput formControlName="lastName" name="lastName" />
      </mat-form-field>
    </div>
  </ng-container>
  <div
    fxLayoutAlign="space-between center"
    *ngIf="documentTypeControl.value"
    class="save-button-container"
  >
    <button
      mat-flat-button
      color="accent"
      class="text"
      (click)="saveDocumentsForm()"
      [disabled]="isButtonDisabled()"
    >
      Save document
    </button>
  </div>
</div>
