<div fxLayout fxLayoutAlign="space-between" class="form-container">
  <div fxLayout="column" class="search-input">
    <label for="search">Search</label>
    <mat-form-field appearance="outline">
      <input matInput (keyup)="applyFilter($event)" />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <div (click)="downloadAllDocuments()" fxLayout fxLayoutAlign="center center">
    <span class="download-text cursor">Download all documents</span>
    <button mat-icon-button>
      <mat-icon fontSet="material-icons-outlined" color="accent"
        >download</mat-icon
      >
    </button>
  </div>
</div>

<div *ngIf="isLoadingTable" fxLayoutAlign="center center">
  <app-big-loader-not-centered></app-big-loader-not-centered>
</div>
<table
  *ngIf="!isLoadingTable"
  mat-table
  matSort
  matSortActive="uploadedDate"
  matSortDirection="desc"
  (matSortChange)="sortData($event)"
  [dataSource]="matTableDataSource"
  [fixedLayout]="true"
>
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>
      <div mat-sort-header="type">Document type</div>
      <div class="sub-name">Name</div>
    </th>
    <td mat-cell *matCellDef="let row" fxLayout="column">
      <div fxLayout>
        <div>{{ row.anotherFileName ? row.anotherFileName : row.type }}</div>
      </div>
      <div class="name" *ngIf="row.firstName && row.lastName">
        <span>{{ row.firstName + ' ' + row.lastName }}</span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>File name</th>
    <td mat-cell *matCellDef="let row">{{ row.name }}</td>
  </ng-container>
  <ng-container matColumnDef="extension">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>File format</th>
    <td mat-cell *matCellDef="let row">{{ row.extension }}</td>
  </ng-container>
  <ng-container matColumnDef="uploadedDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Uploaded</th>
    <td mat-cell *matCellDef="let row">
      {{ row.uploadedDate | date: 'd MMM y HH:mm' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="expiryDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Expiry date</th>
    <td mat-cell *matCellDef="let row">
      {{ row.expiryDate | date }}
    </td>
  </ng-container>
  <ng-container matColumnDef="state">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
    <td mat-cell *matCellDef="let row">
      {{ row.state }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    [class.colored]="isRowColored"
    [class.second-page-colored]="isSecondRowColored"
  ></tr>

  <tr *matNoDataRow>
    <td [attr.colspan]="5">
      <div class="no-data-row" fxLayoutAlign="center center">
        <span>No results for {{ this.matTableDataSource.filter }}</span>
      </div>
    </td>
  </tr>
</table>
