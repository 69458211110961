import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface TopUpResultDialogData {
  isSuccess: boolean;
}

@Component({
  templateUrl: './top-up-result-dialog.component.html',
  styleUrls: ['./top-up-result-dialog.component.scss'],
})
export class TopUpResultDialogComponent {
  // dialog returns "true" if user wants to top up again
  isSuccess: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: TopUpResultDialogData) {
    this.isSuccess = data.isSuccess;
  }
}
