export enum AccountStatus {
  ISSUED = 1,
  OPEN = 2,
  LOST = 3,
  STOLEN = 4,
  DEPOSIT_ONLY = 5,
  CHECK_REASON = 6,
  CLOSED = 7,
  PIN_CHANGE_REQUIRED = 8,
  PHONE_NUMBER_VERIFICATION = 9,
  EXPIRED = 10,
  FRAUD = 11,
  TEMPORARY_BLOCK = 12
}
