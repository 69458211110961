import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CommentType } from '../onboarding-new/models/comment-type.enum';

// Used for AdminDocumentComponent
export function adminDocumentValidator(): ValidatorFn {
  return (formControl: AbstractControl): ValidationErrors | null => {
    // checks if last comment was added by admin
    const hasAdminComment =
      formControl.value?.comments.slice().pop()?.commentType ===
      CommentType.REJECTION_REASON;

    if (
      formControl.value?.isAccepted === null ||
      formControl.value?.isAccepted === undefined
    ) {
      return { indeterminate: true };
    } else if (formControl.value?.isAccepted === false && !hasAdminComment) {
      return { missingComment: true };
    } else {
      return null;
    }
  };
}
