<ng-container [ngSwitch]="isInitialView">
  <!-- INITIAL VIEW -->
  <ng-container *ngSwitchCase="true">
    <div class="top-container">
      <div fxLayout="column" class="accounts-container">
        <div class="template-container">
          <h3>Send SMS activation codes to clients to activate their new wallet through dashboard and a reminder for card users to activate their new card before it expires.</h3>
          <div>Instructions:</div>
          <div>1. Upload an Excel file with at least two columns 'CardholderID' and 'NEW CHID'.</div>
          <div>2. Include only one card account for each user.</div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-left: 195px;">      
          <app-activation-file-upload
          fxFlexAlign="stretch"
          (clearFile)="state = ActivationState.initial"
          (upload)="onUpload($event)"
        ></app-activation-file-upload>
        </div>
      </div>
    
    <!-- FILE PROCESSING -->
    <div
      *ngIf="state === ActivationState.fileProcessing"
      class="upload-state-container"
    >
      <h1>Processing...</h1>
      <div>Please wait</div>
    </div>
    <!-- FILE ERRORING -->
    <div
      *ngIf="state === ActivationState.fileErroring"
      class="upload-state-container"
    >
      {{ uploadError }}
    </div>
  </div>
        <!-- FILE UPLOADED -->
        <div
        *ngIf="state === ActivationState.fileUploaded"
        class="transfers-container"
      ><br/>
        <h1>Accounts</h1>
        <mat-card fxLayout="column" fxLayoutGap="20px">
          <app-activation-file-table
            [dataSource]="activationFile?.lines"
          ></app-activation-file-table>
          <div fxLayout fxLayoutAlign="space-between center" class="summary-row">
            <div class="transfers-count">
              {{ activationFile?.lines?.length }}
              <ng-container [ngPlural]="activationFile?.lines?.length || 0">
                <ng-template ngPluralCase="=1">account</ng-template>
                <ng-template ngPluralCase="other">accounts</ng-template>
              </ng-container>
            </div>
          </div>
          <button
            mat-flat-button
            color="accent"
            fxFlexAlign="end"
            (click)="goToConfirmationStep()"
          >
            Send {{ activationFile?.lines?.length }}
            <ng-container [ngPlural]="activationFile?.lines?.length || 0">
              <ng-template ngPluralCase="=1">SMS</ng-template>
              <ng-template ngPluralCase="other">SMS</ng-template>
            </ng-container>
          </button>
        </mat-card>
      </div>

  </ng-container>

  <!-- SECOND VIEW -->
  <div *ngSwitchCase="false" class="second-view-container">
    <h1 [ngSwitch]="state">
      <ng-container *ngSwitchCase="ActivationState.confirmation"
        >Confirmation</ng-container>
      <ng-container *ngSwitchDefault>Progress</ng-container>
    </h1>
    <mat-card>
      <div class="transfers-status-container" *ngIf="isShowingTransfersStatus">
        <div fxLayout fxLayoutAlign="space-between center" class="success">
          <div>
            {{ successCount }}
            <ng-container [ngPlural]="successCount">
              <ng-template ngPluralCase="=1">transfer</ng-template>
              <ng-template ngPluralCase="other">transfers</ng-template>
            </ng-container>
            done
          </div>
        </div>
        <div
          *ngIf="failureCount"
          fxLayout
          fxLayoutAlign="start center"
          fxLayoutGap="20px"
          class="failure"
        >
          <div>
            {{ failureCount }}
            <ng-container [ngPlural]="successCount">
              <ng-template ngPluralCase="=1">transfer</ng-template>
              <ng-template ngPluralCase="other">transfers</ng-template>
            </ng-container>
            failed
          </div>
          <app-small-loader
            *ngIf="state === ActivationState.retryInProgress"
          ></app-small-loader>
          <button
            *ngIf="state !== ActivationState.retryFinished"
            mat-stroked-button
            color="accent"
            class="g-small"
            [disabled]="state === ActivationState.retryInProgress"
            (click)="retry()"
          >
            Retry the failed transfers
          </button>
        </div>
        <div
          *ngIf="state === ActivationState.retryFinished && retryFailureCount"
          class="retry-failure"
        >
          The transfer retry failed, please double-check the data you provided
          and try to make a single activation code transfer.
        </div>
      </div>

      <app-activation-file-table
        [dataSource]="activationFile?.lines"
        [showStatus]="state !== ActivationState.confirmation"
        [statusMap]="statusMap"
      ></app-activation-file-table>
      <!-- CONFIRMATION -->
      <div
        *ngIf="state === ActivationState.confirmation"
        fxLayout="row"
        fxLayoutAlign="end end"
        fxLayoutGap="20px"
        class="buttons-container"
      >
        <button mat-flat-button color="accent" (click)="confirm()">
          Confirm
        </button>
        <button mat-stroked-button color="accent" (click)="cancel()">
          Cancel
        </button>
      </div>
      <!-- TRANSFERS IN PROGRESS -->
      <div
        *ngIf="state === ActivationState.transfersInProgress"
        class="transfers-started"
      >
        <h2>Sending SMS started</h2>
        <div>Check the progress here above.</div>
      </div>
    </mat-card>
  </div>
</ng-container>