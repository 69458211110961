<div class="main-container">
  <div fxLayout="column" class="header-container">
    <div fxLayout fxLayoutAlign="space-between center">
      <img src="assets/img/logo-dark.svg" alt="eCredo logo" />
      <h1 *ngIf="userStateName">{{ userStateName }} registrations</h1>
      <h1 *ngIf="!userStateName">Ongoing registrations</h1>
    </div>
    <div fxFlexAlign="end" class="description">
      <span *ngIf="!searchText && !dateFrom && !dateTo && !userType">all</span>
      <span *ngIf="searchText">search '{{ searchText }}'</span>
      <span *ngIf="dateFrom"> from {{ dateFrom | date: 'shortDate' }}</span>
      <span *ngIf="dateTo"> to {{ dateTo | date: 'shortDate' }}</span>
      <span *ngIf="userType"> {{ userType }} only</span>
      <span> ({{ now | date: 'short' }})</span>
    </div>
  </div>

  <table
    mat-table
    [dataSource]="data"
    multiTemplateDataRows
    [fixedLayout]="true"
    matSort
    [matSortActive]="orderField"
    [matSortDirection]="sortDirection"
    matSortDisableClear
  >
    <!-- IsPendingForLong Column -->
    <ng-container matColumnDef="isPendingForLong">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="!row.wasDisplayed" svgIcon="dot"></mat-icon>
        <mat-icon *ngIf="row.isPendingForLong" svgIcon="hourglass"></mat-icon>
      </td>
    </ng-container>

    <!-- Names Column -->
    <ng-container matColumnDef="names">
      <th mat-header-cell *matHeaderCellDef>
        <div mat-sort-header="firstName">First name</div>
        <div mat-sort-header="lastName">Last name</div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div>{{ row.firstName }}</div>
        <div>{{ row.lastName }}</div>
      </td>
    </ng-container>

    <!-- Program Column -->
    <ng-container matColumnDef="program">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Account</th>
      <td mat-cell *matCellDef="let row">
        {{ programs[row.program] | titlecase }}
      </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let row">
        {{ convertUserState(row.state) | titlecase }}
      </td>
    </ng-container>

    <!-- Started Column -->
    <ng-container matColumnDef="dateCreated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Started</th>
      <td mat-cell *matCellDef="let row">
        {{ row.dateCreated | date: 'short' }}
      </td>
    </ng-container>

    <!-- Contact Column -->
    <ng-container matColumnDef="contact">
      <th mat-header-cell *matHeaderCellDef>
        <div mat-sort-header="email">Email</div>
        <div mat-sort-header="cellPhoneNumber">Phone number</div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div>{{ row.email }}</div>
        <div>{{ row.cellPhoneNumber }}</div>
      </td>
    </ng-container>

    <!-- CompanyName Column -->
    <ng-container matColumnDef="companyName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Company name</th>
      <td mat-cell *matCellDef="let row">{{ row.companyName || '-' }}</td>
    </ng-container>

    <!-- CountryName Column -->
    <ng-container matColumnDef="countryName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
      <td mat-cell *matCellDef="let row">
        {{ row.countryName | titlecase }}
      </td>
    </ng-container>

    <!-- Notes (expanded) -->
    <ng-container matColumnDef="notes">
      <td
        mat-cell
        *matCellDef="let row"
        [attr.colspan]="displayedColumns.length"
        class="notes-cell"
      >
        <div *ngIf="!!row.notes.length" class="notes-container">
          <div *ngFor="let note of row.notes" class="note">
            <div class="note-header">
              {{ note.authorName }}, {{ note.dateCreated | date: 'short' }}
            </div>
            <div>{{ note.content }}</div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.pending-for-long]="row.isPendingForLong"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['notes']"
      [class.pending-for-long]="row.isPendingForLong"
    ></tr>
  </table>
</div>
