<div
  [formGroup]="directorForm"
  *ngIf="allowResendIdVerificationUrl"
  fxLayout="row"
  fxLayoutAlign="start start"
  class="main-container"
>
  <div class="name" fxFlex="300px">
    {{ label }}
  </div>
  <div
    [attr.data-testid]="generateCorrectDataTestId(label, '-admin-review')"
    fxLayout="row wrap"
    fxFlex="100%"
  >
    <div
      class="value"
      fxFlex="300px"
      [class.showing-comment]="
        (showComment || value.onboardingField?.comment) && !disableComment
      "
      [class.disabled]="disabled"
    >
      <div fxLayout="column">
        <input matInput name="email" formControlName="email" />
        <mat-error
          data-testid="validation-message-box"
          *ngIf="emailControl?.hasError('required')"
          >Field is required</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="emailControl?.hasError('email')"
          >Email is invalid</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="emailControl?.hasError('pattern')"
          >Email is invalid</mat-error
        >
      </div>
    </div>

    <div class="resend-container">
      <button
        mat-stroked-button
        color="accent"
        class="send-button"
        [disabled]="isSending"
        [appButtonLoader]="isSending"
        (click)="resendKeyPersonVerificationUrl()"
      >
        Resend Id verification email
      </button>
    </div>
  </div>
</div>

<div
  *ngIf="!allowResendIdVerificationUrl"
  fxLayout="row"
  fxLayoutAlign="start start"
  class="main-container"
>
  <div [ngClass]="label === 'Status' ? 'name bold' : 'name'" fxFlex="300px">
    {{ label }}
  </div>
  <div
    [attr.data-testid]="generateCorrectDataTestId(label, '-admin-review')"
    fxLayout="row wrap"
    fxFlex="100%"
  >
    <div
      class="value"
      fxFlex="300px"
      [class.showing-comment]="
        (showComment || value.onboardingField?.comment) && !disableComment
      "
      [class.disabled]="disabled"
    >
      {{
        value.value
          ? (value.onboardingField?.id ===
              OnboardingFieldId.BUSINESS_KEY_PERSON_DIRECTOR ||
              value.onboardingField?.id ===
                OnboardingFieldId.BUSINESS_CLOSE_LINK_DIRECTOR) &&
            label === 'Status'
            ? ''
            : value.value
          : '-'
      }}
    </div>

    <div
      *ngIf="value.onboardingField?.oldComment && !disableComment"
      class="old-comment"
    >
      {{ value.onboardingField?.oldComment }}
    </div>

    <div
      [fxShow]="
        !disableComment && (showComment || value.onboardingField?.comment)
      "
      fxLayout="row"
      fxLayoutAlign="start center"
      class="comment-container"
    >
      <button
        mat-icon-button
        (click)="removeComment(commentInput)"
        [disabled]="isLoading || disabled"
      >
        <mat-icon svgIcon="cancel"></mat-icon>
      </button>
      <mat-form-field fxFlex="100%">
        <input
          matInput
          [ngModel]="value.onboardingField?.comment"
          [ngModelOptions]="{ updateOn: 'blur' }"
          (ngModelChange)="addOrEditComment($event)"
          [disabled]="isLoading || disabled"
          required
          #commentInput="matInput"
        />
      </mat-form-field>
    </div>
    <!-- Disable the ability of admin to comment on form fields when in EDD_SUBMITTED -->
    <div
      *ngIf="
        !showComment &&
        !value.onboardingField?.comment &&
        !disabled &&
        !isEDD &&
        !disableComment
      "
      class="comment-button"
      (click)="startCommenting(commentInput)"
    >
      Comment
    </div>
  </div>
</div>
