<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button data-testid="close-modal-button" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content data-testid="program-details-modal">
  <app-account-header [program]="data"></app-account-header>
  <ng-container [ngSwitch]="data">
    <div *ngSwitchCase="programs.JADE">
      <div fxLayout="column" fxLayoutAlign="start center">
        <div class="price">No monthly subscription</div>
        <button
          mat-stroked-button
          color="accent"
          class="g-small"
          (click)="chooseProgram(programs.JADE)"
          data-testid="choose-jade-program-button-in-modal"
        >
          Choose Jade Account
        </button>
      </div>
      <ul>
        <li>
          <strong> Loading on EU IBAN (incl. e-Wallets):</strong><br />
          36,000&euro;/year, 3,000&euro;/month
        </li>
        <li>
          <strong>Loading on Mastercard(s):</strong> <br />
          36,000&euro;/year, 3,000&euro;/month
        </li>
        <li><strong>FREE</strong> IBAN loading via any Debit card</li>
        <li>
          <strong>EU transfers:</strong><br />
          FREE all inbound transfers<br />
          FREE outbound transfers up to<br />
          €2,000/transfer.
        </li>
        <li><strong>International transfers:</strong></li>
        Outward Transfers via SWIFT from e- Wallets and IBAN
        <li><strong>e2e Now:</strong></li>
        Instant money transfers between eCREDO users.
        <li>
          <strong>E-Wallets:</strong><br />
          10 major currency e-Wallets for live currency conversion and outward
          payments.
        </li>
        <li>
          <strong>MasterCard (s):</strong><br />
          1 physical card. Issuance and delivery €10.00 one time fee.
        </li>
        <li class="missing-feature">
          <strong>Additional cards</strong> -
          <span
            class="switch"
            data-testid="switch-to-emerald-link"
            (click)="switchToEmerald()"
            >Switch to Emerald</span
          >
        </li>

        <li>
          <strong>FREE ATM</strong> Cash withdrawals: 5 X €200<br />
          per month<br />
          Global use of Mastercard<br />
          Instore and online payments<br />
          Including Mastercard 3D secure
        </li>
        <li class="missing-feature">
          <strong>Upgrade:</strong> <br />
          <span
            class="switch"
            data-testid="switch-to-emerald-link"
            (click)="switchToEmerald()"
            >Switch to Emerald</span
          >
        </li>
      </ul>
    </div>
    <div *ngSwitchCase="programs.EMERALD">
      <div fxLayout="column" fxLayoutAlign="start center">
        <div class="price">9.95&euro;/month</div>
        <button
          mat-stroked-button
          color="accent"
          class="g-small"
          data-testid="choose-emerald-program-button-in-modal"
          (click)="chooseProgram(programs.EMERALD)"
        >
          Choose Emerald Account
        </button>
      </div>
      <ul>
        <li>
          <strong> Loading on EU IBAN (incl. e-Wallets):</strong><br />
          200,000&euro;/year, 50,000&euro;/month
        </li>
        <li>
          <strong>Loading on Mastercard(s):</strong> <br />
          72,000&euro;/year, 6,000&euro;/month
        </li>
        <li><strong>FREE</strong> IBAN loading via any Debit card</li>
        <li>
          <strong>EU transfers:</strong><br />
          FREE all inbound transfers<br />
          FREE outbound transfers up to<br />
          €3,000/transfer.
        </li>
        <li><strong>International transfers:</strong></li>
        Outward Transfers via SWIFT from e- Wallets and IBAN
        <li><strong>e2e Now:</strong></li>
        Instant money transfers between eCREDO users.
        <li>
          <strong>E-Wallets:</strong><br />
          10 major currency e-Wallets for live currency conversion and outward
          payments.
        </li>
        <li>
          <strong>MasterCard (s):</strong><br />
          1 physical card. Issuance and delivery €5.00 one time fee.
        </li>
        <li>
          <strong>FREE</strong> up to 3 additional cards<br />
          (optional)
        </li>
        <li>
          <strong>FREE ATM</strong> Cash withdrawals: 5 X €200<br />
          per month<br />
          Global use of Mastercard<br />
          Instore and online payments<br />
          Including Mastercard 3D secure
        </li>
      </ul>
    </div>
    <div *ngSwitchCase="programs.ONYX">
      <div fxLayout="column" fxLayoutAlign="start center">
        <div class="price">68.95&euro;/month</div>
        <button
          mat-stroked-button
          color="accent"
          class="g-small"
          data-testid="choose-onyx-program-button-in-modal"
          (click)="chooseProgram(programs.ONYX)"
        >
          Choose Onyx Account
        </button>
      </div>
      <ul>
        <li>
          <strong> Loading on EU IBAN (incl. e-Wallets):</strong><br />
          1,320,000&euro;/year, 110,000&euro;/month
        </li>
        <li>
          <strong>Loading on Mastercard:</strong> <br />
          660,000&euro;/year, 3,000&euro;/month
        </li>
        <li><strong>FREE</strong> IBAN loading via any Debit card</li>
        <li>
          <strong>EU transfers:</strong><br />
          <strong>FREE</strong> all inbound transfers<br />
          <strong>FREE</strong> outbound transfers<br />
          Unlimited number of transfers
        </li>
        <li><strong>International transfers:</strong></li>
        Unlimited outward transfers via SWIFT from e-Wallets and IBAN
        <li><strong>Mass payments/Payroll</strong></li>
        FREE mass payments/payroll features
        <li><strong>e2e Now:</strong></li>
        Instant money transfers between eCREDO users.
        <li>
          <strong>E-Wallets:</strong><br />
          10 major currency e-Wallets for live currency conversion and outward
          payments.
        </li>
        <li>
          <strong>MasterCard (s):</strong><br />
          Create up to 9 Business card(s)
        </li>
        <li><strong>FREE</strong> Issuance and delivery for ALL.<br /></li>
        <li>
          <strong>FREE ATM</strong> Cash withdrawals: 3 X €300<br />
          per month/per card<br />
          Global use of Mastercard(s) Instore and online payments Including
          Mastercard 3D secure
        </li>
        <li class="missing-feature">
          <strong>Upgrade:</strong> <br />
          <span
            class="switch"
            data-testid="switch-to-ruby-link"
            (click)="switchToRuby()"
            >Switch to RUBY account</span
          >
        </li>
      </ul>
    </div>
    <div *ngSwitchCase="programs.RUBY">
      <div fxLayout="column" fxLayoutAlign="start center">
        <div class="price">99.95&euro;/month</div>
        <button
          mat-stroked-button
          color="accent"
          class="g-small"
          data-testid="choose-ruby-program-button-in-modal"
          (click)="chooseProgram(programs.RUBY)"
        >
          Choose Ruby Account
        </button>
      </div>
      <ul>
        <li>
          <strong> Loading on EU IBAN (incl. e-Wallets):</strong><br />
          3,000,000&euro;/year, 1,000,000&euro;/month
        </li>
        <li>
          <strong>Loading on Mastercard:</strong> <br />
          1,320,000&euro;/year, 110,000&euro;/month
        </li>
        
        <li>
          <strong>EU transfers:</strong><br />
          <strong>FREE</strong> all inbound transfers<br />
          <strong>FREE</strong> outbound transfers<br />
          Unlimited number of transfers
        </li>
        <li><strong>International transfers:</strong></li>
        Unlimited outward transfers via SWIFT from e-Wallets and IBAN
        <li><strong>Mass payments/Payroll</strong></li>
        FREE mass payments/payroll features
        <li><strong>e2e Now:</strong></li>
        Instant money transfers between eCREDO users.
        <li>
          <strong>E-Wallets:</strong><br />
          10 major currency e-Wallets for live currency conversion and outward
          payments.
        </li>
        <li>
          <strong>MasterCard (s):</strong><br />
          Create up to 9 Business card(s)
        </li>
        <li><strong>FREE</strong> Issuance and delivery for ALL.<br /></li>
        <li>
          <strong>FREE ATM</strong> Cash withdrawals: 3 X €500<br />
          per month/per card<br />
          Global use of Mastercard(s) Instore and online payments Including
          Mastercard 3D secure
        </li>
        
      </ul>
    </div>
  </ng-container>
</mat-dialog-content>
