<app-big-loader *ngIf="isLoading"></app-big-loader>
<ng-container *ngIf="!isLoading">
  <ng-container *ngTemplateOutlet="certificate"></ng-container>
</ng-container>

<!-- TEMPLATE -->
<ng-template #certificate>
  <div *ngIf="data" class="main-container">
    <div fxLayout="column" class="header-container">
      <div fxLayout fxLayoutAlign="space-between center">
        <img src="assets/img/logo-dark.svg" alt="eCredo logo" />
        <h1>IBAN Certificate</h1>
      </div>
      <div fxFlexAlign="end" class="description">
        <span>{{ now | date: 'mediumDate' }}</span>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="10px" class="data-container">
      <div>
        <div>Account name</div>
        <div>{{ data.name }}</div>
      </div>
      <div>
        <div>Account number</div>
        <div>{{ data.accountNumber }}</div>
      </div>
      <div>
        <div>Currency</div>
        <div>{{ data.currency }}</div>
      </div>
      <div>
        <div>IBAN number (paper format)</div>
        <strong>{{ data.ibanPaperNumber }}</strong>
      </div>
      <div>
        <div>IBAN number (electronic format)</div>
        <strong>{{ data.ibanElectronicNumber }}</strong>
      </div>
      <div>
        <div>eCREDO BIC/SWIFT</div>
        <div>{{ data.bic }}</div>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="26px" class="bottom-container">
      <!-- <div class="italic">
        This document may be used for payment to your above account
      </div> -->
      <div fxLayout="column" fxFlexAlign="end">
        <div fxFlexAlign="end">eCREDO</div>
        <div class="italic">
          This document does not require any authorized signature
        </div>
      </div>
    </div>
  </div>
</ng-template>
