import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Card } from '../../../../models/card.model';
import { CardActivationDialogComponent } from '../../../card-activiation-dialog/card-activation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AccountStatus } from '../../../../shared/account-status.enum';

@Component({
  selector: 'app-card-item',
  templateUrl: './card-item.component.html',
  styleUrls: ['./card-item.component.scss'],
})
export class CardItemComponent {
  @Input() card!: Card;
  @Input() isChevron: boolean = true;
  @Input() isPicked: boolean = false;
  @Input() deactivatedCards: boolean = false;
  @Input() isSmallScreen: boolean = false;
  @Output() readonly cardActivated = new EventEmitter<void>();
  @Output() readonly pickedCard = new EventEmitter<Card>();

  AccountStatus = AccountStatus;

  constructor(private dialog: MatDialog) {}

  activateCard(card: Card, ev: MouseEvent) {
    ev.stopPropagation();
    this.dialog.open(CardActivationDialogComponent, {
      width: '720px',
      height: '460px',
      panelClass: 'dialog-with-close-button',
      autoFocus: false,
      data: { card, onSuccess: () => this.cardActivated.emit() },
    });
  }

  changeCurrentCard() {
    this.pickedCard.emit(this.card);
  }

  expiresSoon(): boolean { // show activate button on 20th of the previous month of expiration
    if (this.card) {
      var strExpDate = this?.card.expireDate || '';
      var year: number = +(strExpDate.substring(0,4));
      var month: number = +(strExpDate.substring(5,7));
      var days = +(strExpDate.substring(8,10));
      const cardExpiryDt = new Date(year, month-1, days);
      const dtAppear = new Date(year, month-2, 20);
      var dateNow = new Date(Date.now());

      // between 20th of previous month of expiration and end of expiration month
      if (dateNow >= dtAppear && dateNow <= cardExpiryDt) {
        return true; 
      }
    }
    return false;
  }
  
  // Show activate button when card is expired or issued
  showActiveButton(): boolean {
    if (this.card) {
      if (this.expiresSoon()) return true;
    }
    return false;
  }
}
