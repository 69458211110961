import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { AccountInfo } from 'src/app/dashboard/models/accountInfo.model';
import { currencies } from 'src/app/dashboard/shared/currencies';
import { TransactionType } from '../models/transaction-type.enum';
import { StatementItem } from '../models/statement-item.model';
import { StatementService } from '../statement.service';

@Component({
  selector: 'app-statement-pdf',
  templateUrl: './statement-pdf.component.html',
  styleUrls: ['./statement-pdf.component.scss'],
})
export class StatementPdfComponent implements OnInit {
  dateFrom?: string;
  dateTo?: string;
  currency?: string;
  cardType?: string;
  data: StatementItem[] = [];
  openingBalance?: number;
  closingBalance?: number;
  statementTypes = TransactionType;
  accountInfo?: AccountInfo;

  allColumns: string[] = ['date', 'description', 'debit', 'credit', 'balance'];

  displayedColumns = this.allColumns;

  constructor(
    private route: ActivatedRoute,
    private statementService: StatementService
  ) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        first(),
        switchMap(
          ({ numAccountId, dtStartDate, dtEndDate, currency, cardType }) => {
            this.dateFrom = dtStartDate;
            this.dateTo = dtEndDate;
            this.currency = currency;
            this.cardType = cardType;
            switch (cardType) {
              case TransactionType.CREDIT:
                this.displayedColumns = this.allColumns.filter(
                  (c) => c !== 'debit'
                );
                break;
              case TransactionType.DEBIT:
                this.displayedColumns = this.allColumns.filter(
                  (c) => c !== 'credit'
                );
                break;
              default:
                this.displayedColumns = this.allColumns;
                break;
            }
            return combineLatest([
              this.statementService.getAccountInfo(numAccountId),
              this.statementService.getStatement({
                accountCode: numAccountId,
                dtStartDate,
                dtEndDate,
                limit: 1000000,
                orderField: 'date',
                orderAscending: true,
              }),
            ]);
          }
        )
      )
      .subscribe(([accountInfo, res]) => {
        this.data = res.items;
        // if (res.items.length > 0) {
        //   this.openingBalance =
        //     res.items[0].availableBalance - res.items[0].amount;
        //   this.closingBalance =
        //     res.items[res.items.length - 1].availableBalance;
        // } else {
        //   this.openingBalance = undefined;
        //   this.closingBalance = undefined;
        // }
        this.accountInfo = accountInfo;
      });
  }

  get currencyName() {
    if (this.currency) {
      return currencies.find((c) => c.short === this.currency)?.name;
    } else {
      return currencies.find(
        (c) => c.isoNumber === this.accountInfo?.cardInfo.accountBaseCurrency
      )?.name;
    }
  }
}
