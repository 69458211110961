export enum UserState {
  INITIATED_REGISTRATION = 1,
  VERIFIED_EMAIL = 2,
  VERIFIED_MOBILE_PHONE_NUMBER = 3,
  ADDITIONAL_INFORMATION_REQUESTED = 6,
  KYC_VERIFIED = 7,
  DEPOSIT_ONLY = 8,
  TEMPORARY_LOGIN_BLOCK = 9,
  CLOSED = 10,
  ACCOUNT_REVIEW_STATUS = 11,
  Fraud_1st_Party_SEPA = 12,
  Fraud_1st_Party_BACS = 13,
  Fraud_1st_Party = 14,
  Fraud_Transaction_Verification = 15,
  Fraud_Transaction_Verification_SEPA = 16,
  Spend_Only = 17,
  Fraud_VOI = 18,
  PNV = 19,
  Fraud_ATO = 20,
  Fraud_CNP = 21,
  Fraud_Compromised_Card = 22,
  Fraud_Skimming = 23,
  Fraud_Transaction_Verification_CNP = 24,
  Fraud_Transaction_Verification_CP = 25,
  Fraud_Transaction_Verification_BACS = 26,
  Fraud_Transaction_Verification_SWIFT = 27,
  Fraud_Transaction_Verification_Skimming = 28,
  Fraud_1st_Party_SWIFT = 29,
  Fraud_CP = 30,
  Fraud = 31,
  AML_Transaction_Monitoring = 32,
  DECISION_POINT = 33,
  EDD = 34,
  REJECTED = 35,
  EDD_SUBMITTED = 36,
  WITHDRAWN = 37,
  KEY_PERSONS_VERIFICATION = 38,
  KEY_PERSONS_DECISION_POINT = 39,
  BUSINESS_CDD_REVIEW = 40,
}
