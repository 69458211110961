import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FileInputValue } from './file-input/file-input.component';

// Used for FileInputComponent
export function documentValidator(): ValidatorFn {
  return (formControl: AbstractControl): ValidationErrors | null => {
    // checks if last comment was added by user
    const hasUserComment = !!(formControl.value as FileInputValue)?.doc
      ?.reasonWhyNotUploaded;
    return !(formControl.value?.doc?.location || hasUserComment)
      ? { document: true }
      : null;
  };
}
