import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Country } from 'src/app/shared/models/country.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  baseUrl = environment.BACKEND_URL;

  constructor(private http: HttpClient) {}

  getAllCountries(): Observable<Country[]> {
    const url = `${this.baseUrl}/countries`;
    return this.http.get<Country[]>(url);
  }
}
