<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close [disabled]="isVerifying">
    <mat-icon data-testid="close-modal-button">close</mat-icon>
  </button>
</div>
<mat-dialog-content
  data-testid="verify-modal-container"
  *ngIf="!showReturnedToEddText"
>
  <h1>Verify {{ userName }}</h1>
  <p>
    By proceeding, you will grant the customer immediate access to the account.
  </p>
  <div class="buttons-container" fxLayoutAlign="end center" fxLayoutGap="40px">
    <button
      mat-stroked-button
      data-testid="cancel-modal-button"
      color="accent"
      mat-dialog-close
      [disabled]="isVerifying"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="accent"
      [disabled]="isVerifying"
      data-testid="proceed-modal-button"
      [appButtonLoader]="isVerifying"
      (click)="verifyClient()"
    >
      Proceed
    </button>
  </div>
</mat-dialog-content>
<mat-dialog-content *ngIf="showReturnedToEddText">
  <h1>Verify {{ userName }}</h1>
  <p>
    The applicant was not verified and was sent back to EDD for AMLCO to review,
    due to an AML status update.
  </p>
  <div fxLayoutAlign="end center">
    <button mat-flat-button color="accent" (click)="closeDialog()">
      Close
    </button>
  </div>
</mat-dialog-content>
