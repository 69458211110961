import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { DateTime } from 'luxon';
import { merge, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  first,
  tap,
} from 'rxjs/operators';
import { AuthService } from 'src/app/login/services/auth.service';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { ReportsService } from '../reports.service';
import { UserIpLoginDataSource } from './user-ip-login.datasource';
import { environment } from 'src/environments/environment';
import { UserLogSearch } from '../../users/registered-users/registered-user/registered-user-logs/models/user-log-search.model';
@Component({
  templateUrl: './user-ip-login.component.html',
  styleUrls: ['./user-ip-login.component.scss'],
})
export class UserIpLoginComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  isLoadingCsv: boolean = false;
  dataSource!: UserIpLoginDataSource;
  displayedColumns: string[] = [
    'userName',
    'action',
    'message',
    'dateCreated',
    'ipAddress',
  ];

  filtersGroup: FormGroup = this.fb.group({
    search: null,
    startDate: null,
    endDate: null,
  });

  orderField: string = '';
  sortDirection: 'asc' | 'desc' = 'asc';

  private formSub?: Subscription;
  private sortSub?: Subscription;
  private mergeSub?: Subscription;

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private authService: AuthService,
    private reportsService: ReportsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.dataSource = new UserIpLoginDataSource(
      this.reportsService,
      this.errorService
    );

    this.route.queryParams
      .pipe(
        first(),
        tap((params) => {
          if (!params.isPdf !== undefined) {
            console.log('params', params.isPdf);
            this.startDateControl.setValue(params.dateFrom);
            this.endDateControl.setValue(params.dateTo);
            this.searchControl.setValue(params.searchText);
            this.orderField = params.orderField || '';

            // sets sortDirection
            if (params.orderDescending) {
              this.sortDirection =
                params.orderDescending === 'true' ? 'desc' : 'asc';
            }

            const userParams = { params, userId: -1 };
            this.dataSource.loadUserIpLogin(userParams);
          } else {
            this.dataSource.loadUserIpLogin({ userId: -1, limit: 20 });
          }
        })
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    // listens for filters values
    this.formSub = merge(
      this.searchControl.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged()
      ),
      this.startDateControl.valueChanges.pipe(distinctUntilChanged()),
      this.endDateControl.valueChanges.pipe(distinctUntilChanged())
    )
      .pipe(
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadUserIpLoginPage();
        })
      )
      .subscribe();

    // reset the paginator after sorting
    this.sortSub = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );

    this.mergeSub = merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadUserIpLoginPage()))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.formSub?.unsubscribe();
    this.sortSub?.unsubscribe();
    this.mergeSub?.unsubscribe();
  }

  onGeneratePdf() {
    // prepares frontend path with params (filters out limit/page and empty params)
    const excludedKeysSet = new Set(['limit', 'page']);
    const frontendPath = Object.entries(this.generateUserIpLoginReqParams())
      .filter(([key, value]) => !excludedKeysSet.has(key) && !!value)
      .reduce(
        (prev, [key, value]) => `${prev}&${key}=${value}`,
        'admin/reports/user-ip-login?'
      );

    const fileName = 'user-ip-login.pdf';
    var a = document.createElement('a');
    a.href = `${environment.BACKEND_URL}/pdf?path=${encodeURIComponent(
      frontendPath
    )}&fileName=${fileName}`;
    this.authService.setTokenInCookie(a.pathname).subscribe(() => a.click());
  }

  onGenerateCsv() {
    const paramsCsv = this.generateUserIpLoginReqParams();
    paramsCsv.limit = this.dataSource.getCount();
    this.isLoadingCsv = true;
    this.reportsService
      .getUserIpLoginCsv(paramsCsv)
      .pipe(finalize(() => (this.isLoadingCsv = false)))
      .subscribe(
        (csv) => {
          var element = document.createElement('a');
          element.setAttribute(
            'href',
            'data:text/plain;charset=utf-8,' + encodeURIComponent(csv)
          );
          element.setAttribute('download', 'user-ip-login.csv');
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        },
        () => this.errorService.showErrorDialog()
      );
  }

  private loadUserIpLoginPage(): void {
    this.dataSource.loadUserIpLogin(this.generateUserIpLoginReqParams());
  }

  private generateUserIpLoginReqParams(): UserLogSearch {
    const params: UserLogSearch = {
      userId: -1,
      page: this.paginator.pageIndex + 1, // backend counts pages from 1 and mat-paginator from 0
      limit: this.paginator.pageSize,
      searchText: this.searchControl.value,
      dateFrom: this.startDateControl.value
        ? DateTime.fromISO(this.startDateControl.value).toFormat('yyyy-MM-dd')
        : '',
      dateTo: this.endDateControl.value
        ? DateTime.fromISO(this.endDateControl.value).toFormat('yyyy-MM-dd')
        : '',
      orderField: this.sort.active,
      orderDescending: this.sort.direction === 'desc',
    };
    return params;
  }

  get searchControl(): FormControl {
    return this.filtersGroup.get('search') as FormControl;
  }
  get startDateControl(): FormControl {
    return this.filtersGroup.get('startDate') as FormControl;
  }
  get endDateControl(): FormControl {
    return this.filtersGroup.get('endDate') as FormControl;
  }
}
