<app-big-loader *ngIf="isLoading"></app-big-loader>
<div *ngIf="!isLoading" class="form-container">
  <app-avatar-input-new
    *ngIf="avatarUrl !== undefined"
    [isAdminProfile]="true"
    [avatarUrl]="avatarUrl"
    [state]="state"
    [isUploading]="isAvatarUploading"
    [errorMessage]="avatarErrorMessage"
    [userId]="userId"
    (avatarChange)="changeAvatar($event)"
    (avatarRemove)="removeAvatar()"
  ></app-avatar-input-new>
  <app-registered-user-profile-individual-new
    *ngIf="registeredUser.personalData"
    [userId]="userId"
    [personalData]="registeredUser.personalData"
    [countries]="countries"
    [acceptedCountries]="acceptedCountries"
    [email]="registeredUser.email"
    [cellPhoneNumber]="registeredUser.cellPhoneNumber"
    [program]="program"
    (reloadUser)="reloadProfile()"
  ></app-registered-user-profile-individual-new>
  <app-registered-user-profile-corporate-new
    *ngIf="registeredUser.corporateData && registeredUser.companyDirectors"
    [userId]="userId"
    [corporateData]="registeredUser.corporateData"
    [companyDirectors]="registeredUser.companyDirectors"
    [companyAccounts]="registeredUser.companyAccounts"
    [companyCounterparties]="registeredUser.companyCounterparties"
    [companyGroupMembers]="registeredUser.companyGroupMembers"
    [companyCloseLinks]="registeredUser.companyCloseLinks"
    [companyDirectorCloseLinks]="registeredUser.companyDirectorCloseLinks"
    [countries]="countries"
    [acceptedCountries]="acceptedCountries"
    [email]="registeredUser.email"
    [cellPhoneNumber]="registeredUser.cellPhoneNumber"
    (reloadUser)="reloadProfile()"
  ></app-registered-user-profile-corporate-new>
</div>
