import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';

export function e2ePhoneNumberValidator(
  getIbanAccountByPhoneNumber: (phoneNumber: string) => Promise<void>
): AsyncValidatorFn {
  return (formControl: AbstractControl): Promise<ValidationErrors | null> => {
    return getIbanAccountByPhoneNumber(formControl.value)
      .then(() => null)
      .catch(() => {
        formControl.setErrors({ ...formControl?.errors, e2ePhoneNumber: true });
        return { e2ePhoneNumber: true };
      });
  };
}
