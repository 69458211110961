import { Component, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/login/services/auth.service';
import { PasswordComponent } from 'src/app/login/shared/password/password.component';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { UserProfileService } from '../../user-profile.service';
import { ChangeEmailDialogNewComponent } from '../change-email-dialog/change-email-dialog.component';

@Component({
  selector: 'app-change-password-dialog-new',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogNewComponent {
  @ViewChild('newPassword')
  passwordComp!: PasswordComponent;

  hideCurrentPassword = true;
  isSending = false;
  isSuccess = false;
  newPassword: string | null = null;
  appPasswordForm: FormGroup = new FormGroup({});

  passwordForm = this.fb.group({
    currentPassword: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private userProfileService: UserProfileService,
    private errorService: ErrorService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<ChangeEmailDialogNewComponent>
  ) {}

  async submit() {
    this.isSending = true;
    this.appPasswordForm = this.passwordComp.getGroup();
    (
      await this.userProfileService.requestPasswordChange(
        this.currentPasswordControl.value,
        this.passwordComp.password
      )
    ).subscribe(
      () => {
        this.isSending = false;
        this.isSuccess = true;
      },
      (err) => {
        this.isSending = false;
        if (err.status === 400) {
          this.currentPasswordControl.setErrors({ wrongPassword: true });
        } else if (err.status === 403) {
          this.authService.logOut(false, true);
          this.dialogRef.close();
        } else if (err.status === 422) {
          this.appPasswordForm
            ?.get('password')
            ?.setErrors({ notUniquePassword: true });
        } else {
          this.errorService.showErrorDialog();
        }
      }
    );
  }

  onNewPasswordChange(newPassword: string | null) {
    this.newPassword = newPassword;
  }

  get currentPasswordControl(): AbstractControl {
    return this.passwordForm.get('currentPassword')!;
  }
}
