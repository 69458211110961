import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { IbanCertificate } from 'src/app/dashboard/models/iban-certificate.model';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';

@Component({
  templateUrl: './iban-certificate.component.html',
  styleUrls: ['./iban-certificate.component.scss'],
})
export class IbanCertificateComponent implements OnInit {
  isLoading: boolean = false;
  data?: IbanCertificate;
  now: Date = new Date();

  constructor(
    private dashboardService: DashboardService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.dashboardService.getIbanCertificateInfo().subscribe(
      (data) => {
        this.data = data;
        this.isLoading = false;
      },
      () => {
        this.errorService.showErrorDialog();
        this.isLoading = false;
      }
    );
  }
}
