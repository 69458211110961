import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commify',
})
export class CommifyPipe implements PipeTransform {
  transform(value: string | undefined): any {
    if (value) {
      return parseInt(value.replace(/\D/g, '')).toLocaleString('en-US');
    }
    return '';
  }
}
