<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content fxLayout="column" fxLayoutGap="20px">
  <h1>Change email address</h1>
  <div class="content">
    <div class="success">Success!</div>
    <div class="description">Email address changed.</div>
  </div>
  <button
    mat-stroked-button
    mat-dialog-close
    fxFlexAlign="end"
    color="accent"
    class="cancel"
  >
    Close
  </button>
</mat-dialog-content>
