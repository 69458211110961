export interface DateRange {
  present: string;
  past: string;
}

// Returns two dates: now and 30 days to the past from now
export const calculateDateRange = (): DateRange => {
  const date = new Date();
  const present = date.toISOString().slice(0, -5);
  date.setDate(date.getDate() - 30);
  const past = date.toISOString().slice(0, -5);
  return { present, past };
};
