import { Component, EventEmitter, Output } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { TransferService } from '../../transfer.service';
import { MassPayment } from '../models/mass-payment.model';

export enum TemplateUploadState {
  isEmpty,
  isProcessing,
  isErroring,
  isUploaded,
}

@Component({
  selector: 'app-template-upload',
  templateUrl: './template-upload.component.html',
  styleUrls: ['./template-upload.component.scss'],
})
export class TemplateUploadComponent {
  @Output() upload = new EventEmitter<Observable<MassPayment>>();
  @Output() clearFile = new EventEmitter<void>();

  file?: File;
  state: TemplateUploadState = TemplateUploadState.isEmpty;
  TemplateUploadState = TemplateUploadState;

  constructor(private transferService: TransferService) {}

  clear(): void {
    this.state = TemplateUploadState.isEmpty;
    this.clearFile.emit();
  }

  dropped(fileDrop: NgxFileDropEntry): void {
    if (
      !(this.state === TemplateUploadState.isProcessing) &&
      fileDrop &&
      fileDrop.fileEntry.isFile
    ) {
      (fileDrop.fileEntry as FileSystemFileEntry).file((file: File) => {
        this.file = file;
        this.state = TemplateUploadState.isProcessing;
        const uploadObs = this.transferService
          .massPaymentUpload(file)
          .pipe(shareReplay());

        uploadObs.subscribe(
          () => (this.state = TemplateUploadState.isUploaded),
          () => (this.state = TemplateUploadState.isErroring)
        );
        this.upload.emit(uploadObs);
      });
    }
  }
}
