import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';
import { LoginComponent } from './login/components/login/login.component';
import { RegistrationComponent } from './login/components/registration/registration.component';
import { ResetPasswordComponent } from './login/components/reset-password/reset-password.component';
import { UserState } from './login/models/user-state.enum';
import { UserStateGuard } from './guards/user-state.guard';
import { ConfirmEmailComponent } from './login/components/further-registration/confirm-email/confirm-email.component';
import { ConnectPhoneComponent } from './login/components/further-registration/connect-phone/connect-phone.component';
import { EmailSentComponent } from './login/components/registration/email-sent/email-sent.component';
import { UserTypeGuard } from './guards/user-type.guard';
import { UserType } from './login/models/user-type.enum';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { UsersComponent } from './admin/users/users.component';
import { RegisteredUsersComponent } from './admin/users/registered-users/registered-users.component';
import { ConfirmEmailChangeNewComponent } from './user-profile-new/components/confirm-email-change/confirm-email-change.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TransferComponent } from './transfer/transfer.component';
import { StatementComponent } from './statement/statement.component';
import { InBetweenComponent } from './transfer/in-between/in-between.component';
import { E2eNowComponent } from './transfer/e2e-now/e2e-now.component';
import { ExternalComponent } from './transfer/external/external.component';
import { MassPaymentsComponent } from './transfer/mass-payments/mass-payments.component';
import { RecurringComponent } from './transfer/recurring/recurring.component';
import { FavouritesComponent } from './transfer/favourites/favourites.component';
import { RegisteredUserComponent } from './admin/users/registered-users/registered-user/registered-user.component';
import { SubDashboardComponent } from './sub-dashboard/sub-dashboard.component';
import { IbanAccountComponent } from './sub-dashboard/iban-account/iban-account.component';
import { CardsComponent } from './sub-dashboard/cards/cards.component';
import { WalletComponent } from './sub-dashboard/wallet/wallet.component';
import { PendingChangesGuard } from './guards/pending-changes-guard';
import { ReportsComponent } from './admin/reports/reports.component';
import { UnpaidFeesComponent } from './admin/reports/unpaid-fees/unpaid-fees.component';
import { ExpiringDocumentsComponent } from './admin/reports/expiring-documents/expiring-documents.component';
import { UserIpLoginComponent } from './admin/reports/user-ip-login/user-ip-login.component';
import { TransactionsNumberComponent } from './admin/reports/transactions-number/transactions-number.component';
import { InitialPaymentComponent } from './login/components/initial-payment/initial-payment.component';
import { CardPaidGuard } from './guards/card-paid.guard';
import { InitialPaymentFailComponent } from './login/components/initial-payment-fail/initial-payment-fail.component';
import { InitialPaymentSuccessComponent } from './login/components/initial-payment-success/initial-payment-success.component';
import { StatementPdfComponent } from './statement/statement-pdf/statement-pdf.component';
import { StatementDetailsPdfComponent } from './statement/statement-details-pdf/statement-details-pdf.component';
import { IbanCertificateComponent } from './sub-dashboard/iban-certificate/iban-certificate.component';
import { ActionsComponent } from './admin/actions/actions.component';
import { ActivationComponent } from './admin/actions/activation/activation.component';
import { OnboardingNewComponent } from './onboarding-new/onboarding.component';
import { UserProfileNewComponent } from './user-profile-new/user-profile.component';
import { UpdateChidsComponent } from './admin/actions/update-chids/update-chids.component';
import { OngoingRegistrationNewComponent } from './admin/users/ongoing-registration-new/ongoing-registration.component';
import { OngoingRegistrationUserNewComponent } from './admin/users/ongoing-registration-new/ongoing-registration-user/ongoing-registration-user.component';
import { OngoingRegistrationPdfNewComponent } from './admin/users/ongoing-registration-new/ongoing-registration-pdf/ongoing-registration-pdf.component';
import { NotificationsComponent } from './admin/notifications/notifications.component';
import { KeyPersonVerificationComponent } from './admin/users/ongoing-registration-new/ongoing-registration-user/risk-assessment/verification/key-person-verification.component';

const routes: Routes = [
  // routes without guards
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegistrationComponent },
  { path: 'email-sent', component: EmailSentComponent },
  { path: 'confirm', component: ConfirmEmailComponent },
  { path: 'verify', component: KeyPersonVerificationComponent },
  { path: 'change-email', component: ConfirmEmailChangeNewComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  // user routes with guards
  {
    path: 'connect-phone',
    component: ConnectPhoneComponent,
    canActivate: [AuthGuard, UserTypeGuard, UserStateGuard],
    data: {
      allowedStates: [UserState.VERIFIED_EMAIL],
      allowedTypes: [UserType.PERSONAL, UserType.CORPORATE],
    },
  },
  {
    path: 'onboarding-new',
    component: OnboardingNewComponent,
    canActivate: [AuthGuard, UserTypeGuard, UserStateGuard],
    data: {
      allowedStates: [
        UserState.VERIFIED_MOBILE_PHONE_NUMBER,
        UserState.ADDITIONAL_INFORMATION_REQUESTED,
        UserState.DECISION_POINT,
        UserState.EDD,
        UserState.EDD_SUBMITTED,
        UserState.KEY_PERSONS_VERIFICATION,
        UserState.KEY_PERSONS_DECISION_POINT,
        UserState.BUSINESS_CDD_REVIEW,
      ],
      allowedTypes: [UserType.PERSONAL, UserType.CORPORATE],
    },
  },
  {
    path: 'initial-payment-fail',
    component: InitialPaymentFailComponent,
    canActivate: [AuthGuard, UserTypeGuard, UserStateGuard],
    data: {
      allowedStates: [UserState.KYC_VERIFIED],
      allowedTypes: [UserType.PERSONAL],
    },
  },
  {
    path: 'initial-payment-success',
    component: InitialPaymentSuccessComponent,
    canActivate: [AuthGuard, UserTypeGuard, UserStateGuard],
    data: {
      allowedStates: [UserState.KYC_VERIFIED],
      allowedTypes: [UserType.PERSONAL],
    },
  },
  {
    path: 'initial-payment',
    component: InitialPaymentComponent,
    canActivate: [AuthGuard, UserTypeGuard, UserStateGuard],
    data: {
      allowedStates: [UserState.KYC_VERIFIED],
      allowedTypes: [UserType.PERSONAL],
    },
  },
  // admin routes
  {
    path: 'admin',
    canActivate: [AuthGuard, UserTypeGuard],
    data: {
      allowedTypes: [
        UserType.ADMINISTRATOR,
        UserType.ONBOARDING_AGENT,
        UserType.OPERATIONS_MANAGER,
        UserType.AMLCO,
        ,
        UserType.BOD,
      ],
    },
    children: [
      {
        path: '',
        component: AdminDashboardComponent,
        children: [
          { path: '', redirectTo: 'users', pathMatch: 'full' },
          {
            path: 'users',
            component: UsersComponent,
            children: [
              {
                path: '',
                redirectTo: 'ongoing-registration-new',
                pathMatch: 'full',
              },
              { path: 'registered-users', component: RegisteredUsersComponent },
              {
                path: 'registered-users/:userId',
                component: RegisteredUserComponent,
                canDeactivate: [PendingChangesGuard],
              },
              {
                path: 'ongoing-registration-new/:userId',
                component: OngoingRegistrationUserNewComponent,
              },
              {
                path: 'ongoing-registration-new',
                component: OngoingRegistrationNewComponent,
              },
            ],
          },
          {
            path: 'reports',
            component: ReportsComponent,
            children: [
              {
                path: '',
                redirectTo: 'unpaid-fees',
                pathMatch: 'full',
              },
              {
                path: 'unpaid-fees',
                component: UnpaidFeesComponent,
              },
              {
                path: 'expiring-documents',
                component: ExpiringDocumentsComponent,
              },
              {
                path: 'user-ip-login',
                component: UserIpLoginComponent,
              },
              {
                path: 'transactions-number',
                component: TransactionsNumberComponent,
              },
            ],
          },
          {
            path: 'actions',
            component: ActionsComponent,
            children: [
              {
                path: '',
                redirectTo: 'activation',
                pathMatch: 'full',
              },
              {
                path: 'activation',
                component: ActivationComponent,
              },
              {
                path: 'update-chids',
                component: UpdateChidsComponent,
              },
            ],
          },
          {
            path: 'notifications',
            component: NotificationsComponent,
            children: [
              {
                path: '',
                redirectTo: 'notifications',
                pathMatch: 'full',
              },
            ],
          },
        ],
      },
      {
        path: 'ongoing-registration-pdf-new',
        component: OngoingRegistrationPdfNewComponent,
      },
    ],
  },
  // user main-dashboard routes
  {
    path: '',
    canActivate: [AuthGuard, UserTypeGuard, CardPaidGuard],
    data: {
      allowedTypes: [UserType.PERSONAL, UserType.CORPORATE],
    },
    component: MainDashboardComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [UserStateGuard],
        data: {
          allowedStates: [UserState.KYC_VERIFIED, UserState.DEPOSIT_ONLY],
        },
      },
      {
        path: 'statement',
        component: StatementComponent,
        canActivate: [UserStateGuard],
        data: {
          allowedStates: [UserState.KYC_VERIFIED, UserState.DEPOSIT_ONLY],
        },
      },
      {
        path: 'statement-pdf',
        component: StatementPdfComponent,
      },
      {
        path: 'statement-details-pdf',
        component: StatementDetailsPdfComponent,
      },
      {
        path: 'my-profile-new',
        component: UserProfileNewComponent,
        canActivate: [UserStateGuard],
        data: {
          // TO DO: change allowed states
          allowedStates: [
            UserState.KYC_VERIFIED,
            UserState.DEPOSIT_ONLY,
            UserState.TEMPORARY_LOGIN_BLOCK,
            UserState.CLOSED,
            UserState.ACCOUNT_REVIEW_STATUS,
          ],
        },
      },
      {
        path: 'sub-dashboard',
        component: SubDashboardComponent,
        canActivate: [UserStateGuard],
        data: {
          allowedStates: [UserState.KYC_VERIFIED, UserState.DEPOSIT_ONLY],
        },
        children: [
          {
            path: '',
            redirectTo: 'iban-account',
            pathMatch: 'full',
          },
          { path: 'iban-account', component: IbanAccountComponent },
          { path: 'iban-certificate', component: IbanCertificateComponent },
          { path: 'cards', component: CardsComponent },
          { path: 'wallet', component: WalletComponent },
        ],
      },
      {
        path: 'transfer',
        component: TransferComponent,
        canActivate: [UserStateGuard],
        data: {
          allowedStates: [UserState.KYC_VERIFIED, UserState.DEPOSIT_ONLY],
        },
        children: [
          {
            path: '',
            redirectTo: 'in-between',
            pathMatch: 'full',
          },
          {
            path: 'in-between',
            component: InBetweenComponent,
          },
          {
            path: 'e2e-now',
            component: E2eNowComponent,
            canActivate: [UserStateGuard],
            data: {
              allowedStates: [UserState.KYC_VERIFIED],
            },
          },
          {
            path: 'external',
            component: ExternalComponent,
            canActivate: [UserStateGuard],
            data: {
              allowedStates: [UserState.KYC_VERIFIED],
            },
          },
          {
            path: 'mass-payments',
            component: MassPaymentsComponent,
            canActivate: [UserTypeGuard, UserStateGuard],
            data: {
              allowedTypes: [UserType.CORPORATE],
              allowedStates: [UserState.KYC_VERIFIED],
            },
          },
          {
            path: 'recurring',
            component: RecurringComponent,
            canActivate: [UserStateGuard],
            data: {
              allowedStates: [UserState.KYC_VERIFIED],
            },
          },
          {
            path: 'favourites',
            component: FavouritesComponent,
            canActivate: [UserStateGuard],
            data: {
              allowedStates: [UserState.KYC_VERIFIED],
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
