import { AbstractControl, ValidatorFn } from '@angular/forms';

export function jointLengthValidator(
  firstControlName: string,
  secondControlName: string,
  maxLength: number
): ValidatorFn {
  return (formGroup: AbstractControl): null => {
    const firstControl = formGroup.get(firstControlName);
    const secondControl = formGroup.get(secondControlName);
    if (`${firstControl?.value}${secondControl?.value}`.length > maxLength) {
      firstControl?.setErrors({ ...firstControl?.errors, jointLength: true });
      secondControl?.setErrors({ ...secondControl?.errors, jointLength: true });
    } else {
      delete firstControl?.errors?.jointLength;
      delete secondControl?.errors?.jointLength;
      if (!Object.keys(firstControl?.errors || {}).length) {
        firstControl?.setErrors(null);
      }
      if (!Object.keys(secondControl?.errors || {}).length) {
        secondControl?.setErrors(null);
      }
    }
    return null;
  };
}
