import { Component, Input } from '@angular/core';
import { AccountDetails } from 'src/app/dashboard/models/account-details.model';
import { AccountType } from 'src/app/dashboard/shared/account-type.enum';

@Component({
  selector: 'app-account-list-item',
  templateUrl: './account-list-item.component.html',
  styleUrls: ['./account-list-item.component.scss'],
})
export class AccountListItemComponent {
  @Input() set account(val: AccountDetails | undefined) {
    if (this.currency && val) {
      this.setBalance(val);
    }
    this._account = val;
  }
  get account(): AccountDetails | undefined {
    return this._account;
  }
  @Input() set currency(val: string) {
    if (val && this.account) {
      this.setBalance(this.account);
    }
    this._currency = val;
  }
  get currency(): string {
    return this._currency;
  }
  @Input() isSmallVersion: boolean = false;
  @Input() isBalanceHidden: boolean = false;

  balance?: number;
  private _account?: AccountDetails;
  private _currency: string = 'EUR';

  get isIban(): boolean {
    return this.account?.type === AccountType.IBAN;
  }

  get isCard(): boolean {
    return this.account?.type === AccountType.CARDS;
  }

  get isWallet(): boolean {
    return this.account?.type === AccountType.WALLETS;
  }

  // show available balance when doing transfers
  private setBalance(account: AccountDetails): void {
    this.balance = account.availableBalance || 0;
  }

  imagePath(name: string): string {
    return `assets/img/country-flags/${name}.svg`;
  }
}
