import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { of } from 'rxjs';
import { AllAccounts } from 'src/app/dashboard/models/all-accounts.model';

export function ibanValidator(
  ibanValidation: (iban: string) => Promise<void>,
  getAccounts: () => AllAccounts
): AsyncValidatorFn {
  return (formControl: AbstractControl): Promise<ValidationErrors | null> => {
    const accounts = getAccounts();
    // if beneficiary iban belongs to sender, return error
    if (accounts?.iban?.iban === formControl.value) {
      return Promise.resolve({ ibanBelongsToSender: true });
    }
    return ibanValidation(formControl.value)
      .then(() => null)
      .catch(() => of({ iban: true }));
  };
}
