import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { FileInputState } from '../../../../../../../shared/file-input/file-input.component';
import { Observable, Subscription } from 'rxjs';
import { allowedFileExtensions } from '../../../shared/allowedFileExtensions';

@Component({
  selector: 'app-all-file-input',
  templateUrl: './all-file-input.component.html',
  styleUrls: ['./all-file-input.component.scss'],
})
export class AllFileInputComponent implements OnInit, OnDestroy {
  @Input() fileLabel?: string;
  @Input() clearFile!: Observable<void>;
  @Output() uploadedFile = new EventEmitter<File | null>();

  private subscription!: Subscription;
  private errorTimeout!: any;

  fileInputStates = FileInputState;
  errorMessage = '';
  file: File | null = null;
  currentState: FileInputState = FileInputState.isEmpty;

  constructor() {}

  ngOnInit(): void {
    this.subscription = this.clearFile.subscribe(() => this.removeFile());
  }

  dropped(files: NgxFileDropEntry[]): void {
    if (files[0] && files[0].fileEntry.isFile) {
      (files[0].fileEntry as FileSystemFileEntry).file((file: File) => {
        this.file = file;

        const extension = this.file.name.split('.').pop()!;
        if (file.name.length > 90) {
          this.setError('File name is too long');
        } else if (file.size > 20000000) {
          this.setError('File is too big');
        } else if (!this.isCorrectExtension(extension)) {
          this.setError('File extension is not supported');
        } else {
          this.currentState = FileInputState.isUploaded;
          this.uploadedFile.emit(file);
        }
      });
    }
  }

  removeFile(): void {
    clearTimeout(this.errorTimeout);
    this.file = null;
    this.currentState = FileInputState.isEmpty;
    this.uploadedFile.emit(null);
  }

  private isCorrectExtension(extension: string): string | undefined {
    return allowedFileExtensions.find((allowedExt) => allowedExt === extension);
  }

  private clearError(): void {
    clearTimeout(this.errorTimeout);
    this.file = null;
    this.errorMessage = '';
    this.currentState = FileInputState.isEmpty;
  }

  private setError(message: string): void {
    this.currentState = FileInputState.isErroring;
    this.errorMessage = message;
    this.errorTimeout = setTimeout(() => this.clearError(), 5000);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
