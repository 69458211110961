import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { AllAccounts } from './models/all-accounts.model';
import { AccountDetails } from './models/accountDetails.model';
import { AccountInfo } from './models/accountInfo.model';
import { Statement } from './models/statement.model';
import { CardActivationForm } from './models/cardActivationForm.model';
import { OrderExtraCardForm } from './models/orderExtraCardForm.model';
import { StatementSearch } from '../statement/models/statement-search.model';
import { IbanCertificate } from './models/iban-certificate.model';
import { TransferType } from '../transfer/shared/enum/transfer-type.enum';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  financialUrl = `${environment.BACKEND_URL}/financial`;

  constructor(private http: HttpClient) {}

  getAccounts(transferType?: TransferType): Observable<AllAccounts> {
    const url = `${this.financialUrl}/all-accounts`;
    return this.http.post<AllAccounts>(url, { transferType });
  }

  getIbanCertificateInfo(): Observable<IbanCertificate> {
    const url = `${this.financialUrl}/iban-certificate-info`;
    return this.http.get<IbanCertificate>(url);
  }

  getAccountInfo(accountDetails: AccountDetails): Observable<AccountInfo> {
    const url = `${this.financialUrl}/accountInfo`;

    return this.http.post<AccountInfo>(url, {
      numUserId: accountDetails.numUserId,
      numAccountId: accountDetails.numId,
    });
  }
  getAccountStatements(
    numAccountId: number,
    statementSearch: StatementSearch
  ): Observable<Statement> {
    const url = `${this.financialUrl}/accountStatement`;
    const body = {
      numAccountId,
      ...statementSearch,
    };
    return this.http.post<Statement>(url, body);
  }

  activateCard(body: CardActivationForm): Observable<string> {
    const url = `${this.financialUrl}/activateCard`;
    return this.http.post<string>(url, body);
  }

  pinRemind(cardId: number): Observable<void> {
    const url = `${this.financialUrl}/pinRemind/${cardId}`;
    return this.http.post<void>(url, {});
  }

  orderExtraCard(body: OrderExtraCardForm): Observable<void> {
    const url = `${this.financialUrl}/orderExtraCard`;
    return this.http.post<void>(url, body);
  }

  lockUnlockCard(accId: number): Observable<void> {
    const url = `${this.financialUrl}/lockUnlock/${accId}`;
    return this.http.patch<void>(url, {});
  }

  reportLostStolen(lostAccId: number): Observable<void> {
    const url = `${this.financialUrl}/lostCard?lostAccId=${lostAccId} `;
    return this.http.post<void>(url, {});
  }
}
