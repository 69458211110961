<div class="table-container">
  <table mat-table [dataSource]="dataSource ?? []" [fixedLayout]="true">
    <!-- Name Column -->
    <ng-container matColumnDef="beneficiaryName">
      <th mat-header-cell *matHeaderCellDef>Beneficiary Name</th>
      <td mat-cell *matCellDef="let row">{{ row.beneficiaryName }}</td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="payerDescription">
      <th mat-header-cell *matHeaderCellDef>Description for Payer</th>
      <td mat-cell *matCellDef="let row">{{ row.payerDescription }}</td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="beneficiaryDescription">
      <th mat-header-cell *matHeaderCellDef>Description for Beneficiary</th>
      <td mat-cell *matCellDef="let row">{{ row.beneficiaryDescription }}</td>
    </ng-container>

    <!-- IBAN BIC Column -->
    <ng-container matColumnDef="ibanBic">
      <th mat-header-cell *matHeaderCellDef>
        <div>IBAN</div>
        <div>Bank SWIFT / BIC</div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div>{{ row.iban }}</div>
        <div>{{ row.bic }}</div>
      </td>
    </ng-container>

    <!-- BANK NAME ADDRESS Column -->
    <ng-container matColumnDef="bankNameAddress">
      <th mat-header-cell *matHeaderCellDef>
        <div>Bank Name</div>
        <div>Bank Address</div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div>{{ row.bankName }}</div>
        <div>{{ row.bankAddress }}</div>
      </td>
    </ng-container>

    <!-- Address Country Column -->
    <ng-container matColumnDef="addressCountry">
      <th mat-header-cell *matHeaderCellDef>
        <div>Beneficiary Address</div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div>{{ row.address }}</div>
        <div>{{ row.zip }}, {{ row.city }}</div>
        <div>{{ row.country | titlecase }}</div>
      </td>
    </ng-container>

    <!-- Value date -->
    <ng-container matColumnDef="valueDate">
      <th mat-header-cell *matHeaderCellDef>Value Date</th>
      <td mat-cell *matCellDef="let row">
        {{ row.valueDate | date: 'yyyy-MM-dd' }}
      </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td
        mat-cell
        *matCellDef="let row"
        [ngSwitch]="statusMap?.get(row.transactionId)"
      >
        <div *ngSwitchCase="undefined">Pending</div>
        <div *ngSwitchCase="true" class="success">Accepted</div>
        <div *ngSwitchCase="false" class="failure">Failed</div>
      </td>
    </ng-container>

    <!-- Amount Column -->
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Amount (EUR)</th>
      <td mat-cell *matCellDef="let row">{{ row.amount | amount }}</td>
    </ng-container>

    <!-- Fee Column -->
    <ng-container matColumnDef="fee">
      <th mat-header-cell *matHeaderCellDef>Indicative Transfer Fee (EUR)</th>
      <td mat-cell *matCellDef="let row">{{ row.fee | amount }}</td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="
        showStatus ? displayedColumnsWithStatus : displayedColumns
      "
    ></tr>
    <tr
      mat-row
      *matRowDef="
        let row;
        columns: showStatus ? displayedColumnsWithStatus : displayedColumns
      "
      [class.last]="row.last"
    ></tr>
  </table>
</div>
