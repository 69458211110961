import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { Observable } from 'rxjs';
import { AppDocumentType } from 'src/app/onboarding-new/models/document-type.enum';
import { OnboardingService } from 'src/app/onboarding-new/onboarding.service';
import {
  AdminConversationDialog,
  AdminConversationDialogNewComponent,
} from 'src/app/onboarding-new/shared/admin-conversation-dialog/admin-conversation-dialog.component';
import { ErrorService } from '../error-dialog/error.service';
import { getDocumentName } from '../helpers/various-helpers.helper';
import { AppDocument } from 'src/app/onboarding-new/models/document.model';

export enum FileInputState {
  isEmpty,
  isProcessing,
  isErroring,
  isUploaded,
  isReason,
}

export interface FileInputValue {
  documentType: AppDocumentType;
  doc?: AppDocument | null;
  customLabel?: string;
}

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
})
export class FileInputComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('anotherFileNameInput') anotherFileNameInput:
    | ElementRef<HTMLInputElement>
    | undefined;

  @Input() companyDirectorId: number | undefined = undefined;
  @Input() viewOnly: boolean = false;
  @Input() disableFileRemove: boolean = false;
  @Input() clientId?: number;
  @Input() userNoteId?: number;
  @Input() isAdmin: boolean = false;
  @Input() showProofOfOperatingAddress: boolean = false;
  @Output() removeField = new EventEmitter<void>(); // emitted when control should be removed
  @Output() canProvide = new EventEmitter<boolean>();
  @Output() openFileDialog = new EventEmitter<void>(); // emitted when document should open
  @Output() downloadFile = new EventEmitter<void>(); // emitted when document should BE DOWNLO

  value: FileInputValue = { documentType: AppDocumentType.ANOTHER_FILE };
  onChange = (val: any) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  fileInputStates = FileInputState;
  set currentState(val: FileInputState) {
    if (val === FileInputState.isProcessing) {
      this.ngControl.control?.markAsPending();
    } else {
      this.onChange(this.value);
    }
    this._currentState = val;
  }
  get currentState(): FileInputState {
    return this._currentState;
  }

  get fileBase(): string | undefined {
    const index = this.fileName?.lastIndexOf('.');
    return index ? `${this.fileName?.slice(0, index)}` : this.fileName;
  }

  get fileExtension(): string | undefined {
    const index = this.fileName?.lastIndexOf('.');
    return index ? this.fileName?.slice(index) : '';
  }

  private get fileName(): string | undefined {
    return (this.file || this.value.doc)?.name;
  }

  private _currentState: FileInputState = FileInputState.isEmpty;
  file: File | null = null;
  errorMessage: string = '';
  errorTimeout: any;
  anotherFileName: string = 'Additional document';

  documentTypes = AppDocumentType;
  getDocumentName = getDocumentName;

  constructor(
    private dialog: MatDialog,
    private ngControl: NgControl,
    private onboardingService: OnboardingService,
    private errorService: ErrorService
  ) {
    this.ngControl.valueAccessor = this;
  }

  ngAfterViewInit(): void {
    // selects input content when click 'Add another document' button
    if (!this.value.doc) {
      setTimeout(() => this.anotherFileNameInput?.nativeElement.select());
    }
  }

  dropped(files: NgxFileDropEntry[]) {
    this.markAsTouched();
    if (files[0] && files[0].fileEntry.isFile) {
      (files[0].fileEntry as FileSystemFileEntry).file((file: File) => {
        this.file = file;

        if (file.name.length > 50) {
          this.setError('File name is too long');
        } else if (file.size > 20000000) {
          this.setError('File is too big');
        } else {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('documentType', this.value.documentType.toString());
          if (this.value.documentType === AppDocumentType.ANOTHER_FILE) {
            formData.append('anotherFileName', this.anotherFileName);
          }
          if (this.value.doc?.id) {
            formData.append('documentId', this.value.doc?.id.toString());
          }
          if (this.clientId) {
            formData.append('clientId', this.clientId.toString());
          }
          if (this.userNoteId) {
            formData.append('userNoteId', this.userNoteId.toString());
          }

          this.currentState = FileInputState.isProcessing;

          this.saveDocument(formData).subscribe(
            (doc) => {
              this.file = null;
              this.value.doc = doc;
              this.currentState = FileInputState.isUploaded;
            },
            (err) => {
              const errorMessage =
                err.status === 400 ? 'Wrong file format' : 'Upload failed';
              this.setError(errorMessage);
              if (err.status === 403) {
                this.errorService.showErrorDialog(err.error.message);
              }
            }
          );
        }
      });
    }
  }

  removeFile(showReason = false) {
    this.markAsTouched();
    const previousState = this.currentState;
    this.currentState = FileInputState.isProcessing;

    this.clearDocument().subscribe(
      (doc) => {
        this.value.doc = doc;
        this.currentState = showReason
          ? FileInputState.isReason
          : FileInputState.isEmpty;
      },
      (error) => {
        this.currentState = previousState;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  clearError() {
    clearTimeout(this.errorTimeout);
    this.file = null;
    this.errorMessage = '';
    this.currentState = FileInputState.isEmpty;
  }

  setError(message: string) {
    this.currentState = FileInputState.isErroring;
    this.errorMessage = message;
    this.errorTimeout = setTimeout(() => this.clearError(), 5000);
  }

  updateFileName(fileName: string): void {
    this.anotherFileName = fileName;
    const documentId = this.value.doc?.id;

    // if there is no document file name will be saved when uploading file
    if (documentId) {
      this.currentState = FileInputState.isProcessing;
      this.updateDocument(documentId, fileName, '').subscribe(
        (doc) => {
          this.value.doc = doc;
          this.currentState = FileInputState.isUploaded;
        },
        (error) => {
          this.currentState = FileInputState.isUploaded;
          this.errorService.showErrorDialog(error.error.message);
        }
      );
    }
  }

  updateReason(reason: string) {
    if (reason) {
      this.currentState = FileInputState.isProcessing;
      const formData = new FormData();
      formData.append('documentType', this.value.documentType.toString());
      formData.append('reasonWhyNotProvided', reason);
      if (this.clientId) {
        formData.append('clientId', this.clientId.toString());
      }
      if (this.userNoteId) {
        formData.append('userNoteId', this.userNoteId.toString());
      }

      const documentId = this.value.doc?.id;
      const saveOrUpdateObs = documentId
        ? this.updateDocument(documentId, '', reason)
        : this.saveDocument(formData);

      saveOrUpdateObs.subscribe(
        (doc) => {
          this.value.doc = doc;
          this.currentState = FileInputState.isReason;
        },
        (error) => {
          this.currentState = FileInputState.isReason;
          this.errorService.showErrorDialog(error.error.message);
        }
      );
    } else {
      this.removeFile(true);
    }
  }

  toggleReasonCheckbox(reasonInput: MatInput): void {
    if (this.currentState === FileInputState.isReason) {
      reasonInput.ngControl.control?.markAsUntouched(); // clears validation for the reason input
      if (this.value.doc) {
        this.removeFile();
      } else {
        this.currentState = FileInputState.isEmpty;
      }
      this.canProvide.emit(true);
    } else {
      this.currentState = FileInputState.isReason;
      this.canProvide.emit(false);
      setTimeout(() => reasonInput.focus()); // focuses reason input
    }
  }

  openWholeConversation(): void {
    this.dialog.open<
      AdminConversationDialogNewComponent,
      AdminConversationDialog
    >(AdminConversationDialogNewComponent, {
      width: '700px',
      panelClass: 'dialog-with-close-button',
      data: {
        isAdmin: false,
        comments: this.value.doc?.comments,
        headerText:
          this.value.customLabel ||
          this.value.doc?.anotherFileName ||
          (this.showProofOfOperatingAddress &&
          this.value.documentType === AppDocumentType.PROOF_OF_ADDRESS
            ? 'Proof of Operating Address'
            : getDocumentName(this.value.documentType)),
      },
    });
  }

  private saveDocument(formData: FormData): Observable<AppDocument> {
    return this.companyDirectorId !== undefined
      ? this.onboardingService.uploadDirectorDocument(
          this.companyDirectorId,
          formData
        )
      : this.onboardingService.uploadDocument(formData);
  }

  private updateDocument(
    documentId: number,
    anotherFileName: string,
    reasonWhyNotProvided: string
  ): Observable<AppDocument> {
    return this.companyDirectorId !== undefined
      ? this.onboardingService.updateDirectorDocument(
          this.companyDirectorId,
          documentId,
          anotherFileName,
          reasonWhyNotProvided
        )
      : this.onboardingService.updateDocument(
          documentId,
          anotherFileName,
          reasonWhyNotProvided,
          this.clientId
        );
  }

  private clearDocument(): Observable<AppDocument | null> {
    return this.companyDirectorId !== undefined
      ? this.onboardingService.clearDirectorDocument(
          this.companyDirectorId,
          this.value.doc!.id
        )
      : this.onboardingService.clearDocument(this.value.doc!.id, this.clientId);
  }

  // Methods below are needed for ControlValueAccessor
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
  writeValue(val: FileInputValue) {
    if (val.doc?.anotherFileName) {
      this.anotherFileName = val.doc?.anotherFileName;
    }
    if (val.doc?.location) {
      this.currentState = FileInputState.isUploaded;
    } else if (val.doc?.reasonWhyNotUploaded) {
      this.currentState = FileInputState.isReason;
    }
    this.value = val;
    setTimeout(
      () => this.canProvide.emit(this.currentState !== FileInputState.isReason),
      0
    );
  }
}
