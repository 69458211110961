import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-expansion-panel-new',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
})
export class ExpansionPanelNewComponent {
  @Input() expanded: boolean = false;
  @Input() name: string = '';
  @Input() isComplete: boolean | undefined;
  generateCorrectDataTestId(name: string, suffix: string): string {
    const nameWithDashes =
      name
        .replace(/\(.*\)/, '')
        .toLowerCase()
        .split(' ')
        .join('-') + suffix;
    return nameWithDashes.split('’').join('').replace(/\-\-/, '-');
  }
}
