<div>
  <div
    fxLayout="row wrap"
    fxLayoutGap="25px grid"
    fxLayoutAlign="space-between"
  >
    <!-- when first time in EDD the documents did not reach admin to accept/reject them, so show them as viewOnly.
         For the other states, admin can only return the application if all the docs are either accepted/rejected,
         so documents don't need to be viewOnly, since their state (accepted/rejected) will be shown. -->
    <app-file-input
      *ngFor="let control of documentsControls; let i = index"
      [formControl]="control"
      [showProofOfOperatingAddress]="true"
      [viewOnly]="isFirstTimeEDD"
      fxFlex="50"
      (removeField)="removeField(i)"
    ></app-file-input>
  </div>

  <button
    *ngIf="isReviewed && !isFirstTimeEDD"
    mat-stroked-button
    color="accent"
    data-testid="add-another-file-button"
    class="add-file"
    (click)="addAnotherFile()"
  >
    Add another document
  </button>
</div>
