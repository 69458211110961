import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserState } from '../login/models/user-state.enum';
import { AuthService } from '../login/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserStateGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const allowedStates = route.data.allowedStates;

    return this.authService.getAuthenticatedUser().pipe(
      map((user) => {
        if (allowedStates.includes(user?.state)) {
          return true;
        } else {
          switch (
            user?.state // directs to onboarding from
          ) {
            case UserState.INITIATED_REGISTRATION:
              return this.router.parseUrl('email-sent');
            case UserState.WITHDRAWN: // Withdrawn users need to restart the registration process
              return this.router.parseUrl('email-sent');
            case UserState.VERIFIED_EMAIL:
              return this.router.parseUrl('connect-phone');
            case UserState.VERIFIED_MOBILE_PHONE_NUMBER:
              return this.router.parseUrl('onboarding-new');
            case UserState.ADDITIONAL_INFORMATION_REQUESTED:
              return this.router.parseUrl('onboarding-new');
            case UserState.DECISION_POINT: // allow user to see onboarding form even at decision point
            case UserState.KEY_PERSONS_VERIFICATION:
            case UserState.KEY_PERSONS_DECISION_POINT:
            case UserState.BUSINESS_CDD_REVIEW:
            case UserState.EDD:
            case UserState.EDD_SUBMITTED:
              return this.router.parseUrl('onboarding-new');
            case UserState.KYC_VERIFIED:
              return this.router.parseUrl('dashboard');
            case UserState.DEPOSIT_ONLY:
              return this.router.parseUrl('dashboard');
            case UserState.TEMPORARY_LOGIN_BLOCK:
              return this.router.parseUrl('login'); // TO DO: to change
            case UserState.CLOSED:
              return this.router.parseUrl('login'); // TO DO: to change
            case UserState.ACCOUNT_REVIEW_STATUS:
              return this.router.parseUrl('login'); // TO DO: to change
            default:
              return this.router.parseUrl('login');
          }
        }
      })
    );
  }
}
