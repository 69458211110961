<div class="file-label">
  <label>{{ fileLabel }}</label>
</div>
<ng-container class="desc" [ngSwitch]="currentState">
  <div *ngSwitchCase="fileInputStates.isEmpty" class="desc">
    No file (.pdf, .png or .jpg. Up to 20MB)
  </div>
  <div *ngSwitchCase="fileInputStates.isErroring" class="desc error">
    {{ errorMessage }}
  </div>
  <div *ngSwitchCase="fileInputStates.isUploaded" class="desc">Uploaded</div>
</ng-container>
<div *ngIf="currentState === fileInputStates.isEmpty">
  <ngx-file-drop
    dropZoneLabel="Drop the document here or"
    accept=".jpg,.jpeg,.png,.pdf,.heic,.tif,.tiff"
    [showBrowseBtn]="true"
    [multiple]="false"
    [directory]="false"
    browseBtnLabel="browse"
    dropZoneClassName="drop-zone"
    contentClassName="content"
    browseBtnClassName="browse-button"
    (onFileDrop)="dropped($event)"
  >
  </ngx-file-drop>
</div>
<div
  *ngIf="currentState !== fileInputStates.isEmpty"
  fxLayout
  fxLayoutAlign="start center"
  class="file-container"
>
  <!-- left icon -->
  <ng-container [ngSwitch]="currentState">
    <mat-icon
      *ngSwitchCase="fileInputStates.isErroring"
      svgIcon="cancel"
      class="left-icon error"
    ></mat-icon>
    <mat-icon
      *ngSwitchCase="fileInputStates.isUploaded"
      svgIcon="checked"
      class="left-icon checked"
    ></mat-icon>
  </ng-container>
  <div fxFlex class="file-name">{{ file?.name }}</div>
  <button
    *ngIf="currentState !== fileInputStates.isProcessing"
    mat-icon-button
    (click)="removeFile()"
  >
    <mat-icon svgIcon="trash" color="accent"></mat-icon>
  </button>
</div>
