<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close [disabled]="isContinuing">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content fxLayout="column">
  <h1>Top up with a DEBIT card</h1>
  <app-big-loader *ngIf="isLoading"></app-big-loader>
  <ng-container *ngIf="!isLoading">
    <div class="text">Please fill in the form and follow the instructions.</div>
    <div class="form-container" [formGroup]="topUpForm">
      <div fxLayout="column" class="middle">
        <label>Amount</label>
        <div fxLayout fxLayoutAlign="start center" fxLayoutGap="12px">
          <mat-form-field>
            <input matInput formControlName="amount" type="number" min="0" />
            <div matSuffix>EUR</div>
            <mat-error *ngIf="amountControl.hasError('required')"
              >Field is required</mat-error
            >
            <mat-error *ngIf="amountControl.hasError('pattern')"
              >Use format: 0.00</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="column">
        <label>First name</label>
        <mat-form-field>
          <input matInput formControlName="firstName" />
          <mat-error *ngIf="firstNameControl.hasError('required')"
            >Field is required</mat-error
          >
          <mat-error *ngIf="firstNameControl.hasError('pattern')"
            >Wrong format</mat-error
          >
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <label>Last name</label>
        <mat-form-field>
          <input matInput formControlName="lastName" />
          <mat-error *ngIf="lastNameControl.hasError('required')"
            >Field is required</mat-error
          >
          <mat-error *ngIf="lastNameControl.hasError('pattern')"
            >Wrong format</mat-error
          >
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <label>Address (Street, building number, place)</label>
        <mat-form-field>
          <input matInput formControlName="billAddress1" />
          <mat-error *ngIf="billAddress1Control.hasError('required')"
            >Field is required</mat-error
          >
          <mat-error *ngIf="billAddress1Control.hasError('pattern')"
            >Wrong format</mat-error
          >
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <label>Address 2nd line (optional)</label>
        <mat-form-field>
          <input matInput formControlName="billAddress2" />
          <mat-error *ngIf="billAddress2Control.hasError('pattern')"
            >Wrong format</mat-error
          >
        </mat-form-field>
      </div>
      <div
        fxLayout
        fxLayoutAlign="space-between"
        fxLayoutGap="35px"
        fxLayoutGap.xs="0"
        fxLayout.xs="column"
      >
        <div fxLayout="column" class="zip">
          <label>Zip/Postal code</label>
          <mat-form-field>
            <input matInput formControlName="billZipCode" />
            <mat-error *ngIf="billZipCodeControl.hasError('required')"
              >Field is required</mat-error
            >
            <mat-error *ngIf="billZipCodeControl.hasError('pattern')"
              >Wrong format</mat-error
            >
          </mat-form-field>
        </div>
        <div fxLayout="column" class="city">
          <label>City</label>
          <mat-form-field>
            <input matInput formControlName="billCity" />
            <mat-error *ngIf="billCityControl.hasError('required')"
              >Field is required</mat-error
            >
            <mat-error *ngIf="billCityControl.hasError('pattern')"
              >Wrong format</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="column" class="middle">
        <label>Country</label>
        <mat-form-field>
          <mat-select formControlName="billCountryCode">
            <mat-option
              *ngFor="let country of countries"
              [value]="country.id"
              >{{ country.name | titlecase }}</mat-option
            >
          </mat-select>
          <mat-error *ngIf="billCountryCodeControl.hasError('required')"
            >Field is required</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <button
      mat-flat-button
      color="accent"
      fxFlexAlign="end"
      fxFlexAlign.xs="center"
      [disabled]="!topUpForm.valid || isContinuing"
      [appButtonLoader]="isContinuing"
      (click)="continueToPayment()"
    >
      Continue to payment
    </button>
  </ng-container>
</mat-dialog-content>
