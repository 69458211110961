<mat-expansion-panel
  hideToggle
  #panel="matExpansionPanel"
  [expanded]="expanded"
>
  <mat-expansion-panel-header
    [attr.data-testid]="generateCorrectDataTestId(name, '-accordion')"
    collapsedHeight="60px"
    expandedHeight="60px"
  >
    <mat-panel-title fxLayoutAlign="space-between center">
      <div class="title">
        {{ name }}
      </div>
      <div
        [ngSwitch]="isComplete"
        class="status"
        [attr.data-testid]="
          generateCorrectDataTestId(name, '-accordion-status')
        "
        [class.complete]="isComplete"
      >
        <ng-container *ngSwitchCase="true">Complete</ng-container>
        <ng-container *ngSwitchCase="false">Incomplete</ng-container>
      </div>
    </mat-panel-title>
    <mat-panel-description>
      <mat-icon
        [svgIcon]="panel?.expanded ? 'chevron-expanded' : 'chevron-contracted'"
      ></mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <ng-content></ng-content>
</mat-expansion-panel>
