import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ErrorDialogComponent,
  ErrorDialogData,
} from './error-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private dialog: MatDialog) {}

  showErrorDialog(message?: string): void {
    this.dialog.open<ErrorDialogComponent, ErrorDialogData>(
      ErrorDialogComponent,
      {
        maxWidth: '720px',
        panelClass: 'dialog-with-close-button',
        disableClose: true,
        data: {
          message,
        },
      }
    );
  }
}
