import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss'],
})
export class MainDashboardComponent {
  constructor(private router: Router) {}

  get isDashboardActive(): boolean {
    return (
      this.router.isActive('dashboard', {
        paths: 'subset',
        queryParams: 'ignored',
        fragment: 'ignored',
        matrixParams: 'ignored',
      }) ||
      this.router.isActive('sub-dashboard', {
        paths: 'subset',
        queryParams: 'ignored',
        fragment: 'ignored',
        matrixParams: 'ignored',
      })
    );
  }
}
