import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Page } from '../users/models/page.model';
import { UserLogItem } from '../users/registered-users/registered-user/registered-user-logs/models/user-log-item.model';
import { UserLogSearch } from '../users/registered-users/registered-user/registered-user-logs/models/user-log-search.model';
import { ExpiringDocumentItem } from './models/expiring-document-item.model';
import { ExpiringDocumentSearch } from './models/expiring-document-search.model';
import { UnpaidFeeSearch } from './models/unpaid-fee-search.model';

@Injectable({
  providedIn: 'root',
})
export class ReportsService { 
  baseUrl = `${environment.BACKEND_URL}/admin-reports`;

  constructor(private http: HttpClient) {}

  // TO DO: implement when backend ready
  getUnpaidFees(data: UnpaidFeeSearch): Observable<Page<any>> {
    const url = this.baseUrl;

    let params = new HttpParams();
    if (data.page) {
      params = params.set('page', data.page);
    }
    if (data.limit) {
      params = params.set('limit', data.limit);
    }
    if (data.orderField) {
      params = params.set('orderField', data.orderField);
    }
    if (data.orderDescending) {
      params = params.set('orderDescending', data.orderDescending);
    }
    if (data.searchText) {
      params = params.set('searchText', data.searchText);
    }
    if (data.dateFrom) {
      params = params.set('dateFrom', data.dateFrom);
    }
    if (data.dateTo) {
      params = params.set('dateTo', data.dateTo);
    }
    if (data.userType) {
      params = params.set('userType', data.userType);
    }
    // return this.http.get<Page<any>>(url, { params });
    return of({ items: [], totalCount: 0 });
  }

  setHttpParams(data: ExpiringDocumentSearch): HttpParams {
    let params = new HttpParams();
    if (data.page) {
      params = params.set('page', data.page);
    }
    if (data.limit) {
      params = params.set('limit', data.limit);
    }
    if (data.orderField) {
      params = params.set('orderField', data.orderField);
    }
    if (data.orderDescending) {
      params = params.set('orderDescending', data.orderDescending);
    }
    if (data.searchText) {
      params = params.set('searchText', data.searchText);
    }
    if (data.dateFrom) {
      params = params.set('dateFrom', data.dateFrom);
    }
    if (data.dateTo) {
      params = params.set('dateTo', data.dateTo);
    }
    if (data.userType) {
      params = params.set('userType', data.userType);
    }
    return params;
  }

  getExpiringDocuments(data: ExpiringDocumentSearch): Observable<Page<any>> {
    const url = `${this.baseUrl}/expiring-documents`;
    const params = this.setHttpParams(data);
    return this.http.get<Page<ExpiringDocumentItem>>(url, { params });
  }

  getExpiringDocumentsCsv(data: ExpiringDocumentSearch): Observable<string> {
    const url = `${this.baseUrl}/expiring-documents.csv`;
    const params = this.setHttpParams(data);
    return this.http.get(url, { params, responseType: 'text' });
  }

  setHttpParamsIpLogin(data: UserLogSearch): HttpParams {
    let params = new HttpParams();
    // do not set userId
    if (data.page) {
      params = params.set('page', data.page);
    }
    if (data.limit) {
      params = params.set('limit', data.limit);
    }
    if (data.orderField) {
      params = params.set('orderField', data.orderField);
    }
    if (data.orderDescending) {
      params = params.set('orderDescending', data.orderDescending);
    }
    if (data.searchText) {
      params = params.set('searchText', data.searchText);
    }
    if (data.dateFrom) {
      params = params.set('dateFrom', data.dateFrom);
    }
    if (data.dateTo) {
      params = params.set('dateTo', data.dateTo);
    }
    return params;
  }

  getUserIpLogin(data: UserLogSearch): Observable<Page<UserLogItem>> {
    const url = `${this.baseUrl}/user-ip-login`;
    const params = this.setHttpParamsIpLogin(data);
    return this.http.get<Page<UserLogItem>>(url, { params });
  }

  getUserIpLoginCsv(data: UserLogSearch): Observable<string> {
    const url = `${this.baseUrl}/user-ip-login.csv`;
    const params = this.setHttpParamsIpLogin(data);
    return this.http.get(url, { params, responseType: 'text' });
  }
}
