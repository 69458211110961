import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthenticatedUser } from 'src/app/login/models/authenticated-user.model';
import { Program } from 'src/app/login/models/program.enum';
import { AuthService } from 'src/app/login/services/auth.service';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { convertUserState } from 'src/app/shared/helpers/various-helpers.helper';
import { UserNote } from '../../models/user-note.model';
import { UsersService } from '../../users.service';
import { AdminNoteDialogData } from './admin-note-dialog-data.model';

@Component({
  templateUrl: './admin-note-dialog.component.html',
  styleUrls: ['./admin-note-dialog.component.scss'],
})
export class AdminNoteDialogComponent implements OnInit {
  programs = Program;
  convertUserState = convertUserState;

  isLoading: boolean = false;
  isSaving: boolean = false;
  authenticatedUser: AuthenticatedUser | undefined | null;
  currentNote: string = '';
  notes: UserNote[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AdminNoteDialogData,
    private authSevice: AuthService,
    private errorService: ErrorService,
    private usersService: UsersService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    forkJoin([
      this.authSevice.getAuthenticatedUserObs().pipe(first()),
      this.usersService.getNotes(this.data.id),
    ]).subscribe(
      ([authenticatedUser, notes]) => {
        this.authenticatedUser = authenticatedUser;
        this.notes = notes;
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  saveNote(): void {
    this.isSaving = true;
    this.usersService.saveNote(this.data.id, this.currentNote).subscribe(
      (note) => {
        this.currentNote = '';
        this.isSaving = false;
        this.notes.push(note);
      },
      (error) => {
        this.isSaving = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }
}
