import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { interval, Observable, Subscription } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { TransferService } from 'src/app/transfer/transfer.service';

export interface BuyCurrencyDialogData {
  fromCurr: string;
  toCurr: string;
  amount: number;
}

@Component({
  templateUrl: './buy-currency-dialog.component.html',
  styleUrls: ['./buy-currency-dialog.component.scss'],
})
export class BuyCurrencyDialogComponent implements OnInit {
  isLoading: boolean = false;
  isUpdating: boolean = false;
  isBuying: boolean = false;
  isSuccess: boolean = false;

  fromCurr: string;
  toCurr: string;
  amount: number;
  amountAfterConversion?: number;
  rate?: number;

  counter?: Observable<number>;
  counterSub?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BuyCurrencyDialogData,
    private dialogRef: MatDialogRef<BuyCurrencyDialogComponent, boolean>, // returns true if should update wallet balances
    private errorService: ErrorService,
    private transferService: TransferService
  ) {
    this.fromCurr = data.fromCurr;
    this.toCurr = data.toCurr;
    this.amount = data.amount;
  }

  ngOnInit(): void {
    this.getRates(false);
  }

  buy(): void {
    this.isBuying = true;
    this.counterSub?.unsubscribe();
    this.transferService
      .getFXBuy(this.fromCurr, this.toCurr, this.amount)
      .subscribe(
        () => {
          this.isBuying = false;
          this.isSuccess = true;
        },
        (err) => {
          this.errorService.showErrorDialog(err.error.message);
          this.isBuying = false;
          this.dialogRef.close(this.isSuccess);
        }
      );
  }

  private getRates(isUpdate: boolean): void {
    isUpdate ? (this.isUpdating = true) : (this.isLoading = true);
    this.transferService
      .getFXQuote(this.fromCurr, this.toCurr, this.amount)
      .pipe(
        map(({ amount, rate }) => ({
          amountAfterConversion: parseFloat(amount),
          rate: parseFloat(rate),
        }))
      )
      .subscribe(
        ({ amountAfterConversion, rate }) => {
          this.amountAfterConversion = amountAfterConversion;
          this.rate = rate;
          this.startCounter();
        },
        () => this.errorService.showErrorDialog(),
        () => {
          isUpdate ? (this.isUpdating = false) : (this.isLoading = false);
        }
      );
  }

  private startCounter(): void {
    this.counterSub?.unsubscribe();
    this.counter = interval(1000).pipe(
      take(30),
      startWith(-1),
      map((val) => 29 - val)
    );

    this.counterSub = this.counter.subscribe({
      complete: () => this.getRates(true),
    });
  }
}
