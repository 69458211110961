<div fxLayout="column" class="main">
  <mat-form-field>
    <input
      matInput
      [formControl]="searchControl"
      placeholder="Search beneficiary"
    />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
  <div *ngIf="isLoading" fxLayoutAlign="center">
    <app-small-loader></app-small-loader>
  </div>

  <ng-container *ngIf="!isLoading">
    <div
      *ngFor="let beneficiary of beneficiaries"
      fxLayoutAlign="space-between"
      class="beneficiary"
      (click)="selectBeneficiary(beneficiary)"
    >
      <div>
        <div>
          <div class="transaction-name">
            {{ beneficiary.name }}
          </div>
          <div class="transaction-info">
            {{ beneficiary.accountNo }}
          </div>
          <div class="transaction-info">
            {{ beneficiary.bic }}
          </div>
          <div class="transaction-info">
            {{ beneficiary.bank }}
          </div>
        </div>
        <div class="transaction-info" [ngSwitch]="beneficiary.transferType">
          <ng-container *ngSwitchCase="TransferType.SEPA"
            >SEPA Transfer</ng-container
          >
          <ng-container *ngSwitchCase="TransferType.SWIFT"
            >Swift Transfer</ng-container
          >
        </div>
      </div>
    </div>
  </ng-container>
</div>
