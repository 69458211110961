<app-base-login-page title="You are Verified!">
  <app-login-card imgName="person.svg">
    <app-big-loader *ngIf="isLoading"></app-big-loader>
    <div *ngIf="!isLoading" fxLayout="column" fxLayoutAlign="start center">
      <h2>Excellent</h2>
      <div class="text">
        Final step to gain access to your account: Issuance and delivery of your
        eCREDO Mastercard requires a
        <strong>one-time payment of €{{ feeValue | amount }} </strong>. Please
        proceed with the order of your card using a valid Debit card.
      </div>
      <button
        mat-flat-button
        color="accent"
        [appButtonLoader]="isPaying"
        [disabled]="isPaying"
        (click)="startPayment()"
      >
        Continue
      </button>
    </div>
  </app-login-card>
</app-base-login-page>
