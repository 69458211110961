import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { MatSelectChange } from '@angular/material/select';
import { RegisteredUsersService } from '../../../../registered-users.service';
import { Subject } from 'rxjs';
import { AppDocumentType } from '../../../../../../../onboarding-new/models/document-type.enum';
import { selectDocumentNamesMap } from '../../../shared/selectDocumentNamesMap';
import { IdentityDocumentNamesEnum } from '../../../shared/identityDocumentNames.enum';
import { ErrorService } from '../../../../../../../shared/error-dialog/error.service';
import { ReviewCycle } from './review-cycle.enum';
import { CompanyDirector } from 'src/app/onboarding-new/models/company-director.model';

export enum ProofOfAddressDocumentNameEnum {
  PROOF_OF_ADDRESS = 'Proof of Address',
}

@Component({
  selector: 'app-all-documents-form',
  templateUrl: './all-documents-form.component.html',
  styleUrls: ['./all-documents-form.component.scss'],
})
export class AllDocumentsFormComponent {
  @Input() displayedDocumentOptions!: string[];
  @Input() userId!: number;
  @Input() companyDirectors?: CompanyDirector[];
  @Input() isCorporateUser?: boolean;
  @Output() refreshTable = new EventEmitter<AppDocumentType>();

  documentTypeForm: FormGroup = this.fb.group({
    documentType: ['', Validators.required],
  });

  ReviewCycle = ReviewCycle;
  uploadedFile: File | null = null;
  secondPageFile: File | null = null;
  clearFile: Subject<void> = new Subject<void>();
  fileForm: FormGroup = this.fb.group({});
  isLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private registeredUsersService: RegisteredUsersService,
    private errorService: ErrorService
  ) {}

  isIdentityDocument(documentType: string): boolean {
    switch (documentType) {
      case IdentityDocumentNamesEnum.ID_CARD:
      case IdentityDocumentNamesEnum.PASSPORT:
        return true;
      default:
        return false;
    }
  }

  isProofOfAddress(documentType: string): boolean {
    return documentType === ProofOfAddressDocumentNameEnum.PROOF_OF_ADDRESS;
  }

  isSecondPage(documentType: string): boolean {
    switch (documentType) {
      case IdentityDocumentNamesEnum.ID_CARD:
        return true;
      default:
        return false;
    }
  }

  isButtonDisabled(): boolean {
    return (
      !this.fileForm.valid ||
      this.uploadedFile === null ||
      (this.isSecondPage(this.documentTypeControl.value) &&
        this.secondPageFile === null) ||
      this.isLoading
    );
  }

  saveDocumentsForm(): void {
    this.isLoading = true;

    const formData = new FormData();

    const type = this.getSelectMapKey(this.documentTypeControl.value);

    if (
      Array.from(selectDocumentNamesMap.values()).includes(
        this.documentTypeControl.value
      )
    ) {
      formData.append('type', type.toString());
    } else {
      formData.append('type', AppDocumentType.ANOTHER_FILE.toString());
      formData.append('anotherFileName', this.documentTypeControl.value);
    }
    if (this.uploadedFile) {
      formData.append('file', this.uploadedFile);
    }
    if (this.secondPageFile) {
      formData.append('secondPageFile', this.secondPageFile);
    }
    if (this.firstNameControl.value) {
      formData.append('firstName', this.firstNameControl.value);
    }
    if (this.lastNameControl.value) {
      formData.append('lastName', this.lastNameControl.value);
    }
    if (this.companyDirectorControl.value) {
      const companyDirector = this.companyDirectors?.find(
        (director) => director.id === this.companyDirectorControl.value
      );
      if (companyDirector) {
        formData.append('firstName', companyDirector?.firstName);
        formData.append('lastName', companyDirector?.lastName);
        formData.append('companyDirectorId', companyDirector?.id.toString());
      }
    }
    if (this.expiryDateControl.value) {
      if (this.expiryDateControl.value in ReviewCycle) {
        const today: Date = new Date(Date.now());
        var expiryDate = new Date(
          today.getFullYear(),
          today.getMonth() + this.expiryDateControl.value,
          today.getDate(),
          0,
          0,
          0
        );
        formData.append('expiryDate', expiryDate.toString());
      } else {
        formData.append('expiryDate', this.expiryDateControl.value);
      }
    }
    this.registeredUsersService.uploadDocument(this.userId, formData).subscribe(
      () => {
        this.isLoading = false;
        this.clearFile.next();
        this.refreshTable.emit(type);
        this.uploadedFile = null;
        this.secondPageFile = null;
        this.fileForm.reset();
      },
      (err) => {
        this.errorService.showErrorDialog(err.error?.message);
        this.isLoading = false;
        this.clearFile.next();
        this.uploadedFile = null;
        this.secondPageFile = null;
        this.fileForm.reset();
      }
    );
  }

  onUploadedFile(file: File | null): void {
    this.uploadedFile = file;
  }

  onUploadedSecondFile(file: File | null): void {
    this.secondPageFile = file;
  }

  changeDocumentType(event: MatSelectChange): void {
    this.fileForm = this.fb.group({
      firstName: [
        '',
        this.isIdentityDocument(event.source.value) && !this.isCorporateUser
          ? Validators.required
          : null,
      ],
      lastName: [
        '',
        this.isIdentityDocument(event.source.value) && !this.isCorporateUser
          ? Validators.required
          : null,
      ],
      expiryDate: ['', Validators.required],
      companyDirector: [
        '',
        (this.isIdentityDocument(event.source.value) ||
          this.isProofOfAddress(event.source.value)) &&
        this.isCorporateUser
          ? Validators.required
          : null,
      ],
    });

    //Clear files on select change
    this.clearFile.next();
  }

  get documentTypeControl(): FormControl {
    return this.documentTypeForm.get('documentType') as FormControl;
  }

  get firstNameControl(): FormControl {
    return this.fileForm.get('firstName') as FormControl;
  }

  get lastNameControl(): FormControl {
    return this.fileForm.get('lastName') as FormControl;
  }

  get companyDirectorControl(): FormControl {
    return this.fileForm.get('companyDirector') as FormControl;
  }

  get expiryDateControl(): FormControl {
    return this.fileForm.get('expiryDate') as FormControl;
  }

  canDeactivate(): boolean {
    if (
      this.firstNameControl &&
      this.lastNameControl &&
      this.expiryDateControl &&
      this.companyDirectorControl
    ) {
      return (
        (this.firstNameControl.value === '' ||
          this.firstNameControl.value === null) &&
        (this.lastNameControl.value === '' ||
          this.lastNameControl.value === null) &&
        (this.expiryDateControl.value === '' ||
          this.expiryDateControl.value === null) &&
        (this.companyDirectorControl.value === '' ||
          this.companyDirectorControl.value === null) &&
        this.uploadedFile === null &&
        this.secondPageFile === null
      );
    } else {
      return true;
    }
  }

  private getSelectMapKey(value: string) {
    const mapKey = [...selectDocumentNamesMap].find(
      ([_, val]) => value === val
    );

    return mapKey ? mapKey[0] : AppDocumentType.ANOTHER_FILE;
  }
}
