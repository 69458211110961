import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CountryISO } from 'ngx-intl-tel-input';
import { Subscription } from 'rxjs';
import { first, skip } from 'rxjs/operators';
import { OnboardingFieldId } from 'src/app/admin/users/models/onboarding-field-id.enum';
import { Country } from 'src/app/shared/models/country.model';
import { AuthService } from 'src/app/login/services/auth.service';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import {
  getCountryById,
  getStringValue,
} from 'src/app/shared/helpers/various-helpers.helper';
import { phoneValidator } from 'src/app/shared/phone.validator';
import { CorporateForm } from '../../models/corporate-form.model';
import { OnboardingData } from '../../models/onboarding-data.model';
import { OnboardingService } from '../../onboarding.service';
import { Occupation } from '../../models/occupation.enum';
import { CashPercentage } from '../../models/cash-percentage.enum';
import { LegalStatus } from '../../models/legal-status.enum';
import { DateTime } from 'luxon';
import { AccountPurposeBusiness } from '../../models/account-purpose-business.enum';
import { RegistrationService } from 'src/app/login/services/registration.service';

@Component({
  selector: 'app-fill-onboarding-business-form-new',
  templateUrl: './fill-onboarding-business-form.component.html',
  styleUrls: ['./fill-onboarding-business-form.component.scss'],
})
export class FillOnboardingBusinessFormNewComponent
  implements OnInit, OnDestroy
{
  @Input() countries: Country[] = [];
  @Input() set onboardingData(val: OnboardingData | undefined) {
    // fieldsMap
    this.fieldsMap.clear();
    val?.fields
      .filter((field) => !!field.comment)
      .forEach((field) => {
        let commentedValue = field.commentedValue;
        if (field.id.endsWith('.countryId')) {
          // converts countryId to country name
          commentedValue = this.getCountryById(
            this.countries,
            parseInt(commentedValue)
          );
        }
        // converts date
        if (field.id.endsWith('.incorporationDate')) {
          commentedValue = DateTime.fromISO(commentedValue).toLocaleString(
            DateTime.DATE_MED
          );
        }
        // convert boolean
        if (commentedValue === 'true') {
          commentedValue = 'Yes';
        } else if (commentedValue === 'false') {
          commentedValue = 'No';
        }
        // convert annual income and monthly loading
        if (
          field.id.endsWith('.annualIncome') ||
          field.id.endsWith('.monthlyLoading')
        ) {
          commentedValue += ' EUR';
        }
        this.fieldsMap.set(field.id, {
          comment: field.comment,
          commentedValue,
        });
      });

    const isSameAddress =
      JSON.stringify(val?.corporateForm?.incorporationAddress) ===
      JSON.stringify(val?.corporateForm?.operatingAddress);

    const formValue = {
      ...val?.corporateForm,
      annualIncome: val?.corporateForm?.annualIncome
        ? parseInt(val?.corporateForm?.annualIncome).toLocaleString('en-US')
        : null,
      monthlyLoading: val?.corporateForm?.monthlyLoading
        ? parseInt(val?.corporateForm?.monthlyLoading).toLocaleString('en-US')
        : null,
      incorporationDate: val?.corporateForm?.incorporationDate
        ? DateTime.fromISO(val.corporateForm?.incorporationDate)
        : null,
      isSameAddress,
      // might be empty if has comment by admin
      accountPurpose: val?.corporateForm?.accountPurpose
        ? val?.corporateForm?.accountPurpose.split(', ')
        : '',
    };
    this.corporateForm.patchValue(formValue);
    if (isSameAddress) {
      this.bindAddresses(); // connects operating address to incorporation address
    }
    // if legal status is not one of the options, set it to Other with its description
    if (
      val?.corporateForm?.legalStatus &&
      val?.corporateForm?.legalStatus !==
        LegalStatus['Private Limited Company'] &&
      val?.corporateForm?.legalStatus !== LegalStatus['Public Limited Company']
    ) {
      this.corporateForm
        .get('legalStatus')
        ?.setValue(LegalStatus['Other type of Company']);
      this.corporateForm
        .get('otherLegalStatus')
        ?.setValue(val?.corporateForm?.legalStatus);
    }
    if (this.corporateForm.valid) {
      setTimeout(() => this.isComplete.emit(true));
    }
    this._onboardingData = val;
  }
  get onboardingData(): OnboardingData | undefined {
    return this._onboardingData;
  }

  @Input() acceptedCountries: Country[] = [];
  @Input() isReviewed: boolean = false;
  @Input() isFirstTimeEDD: boolean = false;
  @Input() verificationAccepted: boolean = false;
  @Output() isComplete = new EventEmitter<boolean>();

  OnboardingFieldId = OnboardingFieldId;
  getCountryById = getCountryById;
  getStringValue = getStringValue;
  occupations = Object.entries(Occupation);
  cashPercentages = Object.entries(CashPercentage);
  legalStatuses = Object.entries(LegalStatus);
  accountPurposes = Object.entries(AccountPurposeBusiness);
  LegalStatus = LegalStatus;
  today: Date = new Date();
  highRiskCountries: string[] = [];

  corporateForm: FormGroup = this.fb.group({
    businessName: [
      '',
      [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
        Validators.maxLength(100),
      ],
    ],
    businessTradingName: [
      '',
      [
        Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
        Validators.maxLength(100),
      ],
    ],
    taxNumber: [
      '',
      [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
        Validators.maxLength(50),
      ],
    ],
    vatNumber: [
      '',
      [
        Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
        Validators.maxLength(50),
      ],
    ],
    companyUrls: [
      '',
      [
        Validators.pattern(
          "^[a-zA-Z0-9 ~!@#$%&*\\(\\)\\-\\+=[\\]\\/:;',.?_]*$"
        ),
        Validators.maxLength(200),
      ],
    ],
    companyActivity: [null, Validators.required],
    companyActivityDescr: [
      '',
      [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
        Validators.maxLength(1000),
      ],
    ],
    isRegulated: ['', Validators.required],
    regulatoryAuthority: [
      '',
      [
        Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
        Validators.maxLength(200),
      ],
    ],
    canFundFromOwnAccount: ['', Validators.required],
    annualIncome: [
      '',
      [
        Validators.required,
        Validators.maxLength(19),
        Validators.pattern('^(?!.*,,)[0-9]+(?:,[0-9]+)*$'), // prohibits comma at the start/end and consecutive commas
      ],
    ],
    accountPurpose: ['', [Validators.required]],
    monthlyLoading: [
      '',
      [
        Validators.required,
        Validators.maxLength(19),
        Validators.pattern('^(?!.*,,)[0-9]+(?:,[0-9]+)*$'), // prohibits comma at the start/end and consecutive commas
      ],
    ],
    percentageOfCash: [null, Validators.required],
    doubleAnnualIncome: ['', Validators.required],
    zeroBalanceStatement: ['', Validators.required],
    legalStatus: ['', Validators.required],
    otherLegalStatus: [
      '',
      [
        Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
        Validators.maxLength(100),
      ],
    ],
    operateInHighRiskCountries: ['', Validators.required],
    isFinancialStatementAudited: ['', Validators.required],
    expectedNumOfPhysicalCards: ['', Validators.required],
    incorporationDate: [null, Validators.required],
    registrationNumber: [
      '',
      [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
        Validators.maxLength(50),
      ],
    ],
    additionalPhoneNumber: [null, phoneValidator()],
    incorporationAddress: this.fb.group({
      streetAddress: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 .,&`'\\/()-]*$"),
          Validators.maxLength(100),
        ],
      ],
      additionalStreetAddress: [
        '',
        [
          Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
          Validators.maxLength(100),
        ],
      ],
      postCode: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9 ()\\/-]*$'),
          Validators.maxLength(50),
        ],
      ],
      city: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 `'\\/()-]*$"),
          Validators.maxLength(50),
        ],
      ],
      countryId: ['', Validators.required],
    }),
    operatingAddress: this.fb.group({
      streetAddress: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 .,&`'\\/()-]*$"),
          Validators.maxLength(100),
        ],
      ],
      additionalStreetAddress: [
        '',
        [
          Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
          Validators.maxLength(100),
        ],
      ],
      postCode: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9 ()\\/-]*$'),
          Validators.maxLength(50),
        ],
      ],
      city: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 `'\\/()-]*$"),
          Validators.maxLength(50),
        ],
      ],
      countryId: ['', Validators.required],
    }),
    isSameAddress: [null, Validators.required],
  });

  countryCode: CountryISO = CountryISO.Cyprus;
  registeredAddressTooltip: string =
    "The registered address is the official address of the company, registered with the Company's house.";
  operatingAddressTooltip: string =
    'The operating address is where the company has existence of a place of business or activity (registered branch).';
  isSaving: boolean = false;
  fieldsMap: Map<
    string,
    { comment: string | null; commentedValue: string | null } | null
  > = new Map<
    string,
    { comment: string | null; commentedValue: string | null } | null
  >();
  private _onboardingData: OnboardingData | undefined = undefined;
  private corporateFormSub: Subscription | undefined = undefined;
  private incorporationAddressGroupSub: Subscription | undefined = undefined;
  private sameAddressControlSub: Subscription | undefined = undefined;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private errorService: ErrorService,
    private onboardingService: OnboardingService,
    private registrationService: RegistrationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.verificationAccepted) {
      this.verificationAccepted = changes.verificationAccepted.currentValue;
      if (this.verificationAccepted) {
        this.disableFormFields();
        // after this the form becomes incomplete - the following solves it
        if (this.corporateForm.valid) {
          setTimeout(() => this.isComplete.emit(true));
        }
      }
    }
  }

  ngOnInit(): void {
    this.authService
      .getAuthenticatedUserObs()
      .pipe(first())
      .subscribe((user) => {
        // set country code only if exists in ngx-intl-tel-input
        if (
          user?.country &&
          Object.values(CountryISO).includes(
            user?.country.code.toLowerCase() as CountryISO
          )
        ) {
          this.countryCode = user?.country.code as CountryISO;
        }
      });

    this.registrationService.getHighRiskCountries().subscribe((countries) => {
      this.highRiskCountries = countries.map((c) =>
        getCountryById(countries, c.id)
      );
    });

    // conditional validators
    this.isRegulatedControl?.valueChanges.subscribe((val) => {
      if (val) {
        this.corporateForm
          .get('regulatoryAuthority')
          ?.setValidators([
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
            Validators.maxLength(200),
          ]);
      } else {
        this.corporateForm.get('regulatoryAuthority')?.clearValidators();
        this.corporateForm.get('regulatoryAuthority')?.setValue(null);
      }
      this.corporateForm.get('regulatoryAuthority')?.updateValueAndValidity();
    });

    this.legalStatusControl?.valueChanges.subscribe((val) => {
      if (val === LegalStatus['Other type of Company']) {
        this.corporateForm
          .get('otherLegalStatus')
          ?.setValidators([
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
            Validators.maxLength(100),
          ]);
      } else {
        this.corporateForm.get('otherLegalStatus')?.clearValidators();
      }
      this.corporateForm.get('otherLegalStatus')?.updateValueAndValidity();
    });

    if (this.verificationAccepted) {
      this.disableFormFields();
    }

    /* It skips 2 changes because of additionalPhoneNumber
      (library emits change event when setting value programatically, and we set it 2 times:
      - @Input set onboardingData
      - below in valueChanges sub)
    */
    this.corporateFormSub = this.corporateForm.valueChanges
      .pipe(skip(2))
      .subscribe(() => this.isComplete.emit(false));

    // removes redundant prefix from phone number when set from backend
    this.additionalPhoneNumberControl?.valueChanges
      .pipe(first())
      .subscribe((val) =>
        this.additionalPhoneNumberControl?.setValue(val, {
          emitEvent: false,
        })
      );

    // copies values between incorporationAddress and operatingAddress
    this.sameAddressControlSub =
      this.isSameAddressControl?.valueChanges.subscribe((isSameAddress) => {
        if (isSameAddress) {
          this.operatingAddressGroup?.patchValue(
            this.incorporationAddressGroup?.value
          );
          this.bindAddresses(); // connects operating address to incorporation address
        } else {
          this.incorporationAddressGroupSub?.unsubscribe();
          // when verificationAccepted we disable the operating address so its value doesn't change,
          // but then this is triggered, so don't reset its value when we disable it
          if (!this.operatingAddressGroup?.disabled) {
            this.operatingAddressGroup?.reset();
          }
        }
      });
  }

  disableFormFields() {
    // disable fields that were cross-verified with the documents
    this.businessNameControl?.disable();
    // if operating address is same with registered: disable registered address and isSameAddress button
    // if operating address is different from registered: disable operating address and isSameAddress button
    if (this.isSameAddressControl?.value) {
      this.operatingAddressGroup?.disable();
      this.incorporationAddressGroup?.disable();
      this.isSameAddressControl?.disable();
    } else {
      this.operatingAddressGroup?.disable();
      this.isSameAddressControl?.disable();
    }
  }

  ngOnDestroy(): void {
    this.corporateFormSub?.unsubscribe();
    this.incorporationAddressGroupSub?.unsubscribe();
    this.sameAddressControlSub?.unsubscribe();
  }

  saveCorporateForm(): void {
    this.isSaving = true;
    this.isComplete.emit(false); // prevents submitting application while saving
    const body: CorporateForm = {
      ...this.corporateForm.getRawValue(), // to get all values including disabled ones
      accountPurpose: this.accountPurposeControl?.value.join(', '),
      incorporationDate: (
        this.incorporationDateControl?.value as DateTime
      ).toISODate(),
      // if legal status is the Other option, save the input description
      legalStatus:
        this.legalStatusControl?.value === LegalStatus['Other type of Company']
          ? this.otherLegalStatusControl?.value
          : this.legalStatusControl?.value,
      annualIncome: this.annualIncomeControl?.value.replace(/\D/g, ''),
      monthlyLoading: this.monthlyLoadingControl?.value.replace(/\D/g, ''),
      additionalPhoneNumber:
        this.additionalPhoneNumberControl?.value?.e164Number || '',
    };
    this.onboardingService.saveCorporateForm(body).subscribe(
      () => {
        this.isSaving = false;
        this.corporateForm.markAsPristine(); // disable save button after change is saved
        this.isComplete.emit(true);
      },
      () => {
        this.isSaving = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  // copies values from incorporation address to operating address in real time
  private bindAddresses(): void {
    this.incorporationAddressGroupSub?.unsubscribe();
    this.incorporationAddressGroupSub =
      this.incorporationAddressGroup?.valueChanges.subscribe((val) =>
        this.operatingAddressGroup?.patchValue(val)
      );
  }

  get businessNameControl(): AbstractControl | null {
    return this.corporateForm.get('businessName');
  }
  get businessTradingNameControl(): AbstractControl | null {
    return this.corporateForm.get('businessTradingName');
  }
  get taxNumberControl(): AbstractControl | null {
    return this.corporateForm.get('taxNumber');
  }
  get vatNumberControl(): AbstractControl | null {
    return this.corporateForm.get('vatNumber');
  }
  get companyUrlsControl(): AbstractControl | null {
    return this.corporateForm.get('companyUrls');
  }
  get companyActivityControl(): AbstractControl | null {
    return this.corporateForm.get('companyActivity');
  }
  get companyActivityDescrControl(): AbstractControl | null {
    return this.corporateForm.get('companyActivityDescr');
  }
  get isRegulatedControl(): AbstractControl | null {
    return this.corporateForm.get('isRegulated');
  }
  get regulatoryAuthorityControl(): AbstractControl | null {
    return this.corporateForm.get('regulatoryAuthority');
  }
  get annualIncomeControl(): AbstractControl | null {
    return this.corporateForm.get('annualIncome');
  }
  get accountPurposeControl(): AbstractControl | null {
    return this.corporateForm.get('accountPurpose');
  }
  get monthlyLoadingControl(): AbstractControl | null {
    return this.corporateForm.get('monthlyLoading');
  }
  get percentageOfCashControl(): AbstractControl | null {
    return this.corporateForm.get('percentageOfCash');
  }
  get legalStatusControl(): AbstractControl | null {
    return this.corporateForm.get('legalStatus');
  }
  get otherLegalStatusControl(): AbstractControl | null {
    return this.corporateForm.get('otherLegalStatus');
  }
  get expectedNumOfPhysicalCardsControl(): AbstractControl | null {
    return this.corporateForm.get('expectedNumOfPhysicalCards');
  }
  get incorporationDateControl(): AbstractControl | null {
    return this.corporateForm.get('incorporationDate');
  }
  get registrationNumberControl(): AbstractControl | null {
    return this.corporateForm.get('registrationNumber');
  }
  get additionalPhoneNumberControl(): AbstractControl | null {
    return this.corporateForm.get('additionalPhoneNumber');
  }
  get isSameAddressControl(): AbstractControl | null {
    return this.corporateForm.get('isSameAddress');
  }

  // incorporation address
  get incorporationAddressGroup(): AbstractControl | null {
    return this.corporateForm.get('incorporationAddress');
  }
  get incorporationStreetAddressControl(): AbstractControl | null | undefined {
    return this.incorporationAddressGroup?.get('streetAddress');
  }
  get incorporationAdditionalStreetAddressControl():
    | AbstractControl
    | null
    | undefined {
    return this.incorporationAddressGroup?.get('additionalStreetAddress');
  }
  get incorporationPostCodeControl(): AbstractControl | null | undefined {
    return this.incorporationAddressGroup?.get('postCode');
  }
  get incorporationCityControl(): AbstractControl | null | undefined {
    return this.incorporationAddressGroup?.get('city');
  }
  get incorporationCountryIdControl(): AbstractControl | null | undefined {
    return this.incorporationAddressGroup?.get('countryId');
  }

  // operating address
  get operatingAddressGroup(): AbstractControl | null {
    return this.corporateForm.get('operatingAddress');
  }
  get operatingStreetAddressControl(): AbstractControl | null | undefined {
    return this.operatingAddressGroup?.get('streetAddress');
  }
  get operatingAdditionalStreetAddressControl():
    | AbstractControl
    | null
    | undefined {
    return this.operatingAddressGroup?.get('additionalStreetAddress');
  }
  get operatingPostCodeControl(): AbstractControl | null | undefined {
    return this.operatingAddressGroup?.get('postCode');
  }
  get operatingCityControl(): AbstractControl | null | undefined {
    return this.operatingAddressGroup?.get('city');
  }
  get operatingCountryIdControl(): AbstractControl | null | undefined {
    return this.operatingAddressGroup?.get('countryId');
  }
}
