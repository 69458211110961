<div class="white-overlay" [ngClass.lt-lg]="'white-overlay mobile'">
  <div fxHide.lt-lg class="image-background"></div>
  <div fxLayout="row" class="row-container">
    <div
      fxHide.lt-lg
      fxFlex="65"
      fxLayoutAlign="center center"
      class="left-container"
    >
      <img
        srcset="
          /assets/img/login.png    1x,
          /assets/img/login@2x.png 2x,
          /assets/img/login@3x.png 3x
        "
      />
    </div>
    <div fxFlex="100" fxFlex.gt-md="35" class="right-container">
      <div class="content" fxLayout="column" fxLayoutAlign="start center">
        <div class="main-container">
          <ng-content select="[info]"></ng-content>
          <div class="header-container" fxLayoutAlign="space-between center">
            <h1 data-testid="page-title-text" [class.centered]="titleCentered">
              {{ title }}
            </h1>
            <button
              data-testid="change-page-button"
              *ngIf="buttonText && buttonRouterLink"
              mat-stroked-button
              [routerLink]="buttonRouterLink"
            >
              {{ buttonText }}
            </button>
          </div>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
