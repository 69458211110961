import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { TransferService } from 'src/app/transfer/transfer.service';
import { Transfer } from '../../models/transfer.model';

export enum SaveFavouriteState {
  initial,
  editing,
  saved,
}

@Component({
  selector: 'app-transfer-save-favourite',
  templateUrl: './transfer-save-favourite.component.html',
  styleUrls: ['./transfer-save-favourite.component.scss'],
})
export class TransferSaveFavouriteComponent {
  @Input() transferBody?: Transfer | null;

  SaveFavouriteState = SaveFavouriteState;
  state: SaveFavouriteState = SaveFavouriteState.initial;
  isSaving: boolean = false;

  saveForm: FormGroup = this.fb.group({
    ftName: [null, [Validators.required, Validators.maxLength(100)]],
  });

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private transferService: TransferService
  ) {}

  cancel(): void {
    this.ftNameControl.reset();
    this.state = SaveFavouriteState.initial;
  }

  async save(): Promise<void> {
    if (this.transferBody) {
      this.isSaving = true;
      this.transferBody.favouriteTransferName = this.ftNameControl.value;
      (
        await this.transferService.saveFavouriteTransfer(this.transferBody)
      ).subscribe(
        () => {
          this.isSaving = false;
          this.saveForm.reset();
          this.state = SaveFavouriteState.saved;
        },
        () => {
          this.isSaving = false;
          this.errorService.showErrorDialog();
        }
      );
    }
  }

  get ftNameControl(): FormControl {
    return this.saveForm.get('ftName') as FormControl;
  }
}
