import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { UserLogSearch } from './models/user-log-search.model';
import { DbLogsService } from './db-logs.service';
import { UserLogItem } from './models/user-log-item.model';

export class RegisteredUserLogsDataSource implements DataSource<UserLogItem> {
  private userLogsSubject = new BehaviorSubject<UserLogItem[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private lengthSubject = new BehaviorSubject<number>(0);
  private currentRequestSub?: Subscription;
  public isLoadingObs = this.loadingSubject.asObservable();

  constructor(
    private dbLogsService: DbLogsService,
    private errorService: ErrorService
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<UserLogItem[]> {
    return this.userLogsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.userLogsSubject.complete();
    this.loadingSubject.complete();
    this.lengthSubject.complete();
  }

  // count of all items in the source
  getLength(): Observable<number> {
    return this.lengthSubject.asObservable();
  }

  loadLogs(data: UserLogSearch): void {
    this.currentRequestSub?.unsubscribe(); // cancels previous request
    this.loadingSubject.next(true);

    this.currentRequestSub = this.dbLogsService
      .getUserLogs(data)
      .pipe(
        map((res) => {
          this.lengthSubject.next(res.totalCount);
          return res.items;
        }),
        catchError(() => {
          this.errorService.showErrorDialog();
          return of([]);
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((userLogs) => this.userLogsSubject.next(userLogs));
  }
}
