import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { mergeMap } from 'rxjs/operators';
import { AuthService } from 'src/app/login/services/auth.service';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { OnboardingService } from '../../onboarding.service';
import { Router } from '@angular/router';

export interface OnboardingSubmitDialogModel {
  directorsNumber?: number | string;
  counterpartiesNumber?: number | string;
  accountsNumber?: number | string;
  groupMembersNumber?: number | string;
}

@Component({
  templateUrl: './onboarding-submit-dialog.component.html',
  styleUrls: ['./onboarding-submit-dialog.component.scss'],
})
export class OnboardingSubmitDialogNewComponent {
  directorsNumber: number | string | undefined = undefined;
  counterpartiesNumber: number | string | undefined = undefined;
  accountsNumber: number | string | undefined = undefined;
  groupMembersNumber: number | string | undefined = undefined;
  isSubmitting = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OnboardingSubmitDialogModel | null,
    public dialogRef: MatDialogRef<OnboardingSubmitDialogNewComponent>,
    private authService: AuthService,
    private errorService: ErrorService,
    private onboardingService: OnboardingService,
    private router: Router
  ) {
    this.directorsNumber = data?.directorsNumber;
    this.counterpartiesNumber = data?.counterpartiesNumber;
    this.accountsNumber = data?.accountsNumber;
    this.groupMembersNumber = data?.groupMembersNumber;
  }

  submitApplication(): void {
    this.isSubmitting = true;

    // Submit onboarding form
    this.onboardingService
      .submitApplication()
      .pipe(mergeMap(() => this.authService.getAuthenticatedUser())) // refreshes user state in the app
      .subscribe(
        () => {
          this.isSubmitting = false;
          this.dialogRef.close();
          this.router.navigate(['..']); // refresh
        },
        (error) => {
          this.isSubmitting = false;
          if (error.status === 409) {
            // handle HttpStatus.CONFLICT
            this.errorService.showErrorDialog(
              'You have documents with unandressed comments that need to be uploaded before submitting your application.'
            );
          } else {
            this.errorService.showErrorDialog(error.error.message);
          }
        }
      );
  }
}
