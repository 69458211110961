<div class="container">
  <button
    *ngIf="showCloseButton"
    class="close"
    mat-icon-button
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
  <iframe [src]="src" frameborder="0" allow="camera"></iframe>
</div>
