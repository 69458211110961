<div [formGroup]="documentsForm" *ngIf="show">
  <ng-container>
    <div
      fxLayout="row wrap"
      fxLayoutGap="25px grid"
      fxLayoutAlign="space-between"
    >
      <ng-container *ngFor="let control of documentsControls; let i = index">
        <app-file-input
          [companyDirectorId]="companyDirectorId"
          [formControl]="control"
          [disableFileRemove]="
            acceptedDocumentTypes.includes(control.value.type)
          "
          fxFlex="50"
          fxFlex.lt-sm="100"
          (removeField)="removeField(i)"
        ></app-file-input>
      </ng-container>
    </div>

    <!-- KYB: Add another document when more information is requested 
         (but not when it is the first time in EDD) -->
    <button
      *ngIf="companyDirectorId && isReviewed && !isFirstTimeEDD"
      mat-stroked-button
      data-testid="add-another-file-button"
      color="accent"
      class="add-file"
      (click)="addAnotherFile()"
    >
      Add another document
    </button>
  </ng-container>
</div>
