<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button [mat-dialog-close]="isSuccess" [disabled]="isBuying">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <h1>Exchange {{ fromCurr }} to {{ toCurr }}</h1>
  <div [ngSwitch]="isLoading" class="container">
    <app-big-loader *ngSwitchCase="true"></app-big-loader>
    <ng-container *ngSwitchDefault>
      <ng-container *ngIf="!isSuccess">
        <div class="info-container" [class.updating]="isUpdating">
          <div>
            You want to buy for
            <strong>{{ amount | amount }} {{ fromCurr }}</strong
            >.
          </div>
          <div>
            Current rate:
            <strong>1 {{ fromCurr }} = {{ rate | number }} {{ toCurr }}</strong
            >.
          </div>
          <div>
            You will receive
            <strong>{{ amountAfterConversion | amount }} {{ toCurr }}</strong
            >.
          </div>
        </div>

        <div
          fxLayout="column"
          fxLayoutAlign="start center"
          fxLayoutGap="20px"
          class="centered-container"
        >
          <div *ngIf="!isBuying" [ngSwitch]="isUpdating">
            <div
              *ngSwitchCase="true"
              fxLayout
              fxLayoutAlign="center"
              fxLayoutGap="15px"
            >
              <app-small-loader></app-small-loader>
              <div>Updating rates...</div>
            </div>
            <div *ngSwitchDefault>{{ counter | async }}s left</div>
          </div>
          <button
            mat-flat-button
            color="accent"
            [disabled]="isUpdating || isBuying"
            [appButtonLoader]="isBuying"
            (click)="buy()"
          >
            Buy now
          </button>
          <button
            mat-stroked-button
            color="accent"
            [mat-dialog-close]="isSuccess"
            [disabled]="isBuying"
          >
            Cancel
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="isSuccess">
        <div class="info-container">Successful transaction</div>
        <div
          fxLayout="column"
          fxLayoutAlign="start center"
          class="centered-container"
        >
          <button mat-flat-button color="accent" [mat-dialog-close]="isSuccess">
            OK
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</mat-dialog-content>
