<mat-card>
  <div class="top-container">
    <div
      class="filters-container"
      fxLayout
      fxLayoutGap="30px"
      fxLayoutAlign="start center"
      [formGroup]="filtersGroup"
    >
      <div fxLayout="column">
        <label for="search">Search</label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="search" name="search" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <label>Date range</label>
        <mat-form-field appearance="outline" class="date">
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              placeholder="Start date"
              formControlName="startDate"
            />
            <input
              matEndDate
              placeholder="End date"
              formControlName="endDate"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <mat-checkbox color="accent" formControlName="individuals"
        >Individuals</mat-checkbox
      >
      <mat-checkbox color="accent" formControlName="businesses"
        >Businesses</mat-checkbox
      >
    </div>
    <!-- <div>
      <button
        mat-stroked-button
        color="accent"
        class="g-small"
        (click)="onGeneratePdf()"
      >
        Download PDF
      </button>
    </div> -->
  </div>
  <div class="table-container">
    <div class="spinner-container" *ngIf="dataSource.isLoadingObs | async">
      <app-big-loader></app-big-loader>
    </div>

    <table
      mat-table
      [dataSource]="dataSource"
      [fixedLayout]="true"
      matSort
      [matSortActive]="orderField"
      [matSortDirection]="sortDirection"
      matSortDisableClear
    >
      <!-- Names Column -->
      <ng-container matColumnDef="names">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header="firstName">First name</div>
          <div mat-sort-header="lastName">Last name</div>
        </th>
        <td mat-cell *matCellDef="let row">
          <div>{{ row.firstName }}</div>
          <div>{{ row.lastName }}</div>
        </td>
      </ng-container>

      <!-- BusinessName Column -->
      <ng-container matColumnDef="businessName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Business name</th>
        <td mat-cell *matCellDef="let row">{{ row.businessName }}</td>
      </ng-container>

      <!-- UsernamePhone Column -->
      <ng-container matColumnDef="usernamePhone">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header="username">Username</div>
          <div mat-sort-header="phone">Phone number</div>
        </th>
        <td mat-cell *matCellDef="let row">
          <div>{{ row.username }}</div>
          <div>{{ row.phone }}</div>
        </td>
      </ng-container>

      <!-- PaidOwed Column -->
      <ng-container matColumnDef="paidOwed">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header="paid">Paid to date of the year</div>
          <div mat-sort-header="owed">Owed</div>
        </th>
        <td mat-cell *matCellDef="let row">
          <div>{{ row.paid }}</div>
          <div>{{ row.owed }}</div>
        </td>
      </ng-container>

      <!-- IbanChidBalance Column -->
      <ng-container matColumnDef="ibanChidBalance">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header="ibanChid">IBAN CHID</div>
          <div mat-sort-header="ibanBalance">IBAN Balance</div>
        </th>
        <td mat-cell *matCellDef="let row">
          <div>{{ row.ibanChid }}</div>
          <div>{{ row.ibanBalance }}</div>
        </td>
      </ng-container>

      <!-- McChidBalance Column -->
      <ng-container matColumnDef="mcChidBalance">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header="mcChid">MC CHID</div>
          <div mat-sort-header="mcBalance">MC Balance</div>
        </th>
        <td mat-cell *matCellDef="let row">
          <div>{{ row.mcChid }}</div>
          <div>{{ row.mcBalance }}</div>
        </td>
      </ng-container>

      <!-- WalletChidBalance Column -->
      <ng-container matColumnDef="walletChidBalance">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header="walletChid">Wallet CHID</div>
          <div mat-sort-header="walletBalance">Wallet Balance</div>
        </th>
        <td mat-cell *matCellDef="let row">
          <div>{{ row.walletChid }}</div>
          <div>{{ row.walletBalance }}</div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [length]="dataSource.getLength() | async"
      [pageSize]="20"
      [pageSizeOptions]="[20, 50, 100]"
    ></mat-paginator>
  </div>
</mat-card>
