<div *ngFor="let step of steps; let last = last" fxLayout="row">
  <div class="left-container">
    <div *ngIf="!last" class="vertical-line"></div>
    <ng-container [ngSwitch]="step.completed">
      <img
        *ngSwitchCase="true"
        [attr.data-testid]="
          step.name.split(' ').join('-').toLowerCase() + '-step-completed-image'
        "
        src="/assets/img/step-completed.svg"
        alt="step completed"
      />
      <img
        *ngSwitchDefault
        [attr.data-testid]="
          step.name.split(' ').join('-').toLowerCase() +
          '-step-incompleted-image'
        "
        src="/assets/img/step-incomplete.svg"
        alt="step incomplete"
      />
    </ng-container>
  </div>
  <div class="right-container">
    <div class="step-name">{{ step.name }}</div>
    <div *ngIf="step.substeps" class="substeps-container">
      <div *ngFor="let substep of step.substeps">
        {{ substep }}
      </div>
    </div>
  </div>
</div>
