import { Component, Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

export interface ActivationSnackbarData {
  successCount: number;
  failureCount: number;
}

@Component({
  selector: 'app-activation-snackbar',
  templateUrl: './activation-snackbar.component.html',
  styleUrls: ['./activation-snackbar.component.scss'],
})
export class ActivationSnackbarComponent {
  successCount: number;
  failureCount: number;

  constructor(
    private snackBarRef: MatSnackBarRef<ActivationSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: ActivationSnackbarData
  ) {
    this.successCount = data.successCount;
    this.failureCount = data.failureCount;
  }

  close(): void {
    this.snackBarRef.dismiss();
  }
}
