import { Component, Inject } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { AppDocument } from 'src/app/onboarding-new/models/document.model';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import {
  acceptedDocumentTypes,
  getDocumentName,
} from 'src/app/shared/helpers/various-helpers.helper';
import { FilesService } from 'src/app/shared/services/files.service';
import { UsersService } from '../../users.service';
import { AdminFileDialogData } from './admin-file-dialog-data.model';
import { AppDocumentType } from 'src/app/onboarding-new/models/document-type.enum';

@Component({
  templateUrl: './admin-file-dialog.component.html',
  styleUrls: ['./admin-file-dialog.component.scss'],
})
export class AdminFileDialogComponent {
  url: string = '';
  isPdf: boolean = false;
  isVideo: boolean = false;
  companyDirectorId?: number;
  doc!: AppDocument;
  parentFormArray!: FormArray;
  userId!: number;
  showProofOfOperatingAddress: boolean = false;
  currentIndex: number = 0;
  firstIndex: number = 0;
  lastIndex: number = 0;

  isLoading: boolean = false;
  isGenerating: boolean = false;
  isDownloading: boolean = false;

  getDocumentName = getDocumentName;
  acceptedDocumentTypes = acceptedDocumentTypes;
  AppDocumentType = AppDocumentType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AdminFileDialogData,
    private errorService: ErrorService,
    private filesService: FilesService,
    private usersService: UsersService
  ) {
    this.parentFormArray = this.data.control.parent as FormArray;
    this.userId = this.data.userId;
    this.companyDirectorId = this.data.companyDirectorId;
    this.showProofOfOperatingAddress = this.data.showProofOfOperatingAddress;

    this.currentIndex = this.parentFormArray.controls.findIndex(
      (control) => control.value.id === this.data.control.value.id
    );
    this.lastIndex = this.parentFormArray.length - 1;
    this.setCurrentDoc(this.currentIndex);
  }

  setCurrentDoc(index: number): void {
    this.currentIndex = index;
    this.doc = this.parentFormArray.at(index).value;
    this.isDownloading = false; // clear downloading status when document changed

    if (this.doc.location) {
      this.isPdf = this.doc.location?.split('.').pop() === 'pdf';
      this.isVideo = this.doc.location?.split('.').pop() === 'mp4';
      this.generateUrl();
    } else {
      this.isPdf = false;
      this.url = '';
    }
  }

  downloadFile(): void {
    this.isDownloading = true;
    this.filesService.downloadFile(this.url).subscribe(
      (blob) => {
        saveAs(blob, this.doc.name);
        this.isDownloading = false;
      },
      () => {
        this.isDownloading = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  generateUrl(): void {
    this.isLoading = true;
    this.isGenerating = true;

    this.url = '';
    this.filesService.generateUrl(this.doc.location).subscribe(
      ({ url }) => {
        this.url = this.isPdf ? encodeURIComponent(url) : url;
        this.isLoading = false;
        this.isGenerating = false;
      },
      () => {
        this.isLoading = false;
        this.isGenerating = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  goToNext(commentInput: MatInput): void {
    commentInput.ngControl.control?.markAsUntouched(); // clears validation for comment textarea
    this.setCurrentDoc(this.currentIndex + 1);
  }

  goToPrevious(commentInput: MatInput): void {
    commentInput.ngControl.control?.markAsUntouched(); // clears validation for comment textarea
    this.setCurrentDoc(this.currentIndex - 1);
  }

  saveAcceptation(accepted: boolean, commentInput: MatInput): void {
    this.isLoading = true;
    commentInput.ngControl.control?.markAsUntouched(); // clears validation for comment textarea
    const control = this.parentFormArray.at(this.currentIndex);
    control.markAsPending();
    const saveObs = this.companyDirectorId
      ? this.usersService.changeDirectorDocumentStatus(
          this.userId,
          this.companyDirectorId,
          this.doc.id,
          accepted
        )
      : this.usersService.changeDocumentStatus(
          this.userId,
          this.doc.id,
          accepted
        );

    saveObs.subscribe(
      (doc) => {
        this.doc = doc;
        control.setValue(this.doc);
        if (!accepted) {
          setTimeout(() => commentInput.focus()); // automatic focus of comment textarea
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  saveRejectionReason(content: string): void {
    this.isLoading = true;
    const control = this.parentFormArray.at(this.currentIndex);
    control.markAsPending();
    const saveObs = this.companyDirectorId
      ? this.usersService.addDirectorDocumentComment(
          this.userId,
          this.doc.id,
          this.companyDirectorId,
          content
        )
      : this.usersService.addDocumentComment(this.userId, this.doc.id, content);

    saveObs.subscribe(
      (doc) => {
        this.doc = doc;
        control.setValue(this.doc);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  get currentControl(): AbstractControl {
    return this.parentFormArray.at(this.currentIndex);
  }
}
