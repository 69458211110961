import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { of, Subscription } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { OnboardingService } from '../../onboarding.service';
import { CompanyAccount } from '../../models/company-account.model';
import { Country } from 'src/app/shared/models/country.model';
import { OnboardingFieldId } from 'src/app/admin/users/models/onboarding-field-id.enum';
import { getCountryById } from 'src/app/shared/helpers/various-helpers.helper';
import { OnboardingField } from '../../models/onboarding-field.model';

@Component({
  selector: 'app-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.scss'],
})
export class AccountFormComponent implements OnInit, OnDestroy {
  @Input() index: number = 0; // used for numbering the components
  @Input() countries: Country[] = [];
  @Input() fields: OnboardingField[] = [];
  @Input() set account(val: CompanyAccount) {
    this._account = val;
    if (JSON.stringify(val) !== JSON.stringify(this.accountForm.value)) {
      this.accountForm.patchValue(val, { emitEvent: false });
    }
    if (this.accountForm.valid) {
      this.inputsComplete = true;
    }
    // fieldsMap
    this.fieldsMap.clear();
    this.fields
      ?.filter((field) => !!field.comment && field.accountId === val.id)
      .forEach((field) => {
        let commentedValue = field.commentedValue;
        if (field.id.endsWith('.country')) {
          // converts countryId to country name
          commentedValue = this.getCountryById(
            this.countries,
            parseInt(commentedValue)
          );
        }
        this.fieldsMap.set(field.id, {
          comment: field.comment,
          commentedValue,
        });
      });
  }
  get account(): CompanyAccount {
    return this._account;
  }
  @Input() isReviewed: boolean = false;
  @Output() accountChange = new EventEmitter<CompanyAccount>();
  @Output() accountRemove = new EventEmitter<number>(); // emits account id
  @Output() isComplete = new EventEmitter<boolean>();

  fieldsMap: Map<
    string,
    { comment: string | null; commentedValue: string | null } | null
  > = new Map<
    string,
    { comment: string | null; commentedValue: string | null } | null
  >();
  isRemoving: boolean = false;

  OnboardingFieldId = OnboardingFieldId;
  getCountryById = getCountryById;

  accountForm: FormGroup = this.fb.group(
    {
      id: [null],
      name: [
        null,
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 .,`'&\\/()-]*$"),
          Validators.maxLength(100),
        ],
      ],
      country: [null, Validators.required],
    },
    { updateOn: 'change' }
  );

  private _inputsComplete: boolean | undefined = undefined;
  private _account!: CompanyAccount;
  private accountFormSub: Subscription | undefined = undefined;

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private onboardingService: OnboardingService
  ) {}

  ngOnInit(): void {
    let lastQueueValue: CompanyAccount | null = null;
    let savedValue: CompanyAccount | null = null;

    // saving Form when values changed (requests are sent one by one, intermediate values are omitted)
    this.accountFormSub = this.accountForm.valueChanges
      .pipe(
        tap((val) => (lastQueueValue = val)),
        concatMap((val) => {
          if (val === lastQueueValue) {
            this.inputsComplete = false; // prevents submitting application when saving in progress or form is invalid
            if (this.accountForm.valid) {
              savedValue = val;
              return this.onboardingService
                .updateCompanyAccount(this.account.id, val)
                .pipe(
                  tap((val) => {
                    if (savedValue === lastQueueValue) {
                      this.inputsComplete = this.accountForm.valid;
                      this.accountChange.emit(val);
                    }
                  })
                );
            } else {
              return of(null);
            }
          } else {
            return of(null);
          }
        })
      )
      .subscribe(
        () => {},
        () => this.errorService.showErrorDialog()
      );
  }

  ngOnDestroy(): void {
    this.accountFormSub?.unsubscribe();
  }

  removeAccount(account: CompanyAccount): void {
    this.inputsComplete = false; // prevents submitting application when removing in progress
    this.isRemoving = true;

    // set them as undefined to trigger removal
    account.name = undefined;
    account.country = undefined;

    this.onboardingService.updateCompanyAccount(account.id, account).subscribe(
      (modifiedAccount) => {
        if (modifiedAccount) {
          this.accountChange.emit(modifiedAccount);
        } else {
          this.accountRemove.emit(account.id);
        }
        this.isRemoving = false;
      },
      () => {
        this.isRemoving = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  // set to true when inputs are saved and valid
  set inputsComplete(val: boolean | undefined) {
    if (val !== undefined) {
      this.isComplete.emit(val);
    }
    this._inputsComplete = val;
  }
  get inputsComplete(): boolean | undefined {
    return this._inputsComplete;
  }
  get nameControl(): AbstractControl | null {
    return this.accountForm.get('name');
  }
  get countryControl(): AbstractControl | null {
    return this.accountForm.get('country');
  }
}
