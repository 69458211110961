import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Card } from '../../../dashboard/models/card.model';
import { DashboardService } from '../../../dashboard/dashboard.service';
import { ErrorService } from '../../../shared/error-dialog/error.service';
import { AccountStatus } from '../../../dashboard/shared/account-status.enum';

@Component({
  selector: 'app-lock-unlock-card-dialog',
  templateUrl: './lock-unlock-card-dialog.component.html',
  styleUrls: ['./lock-unlock-card-dialog.component.scss'],
})
export class LockUnlockCardDialogComponent {
  card?: Card;
  isLockingCard: boolean = false;
  AccountStatus = AccountStatus;
  shouldRefresh: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<LockUnlockCardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: Card,
    private dashboardService: DashboardService,
    private errorService: ErrorService
  ) {
    this.card = data;
  }

  lockUnlockCard() {
    this.isLockingCard = true;
    this.dashboardService.lockUnlockCard(this.card!.numAccountID).subscribe(
      () => {
        this.isLockingCard = false;
        this.shouldRefresh = true;
        this.dialogRef.close(this.shouldRefresh);
      },
      (error) => {
        this.errorService.showErrorDialog(error.error.message);
        this.isLockingCard = false;
      }
    );
  }
}
