import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RegisteredUser } from './models/registered-user.model';
import { RegisteredUserItem } from './models/registered-user-item.model';
import { RegisteredUserSearch } from './models/registered-user-search.model';
import { Page } from '../models/page.model';
import { map } from 'rxjs/operators';
import { RegisteredUserDocument } from './models/registered-user-document.model';
import { AppDocument } from '../../../onboarding-new/models/document.model';
import { FileType } from './registered-user/shared/file-type.model';
import { AllAccountsAdmin } from 'src/app/dashboard/models/all-accounts-admin.model';
import {
  EncryptedDataModel,
  EncryptionService,
} from 'src/app/shared/services/encryption.service';
import { SaveRegisteredUser } from './models/save-registered-user.model';

@Injectable({
  providedIn: 'root',
})
export class RegisteredUsersService {
  baseUrl = `${environment.BACKEND_URL}/admin-registered-users/users`;

  constructor(
    private http: HttpClient,
    private encryptionService: EncryptionService
  ) {}

  getRegisteredUsers(
    data: RegisteredUserSearch
  ): Observable<Page<RegisteredUserItem>> {
    let params = new HttpParams();
    if (data.page) {
      params = params.set('page', data.page);
    }
    if (data.limit) {
      params = params.set('limit', data.limit);
    }
    if (data.orderField) {
      params = params.set('orderField', data.orderField);
    }
    if (data.orderDescending) {
      params = params.set('orderDescending', data.orderDescending);
    }
    if (data.searchText) {
      params = params.set('searchText', data.searchText);
    }
    if (data.dateFrom) {
      params = params.set('dateFrom', data.dateFrom);
    }
    if (data.dateTo) {
      params = params.set('dateTo', data.dateTo);
    }
    if (data.userType) {
      params = params.set('userType', data.userType);
    }
    return this.http.get<Page<RegisteredUserItem>>(this.baseUrl, { params });
  }

  getRegisteredUser(userId: number): Observable<RegisteredUser> {
    const url = `${this.baseUrl}/${userId}`;
    return this.http.get<RegisteredUser>(url);
  }

  // update personal data in registered-user-profile
  async saveRegisteredUserPersonalData(
    registeredUserData: SaveRegisteredUser
  ): Promise<Observable<void>> {
    const url = `${this.baseUrl}/update-personal-data`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(registeredUserData);
    return this.http.patch<void>(url, cipherModel);
  }

  // update corporate data in registered-user-profile
  async saveRegisteredUserCorporateData(
    registeredUserData: SaveRegisteredUser
  ): Promise<Observable<void>> {
    const url = `${this.baseUrl}/update-corporate-data`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(registeredUserData);
    return this.http.patch<void>(url, cipherModel);
  }

  uploadAvatar(userId: number, file: File): Observable<string> {
    const formData = new FormData();
    formData.append('file', file);
    const url = `${this.baseUrl}/${userId}/avatar`;
    return this.http
      .put<{ avatarUrl: string }>(url, formData)
      .pipe(map((avatar) => avatar.avatarUrl));
  }

  deleteAvatar(userId: number): Observable<void> {
    const url = `${this.baseUrl}/${userId}/avatar`;
    return this.http.delete<void>(url);
  }

  getDocuments(userId: number): Observable<RegisteredUserDocument[]> {
    const url = `${this.baseUrl}/${userId}/documents`;
    return this.http.get<RegisteredUserDocument[]>(url);
  }

  uploadDocument(userId: number, formData: FormData): Observable<AppDocument> {
    const url = `${this.baseUrl}/${userId}/documents`;
    return this.http.post<AppDocument>(url, formData);
  }

  updateStatus(userId: number, state: number): Observable<void> {
    const url = `${this.baseUrl}/${userId}/state`;
    return this.http.put<void>(url, { state: state });
  }

  userFiles(formData: FormData): Observable<FileType> {
    const url = `${environment.BACKEND_URL}/files/user-files`;
    return this.http.post<FileType>(url, formData);
  }

  async getAllUserAccounts(
    userId: number
  ): Promise<Observable<AllAccountsAdmin>> {
    const url = `${environment.BACKEND_URL}/admin/all-accounts`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(userId);
    return this.http.post<AllAccountsAdmin>(url, cipherModel);
  }
}
