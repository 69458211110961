import { Component, Input, OnInit } from '@angular/core';
import { RegisteredUsersService } from '../../registered-users.service';
import { ErrorService } from '../../../../../shared/error-dialog/error.service';
import { MatTableDataSource } from '@angular/material/table';
import { AccountsTable } from '../shared/accounts-table.model';
import { currencies } from 'src/app/dashboard/shared/currencies';

@Component({
  selector: 'app-registered-user-accounts',
  templateUrl: './registered-user-accounts.component.html',
  styleUrls: ['./registered-user-accounts.component.scss'],
})
export class RegisteredUserAccountsComponent implements OnInit {
  @Input() userId!: number;

  accountsTable = new MatTableDataSource<AccountsTable>();
  currentAccountTable?: AccountsTable;

  isLoadingIban: boolean = false;
  isLoadingWallet: boolean = false;
  isLoadingCards: boolean = false;

  constructor(
    private registeredUsersService: RegisteredUsersService,
    private errorService: ErrorService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoadingIban = true;
    this.isLoadingWallet = true;
    this.isLoadingCards = true;

    (
      await this.registeredUsersService.getAllUserAccounts(this.userId)
    ).subscribe(
      (accounts) => {
        if (accounts.iban) {
          this.accountsTable.data.push({
            accountName: 'IBAN Account',
            accountIconName: 'iban',
            accountDetails: accounts.iban,
          });
        }

        if (accounts.wallets) {
          currencies.forEach((currency) => {
            const acc = accounts.wallets.find((w) => w.ccy === currency.short);
            if (acc !== undefined) {
              this.accountsTable.data.push({
                accountName: `${currency.short} Wallet`,
                accountIconName: 'wallet',
                accountDetails: acc,
              });
            }
          });
        }

        this.isLoadingIban = false;
        this.isLoadingCards = false;
        this.isLoadingWallet = false;

        // TODO: Cards
        //Card Accounts
        // const allCards = userAccounts.cards.map((card) =>
        //   this.dashboardService.getAccountInfo(card)
        // );
        // if (allCards.length > 0) {
        //   forkJoin(allCards).subscribe(
        //     (cardsInfo: AccountInfo[]) => {
        //       cardsInfo.map((card, index) => {
        //         const number = card.cardHolder.cardNumber.slice(-4);
        //         const holder = `${card.cardHolder.firstName} ${card.cardHolder.lastName}`;

        //         this.accountsTable.data.push({
        //           accountName: `Card - ${number}`,
        //           accountIconName: 'master-card',
        //           holder,
        //           availableBalance: card.cardInfo.availBal,
        //           accountDetails: userAccounts.cards[index],
        //         });
        //       });
        //       this.isLoadingCards = false;
        //     },
        //     () => {
        //       this.isLoadingCards = false;
        //       this.errorService.showErrorDialog();
        //     }
        //   );
        // } else {
        //   this.isLoadingCards = false;
        // }
      },
      (error) => {
        this.isLoadingIban = false;
        this.isLoadingWallet = false;
        this.isLoadingCards = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  isFinishedLoading(): boolean {
    return !this.isLoadingIban && !this.isLoadingCards && !this.isLoadingWallet;
  }

  resetCurrentTable(): void {
    this.currentAccountTable = undefined;
  }

  changeCurrentAccountTable(accountTable: AccountsTable) {
    this.currentAccountTable = accountTable;
  }
}
