import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AccountsTable } from '../../shared/accounts-table.model';
import { MatSort } from '@angular/material/sort';
import { accountStatusNamesMap } from '../../../../../../dashboard/shared/account-status-names.map';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { merge, Subscription } from 'rxjs';
import { ErrorService } from '../../../../../../shared/error-dialog/error.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DashboardService } from '../../../../../../dashboard/dashboard.service';
import { GenericAccountsTableDatasource } from './generic-accounts-table.datasource';
import { calculateDateRange } from '../../../../../../dashboard/shared/calculateDateRange';
import { StatementSearch } from '../../../../../../statement/models/statement-search.model';
import { AccountStatusNew } from 'src/app/dashboard/shared/account-status-new.enum';

@Component({
  selector: 'app-generic-accounts-table',
  templateUrl: './generic-accounts-table.component.html',
  styleUrls: ['./generic-accounts-table.component.scss'],
})
export class GenericAccountsTableComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @Input() accountTable!: AccountsTable;
  @Output() resetCurrentTable = new EventEmitter<void>();

  filtersGroup!: FormGroup;
  dataSource!: GenericAccountsTableDatasource;
  displayedColumns: string[] = [
    'date',
    'description',
    'debit',
    'credit',
    'balance',
  ];

  private formSub?: Subscription;
  private sortSub?: Subscription;
  private mergeSub?: Subscription;

  constructor(
    private fb: FormBuilder,
    private dashboardService: DashboardService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.dataSource = new GenericAccountsTableDatasource(
      this.dashboardService,
      this.errorService
    );

    this.filtersGroup = this.fb.group({
      search: null,
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.loadPage()); // listens for filters values

    this.formSub = this.searchControl.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(() => {
        this.paginator.pageIndex = 0;
        this.loadPage();
      });

    // reset the paginator after sorting
    this.sortSub = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );

    this.mergeSub = merge(this.sort.sortChange, this.paginator.page).subscribe(
      () => {
        this.loadPage();
      }
    );
  }

  loadPage(): void {
    const data = calculateDateRange();
    const params: StatementSearch = {
      dtStartDate: this.accountTable.accountDetails.dateCreated,
      dtEndDate: data.present,
      page: this.paginator.pageIndex + 1,
      limit: this.paginator.pageSize,
      orderField: this.sort.active,
      orderAscending: this.sort.direction === 'asc',
    };

    this.dataSource.clear();
    // TODO: get statement
    // this.dataSource.loadStatements(
    //   this.accountTable.accountDetails.numId,
    //   params
    // );
  }

  isAccountOpen(status: AccountStatusNew): boolean {
    return status === AccountStatusNew.ACTIVE;
  }

  getAccountStatusName(status: AccountStatusNew): string {
    return accountStatusNamesMap.get(status)!;
  }

  goBack(): void {
    this.resetCurrentTable.emit();
  }

  ngOnDestroy(): void {
    this.formSub?.unsubscribe();
    this.sortSub?.unsubscribe();
    this.mergeSub?.unsubscribe();
  }

  get searchControl(): FormControl {
    return this.filtersGroup.get('search') as FormControl;
  }
}
