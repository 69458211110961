<div fxLayout="row" fxLayoutAlign="center">
  <div
    fxFlex="0 1 1056px"
    fxAlign="center"
    fxLayout="column"
    fxLayoutGap="20px"
  >
    <div fxLayout="row" fxLayoutGap="60px" fxLayoutGap.lt-md="0">
      <div
        fxFlex="1 1 0"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="20px"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="flex-start flex-start"
      >
        <ng-content select="[onboardingLayoutTitle]"></ng-content>
      </div>
      <div fxFlex="0 0 250px" fxFlex.lt-md="0 0 0"></div>
    </div>
    <div
      fxLayout="row"
      fxLayoutGap="60px"
      fxLayout.lt-md="column-reverse"
      fxLayoutGap.lt-md="20px"
    >
      <div fxLayout="column" fxLayoutGap="20px" fxFlex="1 1 0">
        <ng-content></ng-content>
      </div>
      <app-stepper-new
        [steps]="steps"
        fxFlex="0 0 250px"
        fxFlex.lt-md="0 0 auto"
      ></app-stepper-new>
    </div>
  </div>
</div>
