<table mat-table [dataSource]="dataSource ?? []" [fixedLayout]="true">
  <!-- Old cardholder ID -->
  <ng-container matColumnDef="oldChid">
    <th mat-header-cell *matHeaderCellDef>Cardholder Id</th>
    <td mat-cell *matCellDef="let row">{{ row.oldChid }}</td>
  </ng-container>

  <!-- New cardholder ID -->
  <ng-container matColumnDef="newChid">
    <th mat-header-cell *matHeaderCellDef>New cardholder Id</th>
    <td mat-cell *matCellDef="let row">{{ row.newChid }}</td>
  </ng-container>

    <!-- New cardholder ID -->
    <ng-container matColumnDef="lineUniqueId">
      <th mat-header-cell *matHeaderCellDef>Unique Id</th>
      <td mat-cell *matCellDef="let row">{{ row.lineUniqueId }}</td>
    </ng-container>

  <!-- Status Column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td
      mat-cell
      *matCellDef="let row"
      [ngSwitch]="statusMap?.get(row.lineUniqueId)"
    >
      <div *ngSwitchCase="undefined">Pending</div>
      <div *ngSwitchCase="true" class="success">Done</div>
      <div *ngSwitchCase="false" class="failure">Failed</div>
    </td>
  </ng-container>
  <tr
    mat-header-row
    *matHeaderRowDef="
      showStatus ? displayedColumnsWithStatus : displayedColumns
    "
  ></tr>
  <tr
    mat-row
    *matRowDef="
      let row;
      columns: showStatus ? displayedColumnsWithStatus : displayedColumns
    "
    [class.last]="row.last"
  ></tr>
</table>
