import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ComponentCanDeactivate } from 'src/app/guards/pending-changes-guard';
import { Country } from 'src/app/shared/models/country.model';
import { UserType } from 'src/app/login/models/user-type.enum';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { RegisteredUser } from '../../models/registered-user.model';
import { RegisteredUsersService } from '../../registered-users.service';
import { UserState } from '../../../../../login/models/user-state.enum';
import { SharedService } from 'src/app/shared/services/shared.service';
import { RegisteredUserProfileIndividualNewComponent } from './registered-user-profile-individual-new/registered-user-profile-individual.component';
import { Program } from 'src/app/login/models/program.enum';
import { RegisteredUserProfileCorporateNewComponent } from './registered-user-profile-corporate-new/registered-user-profile-corporate-new.component';

@Component({
  selector: 'app-registered-user-profile-new',
  templateUrl: './registered-user-profile.component.html',
  styleUrls: ['./registered-user-profile.component.scss'],
})
export class RegisteredUserProfileNewComponent
  implements OnInit, ComponentCanDeactivate
{
  @Input() userId!: number;
  @Input() registeredUser!: RegisteredUser;
  @Input() state?: UserState;
  @Input() program!: Program;
  @Output() reloadUser = new EventEmitter<void>();

  userTypes = UserType;
  userType: UserType | undefined;
  isLoading = false;
  countries: Country[] = [];
  acceptedCountries: Country[] = [];

  avatarUrl?: string | null = null;
  avatarErrorMessage = '';
  isAvatarUploading = false;

  @ViewChild(RegisteredUserProfileIndividualNewComponent)
  individualComponent?: RegisteredUserProfileIndividualNewComponent;
  @ViewChild(RegisteredUserProfileCorporateNewComponent)
  corporateComponent?: RegisteredUserProfileCorporateNewComponent;

  constructor(
    private registeredUsersService: RegisteredUsersService,
    private sharedService: SharedService,
    private errorService: ErrorService
  ) {}

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    const component = this.individualComponent || this.corporateComponent;
    return component ? component.canDeactivate() : true;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.avatarUrl = this.registeredUser.avatarUrl;
    this.sharedService.getAllCountries().subscribe(
      (countries) => {
        this.countries = countries;
        this.acceptedCountries = countries;
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  reloadProfile() {
    this.reloadUser.emit();
  }

  changeAvatar(file: File): void {
    this.isAvatarUploading = true;
    this.registeredUsersService.uploadAvatar(this.userId, file).subscribe(
      (avatarUrl) => {
        this.avatarUrl = avatarUrl;
        this.isAvatarUploading = false;
      },
      (err) => {
        this.avatarErrorMessage =
          err.status === 400 ? 'Wrong file format' : 'Upload failed';
        this.isAvatarUploading = false;
      }
    );
  }

  removeAvatar() {
    this.registeredUsersService.deleteAvatar(this.userId).subscribe(
      () => (this.avatarUrl = null),
      () => this.errorService.showErrorDialog()
    );
  }
}
