<app-base-login-page [title]="title">
  <app-login-card imgName="key.svg">
    <ng-container [ngSwitch]="currentStep">
      <app-provide-email *ngSwitchCase="resetPasswordSteps.EMAIL">
      </app-provide-email>
      <app-provide-new-password
        *ngSwitchCase="resetPasswordSteps.PASSWORD"
        [token]="token"
      >
      </app-provide-new-password>
    </ng-container>
    <a data-testid="back-to-login-page-link" routerLink="/login"
      >Back to log in page</a
    >
  </app-login-card>
</app-base-login-page>
