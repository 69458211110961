import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { countFee } from 'src/app/transfer/shared/fee.helper';
import { TransferService } from 'src/app/transfer/transfer.service';
import { RegistrationService } from '../../services/registration.service';
import { Router } from '@angular/router';

@Component({
  templateUrl: './initial-payment.component.html',
  styleUrls: ['./initial-payment.component.scss'],
})
export class InitialPaymentComponent implements OnInit {
  isLoading: boolean = false;
  isPaying: boolean = false;
  feeValue?: number;

  constructor(
    private errorService: ErrorService,
    private registrationService: RegistrationService,
    private transferService: TransferService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.transferService
      .getFees()
      .pipe(map((fees) => fees.CARDISSUE))
      .subscribe(
        (fee) => {
          this.feeValue = countFee(1, fee);
          this.isLoading = false;
        },
        () => {
          this.errorService.showErrorDialog();
          this.isLoading = false;
        }
      );
  }

  startPayment(): void {
    this.isPaying = true;

    // For now we skip the initial card payment and proceed to the account package creation of the individual
    this.registrationService.createAccountsPackage().subscribe(
      () => {
        // navigate to initial-payment-success once accounts are created
        this.router.navigate(['initial-payment-success']);
      },
      () => {
        this.errorService.showErrorDialog();
        this.isPaying = false;
      }
    );

    // TODO: add card fee payment and then the account creation
    // this.registrationService.initialCardPayment().subscribe(
    //   () => (this.isPaying = false),
    //   () => {
    //     this.errorService.showErrorDialog();
    //     this.isPaying = false;
    //   }
    // );
  }
}
