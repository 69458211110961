import { Component } from '@angular/core';

@Component({
  selector: 'app-big-loader-not-centered',
  templateUrl: './big-loader-not-centered.component.html',
  styleUrls: ['./big-loader-not-centered.component.scss'],
})
export class BigLoaderNotCenteredComponent {
  constructor() {}
}
