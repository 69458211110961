<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close [disabled]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <h1>{{ headerText }}</h1>
  <div class="comments-container" fxLayout="column" fxLayoutGap="33px">
    <ng-container
      *ngFor="let comment of comments; let i = index"
      [ngSwitch]="comment.commentType"
    >
      <!-- FILE UPLOADED -->
      <div *ngSwitchCase="commentTypes.FILE_UPLOADED" class="file-upload">
        <div [ngSwitch]="isAdmin">
          <ng-container *ngSwitchCase="true">
            Client uploads file {{ comment.content }}</ng-container
          >
          <ng-container *ngSwitchDefault
            >You upload file {{ comment.content }}</ng-container
          >
        </div>
      </div>
      <!-- REASON WHY NOT PROVIDED -->
      <div
        *ngSwitchCase="commentTypes.REASON_WHY_NOT_PROVIDED"
        class="client-comment"
      >
        <div class="author" [ngSwitch]="isAdmin">
          <ng-container *ngSwitchCase="true"
            >Client<span *ngIf="isReply(i)"> replies</span>:</ng-container
          >
          <ng-container *ngSwitchDefault
            >You<span *ngIf="isReply(i)"> reply</span>:</ng-container
          >
        </div>
        <div>{{ comment.content }}</div>
      </div>
      <!-- REJECTION REASON -->
      <div *ngSwitchCase="commentTypes.REJECTION_REASON" class="admin-comment">
        <div class="author" [ngSwitch]="isAdmin">
          <ng-container *ngSwitchCase="true"
            >You
            <span *ngIf="isFileRejection(i)">reject the file and </span
            >reply:</ng-container
          >
          <ng-container *ngSwitchDefault
            >eCREDO Team
            <span *ngIf="isFileRejection(i)">rejects the file and </span
            >replies:</ng-container
          >
        </div>
        <div>{{ comment.content }}</div>
      </div>
    </ng-container>
  </div>
  <div fxLayoutAlign="end center">
    <button mat-flat-button color="accent" mat-dialog-close>Close</button>
  </div>
</mat-dialog-content>
