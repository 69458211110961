<div class="main-container">
  <ngx-file-drop
    *ngIf="state === FileUploadState.isEmpty"
    dropZoneLabel="Drop the excel file here or"
    accept=".xlsx"
    [showBrowseBtn]="true"
    [multiple]="false"
    [directory]="false"
    browseBtnLabel="browse"
    dropZoneClassName="drop-zone"
    contentClassName="content"
    browseBtnClassName="browse-button"
    (onFileDrop)="dropped($event[0])"
  ></ngx-file-drop>
  <div *ngIf="state !== FileUploadState.isEmpty" class="states-container">
    <div [ngSwitch]="state" class="left-icon-container">
      <mat-icon
        *ngSwitchCase="FileUploadState.isProcessing"
        svgIcon="file"
        class="left-icon"
      ></mat-icon>
      <mat-icon
        *ngSwitchCase="FileUploadState.isErroring"
        svgIcon="cancel"
        class="left-icon error"
      ></mat-icon>
      <mat-icon
        *ngSwitchCase="FileUploadState.isUploaded"
        svgIcon="checked"
        class="left-icon checked"
      ></mat-icon>
    </div>
    <div class="text" [class.error]="state === FileUploadState.isErroring">
      {{ file?.name }}
    </div>
    <button
      *ngIf="state !== FileUploadState.isProcessing"
      mat-icon-button
      (click)="clear()"
    >
      <mat-icon svgIcon="trash" color="accent"></mat-icon>
    </button>
  </div>
</div>
