import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

enum ResetPasswordSteps {
  EMAIL = 'Email',
  PASSWORD = 'Password',
}

@Component({
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordSteps = ResetPasswordSteps;
  token: string = '';

  currentStep: ResetPasswordSteps = ResetPasswordSteps.EMAIL;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(first()).subscribe(({ token }) => {
      if (token) {
        this.token = token;
        this.currentStep = ResetPasswordSteps.PASSWORD;
      }
    });
  }

  get title(): string {
    switch (this.currentStep) {
      case ResetPasswordSteps.EMAIL:
        return 'My password';
      case ResetPasswordSteps.PASSWORD:
        return 'New password';
    }
  }
}
