<div fxLayout="column" class="main">
  <div fxHide.lt-sm fxLayout="row" fxLayoutAlign="space-between center">
    <h1>From</h1>
    <button mat-icon-button class="info-button" (click)="showInfo()">
      <mat-icon class="info-icon">info_outline</mat-icon>
    </button>
    <div></div>
    <h1>To</h1>
  </div>
  <div
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutAlign="space-between center"
    fxLayoutGap.lt-sm="10px"
  >
    <!-- FROM -->
    <div fxLayoutAlign.lt-sm="start" fxLayout.lt-sm="column">
      <div fxLayout="row">
        <h1 class="small-screen-h1" fxHide.gt-xs>From</h1>
        <button
          mat-icon-button
          fxHide.gt-xs
          class="small-info-button"
          (click)="showInfo()"
        >
          <mat-icon
            class="small-info-icon small-screen-h1 material-icons-outlined"
            >info_outline</mat-icon
          >
        </button>
      </div>

      <div
        fxLayout
        class="account-selector"
        [class.open]="fromTrigger?.menuOpen"
      >
        <app-account-list-item
          [ngClass]="noAccountOptions(true) ? 'empty-list' : 'list'"
          [account]="fromAccountControl.value"
          [currency]="fromAccountControl.value?.ccy"
        ></app-account-list-item>
        <div
          *ngIf="!noAccountOptions(true)"
          class="right-section"
          fxLayoutAlign="center center"
          [matMenuTriggerFor]="fromMenu"
          #fromTrigger="matMenuTrigger"
        >
          <mat-icon
            *ngIf="!noAccountOptions(true)"
            svgIcon="chevron-contracted"
          ></mat-icon>
        </div>
      </div>
    </div>

    <!-- SWITCH ACCOUNTS -->
    <button mat-icon-button color="accent" (click)="switchAccounts()">
      <mat-icon
        svgIcon="both-directions"
        [class.switch-accounts-button]="$breakpointObserver | async"
      ></mat-icon>
    </button>

    <!-- TO -->
    <div fxLayoutAlign.lt-sm="start" fxLayout.lt-sm="column">
      <h1 fxHide.gt-xs class="negative-margin small-screen-h1">To</h1>
      <div fxLayout class="account-selector" [class.open]="toTrigger?.menuOpen">
        <app-account-list-item
          [ngClass]="noAccountOptions(false) ? 'empty-list' : 'list'"
          [account]="toAccountControl.value"
          [currency]="toAccountControl.value?.ccy"
        ></app-account-list-item>
        <div
          *ngIf="!noAccountOptions(false)"
          class="right-section"
          fxLayoutAlign="center center"
          [matMenuTriggerFor]="toMenu"
          #toTrigger="matMenuTrigger"
        >
          <mat-icon
            *ngIf="!noAccountOptions(false)"
            svgIcon="chevron-contracted"
          ></mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- FROM MENU -->
<mat-menu
  #fromMenu="matMenu"
  xPosition="before"
  class="g-accounts-selector-menu"
>
  <div
    *ngFor="let account of fromAccountsList"
    class="menu-item"
    (click)="selectAccount(account, true)"
  >
    <app-account-list-item
      [account]="account"
      [currency]="account.ccy"
      [isSmallVersion]="true"
    ></app-account-list-item>
  </div>
</mat-menu>

<!-- TO MENU -->
<mat-menu #toMenu="matMenu" xPosition="before" class="g-accounts-selector-menu">
  <div
    *ngFor="let account of toAccountsList"
    class="menu-item"
    (click)="selectAccount(account, false)"
  >
    <app-account-list-item
      [account]="account"
      [currency]="account.ccy"
      [isSmallVersion]="true"
    ></app-account-list-item>
  </div>
</mat-menu>
