<div fxLayoutAlign="end">
  <button mat-icon-button [mat-dialog-close]="shouldRefresh">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="dialog-container">
  <div fxLayout="column">
    <h1 class="dialog-title">
      {{ card?.status === AccountStatus.DEPOSIT_ONLY ? 'Unlock' : 'Lock' }} the
      card
    </h1>

    <div class="dialog-description" fxLayout="column">
      <span>
        You are about to
        {{
          card?.status === AccountStatus.DEPOSIT_ONLY ? 'unlock' : 'lock'
        }}
        the <strong>{{ card?.name }}</strong
        >.
      </span>
      <span> Do you want to continue?</span>
    </div>
  </div>
  <div class="buttons">
    <button
      mat-flat-button
      color="accent"
      class="success-button"
      (click)="lockUnlockCard()"
      [disabled]="isLockingCard"
      [appButtonLoader]="isLockingCard"
    >
      Yes,
      {{ card?.status === AccountStatus.DEPOSIT_ONLY ? 'unlock' : 'lock' }} the
      card
    </button>
    <button
      mat-stroked-button
      color="accent"
      [mat-dialog-close]="shouldRefresh"
      [disabled]="isLockingCard"
    >
      {{
        card?.status === AccountStatus.DEPOSIT_ONLY
          ? 'No, keep the card locked'
          : 'Don’t lock the card'
      }}
    </button>
  </div>
</div>
