<app-big-loader *ngIf="isLoading"></app-big-loader>
<div *ngIf="!isLoading">
  <div fxLayout fxLayoutAlign="space-between" class="header-container">
    <h1>{{ name }}</h1>
    <div fxLayout="column" fxLayoutGap="10px">
      <button mat-flat-button class="g-small" routerLink="../">
        Back to the list
      </button>
      <button
        class="g-small"
        data-testid="add-notes-button"
        mat-flat-button
        (click)="addNote()"
      >
        Notes ({{ registeredUser?.noteCount }})
        <mat-icon svgIcon="add"></mat-icon>
      </button>
    </div>
  </div>
  <mat-card>
    <mat-tab-group
      class="g-tabs-with-underline"
      [selectedIndex]="selectedIndex"
    >
      <mat-tab label="Profile">
        <app-registered-user-profile-new
          [userId]="userId"
          [registeredUser]="registeredUser!"
          [state]="state"
          [program]="program!"
          (reloadUser)="reloadUser()"
        ></app-registered-user-profile-new>
      </mat-tab>
      <mat-tab label="Risk Assessment">
        <app-risk-assessment
          *ngIf="registeredUser"
          [userId]="userId"
          [personalData]="registeredUser?.personalData"
          [corporateData]="registeredUser?.corporateData"
          [companyDirectors]="registeredUser?.companyDirectors"
          [companyGroupMembers]="registeredUser?.companyGroupMembers"
          [companyCloseLinks]="registeredUser?.companyCloseLinks"
          [state]="state"
          [isInOnboarding]="false"
          [adjustmentSuggestionMade]="registeredUser?.adjustmentSuggestionMade"
          (reloadUser)="reloadRiskAssessmentTab()"
        ></app-risk-assessment>
      </mat-tab>
      <mat-tab label="Audit Trail">
        <app-action-logs
          *ngIf="registeredUser"
          [userId]="userId"
          [actionLogs]="registeredUser?.actionLogs"
          (reloadActionLogs)="loadActionLogs()"
        ></app-action-logs>
      </mat-tab>
      <mat-tab label="Accounts">
        <ng-template matTabContent>
          <app-registered-user-accounts
            [userId]="userId"
          ></app-registered-user-accounts>
        </ng-template>
      </mat-tab>
      <mat-tab label="Logs">
        <app-registered-user-logs
          [email]="email!"
          [userId]="userId"
        ></app-registered-user-logs>
      </mat-tab>
      <mat-tab label="Documents">
        <ng-template matTabContent>
          <app-registered-user-documents
            [userId]="userId"
          ></app-registered-user-documents
        ></ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</div>
