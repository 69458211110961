<app-big-loader *ngIf="isLoading"></app-big-loader>
<div *ngIf="!isLoading" class="content">
  <app-user-profile-individual-new
    *ngIf="userProfileData.personalData"
    [requestMoreData]="userProfileData.requestMoreData"
    [individualUserProfileData]="userProfileData.personalData"
    [countries]="countries"
    (dataChanged)="refresh()"
  ></app-user-profile-individual-new>
  <app-user-profile-business-new
    *ngIf="userProfileData.corporateData && userProfileData.companyDirectors"
    [requestMoreData]="userProfileData.requestMoreData"
    [businessUserProfileData]="userProfileData.corporateData"
    [companyDirectors]="userProfileData.companyDirectors"
    [companyAccounts]="userProfileData.companyAccounts"
    [companyCounterparties]="userProfileData.companyCounterparties"
    [companyGroupMembers]="userProfileData.companyGroupMembers"
    [companyCloseLinks]="userProfileData.companyCloseLinks"
    [companyDirectorCloseLinks]="userProfileData.companyDirectorCloseLinks"
    [countries]="countries"
    (dataChanged)="refresh()"
  ></app-user-profile-business-new>
</div>
