import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Program } from '../../login/models/program.enum';
import { Sections } from '../shared/sections.enum';
import { Transaction } from '../models/transaction.model';
import { WalletInstance } from '../models/walletInstance';
import { Card } from '../models/card.model';

@Component({
  selector: 'app-account-section',
  templateUrl: './account-section.component.html',
  styleUrls: ['./account-section.component.scss'],
})
export class AccountSectionComponent {
  @Input() svgIconName!: string;
  @Input() name?: string;
  @Input() availableBalance?: number;
  @Input() balance?: number;
  @Input() section!: Sections;
  @Input() isLoadingIBAN!: boolean;
  @Input() isLoadingCards!: boolean;
  @Input() isLoadingIBANTransactions!: boolean;
  @Input() isLoadingCardTransactions!: boolean;
  @Input() accountNumber?: string;
  @Input() cardholder?: string;
  @Input() cards?: Card[];
  @Input() isCardActive?: boolean;
  @Input() program?: Program;
  @Input() accountTransactions?: Transaction[];
  @Input() wallet!: WalletInstance;
  @Input() isIssued?: boolean;
  @Input() isLoadingWallet!: boolean;
  @Input() isLocked?: boolean;
  @Input() currentCard?: Card;
  @Output() readonly cardActivated = new EventEmitter<void>();
  @Output() readonly pickedCard = new EventEmitter<Card>();

  Program = Program;
  isCards?: boolean;
  Sections = Sections;
}
