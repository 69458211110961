import { Component, Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

export interface MassPaymentsSnackbarData {
  successCount: number;
  failureCount: number;
}

@Component({
  selector: 'app-mass-payments-snackbar',
  templateUrl: './mass-payments-snackbar.component.html',
  styleUrls: ['./mass-payments-snackbar.component.scss'],
})
export class MassPaymentsSnackbarComponent {
  successCount: number;
  failureCount: number;

  constructor(
    private snackBarRef: MatSnackBarRef<MassPaymentsSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: MassPaymentsSnackbarData
  ) {
    this.successCount = data.successCount;
    this.failureCount = data.failureCount;
  }

  close(): void {
    this.snackBarRef.dismiss();
  }
}
