import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { documentValidator } from 'src/app/shared/document.validator';
import { getIdentityDocumentsTypesSet } from 'src/app/shared/helpers/various-helpers.helper';
import { AppDocumentType } from '../../models/document-type.enum';
import { AppDocument } from '../../models/document.model';
import { IdentityDocumentType } from '../../models/identity-document-type.enum';

@Component({
  selector: 'app-verified-document-form',
  templateUrl: './verified-document-form.component.html',
  styleUrls: ['./verified-document-form.component.scss'],
})
export class VerifiedDocumentFormComponent implements OnInit, OnDestroy {
  @Input() isEDD?: boolean;
  @Input() isReviewed: boolean = false;
  @Input() isFirstTimeEDD: boolean = false;
  @Input() show: boolean = true;
  @Input() disableFileRemove: boolean = false;
  @Input() set documents(val: AppDocument[]) {
    this.resetForm(); // reset form to remove previous values
    // adds data to predefined controls
    this.documentsControls.forEach((control) =>
      control.setValue({
        ...control.value,
        doc: val.find((doc) => doc.type === control.value.documentType),
        customLabel:
          control.value.documentType === AppDocumentType.PROOF_OF_ADDRESS
            ? this.proofOfAddressLabel
            : '',
      })
    );
    // in EDD don't display another files in Uploaded documents section, because they appear in app-edd-document-form
    // because when in EDD_SUBMITTED, more documents may be requested from customer
    if (!this.isEDD) {
      // creates controls with data for documents with type ADDITIONAL_FILE
      this.addAdditionalDocuments(val);
    }
    // selects identityDocumentType based on uploaded documents
    this.setIdentityDocumentType(val);
    this._documents = val;
    const identityDocumentsTypesSet = getIdentityDocumentsTypesSet();
    const doctype = val.find((doc) =>
      identityDocumentsTypesSet.has(doc.type)
    )?.type;
    this.documentsControls.forEach((control) => {
      if (
        control.value.doc?.isAccepted &&
        control.value.doc.type !== AppDocumentType.ANOTHER_FILE &&
        control.value.doc.type !== AppDocumentType.PROOF_OF_ADDRESS &&
        doctype !== control.value.doc.type // dont disable selected identity document type
      ) {
        control.disable();
      }
    });
  }
  get documents(): AppDocument[] {
    return this._documents;
  }

  @Input() companyDirectorId: number | undefined = undefined;
  @Input() set proofOfAddressLabel(val: string) {
    this._proofOfAddressLabel = val;
  }
  get proofOfAddressLabel(): string {
    return this._proofOfAddressLabel;
  }
  @Output() isComplete = new EventEmitter<boolean>();

  private _documents: AppDocument[] = [];
  private _proofOfAddressLabel: string = 'Proof of Address';
  private identityDocumentTypeSub: Subscription | null = null;
  private documentsFormSub: Subscription | null = null;
  identityDocumentTypesEntries = Object.entries(IdentityDocumentType);

  // KYC: form for id docs/proof of address
  // KYB: form for id docs/proof of address and another files for key persons
  documentsForm = this.fb.group({
    identityDocumentType: ['', Validators.required],
    documents: this.fb.array([
      this.fb.control(
        {
          documentType: AppDocumentType.IDENTITY_CARD,
        },
        documentValidator()
      ),
      this.fb.control(
        {
          documentType: AppDocumentType.IDENTITY_CARD_SECOND_PAGE,
        },
        documentValidator()
      ),
      this.fb.control(
        {
          documentType: AppDocumentType.PASSPORT,
        },
        documentValidator()
      ),
      this.fb.control(
        {
          documentType: AppDocumentType.PROOF_OF_ADDRESS,
        },
        documentValidator()
      ),
    ]),
  });

  resetForm() {
    this.documentsForm = this.fb.group({
      identityDocumentType: ['', Validators.required],
      documents: this.fb.array([
        this.fb.control(
          {
            documentType: AppDocumentType.IDENTITY_CARD,
          },
          documentValidator()
        ),
        this.fb.control(
          {
            documentType: AppDocumentType.IDENTITY_CARD_SECOND_PAGE,
          },
          documentValidator()
        ),
        this.fb.control(
          {
            documentType: AppDocumentType.PASSPORT,
          },
          documentValidator()
        ),
        this.fb.control(
          {
            documentType: AppDocumentType.PROOF_OF_ADDRESS,
          },
          documentValidator()
        ),
      ]),
    });
    this.ngOnInit();
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.identityDocumentTypeSub = this.identityDocumentTypeControl.valueChanges
      .pipe(startWith(this.identityDocumentTypeControl.value))
      .subscribe((val: IdentityDocumentType) => {
        // disables all identity controls
        this.idCardControl?.disable();
        this.idCardSecondPageControl?.disable();
        this.passportControl?.disable();

        // enables needed controls
        switch (val) {
          case IdentityDocumentType['ID card']: {
            this.idCardControl?.enable();
            this.idCardSecondPageControl?.enable();
            break;
          }
          case IdentityDocumentType.Passport: {
            this.passportControl?.enable();
            break;
          }
        }
      });

    this.documentsFormSub = this.documentsForm.statusChanges
      .pipe(startWith(this.documentsForm.status))
      .subscribe((status) =>
        setTimeout(() => {
          this.isComplete.emit(status === 'VALID');
        })
      );
  }

  ngOnDestroy(): void {
    this.identityDocumentTypeSub?.unsubscribe();
    this.documentsFormSub?.unsubscribe();
  }

  addAnotherFile(): void {
    this.documentsArray.push(
      this.fb.control(
        { documentType: AppDocumentType.ANOTHER_FILE },
        documentValidator()
      )
    );
  }

  removeField(index: number): void {
    this.documentsArray.removeAt(index);
  }

  // Adds controls for documents with ANOTHER_FILE type
  private addAdditionalDocuments(documents: AppDocument[]): void {
    documents
      .filter((doc) => doc.type === AppDocumentType.ANOTHER_FILE)
      .forEach((doc) =>
        this.documentsArray.push(
          this.fb.control(
            {
              doc,
              documentType: doc.type,
            },
            documentValidator()
          )
        )
      );
  }

  // Set identityDocumentType based on uploaded documents
  private setIdentityDocumentType(documents: AppDocument[]): void {
    const identityDocumentsTypesSet = getIdentityDocumentsTypesSet();
    const doctype = documents.find((doc) =>
      identityDocumentsTypesSet.has(doc.type)
    )?.type;
    switch (doctype) {
      case AppDocumentType.IDENTITY_CARD:
      case AppDocumentType.IDENTITY_CARD_SECOND_PAGE:
        this.identityDocumentTypeControl.setValue(
          IdentityDocumentType['ID card']
        );
        break;
      case AppDocumentType.PASSPORT:
        this.identityDocumentTypeControl.setValue(
          IdentityDocumentType.Passport
        );
        break;
      default:
        this.identityDocumentTypeControl.reset();
    }
  }

  get identityDocumentTypeControl(): FormControl {
    return this.documentsForm.get('identityDocumentType') as FormControl;
  }
  get documentsArray(): FormArray {
    return this.documentsForm.get('documents') as FormArray;
  }
  get documentsControls(): FormControl[] {
    return this.documentsArray.controls as FormControl[];
  }
  get idCardControl(): AbstractControl | null | undefined {
    return this.documentsArray.get([0]);
  }
  get idCardSecondPageControl(): AbstractControl | null | undefined {
    return this.documentsArray.get([1]);
  }
  get passportControl(): AbstractControl | null | undefined {
    return this.documentsArray.get([2]);
  }
  get proofOfAddressControl(): AbstractControl | null | undefined {
    return this.documentsArray.get([3]);
  }
}
