import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { of, Subscription } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { OnboardingService } from '../../onboarding.service';
import { Country } from 'src/app/shared/models/country.model';
import { OnboardingField } from '../../models/onboarding-field.model';
import { OnboardingFieldId } from 'src/app/admin/users/models/onboarding-field-id.enum';
import {
  getCountryById,
  getStringValue,
} from 'src/app/shared/helpers/various-helpers.helper';
import {
  CompanyGroupMember,
  SaveCompanyGroupMember,
} from '../../models/company-group-member.model';
import { Address } from 'src/app/shared/models/address.model';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-group-member-form',
  templateUrl: './group-member-form.component.html',
  styleUrls: ['./group-member-form.component.scss'],
})
export class GroupMemberFormComponent implements OnInit, OnDestroy {
  @Input() index: number = 0; // used for numbering the components
  @Input() countries: Country[] = [];
  @Input() isReviewed: boolean = false;
  @Input() fields: OnboardingField[] = [];
  @Input() set groupMember(val: CompanyGroupMember) {
    this._groupMember = val;
    if (JSON.stringify(val) !== JSON.stringify(this.groupMemberForm.value)) {
      this.groupMemberForm.patchValue(val, { emitEvent: false });
    }
    if (this.groupMemberForm.valid) {
      this.inputsComplete = true;
    }
    // fieldsMap
    this.fieldsMap.clear();
    this.fields
      ?.filter((field) => !!field.comment && field.groupMemberId === val.id)
      .forEach((field) => {
        // todo
        let commentedValue = field.commentedValue;
        if (field.id.endsWith('.countryId')) {
          // converts countryId to country name
          commentedValue = this.getCountryById(
            this.countries,
            parseInt(commentedValue)
          );
        }
        // converts date
        if (field.id.endsWith('.incorporationDate')) {
          commentedValue = DateTime.fromISO(commentedValue).toLocaleString(
            DateTime.DATE_MED
          );
        }
        this.fieldsMap.set(field.id, {
          comment: field.comment,
          commentedValue,
        });
      });
  }
  get groupMember(): CompanyGroupMember {
    return this._groupMember;
  }
  @Output() groupMemberChange = new EventEmitter<CompanyGroupMember>();
  @Output() groupMemberRemove = new EventEmitter<number>(); // emits group member id
  @Output() isComplete = new EventEmitter<boolean>();

  fieldsMap: Map<
    string,
    { comment: string | null; commentedValue: string | null } | null
  > = new Map<
    string,
    { comment: string | null; commentedValue: string | null } | null
  >();
  isRemoving: boolean = false;

  today: Date = new Date();
  OnboardingFieldId = OnboardingFieldId;
  getCountryById = getCountryById;
  getStringValue = getStringValue;

  groupMemberForm: FormGroup = this.fb.group({
    id: [null],
    name: [
      '',
      [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
        Validators.maxLength(100),
      ],
    ],
    registrationNumber: [
      '',
      [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
        Validators.maxLength(50),
      ],
    ],
    incorporationDate: ['', [Validators.required]],
    address: this.fb.group({
      streetAddress: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 .,&`'\\/()-]*$"),
          Validators.maxLength(100),
        ],
      ],
      additionalStreetAddress: [
        '',
        [
          Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
          Validators.maxLength(100),
        ],
      ],
      postCode: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9 ()\\/-]*$'),
          Validators.maxLength(50),
        ],
      ],
      city: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 `'\\/()-]*$"),
          Validators.maxLength(50),
        ],
      ],
      countryId: ['', Validators.required],
    }),
  });

  private _inputsComplete: boolean | undefined = undefined;
  private _groupMember!: CompanyGroupMember;
  private groupMemberFormSub: Subscription | undefined = undefined;

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private onboardingService: OnboardingService
  ) {}

  ngOnInit(): void {
    let lastQueueValue: CompanyGroupMember | null = null;
    let savedValue: CompanyGroupMember | null = null;

    // saving Form when values changed (requests are sent one by one, intermediate values are omitted)
    this.groupMemberFormSub = this.groupMemberForm.valueChanges
      .pipe(
        tap((val) => (lastQueueValue = val)),
        concatMap((val) => {
          if (val === lastQueueValue) {
            this.inputsComplete = false; // prevents submitting application when saving in progress or form is invalid
            if (this.groupMemberForm.valid) {
              savedValue = val;
              const body = {
                ...val,
                incorporationDate:
                  typeof this.incorporationDateControl?.value === 'string'
                    ? this.incorporationDateControl?.value
                    : (
                        this.incorporationDateControl?.value as DateTime
                      ).toISODate(),
              };
              return this.onboardingService
                .updateCompanyGroupMember(this.groupMember.id, body)
                .pipe(
                  tap((val) => {
                    if (savedValue === lastQueueValue) {
                      this.inputsComplete = this.groupMemberForm.valid;
                      this.groupMemberChange.emit(val);
                    }
                  })
                );
            } else {
              return of(null);
            }
          } else {
            return of(null);
          }
        })
      )
      .subscribe(
        () => {},
        () => this.errorService.showErrorDialog()
      );
  }

  ngOnDestroy(): void {
    this.groupMemberFormSub?.unsubscribe();
  }

  removeGroupMember(groupMember: CompanyGroupMember): void {
    this.inputsComplete = false; // prevents submitting application when removing in progress
    this.isRemoving = true;

    // set them as undefined to trigger removal
    const address: Address = {
      streetAddress: '',
      additionalStreetAddress: '',
      postCode: '',
      city: '',
      countryId: 60,
    };
    const body: SaveCompanyGroupMember = {
      name: '',
      registrationNumber: '',
      incorporationDate: '',
      address: address,
      isActive: false,
    };
    this.onboardingService
      .updateCompanyGroupMember(groupMember.id, body)
      .subscribe(
        (modifiedGroupMember) => {
          if (modifiedGroupMember) {
            this.groupMemberChange.emit(modifiedGroupMember);
          } else {
            this.groupMemberRemove.emit(groupMember.id);
          }
          this.isRemoving = false;
        },
        () => {
          this.isRemoving = false;
          this.errorService.showErrorDialog();
        }
      );
  }

  // set to true when inputs are saved and valid
  set inputsComplete(val: boolean | undefined) {
    if (val !== undefined) {
      this.isComplete.emit(val);
    }
    this._inputsComplete = val;
  }
  get inputsComplete(): boolean | undefined {
    return this._inputsComplete;
  }
  // Company group member
  get nameControl(): AbstractControl | null {
    return this.groupMemberForm.get('name');
  }
  get registrationNumberControl(): AbstractControl | null {
    return this.groupMemberForm.get('registrationNumber');
  }
  get incorporationDateControl(): AbstractControl | null {
    return this.groupMemberForm.get('incorporationDate');
  }
  // Company group member address
  get addressGroup(): AbstractControl | null {
    return this.groupMemberForm.get('address');
  }
  get streetAddressControl(): AbstractControl | null | undefined {
    return this.addressGroup?.get('streetAddress');
  }
  get additionalStreetAddressControl(): AbstractControl | null | undefined {
    return this.addressGroup?.get('additionalStreetAddress');
  }
  get postCodeControl(): AbstractControl | null | undefined {
    return this.addressGroup?.get('postCode');
  }
  get cityControl(): AbstractControl | null | undefined {
    return this.addressGroup?.get('city');
  }
  get countryIdControl(): AbstractControl | null | undefined {
    return this.addressGroup?.get('countryId');
  }
}
