<div fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div>
  <div class="spinner-container" *ngIf="isLoadingAmlHit">
    <app-big-loader></app-big-loader>
  </div>
  <mat-dialog-content *ngIf="amlHit">
    <div class="table-container">
      <table>
        <tr>
          <th class="factors"></th>
          <th></th>
        </tr>

        <tr>
          <td class="field">Full Name</td>
          <td>{{ amlHit.name }}</td>
        </tr>

        <tr>
          <td class="field">Entity Type</td>
          <td>{{ amlHit.entity_type }}</td>
        </tr>
        <tr>
          <td class="field">Score</td>
          <td>{{ amlHit.score }}/2</td>
        </tr>

        <tr>
          <td class="field">Relevance</td>
          <td>
            <div *ngFor="let entry of amlHit.match_types">
              {{ entry | replaceUnderscore | titlecase }}
              {{ getRelevanceDescription(entry) }}
            </div>
          </td>
        </tr>

        <tr>
          <td class="field">Matched</td>
          <td>
            <div *ngFor="let entry of amlHit.types">
              {{ entry | titlecase }}
            </div>
          </td>
        </tr>

        <tr>
          <td colspan="2"><mat-divider></mat-divider></td>
        </tr>

        <tr>
          <td class="field">Alternative names</td>
          <td>
            <div *ngFor="let entry of amlHit.alternative_names">
              {{ entry }}<br />
            </div>
          </td>
        </tr>

        <tr>
          <td colspan="2"><mat-divider></mat-divider></td>
        </tr>

        <tr>
          <td class="field">Assets</td>
          <td>
            <div *ngFor="let entry of amlHit.assets">
              Public URL:
              <a [href]="entry.public_url" target="_blank">{{
                entry.public_url
              }}</a
              ><br />
              Source: {{ entry.source }}<br />
              Type: {{ entry.type }}<br />
              URL: <a [href]="entry.url" target="_blank">{{ entry.url }}</a>
              <br /><br />
            </div>
          </td>
        </tr>

        <tr>
          <td colspan="2"><mat-divider></mat-divider></td>
        </tr>

        <tr>
          <td class="field">Associates</td>
          <td>
            <div *ngFor="let entry of amlHit.associates">
              Name: {{ entry.name }}<br />
              Association: {{ entry.association }} <br /><br />
            </div>
          </td>
        </tr>

        <tr>
          <td colspan="2"><mat-divider></mat-divider></td>
        </tr>

        <tr>
          <td class="field">Media</td>
          <td>
            <div *ngFor="let entry of amlHit.media">
              Date: {{ entry.date }} <br />
              Title: {{ entry.title }}<br />
              Snippet: {{ entry.snippet }}<br />
              URL: <a [href]="entry.url" target="_blank">{{ entry.url }}</a
              ><br /><br />
            </div>
          </td>
        </tr>

        <tr>
          <td colspan="2"><mat-divider></mat-divider></td>
        </tr>

        <ng-container *ngFor="let entry of objectKeys(amlHit.fields)">
          <tr>
            <td class="field">{{ entry }}</td>
            <td>
              <div *ngFor="let item of amlHit.fields[entry]">
                Value: {{ item.value }}<br />
                Source: {{ item.source }}<br />
                <span
                  [innerHTML]="
                    item.tag ? 'Tag: ' + item.tag + '<br /><br />' : '<br />'
                  "
                ></span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2"><mat-divider></mat-divider></td>
          </tr>
        </ng-container>

        <tr>
          <td class="field">Source notes</td>
          <td>
            <div *ngFor="let entry of amlHit.sources">
              Source Name: {{ amlHit.source_notes[entry].name }} <br />
              AML Types: {{ amlHit.source_notes[entry].aml_types }} <br />
              Country codes: {{ amlHit.source_notes[entry].country_codes }}
              <br />
              Listing started (UTC):
              {{ amlHit.source_notes[entry].listing_started_utc }}<br />
              URL:
              <a [href]="amlHit.source_notes[entry].url" target="_blank">{{
                amlHit.source_notes[entry].url
              }}</a>
              <br /><br />
            </div>
          </td>
        </tr>

        <tr>
          <td colspan="2"><mat-divider></mat-divider></td>
        </tr>

        <tr>
          <td class="field">Sources</td>
          <td>
            <div *ngFor="let entry of amlHit.sources">
              {{ entry | titlecase }}
            </div>
          </td>
        </tr>
      </table>
    </div>
  </mat-dialog-content>
</div>
