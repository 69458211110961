import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { StatementItem } from './models/statement-item.model';
import { StatementSearch } from './models/statement-search.model';
import { StatementService } from './statement.service';

export class StatementDataSource implements DataSource<StatementItem> {
  private statementsSubject = new BehaviorSubject<StatementItem[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private lengthSubject = new BehaviorSubject<number>(0);
  public lengthSubjectObs = this.lengthSubject.asObservable();
  private currentRequestSub: Subscription | undefined;
  public isLoadingObs = this.loadingSubject.asObservable();

  constructor(
    private statementService: StatementService,
    private errorService: ErrorService
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<StatementItem[]> {
    return this.statementsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.statementsSubject.complete();
    this.loadingSubject.complete();
    this.lengthSubject.complete();
  }

  // count of all items in the source
  getLength(): Observable<number> {
    return this.lengthSubject.asObservable();
  }

  loadStatements(data: StatementSearch): void {
    this.currentRequestSub?.unsubscribe(); // cancels previous request
    this.loadingSubject.next(true);
    this.currentRequestSub = this.statementService
      .getStatement(data)
      .pipe(
        map((res) => {
          this.lengthSubject.next(res.totalCount);
          return res.items;
        }),
        catchError(() => {
          this.errorService.showErrorDialog();
          return of([]);
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((statements) => this.statementsSubject.next(statements));
  }

  getCount(): number {
    return this.lengthSubject.value;
  }

  clear() {
    this.currentRequestSub?.unsubscribe(); // cancels previous request
    this.lengthSubject.next(0);
    this.statementsSubject.next([]);
    this.loadingSubject.next(false);
  }
}
