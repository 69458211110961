export enum OccupationProfession {
  'Business owner' = 'Business owner',
  'Household management' = 'Household management',
  'Private sector employee' = 'Private sector employee',
  'Public sector employee' = 'Public sector employee',
  'Rentier' = 'Rentier',
  'Retired' = 'Retired',
  'Self-employed' = 'Self-employed',
  'Semi-government employee' = 'Semi-government employee',
  'Student' = 'Student',
  'Unemployed' = 'Unemployed',
}
