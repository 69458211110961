export enum AppDocumentType {
  IDENTITY_CARD = 1,
  PASSPORT = 2,
  PROOF_OF_ADDRESS = 3,
  CERTIFICATE_OF_INCORPORATION = 7,
  MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION = 8,
  LATEST_FINANCIAL_STATEMENTS = 9,
  CERTIFICATE_OF_SHAREHOLDERS = 10,
  CERTIFICATE_OF_DIRECTORS = 11,
  ANOTHER_FILE = 13,
  IDENTITY_CARD_SECOND_PAGE = 14,
  LETTER_OF_RECOMMENDATION = 17,
  SOURCE_OF_WEALTH = 18,
  ORIGIN_OF_FUNDS = 19,
  DESTINATION_OF_OUTWARD_PAYMENTS = 20,
  PROOF_OF_OCCUPATION_STATUS = 21,
  CERTIFICATE_OF_REGISTERED_OFFICE = 22,
  BOARD_RESOLUTION = 23,
  LICENSE = 24,
  FACE = 25,
}
