<div
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="20px"
  class="main"
>
  <!-- SUCCESS -->
  <div
    *ngIf="successCount"
    fxLayout
    fxLayoutAlign="space-between center"
    class="success"
  >
    <div>
      {{ successCount }}
      <ng-container [ngPlural]="successCount">
        <ng-template ngPluralCase="=1">transfer </ng-template>
        <ng-template ngPluralCase="other">transfers </ng-template>
      </ng-container>
      succeeded
    </div>

    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- FAILURE -->
  <div
    *ngIf="failureCount"
    fxLayout
    fxLayoutAlign="space-between center"
    class="failure"
  >
    <div>
      {{ failureCount }}
      <ng-container [ngPlural]="failureCount">
        <ng-template ngPluralCase="=1">transfer </ng-template>
        <ng-template ngPluralCase="other">transfers </ng-template>
      </ng-container>
      failed
    </div>

    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
