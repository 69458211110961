import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as forge from 'node-forge';

export interface EncryptedDataModel {
  ciphertext: forge.util.ByteStringBuffer,
  KEY: string,
  IV: string,
  TAG: forge.util.ByteStringBuffer,
}

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  baseSecurityUrl = `${environment.BACKEND_URL}/security`;

  constructor(private http: HttpClient) {}
  
  getPublicKey(): Observable<{ publicKey: string }> {
    const url = `${this.baseSecurityUrl}/publicKey`;
    return this.http.get<{ publicKey: string }>(url);
  }

  async encrypt(plaintext: any) {

    // generate a random secret key and IV   
    var key = forge.random.getBytesSync(32);
    var iv = forge.random.getBytesSync(12);

    // encrypt data using GCM mode
    var cipher = forge.cipher.createCipher('AES-GCM', key);
    cipher.start({
      iv: iv, // should be a 12-byte binary-encoded string or byte buffer
    });
    cipher.update(forge.util.createBuffer(JSON.stringify(plaintext)));
    cipher.finish();

    try {
      const resp = await this.getPublicKey().toPromise();
      const pubKey = forge.pki.publicKeyFromPem(resp.publicKey);

      // encrypt secret key using RSA
      const cipherModel: EncryptedDataModel = {
        ciphertext: cipher.output,
        KEY: forge.util.encode64(pubKey.encrypt(key, 'RSA-OAEP')),
        IV: iv,
        TAG: cipher.mode.tag, 
      }
      return cipherModel; 
    } catch (error) { // catch encryption errors
      return null;
    }
  }
}