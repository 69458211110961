import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { Country } from 'src/app/shared/models/country.model';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import {
  descriptionMaxLength,
  getCountryByCode,
  getCurrentUtcDateZero,
} from 'src/app/shared/helpers/various-helpers.helper';
import { AccountDetails } from 'src/app/dashboard/models/account-details.model';
import { TransferService } from '../transfer.service';
import { transferAmountValidator } from '../shared/transfer-amount.validator';
import { ibanValidator } from './iban.validator';
import { SharedService } from 'src/app/shared/services/shared.service';
import { FavouriteTransfer } from '../shared/models/favourite-transfer.model';
import { Router } from '@angular/router';
import { recurringCycleValidator } from './recurring-cycle.validator';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { AllAccounts } from 'src/app/dashboard/models/all-accounts.model';
import { AccountType } from 'src/app/dashboard/shared/account-type.enum';
import { Charges } from '../shared/enum/charges.enum';
import { TransferType } from '../shared/enum/transfer-type.enum';
import { GetPriceList } from '../shared/models/get-price-list.model';
import { ValidateExecutionDate } from '../shared/models/validate-execution-date.model';
import { DateTime } from 'luxon';
import { Transfer, TransferWithOtp } from '../shared/models/transfer.model';
import { bicValidator } from './bic.validator';
import { Beneficiary } from '../shared/models/beneficiary.model';
import { Currency } from '../shared/models/currency.model';
import { PriceList } from '../shared/models/price-list.model';

@Component({
  templateUrl: './external.component.html',
  styleUrls: ['./external.component.scss'],
})
export class ExternalComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  isTransferring: boolean = false;
  isInvalidOtp: boolean = false;
  isConfirmationStep: boolean = false;
  transactionId?: number;
  transferBody?: Transfer | null;
  otpVerifyToken!: string; // otp token to verify transaction in core banking (not the sms)

  getCountryByCode = getCountryByCode;

  allAccounts!: AllAccounts;
  accountOwnerGuid!: string;
  accounts: AccountDetails[] = [];
  countries: Country[] = [];
  isValidIban?: boolean;
  isSepaRecipient?: boolean;
  ibanDetails?: any;
  ibanValidCodes = ['001', '002', '003', '004', '005', '006', '007'];
  bicDetails?: any;
  intermediaryBicDetails?: any;
  sepaTooltipText: string =
    'SEPA transfer can be made only In Euro. The beneficiary of the SEPA transfer must reside in one of the EEA country.';
  favourite?: FavouriteTransfer;
  beneficiary?: Beneficiary;
  currencyCodeList: any;
  lastUpdated?: Date;

  transferForm: FormGroup = this.fb.group(
    {
      accounts: this.fb.group({
        ibanAccount: [null, Validators.required], // needed for fee validation (may be the same as fromAccount or not)
        fromAccount: [null, Validators.required], // iban or wallet
        fromCurrency: [null, Validators.required], // transfer currency
        ibanOrAccountNumber: [
          null,
          {
            validators: [Validators.required],
            asyncValidators: [
              ibanValidator(
                this.ibanValidation.bind(this),
                this.getAccounts.bind(this)
              ),
            ],
            updateOn: 'blur',
          },
        ], // sends transfer to this iban number
        isSepa: [null, Validators.required], // is SEPA transfer
      }),
      otherFields: this.fb.group(
        {
          amount: [
            null,
            [Validators.required, Validators.pattern('^[0-9]+[.]?[0-9]{0,2}$')],
          ],
          fee: [null, Validators.required],
          toCurrency: [null, Validators.required],
          toAmount: [
            null,
            [Validators.required, Validators.pattern('^[0-9]+[.]?[0-9]{0,2}$')],
          ],
          executionDate: [null, Validators.required],
          payerDescription: [
            null,
            [
              Validators.required,
              Validators.pattern('^[a-zA-Z0-9 ]*$'),
              Validators.maxLength(descriptionMaxLength),
            ],
          ],
          beneficiaryDescription: [
            null,
            [
              Validators.required,
              Validators.pattern('^[a-zA-Z0-9 ]*$'),
              Validators.maxLength(descriptionMaxLength),
            ],
          ],
          isRecurring: [null],
          recurringCycle: [null],
          bic: [
            null,
            {
              validators: [
                Validators.required,
                Validators.pattern('^[A-Z 0-9-]{8,11}$'),
              ],
              asyncValidators: [
                bicValidator(this.bicValidation.bind(this), false),
              ],
              updateOn: 'blur',
            },
          ],
          intermediaryBic: [
            null,
            {
              validators: [Validators.pattern('^[A-Z 0-9-]{8,11}$')],
              asyncValidators: [
                bicValidator(this.bicValidation.bind(this), true),
              ],
              updateOn: 'blur',
            },
          ],
          beneficiaryName: [
            null,
            [Validators.required, Validators.pattern('^[a-z,A-Z ]{1,100}$')],
          ],
          creditorAddress1: [
            null,
            [
              Validators.required,
              Validators.pattern('^[a-z,A-Z 0-9-]{1,100}$'),
            ],
          ],
          creditorAddress2: [
            null,
            Validators.pattern('^[a-z,A-Z 0-9-]{0,100}$'),
          ],
          creditorCity: [null, Validators.pattern('^[a-zA-Z0-9 ]{1,50}$')],
          creditorZip: [null, Validators.pattern('^[a-zA-Z0-9 ]{1,50}$')],
          creditorCountryCode: [null, Validators.required],
          charges: [null, Validators.required],
        },
        { validators: [recurringCycleValidator()] }
      ),
    },
    {
      validators: [transferAmountValidator()],
    }
  );

  private fromAccountControlSub?: Subscription;
  private feeSub?: Subscription;
  private chargesSub?: Subscription;
  private toCurrencyControlSub?: Subscription;
  private toAmountControlSub?: Subscription;
  private amountControlSub?: Subscription;
  private executionDateControlSub?: Subscription;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private errorService: ErrorService,
    private transferService: TransferService,
    private sharedService: SharedService,
    private dashboardService: DashboardService
  ) {
    this.favourite =
      this.router.getCurrentNavigation()?.extras.state?.favourite;
  }

  ngOnInit(): void {
    this.isLoading = true;

    forkJoin([
      this.dashboardService.getAccounts(TransferType.SWIFT), // get all accounts that allow sepa/swift
      this.sharedService.getAllCountries(),
    ]).subscribe(
      ([accounts, countries]) => {
        this.allAccounts = accounts;
        this.accountOwnerGuid = accounts.accountOwnerGuid;
        this.countries = countries;
        this.setDefaultValues(accounts, this.favourite);
        this.isLoading = false;

        /**
         * if fromAccount changes, the following change:
         *    fromCurrency
         *    toCurrency => eventually toAmount => amount => fees
         *               => eventually isSepa
         *
         * if charges change, the following change:
         *    isSepa
         *
         * if ibanOrAccountNumber changes handled by ibanValidation validator, the following change:
         *    if valid iban, bic is automatically populated and disabled
         *    if not valid iban, bic is populated by user and enabled
         *    isSepa
         *
         * ---
         * amount changes, the following change:
         *    toAmount (using toCurrency) - without emitting event => eventually toAmount
         *    fees
         *
         * toAmount changes, the following change:
         *    amount (using toCurrency) - without emitting event => eventually amount
         *    fees
         * ---
         *
         * if isSepa changes, the following change:
         *    executionDate (depends on executionDate selected by user, isSepa, fromCurrency)
         *    fees (depends on isSepa, fromCurrency, amount)
         *
         */

        // set if SEPA/SWIFT if any changes: fromAccountControl, ibanOrAccountNumberControl, toCurrencyControl, chargesControl
        // no need to have this.ibanOrAccountNumberControl.valueChanges because we have async validator ibanValidation that checks if it is SEPA/SWIFT
        this.fromAccountControlSub =
          this.fromAccountControl.valueChanges.subscribe(async (val) => {
            if (val?.code) {
              (
                await this.transferService.getCurrencyCodeList(val?.code)
              ).subscribe(
                (data: Currency[]) => {
                  this.lastUpdated = new Date();
                  this.currencyCodeList = data.map((c: any) => ({
                    ...c,
                    rate: c.rate.toFixed(4), // 4 digits
                  }));
                  // lock the currency to only transfer out with the fromAccount currency
                  // e.g. transfer euro out from iban, gbp from gbp wallet etc.
                  this.toCurrencyControl.setValue(
                    this.currencyCodeList?.find(
                      (c: any) => c.code === val?.ccy
                    ),
                    { emitEvent: false } // don't emit event, disable() will trigger it
                  );
                  this.toCurrencyControl.disable();
                },
                () => {
                  this.errorService.showErrorDialog();
                }
              );
            }
          });
        this.chargesSub = this.chargesControl.valueChanges.subscribe(() => {
          this.setIsSepaControlValue();
        });
        // if any changes: toCurrencyControl, amountControl, toAmountControl need to update the amounts
        this.toCurrencyControlSub =
          this.toCurrencyControl.valueChanges.subscribe((currency) => {
            this.setIsSepaControlValue();
            if (currency && this.amountControl?.value !== null) {
              this.toAmountControl?.setValue(
                Number((this.amountControl.value * currency?.rate).toFixed(2)),
                {
                  emitEvent: false,
                }
              );
            }
          });
        this.toAmountControlSub = this.toAmountControl.valueChanges.subscribe(
          async (toAmount) => {
            const currentRate = this.toCurrencyControl?.value?.rate;
            if (toAmount !== null && currentRate) {
              this.amountControl?.setValue(
                Number((toAmount / currentRate).toFixed(2)),
                {
                  emitEvent: false,
                }
              );
            }
            if (
              this.amountControl?.value !== null &&
              this.accountOwnerGuid &&
              this.isSepaControl?.value !== null &&
              this.fromCurrencyControl?.value
            ) {
              await this.getPriceList();
            }
          }
        );
        this.amountControlSub = this.amountControl.valueChanges.subscribe(
          async (amount) => {
            const currentRate = this.toCurrencyControl?.value?.rate;
            if (amount !== null && currentRate) {
              this.toAmountControl?.setValue(
                Number((amount * currentRate).toFixed(2)),
                {
                  emitEvent: false,
                }
              );
            }
            if (
              amount !== null &&
              this.accountOwnerGuid &&
              this.isSepaControl?.value !== null &&
              this.fromCurrencyControl?.value
            ) {
              await this.getPriceList();
            }
          }
        );
        this.executionDateControlSub =
          this.executionDateControl.valueChanges.subscribe(async (val) => {
            if (val) {
              await this.validateExecutionDate(val);
            }
          });
      },
      () => {
        this.isLoading = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  async validateExecutionDate(date: string | DateTime) {
    const model: ValidateExecutionDate = {
      executionDate: typeof date === 'string' ? date : date.toISODate(),
      transferType: this.isSepaControl?.value
        ? TransferType.SEPA
        : TransferType.SWIFT,
      fromCurrency: this.fromCurrencyControl?.value,
    };
    (await this.transferService.validateExecutionDate(model)).subscribe(
      (res: any) => {
        this.executionDateControl.setValue(
          DateTime.fromISO(res.data.executeDate),
          {
            emitEvent: false,
          }
        );
      },
      () => this.errorService.showErrorDialog()
    );
  }

  async getPriceList() {
    const model: GetPriceList = {
      accountOwnerGuid: this.accountOwnerGuid,
      accountCode: this.fromAccountControl.value.code,
      paymentType: this.isSepaControl?.value
        ? TransferType.SEPA
        : TransferType.SWIFT,
      fromCurrency: this.fromCurrencyControl?.value,
      amount: this.amountControl?.value,
    };
    (await this.transferService.getPriceList(model)).subscribe(
      (priceList: PriceList[]) => {
        this.feeControl.setValue(priceList[0]);
      },
      () => this.errorService.showErrorDialog()
    );
  }

  ngOnDestroy(): void {
    this.fromAccountControlSub?.unsubscribe();
    this.feeSub?.unsubscribe();
    this.chargesSub?.unsubscribe();
    this.toCurrencyControlSub?.unsubscribe();
    this.toAmountControlSub?.unsubscribe();
    this.amountControlSub?.unsubscribe();
    this.executionDateControlSub?.unsubscribe();
  }

  async goToConfirmationStep(): Promise<void> {
    const transfer = this.prepareTransferBody();
    (await this.transferService.createTransfer(transfer)).subscribe(
      async (data: { otpVerifyToken: string }) => {
        this.otpVerifyToken = data.otpVerifyToken;
        const otpReq = this.isSepaControl.value
          ? await this.transferService.sendSepaOtp(transfer)
          : await this.transferService.sendSwiftOtp(transfer);

        otpReq.subscribe(
          () => {
            this.isConfirmationStep = true;
          },
          () => {
            this.errorService.showErrorDialog();
          }
        );
      },
      (error) => {
        error.status === 400
          ? this.errorService.showErrorDialog(error.error.message)
          : this.errorService.showErrorDialog();
      }
    );
  }

  cancelTransfer(shouldReload: boolean): void {
    this.transferBody = null;
    this.isInvalidOtp = false;
    this.transactionId = undefined;
    this.isConfirmationStep = false;
    if (shouldReload) {
      this.reloadAccounts();
    } else {
      this.setDefaultValues(this.allAccounts);
    }
  }

  onFavouriteSelect(favourite: FavouriteTransfer): void {
    this.favourite = favourite;
    this.setDefaultValues(this.allAccounts, this.favourite);
  }

  onBeneficiarySelect(beneficiary: Beneficiary): void {
    this.beneficiary = beneficiary;
    this.setDefaultValues(this.allAccounts, undefined, this.beneficiary);
  }

  async transfer(otp: string): Promise<void> {
    this.isTransferring = true;
    this.transferBody = this.prepareTransferBody();
    const body: TransferWithOtp = {
      ...this.transferBody,
      otp,
      otpVerifyToken: this.otpVerifyToken,
    };
    const transferReq = this.isSepaControl.value
      ? await this.transferService.transferSepa(body)
      : await this.transferService.transferSwift(body);
    transferReq.subscribe(
      () => {
        this.isTransferring = false;
        this.transactionId = 1;
      },
      (err) => {
        this.isTransferring = false;
        if (err.status === 400) {
          this.isInvalidOtp = true;
        } else {
          this.errorService.showErrorDialog();
        }
      }
    );
  }

  /*
   * All the below should be satisfied to be SEPA:
   * fromAccount allows outgoing SEPA
   * beneficiary's iban is valid and SCT='YES' (meaning bank can accept SCT)
   * toCurrency is EUR
   * charges is SHA
   */
  private async setIsSepaControlValue(): Promise<void> {
    this.isSepaControl.setValue(
      this.fromAccountControl?.value?.allowOutgoingSEPA && // fromAccount allows SEPA
        this.isValidIban && // valid iban
        this.isSepaRecipient && // SCT true
        this.toCurrencyControl?.value.code === 'EUR' && // toCurrency is EUR
        this.chargesControl?.value === Charges.SHA // charges is SHA
    );
    // if SEPA, reset intermediary bank details, because they are hidden for SEPA (shown only for SWIFT)
    if (this.isSepaControl?.value === true) {
      this.resetIntermediaryBicDetails();
    }
    if (this.executionDateControl?.value) {
      await this.validateExecutionDate(this.executionDateControl.value);
    }
    if (
      this.amountControl?.value !== null &&
      this.accountOwnerGuid &&
      this.isSepaControl?.value !== null &&
      this.toCurrencyControl?.value
    ) {
      await this.getPriceList();
    }
  }

  resetIntermediaryBicDetails() {
    this.intermediaryBicDetails = null;
    this.intermediaryBicControl.setValue(null);
  }

  // used in iban validator to compare if beneficiary iban is same as sender
  getAccounts(): AllAccounts {
    return this.allAccounts;
  }

  // check if recipient's IBAN is valid, if not it is account number
  private async ibanValidation(iban: string): Promise<void> {
    return await this.transferService
      .validateIban(iban)
      .then((res: any) => {
        this.ibanDetails = res.data;
        this.isValidIban = Object.values(res.data.validations)
          .map((v: any) => v.code)
          .every((c) => this.ibanValidCodes.includes(c));
        this.isSepaRecipient = res.data.sepa_data.SCT === 'YES';
        if (this.ibanDetails?.bank_data?.bic) {
          this.bicControl?.setValue(this.ibanDetails.bank_data.bic);
          this.bicControl?.disable();
        } else {
          this.bicControl?.setValue(null);
          this.bicControl?.enable();
        }
        this.setIsSepaControlValue();
      })
      .catch((err) => {
        this.errorService.showErrorDialog();
        throw new Error(err.error.message); // throw error so it can be caught by catch() in iban.validator.ts
      });
  }

  // check if BIC is valid
  private async bicValidation(
    bic: string,
    isIntermediary: boolean
  ): Promise<void> {
    if (bic) {
      return await this.transferService
        .validateBic(bic)
        .then((res: any) => {
          isIntermediary
            ? (this.intermediaryBicDetails = res.data)
            : (this.bicDetails = res.data);
        })
        .catch((err) => {
          // reset bic details
          isIntermediary
            ? (this.intermediaryBicDetails = null)
            : (this.bicDetails = null);
          if (err.status !== 422) {
            this.errorService.showErrorDialog();
          }
          throw new Error(err.error.message); // throw error so it can be caught by catch() in bic.validator.ts
        });
    } else {
      // when user clears the intermediary bic, it sends null bic to backend which throws new Error()
      // so it says that intermediary bic is invalid, thus call bic validator only if bic exists and if not, then reset intermediary bic details
      if (isIntermediary) {
        this.resetIntermediaryBicDetails();
      }
    }
  }

  private reloadAccounts(): void {
    this.isLoading = true;
    this.dashboardService.getAccounts(TransferType.SWIFT).subscribe(
      (accounts) => {
        this.allAccounts = accounts;
        this.setDefaultValues(accounts);
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  private prepareTransferBody(): Transfer {
    const transfer: Transfer = {
      transferType: this.isSepaControl?.value
        ? TransferType.SEPA
        : TransferType.SWIFT,
      accountOwnerGuid: this.accountOwnerGuid,
      fromAccountId: this.fromAccountControl.value.id,
      fromAccountGuid: this.fromAccountControl.value.guid,
      ibanOrAccountNumber: this.ibanOrAccountNumberControl?.value,
      beneficiaryName: this.beneficiaryNameControl?.value,
      beneficiaryAddress1: this.creditorAddress1Control?.value,
      beneficiaryAddress2: this.creditorAddress2Control?.value,
      beneficiaryZip: this.creditorZipControl?.value,
      beneficiaryCity: this.creditorCityControl?.value,
      beneficiaryCountryCode: this.creditorCountryCodeControl?.value,
      bankName: this.ibanDetails?.bank_data?.bank || this.bicDetails?.bankName,
      bankAddress:
        this.ibanDetails?.bank_data?.address || this.bicDetails?.bankAddress,
      bicCode: this.bicControl?.value,
      intermediaryBankBicCode: this.intermediaryBicControl?.value, // SWIFT ONLY
      intermediaryBankName: this.intermediaryBicDetails?.bankName, // SWIFT ONLY
      intermediaryBankAddress: this.intermediaryBicDetails?.bankAddress, // SWIFT ONLY
      executionDate: this.executionDateControl?.value,
      amount: this.amountControl?.value,
      payerDescription: this.payerDescriptionControl?.value,
      beneficiaryDescription: this.beneficiaryDescriptionControl?.value,
      charges: this.chargesControl?.value,
      benRateCurrency: this.toCurrencyControl?.value?.code,
      benRateValue: this.toCurrencyControl?.value?.rate,
      chargesId: this.feeControl?.value?.id,
      chargesAmount: this.feeControl?.value?.calculatedAmount,
      isRecurring: this.isRecurringControl?.value,
      recurringCycle: this.recurringCycleControl?.value,
    };
    return transfer;
  }

  private async setDefaultValues(
    accounts: AllAccounts,
    favourite?: FavouriteTransfer,
    beneficiary?: Beneficiary
  ): Promise<void> {
    this.transferForm.reset();

    const ibanAccount = accounts.iban;

    // set accounts
    this.accounts = [ibanAccount, ...accounts.wallets];

    // get favourite from account
    const fromFavouriteAccount = this.accounts.find(
      (a) => a.guid === favourite?.accountSelectedGuid
    );

    (
      await this.transferService.getCurrencyCodeList(
        fromFavouriteAccount?.code || ibanAccount.code
      )
    ).subscribe(
      (data: Currency[]) => {
        this.lastUpdated = new Date();
        this.currencyCodeList = data.map((c: any) => ({
          ...c,
          rate: c.rate.toFixed(4), // 4 digits
        }));
        const newFormValue = {
          accounts: {
            ibanAccount,
            fromAccount: fromFavouriteAccount || ibanAccount,
            fromCurrency: fromFavouriteAccount?.ccy || 'EUR',
            ibanOrAccountNumber: favourite?.benAccNo || beneficiary?.accountNo,
          },
          otherFields: {
            amount: null,
            toCurrency: favourite
              ? this.currencyCodeList?.find(
                  (c: any) => c.code === favourite?.rateCurrency
                )
              : this.currencyCodeList?.find((c: any) => c.code === 'EUR'),
            executionDate: getCurrentUtcDateZero(),
            payerDescription: null,
            beneficiaryDescription: null,
            bic: favourite?.benBic || beneficiary?.bic,
            intermediaryBic: favourite?.intermediaryBankBic,
            beneficiaryName: favourite?.benName || beneficiary?.name,
            creditorCountryCode:
              favourite?.benCountryCode || beneficiary?.countryCode,
            creditorAddress1: favourite?.benAddress || beneficiary?.address,
            creditorAddress2: null,
            creditorCity: favourite?.benCity || beneficiary?.city,
            creditorZip: favourite?.benPostCode || beneficiary?.postcode,
          },
        };

        this.transferForm.patchValue(newFormValue);
      },
      () => {
        this.errorService.showErrorDialog();
      }
    );
  }

  get accountsGroup(): FormGroup {
    return this.transferForm.get('accounts') as FormGroup;
  }
  get ibanAccountControl(): FormControl {
    return this.accountsGroup.get('ibanAccount') as FormControl;
  }
  get fromAccountControl(): FormControl {
    return this.accountsGroup.get('fromAccount') as FormControl;
  }
  get fromCurrencyControl(): FormControl {
    return this.accountsGroup.get('fromCurrency') as FormControl;
  }
  get ibanOrAccountNumberControl(): FormControl {
    return this.accountsGroup.get('ibanOrAccountNumber') as FormControl;
  }
  get isSepaControl(): FormControl {
    return this.accountsGroup.get('isSepa') as FormControl;
  }
  get toAccountControl(): FormControl {
    return this.accountsGroup.get('toAccount') as FormControl;
  }
  get otherFieldsGroup(): FormGroup {
    return this.transferForm.get('otherFields') as FormGroup;
  }
  get amountControl(): FormControl {
    return this.otherFieldsGroup.get('amount') as FormControl;
  }
  get toCurrencyControl(): FormControl {
    return this.otherFieldsGroup.get('toCurrency') as FormControl;
  }
  get toAmountControl(): FormControl {
    return this.otherFieldsGroup.get('toAmount') as FormControl;
  }
  get executionDateControl(): FormControl {
    return this.otherFieldsGroup.get('executionDate') as FormControl;
  }
  get beneficiaryNameControl(): FormControl {
    return this.otherFieldsGroup.get('beneficiaryName') as FormControl;
  }
  get bicControl(): FormControl {
    return this.otherFieldsGroup.get('bic') as FormControl;
  }
  get intermediaryBicControl(): FormControl {
    return this.otherFieldsGroup.get('intermediaryBic') as FormControl;
  }
  get creditorAddress1Control(): FormControl {
    return this.otherFieldsGroup.get('creditorAddress1') as FormControl;
  }
  get creditorAddress2Control(): FormControl {
    return this.otherFieldsGroup.get('creditorAddress2') as FormControl;
  }
  get creditorCityControl(): FormControl {
    return this.otherFieldsGroup.get('creditorCity') as FormControl;
  }
  get creditorCountryCodeControl(): FormControl {
    return this.otherFieldsGroup.get('creditorCountryCode') as FormControl;
  }
  get chargesControl(): FormControl {
    return this.otherFieldsGroup.get('charges') as FormControl;
  }
  get creditorZipControl(): FormControl {
    return this.otherFieldsGroup.get('creditorZip') as FormControl;
  }
  get feeControl(): FormControl {
    return this.otherFieldsGroup.get('fee') as FormControl;
  }
  get payerDescriptionControl(): FormControl {
    return this.otherFieldsGroup.get('payerDescription') as FormControl;
  }
  get beneficiaryDescriptionControl(): FormControl {
    return this.otherFieldsGroup.get('beneficiaryDescription') as FormControl;
  }
  get isRecurringControl(): FormControl {
    return this.otherFieldsGroup.get('isRecurring') as FormControl;
  }
  get recurringCycleControl(): FormControl {
    return this.otherFieldsGroup.get('recurringCycle') as FormControl;
  }
}
