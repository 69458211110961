import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// PFS allows number length (7,16) (without '+'sign)
export function phoneValidator(): ValidatorFn {
  return (formControl: AbstractControl): ValidationErrors | null => {
    const phoneNumber = formControl.value?.e164Number || '';
    return phoneNumber && (phoneNumber.length < 8 || phoneNumber.length > 17)
      ? { phone: true }
      : null;
  };
}
