import { Fee } from './models/fee.model';

export function countFee(amount: number, fee?: Fee): number {
  let feeValue: number = 0;
  if (fee && amount > fee.threshold) {
    if (fee.fixed) {
      feeValue = fee.fee;
    } else {
      const percentFeeValue = Math.round(amount * fee.fee) / 100;
      if (percentFeeValue < fee.minimum) {
        feeValue = fee.minimum;
      } else if (percentFeeValue > fee.maximum) {
        feeValue = fee.maximum;
      } else {
        feeValue = percentFeeValue;
      }
    }
  } else if (fee && amount <= fee.threshold && fee.threshold > 0) {
    if (fee.fixed && fee.minimum) {
      feeValue = fee.minimum;
    }
  }
  return feeValue;
}
