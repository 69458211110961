import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface InfoDialogData {
  title1?: string;
  message1?: string;
  title2?: string;
  message2?: string;
  message3?: string;
}

@Component({
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: InfoDialogData) {}

  reloadPage(): void {
    location.reload();
  }
}
