<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content fxLayout="column" fxLayoutGap="20px">
  <h1>Change phone number</h1>
  <ng-container [formGroup]="form" *ngIf="!isSuccess">
    <div class="description">
      Please select the country code and enter your new mobile phone number
      (without re-entering the country code). We will send you a verification
      code via SMS.
    </div>
    <div class="content">
      <div fxLayout="column">
        <label for="phoneNumber">Mobile number</label>
        <ngx-intl-tel-input
          [enablePlaceholder]="false"
          [searchCountryFlag]="true"
          [separateDialCode]="true"
          name="phoneNumber"
          formControlName="phoneNumber"
        >
        </ngx-intl-tel-input>
        <div class="mat-form-field">
          <div class="mat-form-field-appearance-outline">
            <div class="mat-form-field-wrapper" style="margin-top: 0">
              <div class="mat-form-field-subscript-wrapper">
                <mat-error
                  *ngIf="
                    phoneNumberControl.invalid && phoneNumberControl.touched
                  "
                  >Number is invalid</mat-error
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="column">
        <label>Password</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="password"
            [type]="hidePassword ? 'password' : 'text'"
          />
          <mat-error *ngIf="passwordControl.hasError('required')"
            >Field is required</mat-error
          >
          <mat-error *ngIf="passwordControl.hasError('wrongPassword')"
            >Wrong password</mat-error
          >
          <button
            mat-icon-button
            matSuffix
            (click)="hidePassword = !hidePassword"
          >
            <mat-icon class="material-icons-outlined">{{
              hidePassword ? 'visibility' : 'visibility_off'
            }}</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <ng-container *ngIf="isSent">
        <mat-divider class="with-margin"></mat-divider>
        <div class="text">
          We’ve just sent you a verification SMS. Please
          <strong>check your mobile phone now for new messages</strong
          ><br fxHide.gt-xs />
          and insert the verification code here within the next five minutes.
        </div>
        <div fxLayoutAlign="space-between start" class="verification">
          <div fxLayout="column">
            <label for="otp">Verification code</label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="otp" name="otp" />
              <mat-error *ngIf="otpControl?.hasError('wrongCode')"
                >Wrong code</mat-error
              >
            </mat-form-field>
          </div>
          <button
            mat-flat-button
            color="accent"
            (click)="verify()"
            [disabled]="form.invalid || isPending"
            class="verify"
          >
            Verify
          </button>
        </div>
        <mat-divider></mat-divider>
        <div class="send-sms">
          <button
            mat-stroked-button
            color="accent"
            class="g-small"
            (click)="submit(true)"
            [disabled]="
              isPending ||
              form.get('phoneNumber')?.invalid ||
              isSendAgainDisabled ||
              noMoreResendsAllowed
            "
          >
            Send SMS again
            <span *ngIf="isSendAgainDisabled">({{ countdown }}s)</span>
          </button>
          <img
            *ngIf="smsSentAgain && !noMoreResendsAllowed"
            src="assets/img/accept.svg"
            alt="sent"
            class="sent-again"
          />
          <mat-icon
            *ngIf="noMoreResendsAllowed"
            svgIcon="cancel"
            class="sent-again cancel"
          ></mat-icon>

          <div *ngIf="noMoreResendsAllowed" class="error">
            You have reached the maximum number of allowed SMS resend attempts.
            Please refresh the page and try again.
          </div>
        </div>
      </ng-container>
    </div>
    <button
      mat-flat-button
      *ngIf="!isSent"
      fxFlexAlign="center"
      color="accent"
      (click)="submit()"
      [disabled]="form.invalid || isPending"
    >
      Send SMS
    </button>
    <button
      mat-stroked-button
      mat-dialog-close
      fxFlexAlign="end"
      color="accent"
      class="cancel"
    >
      Cancel
    </button>
  </ng-container>

  <ng-container *ngIf="isSuccess">
    <div class="success-text">
      <div class="success">Success!</div>
      <div class="description">Phone number changed.</div>
    </div>
    <button
      mat-flat-button
      mat-dialog-close
      fxFlexAlign="end"
      color="accent"
      class="cancel"
    >
      Close
    </button>
  </ng-container>
</mat-dialog-content>
