<h2 class="header">Add new documents</h2>
<app-all-documents-form
  [displayedDocumentOptions]="displayedDocumentOptions"
  [userId]="userId"
  (refreshTable)="onRefreshTable($event)"
  [companyDirectors]="companyDirectors"
  [isCorporateUser]="isCorporateUser"
></app-all-documents-form>
<mat-divider></mat-divider>
<h2 class="header">Uploaded documents</h2>
<app-documents-table
  [userId]="userId"
  [documents]="documents"
  [isLoadingTable]="isLoadingTable"
  [isRowColored]="isRowColored"
  [isSecondRowColored]="isSecondRowColored"
></app-documents-table>
