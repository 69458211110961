<div class="buttons-container" fxLayoutAlign="start center" fxLayoutGap="12px">
  <button
    mat-flat-button
    class="g-medium"
    routerLink="in-between"
    routerLinkActive="active"
    color="accent"
  >
    Between your accounts
  </button>
  <button
    *ngIf="!isDepositOnly"
    mat-flat-button
    class="g-medium"
    routerLink="e2e-now"
    routerLinkActive="active"
    color="accent"
  >
    e2e Now
  </button>
  <button
    *ngIf="!isDepositOnly"
    mat-flat-button
    class="g-medium"
    routerLink="external"
    routerLinkActive="active"
    color="accent"
  >
    To other accounts
  </button>
  <button
    *ngIf="isCorporateUser && !isDepositOnly"
    mat-flat-button
    class="g-medium"
    routerLink="mass-payments"
    routerLinkActive="active"
    color="accent"
  >
    Mass payments
  </button>
  <button
    *ngIf="!isDepositOnly"
    mat-flat-button
    class="g-medium"
    routerLink="recurring"
    routerLinkActive="active"
    color="accent"
  >
    Recurring
  </button>
  <button
    *ngIf="!isDepositOnly"
    mat-flat-button
    class="g-medium"
    routerLink="favourites"
    routerLinkActive="active"
    color="accent"
  >
    Favourites
  </button>
</div>
<router-outlet></router-outlet>
