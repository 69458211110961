<div class="main-container">
  <div fxLayout="column" class="header-container">
    <div fxLayout fxLayoutAlign="space-between center">
      <img src="assets/img/logo-dark.svg" alt="eCredo logo" />
      <h1>account Statement</h1>
    </div>
    <div fxFlexAlign="end" class="description">
      <span *ngIf="dateFrom"> from {{ dateFrom | date: 'shortDate' }}</span>
      <span *ngIf="dateTo"> to {{ dateTo | date: 'shortDate' }}</span>
    </div>
  </div>

  <div class="account" fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutGap="20px">
      <div fxFlex="296px">Account number</div>
      <div fxFlex="100">{{ accountInfo?.cardInfo?.accountNumber }}</div>
    </div>
    <div fxLayout="row" fxLayoutGap="20px">
      <div fxFlex="296px">IBAN</div>
      <div fxFlex="100" class="bold">{{ accountInfo?.cardInfo?.iban }}</div>
    </div>
    <div fxLayout="row" fxLayoutGap="20px">
      <div fxFlex="296px">Bank SWIFT / BIC</div>
      <div fxFlex="100">{{ accountInfo?.cardInfo?.bic }}</div>
    </div>
    <div fxLayout="row" fxLayoutGap="20px">
      <div fxFlex="296px">Type</div>
      <div
        fxFlex="100"
        *ngIf="accountInfo?.cardInfo?.cardStatusString === 'Open'"
      >
        Current account
      </div>
      <div
        fxFlex="100"
        *ngIf="accountInfo?.cardInfo?.cardStatusString !== 'Open'"
      >
        {{ accountInfo?.cardInfo?.cardStatusString }} account
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="20px">
      <div fxFlex="296px">Currency</div>
      <div fxFlex="100">{{ currencyName }}</div>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayoutAlign="space-between none"
    class="opening-balance"
  >
    <div>Opening balance</div>
    <div>{{ openingBalance | amount }}</div>
  </div>

  <table
    mat-table
    [dataSource]="data"
    [fixedLayout]="true"
    matSort
    matSortDisableClear
    multiTemplateDataRows
  >
    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>
        <div>Transaction Date</div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div>{{ row.date | date: 'shortDate' }}</div>
      </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>
        <div>Description</div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div>{{ row.description }}</div>
      </td>
    </ng-container>

    <!-- Debit Column -->
    <ng-container matColumnDef="debit">
      <th mat-header-cell *matHeaderCellDef class="right">
        <div>Debit</div>
      </th>
      <td mat-cell *matCellDef="let row" class="right">
        <div *ngIf="row.cardType === statementTypes.DEBIT">
          {{ row.amount | amount }}
        </div>
      </td>
    </ng-container>

    <!-- Credit Column -->
    <ng-container matColumnDef="credit">
      <th mat-header-cell *matHeaderCellDef class="right">
        <div>Credit</div>
      </th>
      <td mat-cell *matCellDef="let row" class="right">
        <div *ngIf="row.cardType === statementTypes.CREDIT">
          {{ row.amount | amount }}
        </div>
      </td>
    </ng-container>

    <!-- Balance Column -->
    <ng-container matColumnDef="balance">
      <th mat-header-cell *matHeaderCellDef class="right">
        <div>Balance</div>
      </th>
      <td mat-cell *matCellDef="let row" class="right">
        <div>{{ row.availableBalance | amount }}</div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      matRipple
      class="element-row"
    ></tr>
  </table>

  <div
    fxLayout="row"
    fxLayoutAlign="space-between none"
    class="closing-balance"
  >
    <div>Closing balance</div>
    <div>{{ closingBalance | amount }}</div>
  </div>
</div>
