<!-- IBAN/WALLET HEADER -->
<app-account-section-header
  *ngIf="section !== Sections.CARD"
  [svgIconName]="svgIconName"
  [name]="name"
  [availableBalance]="availableBalance"
  [balance]="balance"
  [accountNumber]="accountNumber"
  [section]="section"
  [isLoadingIBAN]="isLoadingIBAN"
  [isLoadingWallet]="isLoadingWallet"
  (cardActivated)="cardActivated.emit()"
></app-account-section-header>
<!-- CARD HEADER -->
<app-account-section-card-header
  *ngIf="section === Sections.CARD"
  [program]="program"
  [cards]="cards"
  [isLoadingCards]="isLoadingCards"
  [isCardActive]="isCardActive"
  [name]="name"
  [availableBalance]="availableBalance"
  [balance]="balance"
  [cardholder]="cardholder"
  [isLocked]="isLocked"
  [isIssued]="isIssued"
  [currentCard]="currentCard"
  (cardActivated)="cardActivated.emit()"
  (pickedCard)="pickedCard.emit($event)"
></app-account-section-card-header>
<!-- IBAN/WALLET/CARD SUBSECTION -->
<app-account-sub-section
  [accountTransactions]="accountTransactions"
  [isCardActive]="isCardActive"
  [isLoadingCards]="isLoadingCards"
  [isLoadingIBANTransactions]="isLoadingIBANTransactions"
  [isLoadingCardTransactions]="isLoadingCardTransactions"
  [section]="section"
  [cards]="cards"
  [wallet]="wallet"
></app-account-sub-section>
