<div [formGroup]="documentsForm" *ngIf="show">
  <ng-container *ngIf="identityDocumentTypeControl.value">
    <div
      fxLayout="row wrap"
      fxLayoutGap="25px grid"
      fxLayoutAlign="space-between"
    >
      <ng-container *ngFor="let control of documentsControls; let i = index">
        <app-file-input
          [companyDirectorId]="companyDirectorId"
          [class.hidden]="control.disabled"
          [formControl]="control"
          [disableFileRemove]="true"
          fxFlex="50"
          fxFlex.lt-sm="100"
          (removeField)="removeField(i)"
        ></app-file-input>
      </ng-container>
    </div>

    <!-- KYC: Don't show Add another document here in identity-document form.
         It is only displayed in component Upload additional documents (EDD).
         KYB: Show Add another document here in identity-document form for 
         company key persons and when more information is requested 
         (but not when it is the first time in EDD). -->
    <button
      *ngIf="companyDirectorId && isReviewed && !isFirstTimeEDD"
      mat-stroked-button
      data-testid="add-another-file-button"
      color="accent"
      class="add-file"
      (click)="addAnotherFile()"
    >
      Add another document
    </button>
  </ng-container>
</div>
