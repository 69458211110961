<app-sub-dashboard-header
  svgIconName="wallet"
  [section]="sections.WALLET"
  headerName="Wallet"
></app-sub-dashboard-header>
<div class="icon" fxLayout="row" fxLayoutAlign="start center">
  <mat-icon class="material-icons-outlined balance-header-icon"
    >account_balance_wallet</mat-icon
  >
  <span class="balance-header">Balances</span>
</div>
<div fxLayout fxLayoutGap="20px" class="container" fxLayout.lt-md="column">
  <app-account-sub-section
    [section]="sections.WALLET"
    [wallet]="wallet"
  ></app-account-sub-section>
  <app-currency-exchange
    [isLoadingBalances]="isLoadingBalances"
    [wallet]="wallet"
    (transferSuccess)="refresh()"
  ></app-currency-exchange>
</div>
