<mat-card>
  <div class="top-container">
    <div
      class="filters-container"
      fxLayout
      fxLayoutGap="30px"
      fxLayoutAlign="start center"
      [formGroup]="filtersGroup"
    >
      <div fxLayout="column">
        <label for="search">Search</label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="search" name="search" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <label>Date range</label>
        <mat-form-field appearance="outline" class="date">
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              placeholder="Start date"
              formControlName="startDate"
            />
            <input
              matEndDate
              placeholder="End date"
              formControlName="endDate"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="30px">
      <div>
        <button
          mat-stroked-button
          color="accent"
          class="g-small"
          (click)="onGenerateCsv()"
          [appButtonLoader]="isLoadingCsv"
        >
          Download CSV
        </button>
      </div>
      <!-- <div>
        <button
          mat-stroked-button
          color="accent"
          class="g-small"
          (click)="onGeneratePdf()"
        >
          Download PDF
        </button>
      </div> -->
    </div>
  </div>
  <div class="table-container">
    <div class="spinner-container" *ngIf="dataSource.isLoadingObs | async">
      <app-big-loader></app-big-loader>
    </div>

    <table
      mat-table
      [dataSource]="dataSource"
      [fixedLayout]="true"
      matSort
      [matSortActive]="orderField"
      [matSortDirection]="sortDirection"
      matSortDisableClear
    >
      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header="userName">Username</div>
        </th>
        <td
          data-testid="name-cell"
          mat-cell
          *matCellDef="let row"
          [routerLink]="[row.id]"
        >
          <div>{{ row.userName }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
        <td mat-cell *matCellDef="let row">{{ row.action }}</td>
      </ng-container>

      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Message</th>
        <td mat-cell *matCellDef="let row">{{ row.message }}</td>
      </ng-container>

      <ng-container matColumnDef="ipAddress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>IP Address</th>
        <td mat-cell *matCellDef="let row">{{ row.ipAddress }}</td>
      </ng-container>

      <ng-container matColumnDef="dateCreated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let row">
          {{ row.dateCreated | date: 'dd/MM/yyyy HH:mm' }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [length]="dataSource.getLength() | async"
      [pageSize]="20"
      [pageSizeOptions]="[20, 50, 100]"
    ></mat-paginator>
  </div>
</mat-card>
