import { Component, Input } from '@angular/core';
import { OnboardingFieldId } from 'src/app/admin/users/models/onboarding-field-id.enum';
import { OnboardingData } from '../../models/onboarding-data.model';

@Component({
  selector: 'app-reviewed-info-new',
  templateUrl: './reviewed-info.component.html',
  styleUrls: ['./reviewed-info.component.scss'],
})
export class ReviewedInfoNewComponent {
  @Input() set onboardingData(val: OnboardingData | undefined) {
    this.generalComment = val?.fields.find(
      (field) =>
        field.id === OnboardingFieldId.PERSONAL ||
        field.id === OnboardingFieldId.BUSINESS
    )?.comment;
    this._onboardingData = val;
  }
  get onboardingData(): OnboardingData | undefined {
    return this._onboardingData;
  }

  generalComment?: string | null;
  private _onboardingData?: OnboardingData;
}
