import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface LogDialogData {
  isResponse: boolean;
  text: string;
}

@Component({
  templateUrl: './log-dialog.component.html',
  styleUrls: ['./log-dialog.component.scss'],
})
export class LogDialogComponent {
  formattedText?: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: LogDialogData) {
    if (data.isResponse) {
      try {
        this.formattedText = JSON.stringify(JSON.parse(data.text), null, 2);
      } catch {
        this.formattedText = data.text;
      }
    } else {
      this.formattedText = data.text;
    }
  }
}
