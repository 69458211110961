import { Component, Inject } from '@angular/core';
import { Card } from '../../../dashboard/models/card.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DashboardService } from '../../../dashboard/dashboard.service';
import { ErrorService } from '../../../shared/error-dialog/error.service';

@Component({
  selector: 'app-report-card',
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.scss'],
})
export class ReportCardComponent {
  card?: Card;
  isLoading: boolean = false;
  shouldRefresh: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<ReportCardComponent>,
    @Inject(MAT_DIALOG_DATA) data: Card,
    private dashboardService: DashboardService,
    private errorService: ErrorService
  ) {
    this.card = data;
  }

  reportCard() {
    this.isLoading = true;
    this.dashboardService.reportLostStolen(this.card!.numAccountID).subscribe(
      () => {
        this.isLoading = false;
        this.shouldRefresh = true;
        this.closeDialog();
      },
      (error) => {
        this.isLoading = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }
  closeDialog() {
    this.dialogRef.close(this.shouldRefresh);
  }
}
