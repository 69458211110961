import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  EncryptedDataModel,
  EncryptionService,
} from 'src/app/shared/services/encryption.service';
import { environment } from 'src/environments/environment';
import { AmlHitTitle } from './models/aml-hit-title.model';
import { KybHitTitle } from './models/kyb-hit-title.model';

@Injectable({
  providedIn: 'root',
})
export class RiskAssessmentService {
  baseUrl = `${environment.BACKEND_URL}/risk-assessment`;

  constructor(
    private http: HttpClient,
    private encryptionService: EncryptionService
  ) {}

  async getAmlHits(
    userId: number,
    reportId: number,
    riskReportType: number
  ): Promise<Observable<AmlHitTitle[]>> {
    const url = `${this.baseUrl}/aml-hits`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt({
        userId,
        reportId,
        riskReportType,
      });
    return this.http.post<AmlHitTitle[]>(url, cipherModel);
  }

  async getAmlHit(
    amlHitId: number,
    userId: number
  ): Promise<Observable<string>> {
    const url = `${this.baseUrl}/aml-hit`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt({ amlHitId, userId });
    return this.http.post<string>(url, cipherModel);
  }

  async getKybHits(
    userId: number,
    reportId: number
  ): Promise<Observable<KybHitTitle[]>> {
    const url = `${this.baseUrl}/kyb-hits`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt({
        userId,
        reportId,
      });
    return this.http.post<KybHitTitle[]>(url, cipherModel);
  }

  async getKybHit(
    kybHitId: number,
    userId: number
  ): Promise<Observable<string>> {
    const url = `${this.baseUrl}/kyb-hit`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt({ kybHitId: kybHitId, userId });
    return this.http.post<string>(url, cipherModel);
  }
}
