import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function minControlsNumberValidator(minNumber: number): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    if (Object.keys((formGroup as FormGroup).controls).length < minNumber) {
      return { controlsNumber: true };
    } else {
      return null;
    }
  };
}
