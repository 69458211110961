import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';

export function bicValidator(
  bicValidation: (bic: string, isIntermediary: boolean) => Promise<void>,
  isIntermediary: boolean
): AsyncValidatorFn {
  return (formControl: AbstractControl): Promise<ValidationErrors | null> => {
    return bicValidation(formControl.value, isIntermediary)
      .then(() => null)
      .catch(() => Promise.resolve({ bic: true }));
  };
}
