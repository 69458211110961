export enum AmlMatchResult {
  NAME_EXACT = 'name_exact',
  AKA_EXACT = 'aka_exact',
  NAME_FUZZY = 'name_fuzzy',
  AKA_FUZZY = 'aka_fuzzy',
  PHONETIC_NAME = 'phonetic_name',
  PHONETIC_AKA = 'phonetic_aka',
  EQUIVALENT_NAME = 'equivalent_name',
  EQUIVALENT_AKA = 'equivalent_aka',
  UNKNOWN = 'unknown',
  YEAR_OF_BIRTH = 'year_of_birth',
  REMOVED_PERSONAL_TITLE = 'removed_personal_title',
  REMOVED_PERSONAL_SUFFIX = 'removed_personal_suffix',
  REMOVED_ORGANISATION_PREFIX = 'removed_organisation_prefix',
  REMOVED_ORGANISATION_SUFFIX = 'removed_organisation_suffix',
  REMOVED_CLERICAL_MARK = 'removed_clerical_mark',
}
