<div class="dialog-container">
  <div fxLayoutAlign="end">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div fxLayout="column">
    <h1 class="dialog-title">Obsolete cards</h1>
    <div class="dialog-description" fxLayout="column">
      <span>This is a list of your past cards (expired or closed).</span>
    </div>
  </div>
  <div *ngIf="cards">
    <div class="cards-container" *ngIf="cards.length > 0">
      <div *ngFor="let card of cards; let index = index">
        <app-card-item
          [card]="card"
          [deactivatedCards]="true"
          [isSmallScreen]="($breakpointObserver | async) === true"
        ></app-card-item>
        <mat-divider *ngIf="index !== cards.length - 1"></mat-divider>
      </div>
    </div>
    <div
      class="cards-container empty-state"
      *ngIf="cards.length === 0"
      fxLayoutAlign="center center"
    >
      <span>No obsolete cards available</span>
    </div>
  </div>
  <div class="button-container" fxLayout fxLayoutAlign="end">
    <button mat-flat-button color="accent" (click)="closeDialog()">
      Close
    </button>
  </div>
</div>
