<div fxLayout="column" fxLayoutAlign="start center" class="container">
  <mat-icon>schedule</mat-icon>
  <div class="text">
    You will be automatically <br />
    logged out in a few seconds.
  </div>
  <button mat-flat-button color="accent" class="first" (click)="onContinue()">
    Stay logged in
  </button>
  <button mat-flat-button color="accent" (click)="onLogout()">
    <span>Log out now </span>
    <span class="seconds">({{ counter | async }}s)</span>
  </button>
</div>
