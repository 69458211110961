import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { environment } from 'src/environments/environment.local';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshingInProgress = false;
  private accessTokenSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url === `${environment.BACKEND_URL}/login/refresh`) {
      return next.handle(request);
    } else {
      return next.handle(request).pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            const refreshToken = sessionStorage.getItem('refresh');
            return refreshToken
              ? this.refreshToken(request, next)
              : this.logoutAndRedirect(err);
          }
          // for other errors
          return throwError(err);
        })
      );
    }
  }

  private addAuthorizationHeader(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    return token
      ? request.clone({
          setHeaders: { Authorization: `Bearer ${token}` },
        })
      : request;
  }

  private logoutAndRedirect(err: any): Observable<never> {
    this.authService.logOut();
    return throwError(err);
  }

  private refreshToken(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.refreshingInProgress) {
      this.refreshingInProgress = true;
      this.accessTokenSubject.next('');

      return this.authService.refreshToken().pipe(
        catchError((err) => this.logoutAndRedirect(err)),
        switchMap((res) => {
          this.refreshingInProgress = false;
          this.accessTokenSubject.next(res.token);
          // repeat failed request with new token
          return next.handle(this.addAuthorizationHeader(request, res.token));
        })
      );
    } else {
      // wait while getting new token
      return this.accessTokenSubject.pipe(
        filter((token) => !!token),
        take(1),
        switchMap((token) => {
          // repeat failed request with new token
          return next.handle(this.addAuthorizationHeader(request, token));
        })
      );
    }
  }
}
