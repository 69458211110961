import { Component, Input } from '@angular/core';
import { Program } from '../../../../models/program.enum';

@Component({
  selector: 'app-account-header',
  templateUrl: './account-header.component.html',
  styleUrls: ['./account-header.component.scss'],
})
export class AccountHeaderComponent {
  @Input()
  set program(val: Program | null) {
    this.programName = val ? Program[val]?.toLowerCase() : '';
    this.imgSrc = this.programName ? `assets/img/${this.programName}.svg` : '';
    this.imgAlt = this.programName ? `${this.programName} logo` : '';
    this._program = val;
  }
  get program(): Program | null {
    return this._program;
  }

  imgSrc = '';
  imgAlt = '';
  programName = '';
  private _program: Program | null = null;
}
