import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { CompanyDirectorWithDocuments } from 'src/app/onboarding-new/models/company-director.model';
import {
  getCountryById,
  getStringValue,
  getNationalityById,
} from 'src/app/shared/helpers/various-helpers.helper';
import { Country } from 'src/app/shared/models/country.model';

@Component({
  selector: 'app-admin-director-shareholder-new',
  templateUrl: './admin-director-shareholder.component.html',
  styleUrls: ['./admin-director-shareholder.component.scss'],
})
export class AdminDirectorShareholderNewComponent {
  @Input() control!: FormArray;
  @Input() index: number = 0; // used for numbering the components
  @Input() director!: CompanyDirectorWithDocuments;
  @Input() userId!: number;
  @Input() countries: Country[] = [];
  @Input() directorGroup!: FormGroup;
  @Input() directorCloseLinkGroup!: FormGroup;
  @Input() allowResendIdVerificationUrl!: boolean;

  getCountryById = getCountryById;
  getStringValue = getStringValue;
  getNationalityById = getNationalityById;

  get directorControl(): FormArray {
    return this.directorGroup.get('directorForm') as FormArray;
  }

  get directorCloseLinkControl(): FormArray {
    return this.directorCloseLinkGroup.get(
      'directorCloseLinkForm'
    ) as FormArray;
  }

  get fileDialogHeader(): string {
    return this.director.isLegalPerson
      ? `${this.director.legalPersonName}`
      : `${this.director.firstName} ${this.director.lastName}`;
  }

  get documentsControls(): FormControl[] {
    return this.control.controls as FormControl[];
  }
}
