import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserEventsService {
  private userEventsSubject = new Subject<any>();
  public userEventsObservable = this.userEventsSubject.asObservable();
  private eventSource: EventSource | undefined;

  startListening(setTokenInCookie: (path: string) => Observable<void>): void {
    if (this.eventSource || localStorage.getItem('pdf-generation') === 'true') {
      return;
    }
    const url = `${environment.BACKEND_URL}/user-events`;
    const path = new URL(url, document.location.toString()).pathname;
    const eventSource = new EventSource(url, {
      withCredentials: true,
    });
    this.eventSource = eventSource;
    setTokenInCookie(path).subscribe(() => {
      eventSource.onerror = () => {
        eventSource.close();
        if (this.eventSource === eventSource) {
          this.eventSource = undefined;
          setTimeout(() => this.startListening(setTokenInCookie), 5000);
        }
      };
      eventSource.onmessage = (event) => {
        console.log('New SSE event:', event.data);
        this.userEventsSubject.next(event.data);
      };
    });
  }

  stopListening(): void {
    this.eventSource?.close();
    this.eventSource = undefined;
  }
}
