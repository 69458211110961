import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { TransferService } from '../../transfer.service';
import { TransferType } from '../enum/transfer-type.enum';
import { extractTermsFromBeneficiary } from 'src/app/shared/helpers/various-helpers.helper';
import { Beneficiary } from '../models/beneficiary.model';

@Component({
  selector: 'app-select-beneficiary-overlay',
  templateUrl: './select-beneficiary-overlay.component.html',
  styleUrls: ['./select-beneficiary-overlay.component.scss'],
})
export class SelectBeneficiaryOverlayComponent implements OnInit {
  @Input() accountOwnerGuid!: string;
  @Output() selectBen = new EventEmitter<Beneficiary>();

  TransferType = TransferType;
  isLoading: boolean = false;
  beneficiaries: Beneficiary[] = [];

  searchControl: FormControl = this.fb.control(null);
  searchSub?: Subscription;
  searchTerm: string = '';

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private transferService: TransferService
  ) {}

  ngOnInit(): void {
    this.searchSub = this.searchControl.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        startWith(this.searchTerm)
      )
      .subscribe(async (searchTerm) => {
        this.isLoading = true;
        this.searchTerm = searchTerm;
        (
          await this.transferService.getBeneficiaryList(this.accountOwnerGuid)
        ).subscribe(
          (beneficiaryList: Beneficiary[]) => {
            this.beneficiaries = beneficiaryList.filter(
              (beneficiary: Beneficiary) =>
                (Number(beneficiary.transferType) === TransferType.SEPA ||
                  Number(beneficiary.transferType) === TransferType.SWIFT) &&
                extractTermsFromBeneficiary(beneficiary)
                  .toLowerCase()
                  .includes(this.searchTerm.toLowerCase())
            );
            this.isLoading = false;
          },
          () => {
            this.isLoading = false;
            this.errorService.showErrorDialog();
          }
        );
      });
  }

  selectBeneficiary(beneficiary: Beneficiary) {
    this.selectBen.emit(beneficiary);
  }
}
