import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Country } from 'src/app/shared/models/country.model';
import {
  getCountryById,
  getNationalityById,
  getStringValue,
} from 'src/app/shared/helpers/various-helpers.helper';
import { CompanyDirectorWithDocuments } from '../../models/company-director.model';
import { AppDocumentType } from '../../models/document-type.enum';
import { OnboardingData } from '../../models/onboarding-data.model';
import { Step } from '../../stepper/stepper.component';
import { CompanyCounterparty } from '../../models/company-counterparty.model';
import { CompanyAccount } from '../../models/company-account.model';
import { CompanyDirectorCloseLink } from '../../models/company-director-close-link.model';
import { CompanyCloseLink } from '../../models/company-close-link.model';
import { DirectorCloseLink } from '../../models/director-close-link.model';
import { DateTime } from 'luxon';
import { CompanyGroupMember } from '../../models/company-group-member.model';

@Component({
  selector: 'app-onboarding-business-submitted-new',
  templateUrl: './onboarding-business-submitted.component.html',
  styleUrls: ['./onboarding-business-submitted.component.scss'],
})
export class OnboardingBusinessSubmittedNewComponent {
  @Input() set onboardingData(val: OnboardingData) {
    // prepares documents controls
    this.documentsArray.clear();
    val.documents.forEach((document) =>
      this.documentsArray.push(
        this.fb.control({ documentType: document.type, doc: document })
      )
    );

    // prepares directors/shareholders/UBO/authorized contact person
    this.directors = val.companyDirectors!;

    this.directors.forEach((director) => {
      const directorDocumentsArray = this.fb.array([]);
      director.documents.forEach((document) => {
        const customLabel =
          document.type === AppDocumentType.PROOF_OF_ADDRESS
            ? director.isLegalPerson
              ? 'Proof of Operating Address'
              : 'Proof of Address'
            : '';
        directorDocumentsArray.push(
          this.fb.control({
            documentType: document.type,
            doc: document,
            customLabel,
          })
        );
      });
      this.directorsGroup.addControl(
        director.id.toString(),
        directorDocumentsArray
      );
    });

    // prepares counterparties
    this.counterparties = val.companyCounterparties!;

    // prepares accounts
    this.accounts = val.companyAccounts!;

    // prepares close links
    this.closeLinks = val.companyCloseLinks!;

    // prepares director close links
    this.directorCloseLinks = val.companyDirectorCloseLinks!;

    // prepares group members
    this.groupMembers = val.companyGroupMembers!;

    this.isSameAddress =
      JSON.stringify(val?.corporateForm?.incorporationAddress) ===
      JSON.stringify(val?.corporateForm?.operatingAddress);

    this._onboardingData = val;
  }
  get onboardingData(): OnboardingData {
    return this._onboardingData;
  }

  @Input() countries: Country[] = [];
  @Input() steps: Step[] = [];

  private _onboardingData!: OnboardingData;

  documentsArray: FormArray = this.fb.array([]);
  directorsGroup: FormGroup = this.fb.group({});
  directors: CompanyDirectorWithDocuments[] = [];
  isSameAddress: boolean = false;
  counterparties: CompanyCounterparty[] = [];
  accounts: CompanyAccount[] = [];
  closeLinks: CompanyCloseLink[] = [];
  directorCloseLinks: CompanyDirectorCloseLink[] = [];
  groupMembers: CompanyGroupMember[] = [];

  getCountryById = getCountryById;
  getStringValue = getStringValue;
  getNationalityById = getNationalityById;

  constructor(private fb: FormBuilder) {}

  getDirectorDocumentsControls(id: number): FormControl[] {
    return (this.directorsGroup.get(id.toString()) as FormArray)
      .controls as FormControl[];
  }

  getDirectorCloseLinks(id: number): DirectorCloseLink[] {
    return this.directorCloseLinks
      .filter((companyDirector) => companyDirector.directorId === id)
      .map((d) => this.toDirectorCloseLink(d));
  }

  toDirectorCloseLink(directorCloseLink: CompanyDirectorCloseLink) {
    const closeLink = this.closeLinks.find(
      (g) => g.id === directorCloseLink.closeLinkId
    );
    const body: DirectorCloseLink = {
      name: closeLink?.name,
      registrationNumber: closeLink?.registrationNumber,
      incorporationDate: closeLink?.incorporationDate
        ? DateTime.fromISO(closeLink?.incorporationDate).toLocaleString(
            DateTime.DATE_MED
          )
        : '',
      streetAddress: closeLink?.address?.streetAddress,
      additionalStreetAddress: closeLink?.address?.additionalStreetAddress
        ? closeLink?.address?.additionalStreetAddress
        : '',
      postcode: closeLink?.address?.postCode,
      city: closeLink?.address?.city,
      country: getCountryById(this.countries, closeLink?.address?.countryId),
      isDirector: directorCloseLink.isDirector,
      isShareholder: directorCloseLink.isShareholder,
      isUBO: directorCloseLink.isUBO,
      percentageOwnedByShareholder:
        directorCloseLink.percentageOwnedByShareholder,
      percentageOwnedByUBO: directorCloseLink.percentageOwnedByUBO,
    };
    return body;
  }

  get documentsControls(): FormControl[] {
    return this.documentsArray.controls as FormControl[];
  }

  convertDate(val: string | undefined) {
    return val ? DateTime.fromISO(val).toLocaleString(DateTime.DATE_MED) : '';
  }
}
