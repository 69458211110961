import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { OnboardingService } from '../../onboarding.service';
import { Country } from 'src/app/shared/models/country.model';
import {
  CompanyAccount,
  SaveCompanyAccount,
} from '../../models/company-account.model';
import { OnboardingField } from '../../models/onboarding-field.model';

@Component({
  selector: 'app-company-accounts-form',
  templateUrl: './company-accounts-form.component.html',
  styleUrls: ['./company-accounts-form.component.scss'],
})
export class CompanyAccountsFormComponent implements OnInit, OnDestroy {
  @Input() countries: Country[] = [];
  @Input() fields: OnboardingField[] = [];
  @Input() set companyAccounts(val: CompanyAccount[]) {
    const accountsIds = new Set();
    this.accounts = [];

    val.forEach((account) => {
      accountsIds.add(account.id.toString());
      this.accounts.push(account);

      // adds controls to accountsGroup
      if (!this.accountsGroup.get(account.id.toString())) {
        this.accountsGroup.addControl(
          account.id.toString(),
          this.fb.control(null, Validators.requiredTrue)
        );
      }
    });

    // removes controls from accountsGroup that are not needed anymore
    Object.keys(this.accountsGroup.controls).forEach((key) => {
      if (!accountsIds.has(key)) {
        this.accountsGroup.removeControl(key);
      }
    });

    this._companyAccounts = val;
  }
  get companyAccounts(): CompanyAccount[] {
    return this._companyAccounts;
  }
  @Input() isReviewed: boolean = false;

  // It is emitted when account is modified by user (so that it can be show proprerly in other section)
  @Output() companyAccountsChange = new EventEmitter<CompanyAccount[]>();
  @Output() isComplete = new EventEmitter<boolean>();

  accounts: CompanyAccount[] = [];
  isAdding: boolean = false;
  private _companyAccounts: CompanyAccount[] = [];
  private accountsFormSub: Subscription | undefined = undefined;

  accountsForm: FormGroup = this.fb.group(
    {
      accounts: this.fb.group(
        {}
        // { validators: minControlsNumberValidator(1) } // it has to be at least one account
      ), // this group is used only for validation purposes (contains values 'isValid', and validation 'requiredTrue')
    },
    { updateOn: 'blur' }
  );

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private onboardingService: OnboardingService
  ) {}

  ngOnInit(): void {
    // Emits status of the form when it changes
    this.accountsFormSub = this.accountsForm.statusChanges
      .pipe(startWith(this.accountsForm.status))
      .subscribe((status) => {
        setTimeout(() => this.isComplete.emit(status === 'VALID'));
      });
  }

  ngOnDestroy(): void {
    this.accountsFormSub?.unsubscribe();
  }

  addAccount(): void {
    this.isComplete.emit(false); // prevents saving application while adding account
    this.isAdding = true;
    const body: SaveCompanyAccount = {
      name: '',
      country: 0,
      isActive: true,
    };
    this.onboardingService.createCompanyAccount(body).subscribe(
      (newAccount) => {
        this.companyAccountsChange.emit([...this.companyAccounts, newAccount]);
        this.isAdding = false;
      },
      () => {
        this.errorService.showErrorDialog();
        this.isAdding = false;
      }
    );
  }

  // used for emitting company accounts when one was changed
  onAccountChange(newAccount: CompanyAccount): void {
    const index = this.companyAccounts.findIndex(
      (companyAccount) => newAccount.id === companyAccount.id
    );

    const newCompanyAccounts = [...this.companyAccounts];
    newCompanyAccounts[index] = {
      ...this.companyAccounts[index],
      ...newAccount,
    };

    this.companyAccountsChange.emit(newCompanyAccounts);
  }

  // used for emitting company accounts when one was removed
  onAccountRemove(id: number): void {
    const newCompanyAccounts = this.companyAccounts.filter(
      (companyAccount) => companyAccount.id !== id
    );
    this.companyAccountsChange.emit(newCompanyAccounts);
  }

  // used for setting account as complete (in accountsGroup)
  setComplete(accountId: number, isComplete: boolean): void {
    this.accountsGroup.get(accountId.toString())?.setValue(isComplete);
  }

  trackByFn(index: number, item: CompanyAccount): number {
    return item.id;
  }

  get accountsGroup(): FormGroup {
    return this.accountsForm.get('accounts') as FormGroup;
  }
}
