<div *ngFor="let card of cards">
  <app-card-item
    *ngIf="
      card.status !== AccountStatus.EXPIRED &&
      card.status !== AccountStatus.CLOSED
    "
    class="cursor"
    [card]="card"
    (cardActivated)="cardActivated.emit()"
    (pickedCard)="pickedCard.emit($event)"
  ></app-card-item>
  <mat-divider></mat-divider>
</div>
<div
  fxLayoutAlign="start center"
  class="new-card cursor"
  *ngIf="isOrderNewCard() && !isLoadingCards"
  (click)="orderNewCard()"
>
  <mat-icon svgIcon="add"></mat-icon>
  <span>Order new card</span>
</div>
