import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserType } from '../login/models/user-type.enum';
import { AuthService } from '../login/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class CardPaidGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.getAuthenticatedUser().pipe(
      map((user) => {
        if (user.isCardIssuePaid || user.type === UserType.CORPORATE) {
          return true;
        } else {
          return this.router.parseUrl('initial-payment');
        }
      })
    );
  }
}
