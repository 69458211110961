import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AccountsTable } from '../../shared/accounts-table.model';
import { accountStatusNamesMap } from '../../../../../../dashboard/shared/account-status-names.map';
import { AccountStatusNew } from 'src/app/dashboard/shared/account-status-new.enum';

@Component({
  selector: 'app-accounts-table',
  templateUrl: './accounts-table.component.html',
  styleUrls: ['./accounts-table.component.scss'],
})
export class AccountsTableComponent {
  @Input() matTableDataSource!: MatTableDataSource<AccountsTable>;
  @Input() isFinishedLoading!: boolean;
  @Output() currentAccountTableEmitter = new EventEmitter<AccountsTable>();

  AccountStatusNew = AccountStatusNew;
  displayedColumns: string[] = [
    'accountName',
    'iban',
    'code',
    'correspondentBankName',
    'correspondentBankCode',
    'status',
    'dateCreated',
    'availableBalance',
    'balance',
    'chevron',
  ];

  constructor() {}

  imagePath(name: string): string {
    return `assets/img/country-flags/${name}.svg`;
  }

  isAccountOpen(status: AccountStatusNew): boolean {
    return status === AccountStatusNew.ACTIVE;
  }

  getAccountStatusName(status: AccountStatusNew): string {
    return accountStatusNamesMap.get(status)!;
  }

  selectAccount(accountTable: AccountsTable) {
    this.currentAccountTableEmitter.emit(accountTable);
  }
}
