import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { OnboardingService } from '../../onboarding.service';
import { Country } from 'src/app/shared/models/country.model';
import { CompanyCloseLink } from '../../models/company-close-link.model';
import { DateTime } from 'luxon';
import { OnboardingFieldId } from 'src/app/admin/users/models/onboarding-field-id.enum';
import { OnboardingField } from '../../models/onboarding-field.model';
import { getCountryById } from 'src/app/shared/helpers/various-helpers.helper';
import { v4 as uuidv4 } from 'uuid';

export interface CompanyCloseLinkDialogData {
  countries: Country[];
  companyCloseLink: CompanyCloseLink;
  isReviewed: boolean;
  isFirstTimeEDD: boolean;
  fields: OnboardingField[];
  isNotOnboarding: boolean;
}

@Component({
  selector: 'app-company-close-link-dialog',
  templateUrl: './company-close-link-dialog.component.html',
  styleUrls: ['./company-close-link-dialog.component.scss'],
})
export class CompanyCloseLinkDialogComponent implements OnInit {
  isAdding: boolean = false;
  today: Date = new Date();
  countries!: Country[];
  companyCloseLink?: CompanyCloseLink;
  isReviewed!: boolean;
  isFirstTimeEDD!: boolean;
  fields: OnboardingField[] = [];
  isNotOnboarding!: boolean;
  fieldsMap: Map<
    string,
    { comment: string | null; commentedValue: string | null } | null
  > = new Map<
    string,
    { comment: string | null; commentedValue: string | null } | null
  >();

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: CompanyCloseLinkDialogData,
    private errorService: ErrorService,
    private dialogRef: MatDialogRef<CompanyCloseLinkDialogComponent>,
    private onboardingService: OnboardingService
  ) {}

  OnboardingFieldId = OnboardingFieldId;
  getCountryById = getCountryById;

  closeLinkForm: FormGroup = this.fb.group({
    id: [null],
    name: [
      '',
      [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
        Validators.maxLength(100),
      ],
    ],
    registrationNumber: [
      '',
      [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
        Validators.maxLength(50),
      ],
    ],
    incorporationDate: ['', [Validators.required]],
    address: this.fb.group({
      streetAddress: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 .,&`'\\/()-]*$"),
          Validators.maxLength(100),
        ],
      ],
      additionalStreetAddress: [
        '',
        [
          Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
          Validators.maxLength(100),
        ],
      ],
      postCode: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9 ()\\/-]*$'),
          Validators.maxLength(50),
        ],
      ],
      city: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 `'\\/()-]*$"),
          Validators.maxLength(50),
        ],
      ],
      countryId: ['', Validators.required],
    }),
  });

  ngOnInit(): void {
    this.countries = this.data.countries;
    this.companyCloseLink = this.data.companyCloseLink;
    this.isReviewed = this.data.isReviewed;
    this.isFirstTimeEDD = this.data.isFirstTimeEDD;
    this.fields = this.data.fields;
    this.isNotOnboarding = this.data.isNotOnboarding;

    if (this.companyCloseLink) {
      this.closeLinkForm.patchValue(this.companyCloseLink);
      // fieldsMap
      this.fieldsMap.clear();
      this.fields
        ?.filter(
          (field) =>
            !!field.comment && field.closeLinkId === this.companyCloseLink?.id
        )
        .forEach((field) => {
          let commentedValue = field.commentedValue;
          if (field.id.endsWith('.countryId')) {
            // converts countryId to country name
            commentedValue = this.getCountryById(
              this.countries,
              parseInt(commentedValue)
            );
          }
          // converts date
          if (field.id.endsWith('.incorporationDate')) {
            commentedValue = DateTime.fromISO(commentedValue).toLocaleString(
              DateTime.DATE_MED
            );
          }
          this.fieldsMap.set(field.id, {
            comment: field.comment,
            commentedValue,
          });
        });
    }
  }

  save(form: FormGroup) {
    const body = {
      ...form.value,
      incorporationDate:
        typeof form.value.incorporationDate === 'string'
          ? form.value.incorporationDate
          : (form.value.incorporationDate as DateTime).toISODate(),
    };
    if (this.isNotOnboarding) {
      if (this.companyCloseLink?.id) {
        // update existing close link and emit
        this.dialogRef.close(body);
      } else {
        // add new close link and emit
        const newBody = {
          ...body,
          id: uuidv4(),
        };
        this.dialogRef.close(newBody);
      }
    } else {
      if (this.companyCloseLink?.id) {
        this.onboardingService
          .updateCompanyCloseLink(this.companyCloseLink.id, body)
          .subscribe(
            (updatedCloseLink) => {
              this.dialogRef.close(updatedCloseLink);
              this.isAdding = false;
            },
            () => {
              this.errorService.showErrorDialog();
              this.isAdding = false;
            }
          );
      } else {
        this.onboardingService.createCompanyCloseLink(body).subscribe(
          (newCloseLink) => {
            this.dialogRef.close(newCloseLink);
            this.isAdding = false;
          },
          () => {
            this.errorService.showErrorDialog();
            this.isAdding = false;
          }
        );
      }
    }
  }

  // Company close link
  get nameControl(): AbstractControl | null {
    return this.closeLinkForm.get('name');
  }
  get registrationNumberControl(): AbstractControl | null {
    return this.closeLinkForm.get('registrationNumber');
  }
  get incorporationDateControl(): AbstractControl | null {
    return this.closeLinkForm.get('incorporationDate');
  }
  // Company close link address
  get addressGroup(): AbstractControl | null {
    return this.closeLinkForm.get('address');
  }
  get streetAddressControl(): AbstractControl | null | undefined {
    return this.addressGroup?.get('streetAddress');
  }
  get additionalStreetAddressControl(): AbstractControl | null | undefined {
    return this.addressGroup?.get('additionalStreetAddress');
  }
  get postCodeControl(): AbstractControl | null | undefined {
    return this.addressGroup?.get('postCode');
  }
  get cityControl(): AbstractControl | null | undefined {
    return this.addressGroup?.get('city');
  }
  get countryIdControl(): AbstractControl | null | undefined {
    return this.addressGroup?.get('countryId');
  }
}
