<div fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="dialog-container" *ngIf="!isSuccess">
  <div fxLayout="column">
    <h1 class="dialog-title">Activate the card</h1>
    <span class="card-information">
      Activate the card on the name of
      <strong>{{ card?.cardholder | titlecase }}</strong
      >.
    </span>
    <span class="error-message">{{ errorMessage }}</span>
  </div>
  <form
    fxLayout="column"
    class="dialog-form"
    [formGroup]="cardActivationForm"
    (ngSubmit)="submitCardActivation()"
  >
    <label>Activation code</label>
    <mat-form-field appearance="outline">
      <input
        matInput
        name="activationCode"
        formControlName="activationCode"
        required
        (focus)="onFocus()"
      />
    </mat-form-field>
    <label>Your ZIP/Postal code</label>
    <mat-form-field appearance="outline">
      <input
        matInput
        name="postalCode"
        formControlName="postalCode"
        required
        (focus)="onFocus()"
      />
    </mat-form-field>
  </form>
  <span class="bottom-text">
    Please, type the ZIP/Postal code exactly as you did within your address.
  </span>

  <div fxLayoutAlign="end center" class="button-container">
    <button
      mat-flat-button
      color="accent"
      (click)="submitCardActivation()"
      [disabled]="!cardActivationForm.valid"
      [appButtonLoader]="isLoadingActivation"
    >
      Activate the card
    </button>
  </div>
</div>
<div *ngIf="isSuccess" class="dialog-container">
  <h1 class="dialog-title" *ngIf="newCardNumber">The Card - {{ newCardNumber }} is activated</h1>
  <h1 class="dialog-title" *ngIf="!newCardNumber">The {{ card?.name }} is activated</h1>
  <span class="card-information"
    >Your card is now active.<br />
    Please remember the PIN code.<br
  /></span>
  <div class="pin">
    <span>You will receive your PIN number in an SMS message.</span>
  </div>
  <h2>First use</h2>
  <span class="bottom-text"
    >The first time you use your card <strong>at a POS terminal</strong> please insert it 
    <strong>into the POS terminal</strong>.<br />
    After that, it can be used contact-less.
  </span>
  <h2>Security</h2>
  <span class="bottom-text"
    >Use the PIN only to validate the transactions.<br />
    You should not reveal this number to anyone.<br />
    If you need to note the PIN (or take a picture of this screen), keep the
    number away from the card.
  </span>
  <div fxLayoutAlign="end center" class="button-container">
    <button mat-flat-button color="accent" (click)="closeDialog()">
      Close
    </button>
  </div>
</div>
