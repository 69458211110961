<div [formGroup]="documentsForm">
  <ng-container>
    <div
      fxLayout="row wrap"
      fxLayoutGap="25px grid"
      fxLayoutAlign="space-between"
    >
      <!-- filter out documents not included in eddDocumentTypes -->
      <ng-container *ngFor="let control of documentsControls; let i = index">
        <app-file-input
          *ngIf="eddDocumentTypes.includes(control.value.documentType)"
          [formControl]="control"
          fxFlex="50"
          fxFlex.lt-sm="100"
          (removeField)="removeField(i)"
        ></app-file-input>
      </ng-container>
    </div>

    <!-- in edd have the option to upload another file that may be requested by admin -->
    <button
      mat-stroked-button
      data-testid="add-another-file-button"
      color="accent"
      class="add-file"
      (click)="addAnotherFile()"
    >
      Add another document
    </button>
  </ng-container>
</div>
