import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { RiskAssessmentService } from '../risk-assessment.service';
import {
  getJurisdictionName,
  getStringValue,
} from 'src/app/shared/helpers/various-helpers.helper';

export interface ShowKybHitDialogData {
  kybHitId: number;
  reportId: number;
  userId: number;
}

@Component({
  selector: 'app-show-kyb-hit-dialog',
  templateUrl: './show-kyb-hit-dialog.component.html',
  styleUrls: ['./show-kyb-hit-dialog.component.scss'],
})
export class ShowKybHitDialogComponent implements OnInit {
  isLoadingKybHit: boolean = false;

  kybHitId!: number;
  userId!: number;

  kybHit: any;

  getJurisdictionName = getJurisdictionName;
  getStringValue = getStringValue;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShowKybHitDialogData,
    private riskAssessmentService: RiskAssessmentService,
    private errorService: ErrorService
  ) {}

  async ngOnInit(): Promise<void> {
    this.userId = this.data.userId;
    this.kybHitId = this.data.kybHitId;
    await this.getKybHit();
  }

  async getKybHit() {
    this.isLoadingKybHit = true;
    (
      await this.riskAssessmentService.getKybHit(this.kybHitId, this.userId)
    ).subscribe(
      (kybHit) => {
        this.kybHit = kybHit;
        this.isLoadingKybHit = false;
      },
      (error) => {
        this.isLoadingKybHit = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  // string
  convertToString(value: any): string {
    return String(value);
  }

  isString(data: any) {
    return data !== null && !Array.isArray(data) && typeof data !== 'object';
  }

  // object
  isNonNullObject(data: any) {
    // if it is a single object and not an array and
    //    has all null values, don't show the object
    //    has values, show the object
    if (data !== null && !Array.isArray(data) && typeof data === 'object') {
      return !Object.values(data).every((value) => value === null);
    }
    return false;
  }

  isObject(data: any) {
    if (data !== null && typeof data === 'object') {
      return true;
    }
    return false;
  }

  getObjectEntries(obj: any): [string, any][] {
    return Object.entries(obj);
  }

  getFirstObjectProperties(value: any): string[] {
    return value ? Object.keys(value[0]) : [];
  }

  getObjectProperties(obj: any): string[] {
    return obj ? Object.keys(obj) : [];
  }

  // array
  isArray(data: any) {
    // if array with objects show objects in table, if empty don't
    return Array.isArray(data) && data.length > 0;
  }

  // json
  formatJson(data: any) {
    var formattedText;
    if (data) {
      try {
        formattedText = JSON.stringify(data, null, 2);
      } catch {
        formattedText = data;
      }
    } else {
      formattedText = data;
    }
    return formattedText;
  }
}
