<table mat-table [dataSource]="matTableDataSource" *ngIf="isFinishedLoading">
  <ng-container matColumnDef="accountName">
    <th mat-header-cell *matHeaderCellDef>Account</th>
    <td mat-cell *matCellDef="let row">
      <div fxLayoutAlign="start center" class="account-name">
        <img
          *ngIf="row.accountIconName === 'master-card'"
          src="assets/img/master-card.svg"
          alt="master-card"
        />
        <mat-icon
          *ngIf="row.accountIconName === 'iban'"
          [svgIcon]="row.accountIconName"
        ></mat-icon>
        <img
          *ngIf="row.accountIconName === 'wallet'"
          [src]="imagePath(row.accountDetails.ccy)"
          [alt]="row.accountDetails.ccy"
        />
        {{ row.accountName }}
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="iban">
    <th mat-header-cell *matHeaderCellDef>Iban</th>
    <td mat-cell *matCellDef="let row" class="account-info">
      {{ row.accountDetails.iban }}
    </td>
  </ng-container>
  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef>Code</th>
    <td mat-cell *matCellDef="let row" class="account-info">
      {{ row.accountDetails.code }}
    </td>
  </ng-container>
  <ng-container matColumnDef="correspondentBankName">
    <th mat-header-cell *matHeaderCellDef>Correspondent Bank Name</th>
    <td mat-cell *matCellDef="let row" class="account-info">
      {{ row.accountDetails.correspondanceName }}
    </td>
  </ng-container>
  <ng-container matColumnDef="correspondentBankCode">
    <th mat-header-cell *matHeaderCellDef>Correspondent Bank Code</th>
    <td mat-cell *matCellDef="let row" class="account-info">
      {{ row.accountDetails.correspondanceCode }}
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let row">
      <div
        class="account-status"
        [ngClass]="isAccountOpen(row.accountDetails.status) ? 'open' : 'other'"
      >
        {{ getAccountStatusName(row.accountDetails.status) }}
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="dateCreated">
    <th mat-header-cell *matHeaderCellDef>Date Created</th>
    <td mat-cell *matCellDef="let row" class="account-info">
      {{ row.accountDetails.dateCreated | date: 'dd/MM/yyy' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="availableBalance">
    <th mat-header-cell *matHeaderCellDef>Available balance</th>
    <td mat-cell *matCellDef="let row" class="account-info balance">
      <div>
        {{ row.accountDetails.availableBalance | amount }}
        {{ row.accountDetails.ccy }}
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="balance">
    <th mat-header-cell *matHeaderCellDef>Book balance</th>
    <td mat-cell *matCellDef="let row" class="account-info balance">
      <div>
        {{ row.accountDetails.balance | amount }}
        {{ row.accountDetails.ccy }}
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="chevron">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let row">
      <div fxLayoutAlign="end center">
        <mat-icon svgIcon="chevron-contracted" class="chevron"></mat-icon>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    (click)="selectAccount(row)"
    [class.cursor]="true"
  ></tr>
</table>
