import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { DateTime } from 'luxon';
import { merge, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, tap } from 'rxjs/operators';
import { UserType } from 'src/app/login/models/user-type.enum';
import { AuthService } from 'src/app/login/services/auth.service';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { environment } from 'src/environments/environment.local';
import { UnpaidFeeSearch } from '../models/unpaid-fee-search.model';
import { ReportsService } from '../reports.service';
import { UnpaidFeesDataSource } from './unpaid-fees.datasource';

@Component({
  templateUrl: './unpaid-fees.component.html',
  styleUrls: ['./unpaid-fees.component.scss'],
})
export class UnpaidFeesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource!: UnpaidFeesDataSource;
  displayedColumns: string[] = [
    'names',
    'businessName',
    'usernamePhone',
    'paidOwed',
    'ibanChidBalance',
    'mcChidBalance',
    'walletChidBalance',
  ];

  filtersGroup: FormGroup = this.fb.group({
    search: null,
    startDate: null,
    endDate: null,
    individuals: true,
    businesses: true,
  });

  orderField: string = '';
  sortDirection: 'asc' | 'desc' = 'asc';

  private formSub?: Subscription;
  private sortSub?: Subscription;
  private mergeSub?: Subscription;

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private authService: AuthService,
    private reportsService: ReportsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.dataSource = new UnpaidFeesDataSource(
      this.reportsService,
      this.errorService
    );

    this.route.queryParams
      .pipe(
        first(),
        tap((params) => {
          if (!params.isPdf !== undefined) {
            console.log('params', params.isPdf);
            this.startDateControl.setValue(params.dateFrom);
            this.endDateControl.setValue(params.dateTo);
            this.searchControl.setValue(params.searchText);
            this.orderField = params.orderField || '';

            // sets sortDirection
            if (params.orderDescending) {
              this.sortDirection =
                params.orderDescending === 'true' ? 'desc' : 'asc';
            }

            // sets userType string
            switch (params.userType ? parseInt(params.userType) : null) {
              case UserType.PERSONAL:
                this.businessesControl.setValue(false);
                break;
              case UserType.CORPORATE:
                this.individualsControl.setValue(false);
                break;
              default:
                break;
            }

            this.dataSource.loadUnpaidFees(params);
          } else {
            this.dataSource.loadUnpaidFees({ limit: 20 });
          }
        })
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    // listens for filters values
    this.formSub = merge(
      this.searchControl.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged()
      ),
      this.startDateControl.valueChanges.pipe(distinctUntilChanged()),
      this.endDateControl.valueChanges.pipe(distinctUntilChanged()),
      this.individualsControl.valueChanges,
      this.businessesControl.valueChanges
    )
      .pipe(
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadOngoingRegistrationsPage();
        })
      )
      .subscribe();

    // reset the paginator after sorting
    this.sortSub = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );

    this.mergeSub = merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadOngoingRegistrationsPage()))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.formSub?.unsubscribe();
    this.sortSub?.unsubscribe();
    this.mergeSub?.unsubscribe();
  }

  onGeneratePdf() {
    // prepares frontend path with params (filters out limit/page and empty params)
    const excludedKeysSet = new Set(['limit', 'page']);
    const frontendPath = Object.entries(this.generateUnpaidFeesReqParams())
      .filter(([key, value]) => !excludedKeysSet.has(key) && !!value)
      .reduce(
        (prev, [key, value]) => `${prev}&${key}=${value}`,
        'admin/reports/unpaid-fees?'
      );

    const fileName = 'unpaid-monthly-fees.pdf';
    var a = document.createElement('a');
    a.href = `${environment.BACKEND_URL}/pdf?path=${encodeURIComponent(
      frontendPath
    )}&fileName=${fileName}`;
    this.authService.setTokenInCookie(a.pathname).subscribe(() => a.click());
  }

  private loadOngoingRegistrationsPage(): void {
    this.dataSource.loadUnpaidFees(this.generateUnpaidFeesReqParams());
  }

  private generateUnpaidFeesReqParams(): UnpaidFeeSearch {
    // set value for userType
    let userType;
    if (this.individualsControl.value && !this.businessesControl.value) {
      userType = UserType.PERSONAL;
    } else if (!this.individualsControl.value && this.businessesControl.value) {
      userType = UserType.CORPORATE;
    }

    const params: UnpaidFeeSearch = {
      page: this.paginator.pageIndex + 1, // backend counts pages from 1 and mat-paginator from 0
      limit: this.paginator.pageSize,
      searchText: this.searchControl.value,
      dateFrom: this.startDateControl.value
        ? DateTime.fromISO(this.startDateControl.value).toFormat('yyyy-MM-dd')
        : '',
      dateTo: this.endDateControl.value
        ? DateTime.fromISO(this.endDateControl.value).toFormat('yyyy-MM-dd')
        : '',
      userType,
      orderField: this.sort.active,
      orderDescending: this.sort.direction === 'desc',
    };
    return params;
  }

  get searchControl(): FormControl {
    return this.filtersGroup.get('search') as FormControl;
  }
  get startDateControl(): FormControl {
    return this.filtersGroup.get('startDate') as FormControl;
  }
  get endDateControl(): FormControl {
    return this.filtersGroup.get('endDate') as FormControl;
  }
  get individualsControl(): FormControl {
    return this.filtersGroup.get('individuals') as FormControl;
  }
  get businessesControl(): FormControl {
    return this.filtersGroup.get('businesses') as FormControl;
  }
}
