import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, throwError } from 'rxjs';
import { catchError, first, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/login/services/auth.service';
import { RegistrationService } from 'src/app/login/services/registration.service';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';

@Component({
  templateUrl: './confirm-email.component.html',
})
export class ConfirmEmailComponent implements OnInit {
  isLoading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private errorService: ErrorService,
    private registrationService: RegistrationService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.route.queryParams
      .pipe(
        first(),
        mergeMap(({ token }) =>
          this.registrationService.confirmEmail(token).pipe(
            catchError((err) => {
              if (err.status === 400) {
                this.router.navigate(['login'], {
                  state: { isEmailConfirmationProblem: true },
                });
                return EMPTY;
              } else {
                return throwError(err);
              }
            })
          )
        ),
        tap(({ refresh, token }) =>
          this.authService.saveLoginCredentials(token, refresh)
        ),
        switchMap(() => this.authService.getAuthenticatedUser()) // refreshes user state in the app
      )
      .subscribe(
        () => {
          this.isLoading = false;
          this.router.navigate(['connect-phone']);
        },
        () => {
          this.errorService.showErrorDialog();
          this.isLoading = false;
        }
      );
  }
}
