import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Country } from 'src/app/shared/models/country.model';
import { CorporateForm } from 'src/app/onboarding-new/models/corporate-form.model';
import { PersonalForm } from 'src/app/onboarding-new/models/personal-form.model';
import { adminFormValidator } from 'src/app/shared/admin-form.validator';
import {
  getCountryById,
  getSizeOfWealth,
  getMonthlyIncomeNew,
  getMonthlySpend,
  getNationalityById,
  getStringValue,
} from 'src/app/shared/helpers/various-helpers.helper';
import { OnboardingAdminField } from '../../models/onboarding-admin-field.model';
import { OnboardingFieldId } from '../../models/onboarding-field-id.enum';
import { CompanyAccount } from 'src/app/onboarding-new/models/company-account.model';
import { CompanyCounterparty } from 'src/app/onboarding-new/models/company-counterparty.model';
import { CompanyDirector } from 'src/app/onboarding-new/models/company-director.model';
import { DateTime } from 'luxon';
import { CompanyGroupMember } from 'src/app/onboarding-new/models/company-group-member.model';
import { CompanyCloseLink } from 'src/app/onboarding-new/models/company-close-link.model';
import { CompanyDirectorCloseLink } from 'src/app/onboarding-new/models/company-director-close-link.model';

export function generateAdminCorporateForm(
  fb: FormBuilder,
  corporateForm: CorporateForm,
  fields: OnboardingAdminField[],
  countries: Country[]
): FormGroup {
  return fb.group({
    businessName: [
      {
        value: corporateForm.businessName,
        fieldId: OnboardingFieldId.BUSINESS_NAME,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.BUSINESS_NAME
        ),
      },
      adminFormValidator(),
    ],
    businessTradingName: [
      {
        value: corporateForm.businessTradingName,
        fieldId: OnboardingFieldId.BUSINESS_TRADING_NAME,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.BUSINESS_TRADING_NAME
        ),
      },
      adminFormValidator(),
    ],
    legalStatus: [
      {
        value: corporateForm.legalStatus,
        fieldId: OnboardingFieldId.BUSINESS_LEGAL_STATUS,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.BUSINESS_LEGAL_STATUS
        ),
      },
      adminFormValidator(),
    ],
    registrationNumber: [
      {
        value: corporateForm.registrationNumber,
        fieldId: OnboardingFieldId.BUSINESS_REGISTRATION_NUMBER,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.BUSINESS_REGISTRATION_NUMBER
        ),
      },
      adminFormValidator(),
    ],
    incorporationDate: [
      {
        value: corporateForm.incorporationDate
          ? DateTime.fromISO(corporateForm.incorporationDate).toLocaleString(
              DateTime.DATE_MED
            )
          : null,
        fieldId: OnboardingFieldId.BUSINESS_DATE_OF_INCORPORATION,
        onboardingField: fields.find(
          (field) =>
            field.id === OnboardingFieldId.BUSINESS_DATE_OF_INCORPORATION
        ),
      },
      adminFormValidator(),
    ],
    additionalPhoneNumber: [
      {
        value: corporateForm.additionalPhoneNumber,
        fieldId: OnboardingFieldId.BUSINESS_ADDITIONAL_PHONE_NUMBER,
        onboardingField: fields.find(
          (field) =>
            field.id === OnboardingFieldId.BUSINESS_ADDITIONAL_PHONE_NUMBER
        ),
      },
      adminFormValidator(),
    ],
    incorporationAddress: fb.group({
      streetAddress: [
        {
          value: corporateForm.incorporationAddress?.streetAddress,
          fieldId: OnboardingFieldId.BUSINESS_ADDRESS,
          onboardingField: fields.find(
            (field) => field.id === OnboardingFieldId.BUSINESS_ADDRESS
          ),
        },
        adminFormValidator(),
      ],
      additionalStreetAddress: [
        {
          value: corporateForm.incorporationAddress?.additionalStreetAddress,
          fieldId: OnboardingFieldId.BUSINESS_ADDRESS_2ND_LINE,
          onboardingField: fields.find(
            (field) => field.id === OnboardingFieldId.BUSINESS_ADDRESS_2ND_LINE
          ),
        },
        adminFormValidator(),
      ],
      postCode: [
        {
          value: corporateForm.incorporationAddress?.postCode,
          fieldId: OnboardingFieldId.BUSINESS_ZIP_POSTAL_CODE,
          onboardingField: fields.find(
            (field) => field.id === OnboardingFieldId.BUSINESS_ZIP_POSTAL_CODE
          ),
        },
        adminFormValidator(),
      ],
      city: [
        {
          value: corporateForm.incorporationAddress?.city,
          fieldId: OnboardingFieldId.BUSINESS_CITY,
          onboardingField: fields.find(
            (field) => field.id === OnboardingFieldId.BUSINESS_CITY
          ),
        },
        adminFormValidator(),
      ],
      countryId: [
        {
          value: getCountryById(
            countries,
            corporateForm.incorporationAddress?.countryId
          ),
          fieldId: OnboardingFieldId.BUSINESS_COUNTRY_OF_INCORPORATION,
          onboardingField: fields.find(
            (field) =>
              field.id === OnboardingFieldId.BUSINESS_COUNTRY_OF_INCORPORATION
          ),
        },
        adminFormValidator(),
      ],
    }),
    operatingAddress: fb.group({
      streetAddress: [
        {
          value: corporateForm.operatingAddress?.streetAddress,
          fieldId: OnboardingFieldId.BUSINESS_OPERATING_ADDRESS,
          onboardingField: fields.find(
            (field) => field.id === OnboardingFieldId.BUSINESS_OPERATING_ADDRESS
          ),
        },
        adminFormValidator(),
      ],
      additionalStreetAddress: [
        {
          value: corporateForm.operatingAddress?.additionalStreetAddress,
          fieldId: OnboardingFieldId.BUSINESS_OPERATING_ADDRESS_2ND_LINE,
          onboardingField: fields.find(
            (field) =>
              field.id === OnboardingFieldId.BUSINESS_OPERATING_ADDRESS_2ND_LINE
          ),
        },
        adminFormValidator(),
      ],
      postCode: [
        {
          value: corporateForm.operatingAddress?.postCode,
          fieldId: OnboardingFieldId.BUSINESS_OPERATING_ZIP_POSTAL_CODE,
          onboardingField: fields.find(
            (field) =>
              field.id === OnboardingFieldId.BUSINESS_OPERATING_ZIP_POSTAL_CODE
          ),
        },
        adminFormValidator(),
      ],
      city: [
        {
          value: corporateForm.operatingAddress?.city,
          fieldId: OnboardingFieldId.BUSINESS_OPERATING_CITY,
          onboardingField: fields.find(
            (field) => field.id === OnboardingFieldId.BUSINESS_OPERATING_CITY
          ),
        },
        adminFormValidator(),
      ],
      countryId: [
        {
          value: getCountryById(
            countries,
            corporateForm.operatingAddress?.countryId
          ),
          fieldId: OnboardingFieldId.BUSINESS_OPERATING_COUNTRY,
          onboardingField: fields.find(
            (field) => field.id === OnboardingFieldId.BUSINESS_OPERATING_COUNTRY
          ),
        },
        adminFormValidator(),
      ],
    }),
    taxNumber: [
      {
        value: corporateForm.taxNumber,
        fieldId: OnboardingFieldId.BUSINESS_TAX_NUMBER,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.BUSINESS_TAX_NUMBER
        ),
      },
      adminFormValidator(),
    ],
    vatNumber: [
      {
        value: corporateForm.vatNumber,
        fieldId: OnboardingFieldId.BUSINESS_VAT_NUMBER,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.BUSINESS_VAT_NUMBER
        ),
      },
      adminFormValidator(),
    ],
    companyUrls: [
      {
        value: corporateForm.companyUrls,
        fieldId: OnboardingFieldId.BUSINESS_URLS,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.BUSINESS_URLS
        ),
      },
      adminFormValidator(),
    ],
    companyActivity: [
      {
        value: corporateForm.companyActivity,
        fieldId: OnboardingFieldId.BUSINESS_ACTIVITY,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.BUSINESS_ACTIVITY
        ),
      },
      adminFormValidator(),
    ],
    companyActivityDescr: [
      {
        value: corporateForm.companyActivityDescr,
        fieldId: OnboardingFieldId.BUSINESS_ACTIVITY_DESCR,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.BUSINESS_ACTIVITY_DESCR
        ),
      },
      adminFormValidator(),
    ],
    annualIncome: [
      {
        value: corporateForm.annualIncome
          ? parseInt(corporateForm.annualIncome).toLocaleString('en-US') +
            ' EUR'
          : corporateForm.annualIncome,
        fieldId: OnboardingFieldId.BUSINESS_ANNUAL_INCOME,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.BUSINESS_ANNUAL_INCOME
        ),
      },
      adminFormValidator(),
    ],
    doubleAnnualIncome: [
      {
        value: getStringValue(corporateForm.doubleAnnualIncome),
        fieldId: OnboardingFieldId.BUSINESS_DOUBLE_ANNUAL_INCOME,
        onboardingField: fields.find(
          (field) =>
            field.id === OnboardingFieldId.BUSINESS_DOUBLE_ANNUAL_INCOME
        ),
      },
      adminFormValidator(),
    ],
    percentageOfCash: [
      {
        value: corporateForm.percentageOfCash,
        fieldId: OnboardingFieldId.BUSINESS_PERCENTAGE_OF_CASH,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.BUSINESS_PERCENTAGE_OF_CASH
        ),
      },
      adminFormValidator(),
    ],
    isRegulated: [
      {
        value: getStringValue(corporateForm.isRegulated),
        fieldId: OnboardingFieldId.BUSINESS_IS_REGULATED,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.BUSINESS_IS_REGULATED
        ),
      },
      adminFormValidator(),
    ],
    regulatoryAuthority: [
      {
        value: corporateForm.regulatoryAuthority,
        fieldId: OnboardingFieldId.BUSINESS_REGULATORY_AUTHORITY,
        onboardingField: fields.find(
          (field) =>
            field.id === OnboardingFieldId.BUSINESS_REGULATORY_AUTHORITY
        ),
      },
      adminFormValidator(),
    ],
    operateInHighRiskCountries: [
      {
        value: getStringValue(corporateForm.operateInHighRiskCountries),
        fieldId: OnboardingFieldId.BUSINESS_OPERATE_IN_HIGH_RISK_COUNTRIES,
        onboardingField: fields.find(
          (field) =>
            field.id ===
            OnboardingFieldId.BUSINESS_OPERATE_IN_HIGH_RISK_COUNTRIES
        ),
      },
      adminFormValidator(),
    ],
    canFundFromOwnAccount: [
      {
        value: getStringValue(corporateForm.canFundFromOwnAccount),
        fieldId: OnboardingFieldId.BUSINESS_CAN_FUND_FROM_OWN_ACCOUNT,
        onboardingField: fields.find(
          (field) =>
            field.id === OnboardingFieldId.BUSINESS_CAN_FUND_FROM_OWN_ACCOUNT
        ),
      },
      adminFormValidator(),
    ],
    isFinancialStatementAudited: [
      {
        value: getStringValue(corporateForm.isFinancialStatementAudited),
        fieldId: OnboardingFieldId.BUSINESS_IS_FINANCIAL_STATEMENT_AUDITED,
        onboardingField: fields.find(
          (field) =>
            field.id ===
            OnboardingFieldId.BUSINESS_IS_FINANCIAL_STATEMENT_AUDITED
        ),
      },
      adminFormValidator(),
    ],
    zeroBalanceStatement: [
      {
        value: getStringValue(corporateForm.zeroBalanceStatement),
        fieldId: OnboardingFieldId.BUSINESS_ZERO_BALANCE_STATEMENT,
        onboardingField: fields.find(
          (field) =>
            field.id === OnboardingFieldId.BUSINESS_ZERO_BALANCE_STATEMENT
        ),
      },
      adminFormValidator(),
    ],
    accountPurpose: [
      {
        value: corporateForm.accountPurpose,
        fieldId: OnboardingFieldId.BUSINESS_ACCOUNT_PURPOSE,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.BUSINESS_ACCOUNT_PURPOSE
        ),
      },
      adminFormValidator(),
    ],
    monthlyLoading: [
      {
        value: corporateForm.monthlyLoading
          ? parseInt(corporateForm.monthlyLoading).toLocaleString('en-US') +
            ' EUR'
          : corporateForm.monthlyLoading,
        fieldId: OnboardingFieldId.BUSINESS_MONTHLY_LOADING,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.BUSINESS_MONTHLY_LOADING
        ),
      },
      adminFormValidator(),
    ],
    expectedNumOfPhysicalCards: [
      {
        value: corporateForm.expectedNumOfPhysicalCards,
        fieldId: OnboardingFieldId.BUSINESS_EXPECTED_NUMBER_OF_PHYSICAL_CARDS,
        onboardingField: fields.find(
          (field) =>
            field.id ===
            OnboardingFieldId.BUSINESS_EXPECTED_NUMBER_OF_PHYSICAL_CARDS
        ),
      },
      adminFormValidator(),
    ],
  });
}

export function generateAdminAccountForm(
  fb: FormBuilder,
  accountControl: FormArray,
  companyAccounts: CompanyAccount[],
  fields: OnboardingAdminField[],
  countries: Country[]
) {
  companyAccounts.forEach((account) => {
    accountControl.push(
      fb.group({
        name: [
          {
            id: account.id,
            value: account.name,
            fieldId: OnboardingFieldId.BUSINESS_BANK_PAYMENT_INSTITUTION_NAME,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_BANK_PAYMENT_INSTITUTION_NAME &&
                account.id === field.accountId
            ),
          },
          adminFormValidator(),
        ],
        country: [
          {
            id: account.id,
            value: getCountryById(countries, account.country),
            fieldId:
              OnboardingFieldId.BUSINESS_BANK_PAYMENT_INSTITUTION_COUNTRY,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_BANK_PAYMENT_INSTITUTION_COUNTRY &&
                account.id === field.accountId
            ),
          },
          adminFormValidator(),
        ],
      })
    );
  });
}

export function generateAdminCounterpartyForm(
  fb: FormBuilder,
  counterpartyControl: FormArray,
  companyCounterparties: CompanyCounterparty[],
  fields: OnboardingAdminField[],
  countries: Country[]
) {
  companyCounterparties.forEach((counterparty) => {
    counterpartyControl.push(
      fb.group({
        name: [
          {
            id: counterparty.id,
            value: counterparty.name,
            fieldId: OnboardingFieldId.BUSINESS_COUNTERPARTY_NAME,
            onboardingField: fields.find(
              (field) =>
                field.id === OnboardingFieldId.BUSINESS_COUNTERPARTY_NAME &&
                counterparty.id === field.counterpartyId
            ),
          },
          adminFormValidator(),
        ],
        country: [
          {
            id: counterparty.id,
            value: getCountryById(countries, counterparty.country),
            fieldId: OnboardingFieldId.BUSINESS_COUNTERPARTY_COUNTRY,
            onboardingField: fields.find(
              (field) =>
                field.id === OnboardingFieldId.BUSINESS_COUNTERPARTY_COUNTRY &&
                counterparty.id === field.counterpartyId
            ),
          },
          adminFormValidator(),
        ],
        businessActivity: [
          {
            id: counterparty.id,
            value: counterparty.businessActivity,
            fieldId: OnboardingFieldId.BUSINESS_COUNTERPARTY_BUSINESS_ACTIVITY,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_COUNTERPARTY_BUSINESS_ACTIVITY &&
                counterparty.id === field.counterpartyId
            ),
          },
          adminFormValidator(),
        ],
        isIncomingTransaction: [
          {
            id: counterparty.id,
            value: getStringValue(counterparty.isIncomingTransaction),
            fieldId:
              OnboardingFieldId.BUSINESS_COUNTERPARTY_INCOMING_TRANSACTION,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_COUNTERPARTY_INCOMING_TRANSACTION &&
                counterparty.id === field.counterpartyId
            ),
          },
          adminFormValidator(),
        ],
        isOutgoingTransaction: [
          {
            id: counterparty.id,
            value: getStringValue(counterparty.isOutgoingTransaction),
            fieldId:
              OnboardingFieldId.BUSINESS_COUNTERPARTY_OUTGOING_TRANSACTION,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_COUNTERPARTY_OUTGOING_TRANSACTION &&
                counterparty.id === field.counterpartyId
            ),
          },
          adminFormValidator(),
        ],
      })
    );
  });
}

export function generateAdminDirectorForm(
  fb: FormBuilder,
  directorControl: FormArray,
  companyDirectors: CompanyDirector[],
  fields: OnboardingAdminField[],
  countries: Country[]
) {
  companyDirectors.forEach((director) => {
    directorControl.push(
      fb.group({
        firstName: [
          {
            id: director.id,
            value: director.firstName,
            fieldId: OnboardingFieldId.BUSINESS_KEY_PERSON_FIRST_NAME,
            onboardingField: fields.find(
              (field) =>
                field.id === OnboardingFieldId.BUSINESS_KEY_PERSON_FIRST_NAME &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        lastName: [
          {
            id: director.id,
            value: director.lastName,
            fieldId: OnboardingFieldId.BUSINESS_KEY_PERSON_LAST_NAME,
            onboardingField: fields.find(
              (field) =>
                field.id === OnboardingFieldId.BUSINESS_KEY_PERSON_LAST_NAME &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],

        isDirector: [
          {
            id: director.id,
            value: getStringValue(director.isDirector),
            fieldId: OnboardingFieldId.BUSINESS_KEY_PERSON_DIRECTOR,
            onboardingField: fields.find(
              (field) =>
                field.id === OnboardingFieldId.BUSINESS_KEY_PERSON_DIRECTOR &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        isShareholder: [
          {
            id: director.id,
            value: getStringValue(director.isShareholder),
            fieldId: OnboardingFieldId.BUSINESS_KEY_PERSON_SHAREHOLDER,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_KEY_PERSON_SHAREHOLDER &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        isUBO: [
          {
            id: director.id,
            value: getStringValue(director.isUBO),
            fieldId: OnboardingFieldId.BUSINESS_KEY_PERSON_UBO,
            onboardingField: fields.find(
              (field) =>
                field.id === OnboardingFieldId.BUSINESS_KEY_PERSON_UBO &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        isAuthorizedPerson: [
          {
            id: director.id,
            value: getStringValue(director.isAuthorizedPerson),
            fieldId:
              OnboardingFieldId.BUSINESS_KEY_PERSON_AUTHORIZED_CONTACT_PERSON,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_KEY_PERSON_AUTHORIZED_CONTACT_PERSON &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        percentageOwnedByShareholder: [
          {
            id: director.id,
            value: director.percentageOwnedByShareholder
              ? director.percentageOwnedByShareholder + '%'
              : null,
            fieldId: OnboardingFieldId.BUSINESS_KEY_PERSON_SHAREHOLDER_SHARES,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_KEY_PERSON_SHAREHOLDER_SHARES &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        percentageOwnedByUBO: [
          {
            id: director.id,
            value: director.percentageOwnedByUBO
              ? director.percentageOwnedByUBO + '%'
              : null,
            fieldId: OnboardingFieldId.BUSINESS_KEY_PERSON_UBO_SHARES,
            onboardingField: fields.find(
              (field) =>
                field.id === OnboardingFieldId.BUSINESS_KEY_PERSON_UBO_SHARES &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        email: [
          {
            id: director.id,
            value: director.email,
            fieldId: OnboardingFieldId.BUSINESS_KEY_PERSON_EMAIL,
            onboardingField: fields.find(
              (field) =>
                field.id === OnboardingFieldId.BUSINESS_KEY_PERSON_EMAIL &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        phoneNumber: [
          {
            id: director.id,
            value: director.phoneNumber,
            fieldId: OnboardingFieldId.BUSINESS_KEY_PERSON_PHONE_NUMBER,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_KEY_PERSON_PHONE_NUMBER &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        dateOfBirth: [
          {
            id: director.id,
            value: director.dateOfBirth
              ? DateTime.fromISO(director.dateOfBirth).toLocaleString(
                  DateTime.DATE_MED
                )
              : null,
            fieldId: OnboardingFieldId.BUSINESS_KEY_PERSON_DATE_OF_BIRTH,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_KEY_PERSON_DATE_OF_BIRTH &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        nationality: [
          {
            id: director.id,
            value: getNationalityById(countries, director.nationality),
            fieldId: OnboardingFieldId.BUSINESS_KEY_PERSON_NATIONALITY,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_KEY_PERSON_NATIONALITY &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        streetAddress: [
          {
            id: director.id,
            value: director.address?.streetAddress,
            fieldId: OnboardingFieldId.BUSINESS_KEY_PERSON_ADDRESS,
            onboardingField: fields.find(
              (field) =>
                field.id === OnboardingFieldId.BUSINESS_KEY_PERSON_ADDRESS &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        additionalStreetAddress: [
          {
            id: director.id,
            value: director.address?.additionalStreetAddress,
            fieldId: OnboardingFieldId.BUSINESS_KEY_PERSON_ADDRESS_2ND_LINE,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_KEY_PERSON_ADDRESS_2ND_LINE &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        postCode: [
          {
            id: director.id,
            value: director.address?.postCode,
            fieldId: OnboardingFieldId.BUSINESS_KEY_PERSON_ZIP_POSTAL_CODE,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_KEY_PERSON_ZIP_POSTAL_CODE &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        city: [
          {
            id: director.id,
            value: director.address?.city,
            fieldId: OnboardingFieldId.BUSINESS_KEY_PERSON_CITY,
            onboardingField: fields.find(
              (field) =>
                field.id === OnboardingFieldId.BUSINESS_KEY_PERSON_CITY &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        countryId: [
          {
            id: director.id,
            value: getCountryById(countries, director.address?.countryId),
            fieldId: OnboardingFieldId.BUSINESS_KEY_PERSON_COUNTRY,
            onboardingField: fields.find(
              (field) =>
                field.id === OnboardingFieldId.BUSINESS_KEY_PERSON_COUNTRY &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        taxNumber: [
          {
            id: director.id,
            value: director.taxNumber,
            fieldId: OnboardingFieldId.BUSINESS_KEY_PERSON_TAX_NUMBER,
            onboardingField: fields.find(
              (field) =>
                field.id === OnboardingFieldId.BUSINESS_KEY_PERSON_TAX_NUMBER &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        taxResidencyCountry: [
          {
            id: director.id,
            value: getCountryById(countries, director.taxResidencyCountry),
            fieldId:
              OnboardingFieldId.BUSINESS_KEY_PERSON_TAX_RESIDENCY_COUNTRY,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_KEY_PERSON_TAX_RESIDENCY_COUNTRY &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        isPEP: [
          {
            id: director.id,
            value: getStringValue(director.isPEP),
            fieldId: OnboardingFieldId.BUSINESS_KEY_PERSON_IS_PEP,
            onboardingField: fields.find(
              (field) =>
                field.id === OnboardingFieldId.BUSINESS_KEY_PERSON_IS_PEP &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        legalPersonName: [
          {
            id: director.id,
            value: director.legalPersonName,
            fieldId: OnboardingFieldId.BUSINESS_KEY_PERSON_LEGAL_PERSON_NAME,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_KEY_PERSON_LEGAL_PERSON_NAME &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        legalPersonRegistrationNumber: [
          {
            id: director.id,
            value: director.legalPersonRegistrationNumber,
            fieldId: OnboardingFieldId.BUSINESS_KEY_PERSON_REGISTRATION_NUMBER,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_KEY_PERSON_REGISTRATION_NUMBER &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        legalPersonIncorporationDate: [
          {
            id: director.id,
            value: director.legalPersonIncorporationDate
              ? DateTime.fromISO(
                  director.legalPersonIncorporationDate
                ).toLocaleString(DateTime.DATE_MED)
              : null,
            fieldId:
              OnboardingFieldId.BUSINESS_KEY_PERSON_DATE_OF_INCORPORATION,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_KEY_PERSON_DATE_OF_INCORPORATION &&
                director.id === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        onboardingVerificationDone: [
          {
            id: director.id,
            value: getStringValue(director.onboardingVerificationDone),
            fieldId: null,
            onboardingField: null,
          },
        ],
      })
    );
  });
}

export function generateAdminCloseLinkForm(
  fb: FormBuilder,
  closeLinkControl: FormArray,
  companyCloseLinks: CompanyCloseLink[],
  fields: OnboardingAdminField[],
  countries: Country[]
) {
  companyCloseLinks.forEach((closeLink) => {
    closeLinkControl.push(
      fb.group({
        name: [
          {
            id: closeLink.id,
            value: closeLink.name,
            fieldId: OnboardingFieldId.BUSINESS_CLOSE_LINK_NAME,
            onboardingField: fields.find(
              (field) =>
                field.id === OnboardingFieldId.BUSINESS_CLOSE_LINK_NAME &&
                closeLink.id === field.closeLinkId
            ),
          },
          adminFormValidator(),
        ],
        registrationNumber: [
          {
            id: closeLink.id,
            value: closeLink.registrationNumber,
            fieldId: OnboardingFieldId.BUSINESS_CLOSE_LINK_REGISTRATION_NUMBER,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_CLOSE_LINK_REGISTRATION_NUMBER &&
                closeLink.id === field.closeLinkId
            ),
          },
          adminFormValidator(),
        ],
        incorporationDate: [
          {
            id: closeLink.id,
            value: DateTime.fromISO(closeLink.incorporationDate).toLocaleString(
              DateTime.DATE_MED
            ),
            fieldId:
              OnboardingFieldId.BUSINESS_CLOSE_LINK_DATE_OF_INCORPORATION,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_CLOSE_LINK_DATE_OF_INCORPORATION &&
                closeLink.id === field.closeLinkId
            ),
          },
          adminFormValidator(),
        ],
        address: fb.group({
          streetAddress: [
            {
              id: closeLink.id,
              value: closeLink.address?.streetAddress,
              fieldId: OnboardingFieldId.BUSINESS_CLOSE_LINK_ADDRESS,
              onboardingField: fields.find(
                (field) =>
                  field.id === OnboardingFieldId.BUSINESS_CLOSE_LINK_ADDRESS &&
                  closeLink.id === field.closeLinkId
              ),
            },
            adminFormValidator(),
          ],
          additionalStreetAddress: [
            {
              id: closeLink.id,
              value: closeLink.address?.additionalStreetAddress,
              fieldId: OnboardingFieldId.BUSINESS_CLOSE_LINK_ADDRESS_2ND_LINE,
              onboardingField: fields.find(
                (field) =>
                  field.id ===
                    OnboardingFieldId.BUSINESS_CLOSE_LINK_ADDRESS_2ND_LINE &&
                  closeLink.id === field.closeLinkId
              ),
            },
            adminFormValidator(),
          ],
          postCode: [
            {
              id: closeLink.id,
              value: closeLink.address?.postCode,
              fieldId: OnboardingFieldId.BUSINESS_CLOSE_LINK_ZIP_POSTAL_CODE,
              onboardingField: fields.find(
                (field) =>
                  field.id ===
                    OnboardingFieldId.BUSINESS_CLOSE_LINK_ZIP_POSTAL_CODE &&
                  closeLink.id === field.closeLinkId
              ),
            },
            adminFormValidator(),
          ],
          city: [
            {
              id: closeLink.id,
              value: closeLink.address?.city,
              fieldId: OnboardingFieldId.BUSINESS_CLOSE_LINK_CITY,
              onboardingField: fields.find(
                (field) =>
                  field.id === OnboardingFieldId.BUSINESS_CLOSE_LINK_CITY &&
                  closeLink.id === field.closeLinkId
              ),
            },
            adminFormValidator(),
          ],
          countryId: [
            {
              id: closeLink.id,
              value: getCountryById(countries, closeLink.address?.countryId),
              fieldId: OnboardingFieldId.BUSINESS_CLOSE_LINK_COUNTRY,
              onboardingField: fields.find(
                (field) =>
                  field.id === OnboardingFieldId.BUSINESS_CLOSE_LINK_COUNTRY &&
                  closeLink.id === field.closeLinkId
              ),
            },
            adminFormValidator(),
          ],
        }),
      })
    );
  });
}

export function generateAdminCloseLinkCombinationsForm(
  fb: FormBuilder,
  companyCloseLinks: CompanyCloseLink[],
  directorCloseLinkControl: FormArray,
  companyDirectorCloseLinks: CompanyDirectorCloseLink[],
  fields: OnboardingAdminField[],
  countries: Country[]
) {
  companyDirectorCloseLinks.forEach((directorCloseLink) => {
    const closeLink = companyCloseLinks.find(
      (c) => c.id === directorCloseLink.closeLinkId
    );
    directorCloseLinkControl.push(
      fb.group({
        name: [
          {
            id: directorCloseLink.directorId,
            closeLinkId: directorCloseLink.closeLinkId,
            value: closeLink?.name,
            fieldId: OnboardingFieldId.BUSINESS_CLOSE_LINK_NAME,
            onboardingField: {
              // only for display (not to comment)
              id: null,
              comment: null,
              oldcomment: null,
              accountId: null,
              counterpartyId: null,
              groupMemberId: null,
              closeLinkId: null,
            },
          },
          adminFormValidator(),
        ],

        registrationNumber: [
          {
            id: directorCloseLink.directorId,
            closeLinkId: directorCloseLink.closeLinkId,
            value: closeLink?.registrationNumber,
            fieldId: OnboardingFieldId.BUSINESS_CLOSE_LINK_REGISTRATION_NUMBER,
            onboardingField: {
              // only for display (not to comment)
              id: null,
              comment: null,
              oldcomment: null,
              accountId: null,
              counterpartyId: null,
              groupMemberId: null,
              closeLinkId: null,
            },
          },
          adminFormValidator(),
        ],
        incorporationDate: [
          {
            id: directorCloseLink.directorId,
            closeLinkId: directorCloseLink.closeLinkId,
            value: closeLink?.incorporationDate
              ? DateTime.fromISO(closeLink?.incorporationDate).toLocaleString(
                  DateTime.DATE_MED
                )
              : null,
            fieldId:
              OnboardingFieldId.BUSINESS_CLOSE_LINK_DATE_OF_INCORPORATION,
            onboardingField: {
              // only for display (not to comment)
              id: null,
              comment: null,
              oldcomment: null,
              accountId: null,
              counterpartyId: null,
              groupMemberId: null,
              closeLinkId: null,
            },
          },
          adminFormValidator(),
        ],
        address: fb.group({
          streetAddress: [
            {
              id: directorCloseLink.directorId,
              closeLinkId: directorCloseLink.closeLinkId,
              value: closeLink?.address?.streetAddress,
              fieldId: OnboardingFieldId.BUSINESS_CLOSE_LINK_ADDRESS,
              onboardingField: {
                // only for display (not to comment)
                id: null,
                comment: null,
                oldcomment: null,
                accountId: null,
                counterpartyId: null,
                groupMemberId: null,
                closeLinkId: null,
              },
            },
            adminFormValidator(),
          ],
          additionalStreetAddress: [
            {
              id: directorCloseLink.directorId,
              closeLinkId: directorCloseLink.closeLinkId,
              value: closeLink?.address?.additionalStreetAddress,
              fieldId: OnboardingFieldId.BUSINESS_CLOSE_LINK_ADDRESS_2ND_LINE,
              onboardingField: {
                // only for display (not to comment)
                id: null,
                comment: null,
                oldcomment: null,
                accountId: null,
                counterpartyId: null,
                groupMemberId: null,
                closeLinkId: null,
              },
            },
            adminFormValidator(),
          ],
          postCode: [
            {
              id: directorCloseLink.directorId,
              closeLinkId: directorCloseLink.closeLinkId,
              value: closeLink?.address?.postCode,
              fieldId: OnboardingFieldId.BUSINESS_CLOSE_LINK_ZIP_POSTAL_CODE,
              onboardingField: {
                // only for display (not to comment)
                id: null,
                comment: null,
                oldcomment: null,
                accountId: null,
                counterpartyId: null,
                groupMemberId: null,
                closeLinkId: null,
              },
            },
            adminFormValidator(),
          ],
          city: [
            {
              id: directorCloseLink.directorId,
              closeLinkId: directorCloseLink.closeLinkId,
              value: closeLink?.address?.city,
              fieldId: OnboardingFieldId.BUSINESS_CLOSE_LINK_CITY,
              onboardingField: {
                // only for display (not to comment)
                id: null,
                comment: null,
                oldcomment: null,
                accountId: null,
                counterpartyId: null,
                groupMemberId: null,
                closeLinkId: null,
              },
            },
            adminFormValidator(),
          ],
          countryId: [
            {
              id: directorCloseLink.directorId,
              closeLinkId: directorCloseLink.closeLinkId,
              value: getCountryById(countries, closeLink?.address?.countryId),
              fieldId: OnboardingFieldId.BUSINESS_CLOSE_LINK_COUNTRY,
              onboardingField: {
                // only for display (not to comment)
                id: null,
                comment: null,
                oldcomment: null,
                accountId: null,
                counterpartyId: null,
                groupMemberId: null,
                closeLinkId: null,
              },
            },
            adminFormValidator(),
          ],
        }),
        isDirector: [
          {
            id: directorCloseLink.directorId,
            closeLinkId: directorCloseLink.closeLinkId,
            value: getStringValue(directorCloseLink.isDirector),
            fieldId: OnboardingFieldId.BUSINESS_CLOSE_LINK_DIRECTOR,
            onboardingField: fields.find(
              (field) =>
                field.id === OnboardingFieldId.BUSINESS_CLOSE_LINK_DIRECTOR &&
                directorCloseLink.closeLinkId === field.closeLinkId &&
                directorCloseLink.directorId === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        isShareholder: [
          {
            id: directorCloseLink.directorId,
            closeLinkId: directorCloseLink.closeLinkId,
            value: getStringValue(directorCloseLink.isShareholder),
            fieldId: OnboardingFieldId.BUSINESS_CLOSE_LINK_SHAREHOLDER,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_CLOSE_LINK_SHAREHOLDER &&
                directorCloseLink.closeLinkId === field.closeLinkId &&
                directorCloseLink.directorId === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        isUBO: [
          {
            id: directorCloseLink.directorId,
            closeLinkId: directorCloseLink.closeLinkId,
            value: getStringValue(directorCloseLink.isUBO),
            fieldId: OnboardingFieldId.BUSINESS_CLOSE_LINK_UBO,
            onboardingField: fields.find(
              (field) =>
                field.id === OnboardingFieldId.BUSINESS_CLOSE_LINK_UBO &&
                directorCloseLink.closeLinkId === field.closeLinkId &&
                directorCloseLink.directorId === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        percentageOwnedByShareholder: [
          {
            id: directorCloseLink.directorId,
            closeLinkId: directorCloseLink.closeLinkId,
            value: directorCloseLink.percentageOwnedByShareholder
              ? directorCloseLink.percentageOwnedByShareholder + '%'
              : null,
            fieldId: OnboardingFieldId.BUSINESS_CLOSE_LINK_SHAREHOLDER_SHARES,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_CLOSE_LINK_SHAREHOLDER_SHARES &&
                directorCloseLink.closeLinkId === field.closeLinkId &&
                directorCloseLink.directorId === field.directorId
            ),
          },
          adminFormValidator(),
        ],
        percentageOwnedByUBO: [
          {
            id: directorCloseLink.directorId,
            closeLinkId: directorCloseLink.closeLinkId,
            value: directorCloseLink.percentageOwnedByUBO
              ? directorCloseLink.percentageOwnedByUBO + '%'
              : null,
            fieldId: OnboardingFieldId.BUSINESS_CLOSE_LINK_UBO_SHARES,
            onboardingField: fields.find(
              (field) =>
                field.id === OnboardingFieldId.BUSINESS_CLOSE_LINK_UBO_SHARES &&
                directorCloseLink.closeLinkId === field.closeLinkId &&
                directorCloseLink.directorId === field.directorId
            ),
          },
          adminFormValidator(),
        ],
      })
    );
  });
}

export function generateAdminGroupMemberForm(
  fb: FormBuilder,
  groupMemberControl: FormArray,
  companyGroupMembers: CompanyGroupMember[],
  fields: OnboardingAdminField[],
  countries: Country[]
) {
  companyGroupMembers.forEach((groupMember) => {
    groupMemberControl.push(
      fb.group({
        name: [
          {
            id: groupMember.id,
            value: groupMember.name,
            fieldId: OnboardingFieldId.BUSINESS_GROUP_MEMBER_NAME,
            onboardingField: fields.find(
              (field) =>
                field.id === OnboardingFieldId.BUSINESS_GROUP_MEMBER_NAME &&
                groupMember.id === field.groupMemberId
            ),
          },
          adminFormValidator(),
        ],
        registrationNumber: [
          {
            id: groupMember.id,
            value: groupMember.registrationNumber,
            fieldId:
              OnboardingFieldId.BUSINESS_GROUP_MEMBER_REGISTRATION_NUMBER,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_GROUP_MEMBER_REGISTRATION_NUMBER &&
                groupMember.id === field.groupMemberId
            ),
          },
          adminFormValidator(),
        ],
        incorporationDate: [
          {
            id: groupMember.id,
            value: groupMember.incorporationDate
              ? DateTime.fromISO(groupMember.incorporationDate).toLocaleString(
                  DateTime.DATE_MED
                )
              : null,
            fieldId:
              OnboardingFieldId.BUSINESS_GROUP_MEMBER_DATE_OF_INCORPORATION,
            onboardingField: fields.find(
              (field) =>
                field.id ===
                  OnboardingFieldId.BUSINESS_GROUP_MEMBER_DATE_OF_INCORPORATION &&
                groupMember.id === field.groupMemberId
            ),
          },
          adminFormValidator(),
        ],
        address: fb.group({
          streetAddress: [
            {
              id: groupMember.id,
              value: groupMember.address?.streetAddress,
              fieldId: OnboardingFieldId.BUSINESS_GROUP_MEMBER_ADDRESS,
              onboardingField: fields.find(
                (field) =>
                  field.id ===
                    OnboardingFieldId.BUSINESS_GROUP_MEMBER_ADDRESS &&
                  groupMember.id === field.groupMemberId
              ),
            },
            adminFormValidator(),
          ],
          additionalStreetAddress: [
            {
              id: groupMember.id,
              value: groupMember.address?.additionalStreetAddress,
              fieldId: OnboardingFieldId.BUSINESS_GROUP_MEMBER_ADDRESS_2ND_LINE,
              onboardingField: fields.find(
                (field) =>
                  field.id ===
                    OnboardingFieldId.BUSINESS_GROUP_MEMBER_ADDRESS_2ND_LINE &&
                  groupMember.id === field.groupMemberId
              ),
            },
            adminFormValidator(),
          ],
          postCode: [
            {
              id: groupMember.id,
              value: groupMember.address?.postCode,
              fieldId: OnboardingFieldId.BUSINESS_GROUP_MEMBER_ZIP_POSTAL_CODE,
              onboardingField: fields.find(
                (field) =>
                  field.id ===
                    OnboardingFieldId.BUSINESS_GROUP_MEMBER_ZIP_POSTAL_CODE &&
                  groupMember.id === field.groupMemberId
              ),
            },
            adminFormValidator(),
          ],
          city: [
            {
              id: groupMember.id,
              value: groupMember.address?.city,
              fieldId: OnboardingFieldId.BUSINESS_GROUP_MEMBER_CITY,
              onboardingField: fields.find(
                (field) =>
                  field.id === OnboardingFieldId.BUSINESS_GROUP_MEMBER_CITY &&
                  groupMember.id === field.groupMemberId
              ),
            },
            adminFormValidator(),
          ],
          countryId: [
            {
              id: groupMember.id,
              value: getCountryById(countries, groupMember.address?.countryId),
              fieldId: OnboardingFieldId.BUSINESS_GROUP_MEMBER_COUNTRY,
              onboardingField: fields.find(
                (field) =>
                  field.id ===
                    OnboardingFieldId.BUSINESS_GROUP_MEMBER_COUNTRY &&
                  groupMember.id === field.groupMemberId
              ),
            },
            adminFormValidator(),
          ],
        }),
      })
    );
  });
}

export function generateAdminPersonalForm(
  fb: FormBuilder,
  personalForm: PersonalForm,
  fields: OnboardingAdminField[],
  countries: Country[]
): FormGroup {
  return fb.group({
    firstName: [
      {
        value: personalForm.firstName,
        fieldId: OnboardingFieldId.PERSONAL_FIRST_NAME,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.PERSONAL_FIRST_NAME
        ),
      },
      adminFormValidator(),
    ],
    lastName: [
      {
        value: personalForm.lastName,
        fieldId: OnboardingFieldId.PERSONAL_LAST_NAME,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.PERSONAL_LAST_NAME
        ),
      },
      adminFormValidator(),
    ],
    dateOfBirth: [
      {
        value: personalForm.dateOfBirth
          ? DateTime.fromISO(personalForm.dateOfBirth).toLocaleString(
              DateTime.DATE_MED
            )
          : null,
        fieldId: OnboardingFieldId.PERSONAL_DATE_OF_BIRTH,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.PERSONAL_DATE_OF_BIRTH
        ),
      },
      adminFormValidator(),
    ],
    countryOfBirth: [
      {
        value: getCountryById(countries, personalForm.countryOfBirth),
        fieldId: OnboardingFieldId.PERSONAL_COUNTRY_OF_BIRTH,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.PERSONAL_COUNTRY_OF_BIRTH
        ),
      },
      adminFormValidator(),
    ],
    nationality: [
      {
        value: getNationalityById(countries, personalForm.nationality),
        fieldId: OnboardingFieldId.PERSONAL_NATIONALITY,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.PERSONAL_NATIONALITY
        ),
      },
      adminFormValidator(),
    ],
    address: fb.group({
      streetAddress: [
        {
          value: personalForm.address?.streetAddress,
          fieldId: OnboardingFieldId.PERSONAL_ADDRESS,
          onboardingField: fields.find(
            (field) => field.id === OnboardingFieldId.PERSONAL_ADDRESS
          ),
        },
        adminFormValidator(),
      ],
      additionalStreetAddress: [
        {
          value: personalForm.address?.additionalStreetAddress,
          fieldId: OnboardingFieldId.PERSONAL_ADDRESS_2ND_LINE,
          onboardingField: fields.find(
            (field) => field.id === OnboardingFieldId.PERSONAL_ADDRESS_2ND_LINE
          ),
        },
        adminFormValidator(),
      ],
      postCode: [
        {
          value: personalForm.address?.postCode,
          fieldId: OnboardingFieldId.PERSONAL_ZIP_POSTAL_CODE,
          onboardingField: fields.find(
            (field) => field.id === OnboardingFieldId.PERSONAL_ZIP_POSTAL_CODE
          ),
        },
        adminFormValidator(),
      ],
      city: [
        {
          value: personalForm.address?.city,
          fieldId: OnboardingFieldId.PERSONAL_CITY,
          onboardingField: fields.find(
            (field) => field.id === OnboardingFieldId.PERSONAL_CITY
          ),
        },
        adminFormValidator(),
      ],
      countryId: [
        {
          value: getCountryById(countries, personalForm.address?.countryId),
          fieldId: OnboardingFieldId.PERSONAL_COUNTRY,
          onboardingField: fields.find(
            (field) => field.id === OnboardingFieldId.PERSONAL_COUNTRY
          ),
        },
        adminFormValidator(),
      ],
    }),
    additionalPhoneNumber: [
      {
        value: personalForm.additionalPhoneNumber,
        fieldId: OnboardingFieldId.PERSONAL_ADDITIONAL_PHONE_NUMBER,
        onboardingField: fields.find(
          (field) =>
            field.id === OnboardingFieldId.PERSONAL_ADDITIONAL_PHONE_NUMBER
        ),
      },
      adminFormValidator(),
    ],
    additionalEmail: [
      {
        value: personalForm.additionalEmail,
        fieldId: OnboardingFieldId.PERSONAL_ADDITIONAL_EMAIL,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.PERSONAL_ADDITIONAL_EMAIL
        ),
      },
      adminFormValidator(),
    ],
    dataOccupationProfession: [
      {
        value: personalForm.dataOccupationProfession,
        fieldId: OnboardingFieldId.PERSONAL_DATA_OCCUPATION_PROFESSION,
        onboardingField: fields.find(
          (field) =>
            field.id === OnboardingFieldId.PERSONAL_DATA_OCCUPATION_PROFESSION
        ),
      },
      adminFormValidator(),
    ],
    dataOccupation: [
      {
        value: personalForm.dataOccupation,
        fieldId: OnboardingFieldId.PERSONAL_DATA_OCCUPATION,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.PERSONAL_DATA_OCCUPATION
        ),
      },
      adminFormValidator(),
    ],
    dataCardPurpose: [
      {
        value: personalForm.dataCardPurpose,
        fieldId: OnboardingFieldId.PERSONAL_DATA_CARD_PURPOSE,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.PERSONAL_DATA_CARD_PURPOSE
        ),
      },
      adminFormValidator(),
    ],
    dataMonthlyIncome: [
      {
        value: getMonthlyIncomeNew(personalForm.dataMonthlyIncome),
        fieldId: OnboardingFieldId.PERSONAL_DATA_MONTHLY_INCOME,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.PERSONAL_DATA_MONTHLY_INCOME
        ),
      },
      adminFormValidator(),
    ],
    dataMonthlySpend: [
      {
        value: getMonthlySpend(personalForm.dataMonthlySpend),
        fieldId: OnboardingFieldId.PERSONAL_DATA_MONTHLY_SPEND,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.PERSONAL_DATA_MONTHLY_SPEND
        ),
      },
      adminFormValidator(),
    ],
    isPEP: [
      {
        value: getStringValue(personalForm.isPEP),
        fieldId: OnboardingFieldId.PERSONAL_DATA_IS_PEP,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.PERSONAL_DATA_IS_PEP
        ),
      },
      adminFormValidator(),
    ],
    referee: [
      {
        value: personalForm.referee,
        fieldId: OnboardingFieldId.PERSONAL_EMAIL_OF_REFEREE,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.PERSONAL_EMAIL_OF_REFEREE
        ),
      },
      adminFormValidator(),
    ],
    heardFrom: [
      {
        value: personalForm.heardFrom,
        fieldId: OnboardingFieldId.PERSONAL_HEARD_FROM,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.PERSONAL_HEARD_FROM
        ),
      },
      adminFormValidator(),
    ],
    dataSourceOfWealth: [
      {
        value: personalForm.dataSourceOfWealth,
        fieldId: OnboardingFieldId.PERSONAL_DATA_SOURCE_OF_WEALTH,
        onboardingField: fields.find(
          (field) =>
            field.id === OnboardingFieldId.PERSONAL_DATA_SOURCE_OF_WEALTH
        ),
      },
      adminFormValidator(),
    ],
    countryOfSourceOfWealth: [
      {
        value: getCountryById(countries, personalForm.countryOfSourceOfWealth),
        fieldId: OnboardingFieldId.PERSONAL_DATA_COUNTRY_OF_SOURCE_OF_WEALTH,
        onboardingField: fields.find(
          (field) =>
            field.id ===
            OnboardingFieldId.PERSONAL_DATA_COUNTRY_OF_SOURCE_OF_WEALTH
        ),
      },
      adminFormValidator(),
    ],
    dataSourceOfIncome: [
      {
        value: personalForm.dataSourceOfIncome,
        fieldId: OnboardingFieldId.PERSONAL_DATA_SOURCE_OF_INCOME,
        onboardingField: fields.find(
          (field) =>
            field.id === OnboardingFieldId.PERSONAL_DATA_SOURCE_OF_INCOME
        ),
      },
      adminFormValidator(),
    ],
    countryOfSourceOfIncome: [
      {
        value: getCountryById(countries, personalForm.countryOfSourceOfIncome),
        fieldId: OnboardingFieldId.PERSONAL_DATA_COUNTRY_OF_SOURCE_OF_INCOME,
        onboardingField: fields.find(
          (field) =>
            field.id ===
            OnboardingFieldId.PERSONAL_DATA_COUNTRY_OF_SOURCE_OF_INCOME
        ),
      },
      adminFormValidator(),
    ],
    dataSizeOfWealth: [
      {
        value: getSizeOfWealth(personalForm.dataSizeOfWealth),
        fieldId: OnboardingFieldId.PERSONAL_DATA_SIZE_OF_WEALTH,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.PERSONAL_DATA_SIZE_OF_WEALTH
        ),
      },
      adminFormValidator(),
    ],
    dataTaxNumber: [
      {
        value: personalForm.dataTaxNumber,
        fieldId: OnboardingFieldId.PERSONAL_DATA_TAX_NUMBER,
        onboardingField: fields.find(
          (field) => field.id === OnboardingFieldId.PERSONAL_DATA_TAX_NUMBER
        ),
      },
      adminFormValidator(),
    ],
    dataTaxResidencyCountry: [
      {
        value: getCountryById(countries, personalForm.dataTaxResidencyCountry),
        fieldId: OnboardingFieldId.PERSONAL_DATA_TAX_RESIDENCY_COUNTRY,
        onboardingField: fields.find(
          (field) =>
            field.id === OnboardingFieldId.PERSONAL_DATA_TAX_RESIDENCY_COUNTRY
        ),
      },
      adminFormValidator(),
    ],
  });
}
