import { Component, Input } from '@angular/core';
import { Sections } from '../../../dashboard/shared/sections.enum';
import { convertIban } from '../../../shared/helpers/various-helpers.helper';

@Component({
  selector: 'app-sub-dashboard-header',
  templateUrl: './sub-dashboard-header.component.html',
  styleUrls: ['./sub-dashboard-header.component.scss'],
})
export class SubDashboardHeaderComponent {
  @Input() svgIconName!: string;
  @Input() headerName?: string;
  @Input() isLoadingHeader!: boolean;
  @Input() availableBalance?: number;
  @Input() balance?: number;
  @Input() section?: Sections;
  @Input() accountNumber?: string;
  @Input() expiryDate?: string;
  @Input() cardHolder?: string;
  @Input() isLocked?: boolean;
  sections = Sections;
  convertIban = convertIban;

  constructor() {}
}
