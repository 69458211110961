import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserProfileData } from './models/user-profile-data.model';
import {
  EncryptedDataModel,
  EncryptionService,
} from '../shared/services/encryption.service';
import { SaveRegisteredUser } from '../admin/users/registered-users/models/save-registered-user.model';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  constructor(
    private http: HttpClient,
    private encryptionService: EncryptionService
  ) {}

  getUserProfile(): Observable<UserProfileData> {
    const url = `${environment.BACKEND_URL}/user-profile/profile`;
    return this.http.get<UserProfileData>(url);
  }

  async requestEmailChange(
    email: string,
    password: string
  ): Promise<Observable<void>> {
    const url = `${environment.BACKEND_URL}/user-profile/change-email-requests`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt({ email, password });
    return this.http.post<void>(url, cipherModel);
  }

  confirmEmail(token: string): Observable<{ refresh: string; token: string }> {
    const url = `${environment.BACKEND_URL}/user-profile/change-email-confirmation-requests`;
    return this.http.post<{ refresh: string; token: string }>(url, { token });
  }

  async requestMobilePhoneNumberChange(
    phoneNumber: string,
    password: string
  ): Promise<Observable<void>> {
    const url = `${environment.BACKEND_URL}/user-profile/mobile-phone-change-requests`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt({ phoneNumber, password });
    return this.http.post<void>(url, cipherModel);
  }

  verifyPhoneNumber(otp: string, phoneNumber: string): Observable<void> {
    const url = `${environment.BACKEND_URL}/user-profile/mobile-phone-confirmation-requests`;
    return this.http.post<void>(url, { otp, phoneNumber });
  }

  async requestPasswordChange(
    currentPassword: string,
    newPassword: string
  ): Promise<Observable<void>> {
    const url = `${environment.BACKEND_URL}/user-profile/change-password-requests`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt({ currentPassword, newPassword });
    return this.http.post<void>(url, cipherModel);
  }

  // update marketing consent in user-profile
  updateMarketingConsent(data: { isMarketingConsent?: boolean }) {
    const url = `${environment.BACKEND_URL}/user-profile/update-marketing-consent`;
    return this.http.patch<void>(url, data);
  }

  // update personal data in user-profile
  async updatePersonalForm(registeredUserData: SaveRegisteredUser) {
    const url = `${environment.BACKEND_URL}/user-profile/update-personal-form`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(registeredUserData);
    return this.http.patch<void>(url, cipherModel);
  }

  // update corporate data in user-profile
  async updateCorporateForm(registeredUserData: SaveRegisteredUser) {
    const url = `${environment.BACKEND_URL}/user-profile/update-corporate-form`;
    const cipherModel: EncryptedDataModel | null =
      await this.encryptionService.encrypt(registeredUserData);
    return this.http.patch<void>(url, cipherModel);
  }

  uploadAvatar(file: File): Observable<string> {
    const formData = new FormData();
    formData.append('file', file);
    const url = `${environment.BACKEND_URL}/user-profile/avatar`;
    return this.http
      .put<{ avatarUrl: string }>(url, formData)
      .pipe(map((avatar) => avatar.avatarUrl));
  }

  deleteAvatar(): Observable<void> {
    const url = `${environment.BACKEND_URL}/user-profile/avatar`;
    return this.http.delete<void>(url);
  }

  upgradeAccount(): Observable<void> {
    const url = `${environment.BACKEND_URL}/financial/upgradeAccount`;
    return this.http.post<void>(url, {});
  }
}
